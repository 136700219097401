import "../App.css";
import AppBar from "@mui/material/AppBar";
import { angleOptionMultipliers, useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useSubmitTakeoffAssembly } from "../hooks/useSubmitTakeoffAssembly";
import { useFetchCountedAssemblies } from "../hooks/useFetchCountedAssemblies";
import { useEffect, useRef, useState } from "react";
import { CustomScale } from "./CustomScale";
import { useStore } from "zustand";
import { useGlobalAssemblyStore } from "../states/globalAssemblyStore";
import { SCALE_OPTIONS } from "../utils/constants";
import { ToolBarHelper } from "../hooks/toolbarHooks";
import { useFetchObjects } from "../hooks/objectHooks";
import { DocumentManagerToggle } from "./objectComponents/DocumentManagerToggle";
import { IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Object as ProtoObject } from "../api/protosCompiled/object/object_pb";
import { useKeyBoardEventStore } from "../states/keyEventStore";

type ExtendedObject = ProtoObject.AsObject & {
  previewUrl: string;
};

export const ToolBar = () => {
  const ubs = useStore(useUnityBuildStore);
  const globalAssemblyStore = useStore(useGlobalAssemblyStore);
  const { shiftDown, setShiftDown, ctrlDown } = useStore(useKeyBoardEventStore);

  const { data: fetchedAssemblies } = useFetchCountedAssemblies();
  const { data: availableObjects, refetchObjects } = useFetchObjects();

  const [isCreatingScale, setIsCreatingScale] = useState<boolean>(false);
  const [isViewingFileManager, setIsViewingFileManager] = useState(false);

  const [selectedDocument, setSelectedDocument] =
    useState<ExtendedObject | null>(null);

  const submitAssembly = useSubmitTakeoffAssembly();
  const currentObjectRef = useRef(0);
  const currentPageIndex = ubs.currentPage - 1;

  const [documentsWithTakeoff, setDocumentsWithTakeoff] = useState<string[]>(
    []
  );

  // Update documentsWithTakeoff
  useEffect(() => {
    if (ubs.countedAssemblies) {
      const uniqueObjectIds = ubs.countedAssemblies.map(
        (assembly) => assembly.objectid
      );
      setDocumentsWithTakeoff(uniqueObjectIds);
    }
  }, [ubs.countedAssemblies]);

  // Return true if object id is in the list of documents with takeoff
  const isDocumentWithTakeoff = (id: string) => {
    return documentsWithTakeoff.includes(id);
  };

  // When userScale changes, update assemblies
  useEffect(() => {
    const submitAssembliesWithUpdatedScale = async () => {
      if (fetchedAssemblies && fetchedAssemblies.length > 0 && ubs.userScale) {
        for (const assembly of fetchedAssemblies) {
          if (
            (assembly.assemblymeasurementtype === "length" ||
              assembly.assemblymeasurementtype === "area") &&
            assembly.pagenumber === ubs.currentPage
          ) {
            await submitAssembly({
              assembly,
              isUpdating: true,
            });
          }
        }
      }
      refetchObjects();
    };

    submitAssembliesWithUpdatedScale();
    // eslint-disable-next-line
  }, [ubs.userScale]);

  // Toggle the takeoff palette visibility
  const handleIsViewingTakeoffPallette = () => {
    ubs.setIsViewingTakeoffPallette(!ubs.isViewingTakeoffPallette);
  };

  // Toggle file manager
  const handleFileManager = () => {
    setIsViewingFileManager(!isViewingFileManager);
  };

  // When user selects a doc
  const handleGetObject = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (availableObjects) {
      const selectedObject = await ToolBarHelper.handleGetObject(
        e,
        availableObjects,
        ubs.setSelectedObjectId,
        ubs.setSelectedObjectName,
        ubs.setUserScaleList,
        ubs.setUserScale,
        ubs.numPages,
        currentPageIndex,
        currentObjectRef
      );

      if (selectedObject) {
        setSelectedDocument(selectedObject as ExtendedObject);
      }
    }
  };

  // Open the doc in a new tab
  const handleOpenDocument = () => {
    if (selectedDocument?.previewUrl) {
      window.open(selectedDocument.previewUrl, "_blank", "noopener,noreferrer");
    } else {
      alert("Please select a document to open.");
    }
  };

  // Set scale from a <select>
  const handleSetScale = (e: React.ChangeEvent<HTMLSelectElement>) => {
    ToolBarHelper.handleSetScale(
      e,
      ubs.currentPage,
      ubs.setUserScale,
      setIsCreatingScale,
      ubs.setUserScaleList,
      ubs.selectedObjectId,
      ubs.userScaleList,
      refetchObjects
    );
  };

  // Toggle crosshair
  const handleEnableCrosshair = () => {
    ubs.setEnableCrosshair(!ubs.enableCrosshair);
  };

  // Start/stop measuring
  const handleMeasure = () => {
    ResetStates.resetTakeoffStates(ubs, globalAssemblyStore);
    ubs.setIsAnnotating(!ubs.isAnnotating);
    ubs.setIsMeasuring(!ubs.isMeasuring);
  };

  // Angle snaps
  const handleSetSnaps = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let angleOption = parseInt(e.target.value, 10);
    const angleOptions = Array.from(
      { length: 360 / angleOption },
      (_, i) => i * angleOption
    );
    ubs.setAngleOptions(angleOptions);
  };

  // Our toggle for "continuous mode" or "non-continuous mode"
  const handleToggleContinuousMode = () => {
    setShiftDown(!shiftDown); // flip it in the Zustand store
  };

  return (
    <>
      <AppBar position="relative">
        <div
          className="toolbar"
          style={{ backgroundColor: ubs.userBackgroundColor }}
        >
          <label
            key="drawings"
            style={{ marginRight: "5px", marginLeft: "5px" }}
          >
            <button onClick={handleFileManager}>Document:</button>
          </label>
          <select
            onChange={handleGetObject}
            style={{ margin: "1px", cursor: "pointer", width: "200px" }}
            defaultValue=""
          >
            <option value="" disabled>
              Select a document
            </option>
            {availableObjects &&
              availableObjects.map((option, index) => (
                <option
                  key={index}
                  value={`${option.id},${option.gcsobjectname}`}
                  style={{
                    backgroundColor: isDocumentWithTakeoff(option.id)
                      ? "lightYellow"
                      : "white",
                    padding: "15px 10px",
                  }}
                >
                  {option.objectname}
                </option>
              ))}
          </select>
          {selectedDocument ? (
            <IconButton
              onClick={handleOpenDocument}
              style={{ marginLeft: "5px" }}
              aria-label="Open document"
            >
              <OpenInNewIcon />
            </IconButton>
          ) : null}
          <label key="Scale" style={{ marginRight: "5px", marginLeft: "5px" }}>
            Scale:
            <select
              onChange={handleSetScale}
              value={ubs.userScale}
              style={{ margin: "1px", cursor: "pointer" }}
            >
              {SCALE_OPTIONS.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
          <button
            onClick={handleEnableCrosshair}
            className="button"
            style={{ color: ubs.enableCrosshair ? "blue" : "black" }}
          >
            Crosshair
          </button>
          <button
            onClick={handleMeasure}
            className="button"
            style={{ color: ubs.isMeasuring ? "blue" : "black" }}
          >
            Measure
          </button>

          <label style={{ marginRight: "5px", marginLeft: "5px" }}>
            Angle Snap:
            <select
              onChange={handleSetSnaps}
              style={{
                marginLeft: "10px",
                backgroundColor: ctrlDown ? "green" : "white",
              }}
            >
              {angleOptionMultipliers.map((angle) => (
                <option key={angle} value={angle}>
                  {angle}
                </option>
              ))}
            </select>
          </label>

          {/* Toggle continuous mode */}
          <button onClick={handleToggleContinuousMode}>
            {!shiftDown ? "o / o" : "o-o-o"}
          </button>
          <span>{!shiftDown ? "Non-Continuous Mode" : "Continuous Mode"}</span>
          <button
            onClick={handleIsViewingTakeoffPallette}
            style={{ position: "absolute", right: "20px" }}
          >
            {ubs.isViewingTakeoffPallette ? "Hide" : "Show"} Takeoff Pallette
          </button>
        </div>
        {isCreatingScale && <CustomScale />}
      </AppBar>

      {isViewingFileManager && (
        <DocumentManagerToggle onClose={() => setIsViewingFileManager(false)} />
      )}
    </>
  );
};
