import { totalPricingDefaults } from "../components/closeout/closeoutTypes";
import { defaultFolder } from "../types/FolderTypes";
import { CloseoutStore } from "./closeoutStore";
import { FolderStore } from "./folderStore";
import { GlobalAssemblyStore } from "./globalAssemblyStore";
import { GlobalItemStore } from "./globalItemStore";
import { InferenceStore } from "./inferenceStore";
import {
  defaultChangeOrder,
  defaultContract,
  defaultEstimate,
  defaultGlobalAssembly,
  EstimateStore,
} from "./store";

export namespace ResetStates {
  export function resetTakeoffStates(
    estimateStore: EstimateStore,
    globalAssemblyStore: GlobalAssemblyStore
  ) {
    estimateStore.setIsCounting(false);
    estimateStore.setIsMeasuring(false);
    estimateStore.setMeasuredPoints([]);
    estimateStore.setPointIsSelected(false);
    estimateStore.setIsGettingLength(false);
    estimateStore.setIsGettingArea(false);
    estimateStore.setIsAnnotating(false);
    estimateStore.setIsCreatingTakeoffAssembly(false);
    estimateStore.setIsUpdatingTakeoffAssembly(false);
    estimateStore.setSelectedTotalLength(0);
    estimateStore.setTemporaryPoints([]);
    estimateStore.setIsDragging(false);
    estimateStore.setSelectedTakeoffAssembly(null);
    estimateStore.setIsEditingPoint({
      isEditingPoint: false,
      isEditingStyle: false,
      assemblyIndex: null,
      pointIndex: 0,
      x: null,
      y: null,
    });
    estimateStore.setIsEditingPointStyle({
      isEditingPointFillColor: false,
      isEditingPointBorderColor: false,
      isEditingLineColor: false,
    });
    estimateStore.setIsViewingExtension(false);
    estimateStore.setIncludeAllPoints(false);
    estimateStore.setIsInsertingPoint(false);
    globalAssemblyStore.setCurrentAssembly(defaultGlobalAssembly);
  }

  export function resetMasterProjectViewStates(
    store: EstimateStore,
    globalAssemblyStore: GlobalAssemblyStore,
    store2: InferenceStore
  ) {
    store.setSelectedObjectName("");
    store.setIsOpeningViewport(false);
    store.setIsCreatingChangeOrder(false);
    store.setIsCreatingEstimate(false);
    store.setSelectedChangeOrder(defaultChangeOrder);
    store.setSelectedEstimate(defaultEstimate);
    store.setSelectedContract(defaultContract);
    store.setSelectedChangeOrder(defaultChangeOrder);
    store.setFilteredCountedAssemblies([]);
    store.setSelectedObjectId("");
    globalAssemblyStore.setCurrentAssembly(defaultGlobalAssembly);
    store2.setInferenceCountObjects([]);
  }

  export function resetCreateAssemblyStatesAndClose(
    assemblyStore: GlobalAssemblyStore,
    itemStore: GlobalItemStore,
    folderStore: FolderStore,
    unityBuildStore: EstimateStore
  ) {
    folderStore.setCurrentFolder(defaultFolder);

    itemStore.setIsCreatingItem(false);
    itemStore.setIsViewingItemForm(false);
    itemStore.setCurrentItem(null);

    assemblyStore.setFilteredItems([]);
    assemblyStore.setAssemblyItems([]);
    assemblyStore.setIsCreatingAssembly(false);
    assemblyStore.setCurrentAssembly(defaultGlobalAssembly);
    assemblyStore.setIsViewingAssemblyForm(false);
    assemblyStore.setAssemblyOption("");

    unityBuildStore.setSelectedTakeoffAssembly(null);
    unityBuildStore.setIsUpdatingBreakout(false);
    unityBuildStore.setIsEditingPoint({
      isEditingPoint: false,
      isEditingStyle: false,
      assemblyIndex: null,
      pointIndex: 0,
      x: null,
      y: null,
    });
    unityBuildStore.setIsAnnotating(false);
  }

  export function resetCreateAssemblyStates(
    assemblyStore: GlobalAssemblyStore,
    itemStore: GlobalItemStore,
    folderStore: FolderStore,
    unityBuildStore: EstimateStore
  ) {
    // folderStore.setCurrentFolder(defaultFolder);

    itemStore.setIsCreatingItem(false);
    itemStore.setIsViewingItemForm(false);
    itemStore.setCurrentItem(null);

    assemblyStore.setFilteredItems([]);
    assemblyStore.setAssemblyItems([]);
    assemblyStore.setCurrentAssembly(defaultGlobalAssembly);

    unityBuildStore.setSelectedTakeoffAssembly(null);
    unityBuildStore.setIsUpdatingBreakout(false);
    unityBuildStore.setIsEditingPoint({
      isEditingPoint: false,
      isEditingStyle: false,
      assemblyIndex: null,
      pointIndex: 0,
      x: null,
      y: null,
    });
    unityBuildStore.setIsAnnotating(false);
  }

  export function resetAssemblyPickerStates(
    globalAssemblyStore: GlobalAssemblyStore
  ) {
    globalAssemblyStore.setCurrentAssembly(defaultGlobalAssembly);
  }

  export function resetMainMenuStates(
    store: EstimateStore,
    globalAssemblyStore: GlobalAssemblyStore
  ) {
    store.setIsCreatingNewProject(false);
    store.setIsCreatingContract(false);
    store.setIsViewingEstimateList(false);
    store.setIsViewingContractList(false);
    store.setIsViewingChangeOrderList(false);
    store.setSelectedEstimate(defaultEstimate);
    store.setSelectedContract(defaultContract);
    store.setCurrentPage(1);
    store.setNumPages(1);
    globalAssemblyStore.setCurrentAssembly(defaultGlobalAssembly);
  }

  export function resetCloseoutStates(store: CloseoutStore) {
    store.setIsUpdatingDirectLaborAllocation(false);
    store.setIncludedExtentionTypes([]);
    store.setIncludedDirectLaborTypes([]);
    store.setIncludedLaborFactoringTypes([]);
    store.setIncludedIncidentalLaborTypes([]);
    store.setIncludedIndirectLaborTypes([]);
    store.setIncludedEquipmentTypes([]);
    store.setIncludedGenExpenseTypes([]);
    store.setIncludedSubcontractTypes([]);
    store.setIncludedQuoteTypes([]);
    store.setIncludedTotalPricingTypes(totalPricingDefaults);
  }
}
