// source: globalAssembly.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.AssemblyItemAttribute', null, global);
goog.exportSymbol('proto.CreateGlobalAssemblyRequest', null, global);
goog.exportSymbol('proto.CreateGlobalAssemblyResponse', null, global);
goog.exportSymbol('proto.DeleteGlobalAssemblyRequest', null, global);
goog.exportSymbol('proto.DeleteGlobalAssemblyResponse', null, global);
goog.exportSymbol('proto.GetAllGlobalAssembliesRequest', null, global);
goog.exportSymbol('proto.GetAllGlobalAssembliesResponse', null, global);
goog.exportSymbol('proto.GetAssembliesByFileIdRequest', null, global);
goog.exportSymbol('proto.GetAssembliesByFileIdResponse', null, global);
goog.exportSymbol('proto.GetGlobalAssemblyRequest', null, global);
goog.exportSymbol('proto.GetGlobalAssemblyResponse', null, global);
goog.exportSymbol('proto.GlobalAssembly', null, global);
goog.exportSymbol('proto.ItemQtyFormula', null, global);
goog.exportSymbol('proto.UpdateGlobalAssemblyRequest', null, global);
goog.exportSymbol('proto.UpdateGlobalAssemblyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GlobalAssembly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GlobalAssembly.repeatedFields_, null);
};
goog.inherits(proto.GlobalAssembly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GlobalAssembly.displayName = 'proto.GlobalAssembly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ItemQtyFormula = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ItemQtyFormula.repeatedFields_, null);
};
goog.inherits(proto.ItemQtyFormula, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ItemQtyFormula.displayName = 'proto.ItemQtyFormula';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AssemblyItemAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AssemblyItemAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AssemblyItemAttribute.displayName = 'proto.AssemblyItemAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGlobalAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGlobalAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGlobalAssemblyRequest.displayName = 'proto.CreateGlobalAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGlobalAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGlobalAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGlobalAssemblyResponse.displayName = 'proto.CreateGlobalAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetGlobalAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetGlobalAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetGlobalAssemblyRequest.displayName = 'proto.GetGlobalAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetGlobalAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetGlobalAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetGlobalAssemblyResponse.displayName = 'proto.GetGlobalAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAllGlobalAssembliesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetAllGlobalAssembliesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAllGlobalAssembliesRequest.displayName = 'proto.GetAllGlobalAssembliesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAllGlobalAssembliesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetAllGlobalAssembliesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetAllGlobalAssembliesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAllGlobalAssembliesResponse.displayName = 'proto.GetAllGlobalAssembliesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateGlobalAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateGlobalAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateGlobalAssemblyRequest.displayName = 'proto.UpdateGlobalAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateGlobalAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateGlobalAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateGlobalAssemblyResponse.displayName = 'proto.UpdateGlobalAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteGlobalAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteGlobalAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteGlobalAssemblyRequest.displayName = 'proto.DeleteGlobalAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteGlobalAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteGlobalAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteGlobalAssemblyResponse.displayName = 'proto.DeleteGlobalAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAssembliesByFileIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetAssembliesByFileIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAssembliesByFileIdRequest.displayName = 'proto.GetAssembliesByFileIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAssembliesByFileIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetAssembliesByFileIdResponse.repeatedFields_, null);
};
goog.inherits(proto.GetAssembliesByFileIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAssembliesByFileIdResponse.displayName = 'proto.GetAssembliesByFileIdResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GlobalAssembly.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GlobalAssembly.prototype.toObject = function(opt_includeInstance) {
  return proto.GlobalAssembly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GlobalAssembly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalAssembly.toObject = function(includeInstance, msg) {
  var f, obj = {
    folderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    globalassemblyid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 5, ""),
    parentid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    level: jspb.Message.getFieldWithDefault(msg, 7, 0),
    entitytype: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isfile: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    assemblymeasurementtype: jspb.Message.getFieldWithDefault(msg, 10, ""),
    itemqtyformulasList: jspb.Message.toObjectList(msg.getItemqtyformulasList(),
    proto.ItemQtyFormula.toObject, includeInstance),
    imageclassificationid: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GlobalAssembly}
 */
proto.GlobalAssembly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GlobalAssembly;
  return proto.GlobalAssembly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GlobalAssembly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GlobalAssembly}
 */
proto.GlobalAssembly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFolderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalassemblyid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLevel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntitytype(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfile(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblymeasurementtype(value);
      break;
    case 11:
      var value = new proto.ItemQtyFormula;
      reader.readMessage(value,proto.ItemQtyFormula.deserializeBinaryFromReader);
      msg.addItemqtyformulas(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImageclassificationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GlobalAssembly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GlobalAssembly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GlobalAssembly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GlobalAssembly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFolderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGlobalassemblyid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getParentid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getEntitytype();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsfile();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAssemblymeasurementtype();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getItemqtyformulasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.ItemQtyFormula.serializeBinaryToWriter
    );
  }
  f = message.getImageclassificationid();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional string folderId = 1;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getFolderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setFolderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string globalAssemblyId = 2;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getGlobalassemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setGlobalassemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assemblyName = 3;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isActive = 4;
 * @return {boolean}
 */
proto.GlobalAssembly.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string lastUpdated = 5;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string parentId = 6;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getParentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setParentid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 level = 7;
 * @return {number}
 */
proto.GlobalAssembly.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string entityType = 8;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getEntitytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setEntitytype = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool isFile = 9;
 * @return {boolean}
 */
proto.GlobalAssembly.prototype.getIsfile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setIsfile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string assemblyMeasurementType = 10;
 * @return {string}
 */
proto.GlobalAssembly.prototype.getAssemblymeasurementtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setAssemblymeasurementtype = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated ItemQtyFormula itemQtyFormulas = 11;
 * @return {!Array<!proto.ItemQtyFormula>}
 */
proto.GlobalAssembly.prototype.getItemqtyformulasList = function() {
  return /** @type{!Array<!proto.ItemQtyFormula>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ItemQtyFormula, 11));
};


/**
 * @param {!Array<!proto.ItemQtyFormula>} value
 * @return {!proto.GlobalAssembly} returns this
*/
proto.GlobalAssembly.prototype.setItemqtyformulasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.ItemQtyFormula=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ItemQtyFormula}
 */
proto.GlobalAssembly.prototype.addItemqtyformulas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.ItemQtyFormula, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.clearItemqtyformulasList = function() {
  return this.setItemqtyformulasList([]);
};


/**
 * optional int32 imageClassificationId = 12;
 * @return {number}
 */
proto.GlobalAssembly.prototype.getImageclassificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.GlobalAssembly} returns this
 */
proto.GlobalAssembly.prototype.setImageclassificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ItemQtyFormula.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ItemQtyFormula.prototype.toObject = function(opt_includeInstance) {
  return proto.ItemQtyFormula.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ItemQtyFormula} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ItemQtyFormula.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    forevery: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    takeoffvariabletype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    and: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    or: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.AssemblyItemAttribute.toObject, includeInstance),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 10, ""),
    quotegroup: jspb.Message.getFieldWithDefault(msg, 11, ""),
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 12, 0),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ItemQtyFormula}
 */
proto.ItemQtyFormula.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ItemQtyFormula;
  return proto.ItemQtyFormula.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ItemQtyFormula} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ItemQtyFormula}
 */
proto.ItemQtyFormula.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setForevery(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffvariabletype(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnd(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOr(value);
      break;
    case 8:
      var value = new proto.AssemblyItemAttribute;
      reader.readMessage(value,proto.AssemblyItemAttribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributegroupid(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ItemQtyFormula.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ItemQtyFormula.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ItemQtyFormula} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ItemQtyFormula.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getForevery();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTakeoffvariabletype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAnd();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getOr();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.AssemblyItemAttribute.serializeBinaryToWriter
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAttributegroupid();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string itemid = 1;
 * @return {string}
 */
proto.ItemQtyFormula.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itemName = 2;
 * @return {string}
 */
proto.ItemQtyFormula.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float itemQty = 3;
 * @return {number}
 */
proto.ItemQtyFormula.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float forEvery = 4;
 * @return {number}
 */
proto.ItemQtyFormula.prototype.getForevery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setForevery = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string takeoffVariableType = 5;
 * @return {string}
 */
proto.ItemQtyFormula.prototype.getTakeoffvariabletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setTakeoffvariabletype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool and = 6;
 * @return {boolean}
 */
proto.ItemQtyFormula.prototype.getAnd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setAnd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool or = 7;
 * @return {boolean}
 */
proto.ItemQtyFormula.prototype.getOr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setOr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated AssemblyItemAttribute attributes = 8;
 * @return {!Array<!proto.AssemblyItemAttribute>}
 */
proto.ItemQtyFormula.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.AssemblyItemAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AssemblyItemAttribute, 8));
};


/**
 * @param {!Array<!proto.AssemblyItemAttribute>} value
 * @return {!proto.ItemQtyFormula} returns this
*/
proto.ItemQtyFormula.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.AssemblyItemAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AssemblyItemAttribute}
 */
proto.ItemQtyFormula.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.AssemblyItemAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * optional bool isQuoted = 9;
 * @return {boolean}
 */
proto.ItemQtyFormula.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string assemblyName = 10;
 * @return {string}
 */
proto.ItemQtyFormula.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string quoteGroup = 11;
 * @return {string}
 */
proto.ItemQtyFormula.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 attributeGroupId = 12;
 * @return {number}
 */
proto.ItemQtyFormula.prototype.getAttributegroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string attributeGroupName = 13;
 * @return {string}
 */
proto.ItemQtyFormula.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ItemQtyFormula} returns this
 */
proto.ItemQtyFormula.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AssemblyItemAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.AssemblyItemAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AssemblyItemAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AssemblyItemAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attributevaluename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AssemblyItemAttribute}
 */
proto.AssemblyItemAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AssemblyItemAttribute;
  return proto.AssemblyItemAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AssemblyItemAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AssemblyItemAttribute}
 */
proto.AssemblyItemAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevaluename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributegroupid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AssemblyItemAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AssemblyItemAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AssemblyItemAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AssemblyItemAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAttributevaluename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttributegroupid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 attributeValueId = 1;
 * @return {number}
 */
proto.AssemblyItemAttribute.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.AssemblyItemAttribute} returns this
 */
proto.AssemblyItemAttribute.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string attributeValueName = 2;
 * @return {string}
 */
proto.AssemblyItemAttribute.prototype.getAttributevaluename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AssemblyItemAttribute} returns this
 */
proto.AssemblyItemAttribute.prototype.setAttributevaluename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastUpdated = 3;
 * @return {string}
 */
proto.AssemblyItemAttribute.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AssemblyItemAttribute} returns this
 */
proto.AssemblyItemAttribute.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 attributeGroupId = 4;
 * @return {number}
 */
proto.AssemblyItemAttribute.prototype.getAttributegroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.AssemblyItemAttribute} returns this
 */
proto.AssemblyItemAttribute.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string attributeGroupName = 5;
 * @return {string}
 */
proto.AssemblyItemAttribute.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.AssemblyItemAttribute} returns this
 */
proto.AssemblyItemAttribute.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGlobalAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGlobalAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGlobalAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGlobalAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalassembly: (f = msg.getGlobalassembly()) && proto.GlobalAssembly.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGlobalAssemblyRequest}
 */
proto.CreateGlobalAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGlobalAssemblyRequest;
  return proto.CreateGlobalAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGlobalAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGlobalAssemblyRequest}
 */
proto.CreateGlobalAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.setGlobalassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGlobalAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGlobalAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGlobalAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGlobalAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional GlobalAssembly globalAssembly = 1;
 * @return {?proto.GlobalAssembly}
 */
proto.CreateGlobalAssemblyRequest.prototype.getGlobalassembly = function() {
  return /** @type{?proto.GlobalAssembly} */ (
    jspb.Message.getWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {?proto.GlobalAssembly|undefined} value
 * @return {!proto.CreateGlobalAssemblyRequest} returns this
*/
proto.CreateGlobalAssemblyRequest.prototype.setGlobalassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateGlobalAssemblyRequest} returns this
 */
proto.CreateGlobalAssemblyRequest.prototype.clearGlobalassembly = function() {
  return this.setGlobalassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateGlobalAssemblyRequest.prototype.hasGlobalassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateGlobalAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateGlobalAssemblyRequest} returns this
 */
proto.CreateGlobalAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGlobalAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGlobalAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGlobalAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGlobalAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalassembly: (f = msg.getGlobalassembly()) && proto.GlobalAssembly.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGlobalAssemblyResponse}
 */
proto.CreateGlobalAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGlobalAssemblyResponse;
  return proto.CreateGlobalAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGlobalAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGlobalAssemblyResponse}
 */
proto.CreateGlobalAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.setGlobalassembly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGlobalAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGlobalAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGlobalAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGlobalAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * optional GlobalAssembly globalAssembly = 1;
 * @return {?proto.GlobalAssembly}
 */
proto.CreateGlobalAssemblyResponse.prototype.getGlobalassembly = function() {
  return /** @type{?proto.GlobalAssembly} */ (
    jspb.Message.getWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {?proto.GlobalAssembly|undefined} value
 * @return {!proto.CreateGlobalAssemblyResponse} returns this
*/
proto.CreateGlobalAssemblyResponse.prototype.setGlobalassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateGlobalAssemblyResponse} returns this
 */
proto.CreateGlobalAssemblyResponse.prototype.clearGlobalassembly = function() {
  return this.setGlobalassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateGlobalAssemblyResponse.prototype.hasGlobalassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetGlobalAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetGlobalAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetGlobalAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGlobalAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalassemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    folderid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetGlobalAssemblyRequest}
 */
proto.GetGlobalAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetGlobalAssemblyRequest;
  return proto.GetGlobalAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetGlobalAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetGlobalAssemblyRequest}
 */
proto.GetGlobalAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalassemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFolderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetGlobalAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetGlobalAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetGlobalAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGlobalAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalassemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFolderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string globalAssemblyId = 1;
 * @return {string}
 */
proto.GetGlobalAssemblyRequest.prototype.getGlobalassemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetGlobalAssemblyRequest} returns this
 */
proto.GetGlobalAssemblyRequest.prototype.setGlobalassemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string folderId = 2;
 * @return {string}
 */
proto.GetGlobalAssemblyRequest.prototype.getFolderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetGlobalAssemblyRequest} returns this
 */
proto.GetGlobalAssemblyRequest.prototype.setFolderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sessionToken = 3;
 * @return {string}
 */
proto.GetGlobalAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetGlobalAssemblyRequest} returns this
 */
proto.GetGlobalAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetGlobalAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetGlobalAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetGlobalAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGlobalAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalassembly: (f = msg.getGlobalassembly()) && proto.GlobalAssembly.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetGlobalAssemblyResponse}
 */
proto.GetGlobalAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetGlobalAssemblyResponse;
  return proto.GetGlobalAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetGlobalAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetGlobalAssemblyResponse}
 */
proto.GetGlobalAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.setGlobalassembly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetGlobalAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetGlobalAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetGlobalAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetGlobalAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * optional GlobalAssembly globalAssembly = 1;
 * @return {?proto.GlobalAssembly}
 */
proto.GetGlobalAssemblyResponse.prototype.getGlobalassembly = function() {
  return /** @type{?proto.GlobalAssembly} */ (
    jspb.Message.getWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {?proto.GlobalAssembly|undefined} value
 * @return {!proto.GetGlobalAssemblyResponse} returns this
*/
proto.GetGlobalAssemblyResponse.prototype.setGlobalassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetGlobalAssemblyResponse} returns this
 */
proto.GetGlobalAssemblyResponse.prototype.clearGlobalassembly = function() {
  return this.setGlobalassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetGlobalAssemblyResponse.prototype.hasGlobalassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAllGlobalAssembliesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAllGlobalAssembliesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAllGlobalAssembliesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAllGlobalAssembliesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagenumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    searchkeyword: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sortby: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sortdescending: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAllGlobalAssembliesRequest}
 */
proto.GetAllGlobalAssembliesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAllGlobalAssembliesRequest;
  return proto.GetAllGlobalAssembliesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAllGlobalAssembliesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAllGlobalAssembliesRequest}
 */
proto.GetAllGlobalAssembliesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchkeyword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortby(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSortdescending(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAllGlobalAssembliesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAllGlobalAssembliesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAllGlobalAssembliesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAllGlobalAssembliesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSearchkeyword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSortby();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSortdescending();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 pageNumber = 1;
 * @return {number}
 */
proto.GetAllGlobalAssembliesRequest.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetAllGlobalAssembliesRequest} returns this
 */
proto.GetAllGlobalAssembliesRequest.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pageSize = 2;
 * @return {number}
 */
proto.GetAllGlobalAssembliesRequest.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.GetAllGlobalAssembliesRequest} returns this
 */
proto.GetAllGlobalAssembliesRequest.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string searchKeyword = 3;
 * @return {string}
 */
proto.GetAllGlobalAssembliesRequest.prototype.getSearchkeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAllGlobalAssembliesRequest} returns this
 */
proto.GetAllGlobalAssembliesRequest.prototype.setSearchkeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sortBy = 4;
 * @return {string}
 */
proto.GetAllGlobalAssembliesRequest.prototype.getSortby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAllGlobalAssembliesRequest} returns this
 */
proto.GetAllGlobalAssembliesRequest.prototype.setSortby = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool sortDescending = 5;
 * @return {boolean}
 */
proto.GetAllGlobalAssembliesRequest.prototype.getSortdescending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GetAllGlobalAssembliesRequest} returns this
 */
proto.GetAllGlobalAssembliesRequest.prototype.setSortdescending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string sessionToken = 6;
 * @return {string}
 */
proto.GetAllGlobalAssembliesRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAllGlobalAssembliesRequest} returns this
 */
proto.GetAllGlobalAssembliesRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetAllGlobalAssembliesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAllGlobalAssembliesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAllGlobalAssembliesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAllGlobalAssembliesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAllGlobalAssembliesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assembliesList: jspb.Message.toObjectList(msg.getAssembliesList(),
    proto.GlobalAssembly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAllGlobalAssembliesResponse}
 */
proto.GetAllGlobalAssembliesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAllGlobalAssembliesResponse;
  return proto.GetAllGlobalAssembliesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAllGlobalAssembliesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAllGlobalAssembliesResponse}
 */
proto.GetAllGlobalAssembliesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.addAssemblies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAllGlobalAssembliesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAllGlobalAssembliesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAllGlobalAssembliesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAllGlobalAssembliesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssembliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GlobalAssembly assemblies = 1;
 * @return {!Array<!proto.GlobalAssembly>}
 */
proto.GetAllGlobalAssembliesResponse.prototype.getAssembliesList = function() {
  return /** @type{!Array<!proto.GlobalAssembly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {!Array<!proto.GlobalAssembly>} value
 * @return {!proto.GetAllGlobalAssembliesResponse} returns this
*/
proto.GetAllGlobalAssembliesResponse.prototype.setAssembliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GlobalAssembly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GlobalAssembly}
 */
proto.GetAllGlobalAssembliesResponse.prototype.addAssemblies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GlobalAssembly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetAllGlobalAssembliesResponse} returns this
 */
proto.GetAllGlobalAssembliesResponse.prototype.clearAssembliesList = function() {
  return this.setAssembliesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateGlobalAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateGlobalAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateGlobalAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGlobalAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalassembly: (f = msg.getGlobalassembly()) && proto.GlobalAssembly.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateGlobalAssemblyRequest}
 */
proto.UpdateGlobalAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateGlobalAssemblyRequest;
  return proto.UpdateGlobalAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateGlobalAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateGlobalAssemblyRequest}
 */
proto.UpdateGlobalAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.setGlobalassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateGlobalAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateGlobalAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateGlobalAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGlobalAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional GlobalAssembly globalAssembly = 1;
 * @return {?proto.GlobalAssembly}
 */
proto.UpdateGlobalAssemblyRequest.prototype.getGlobalassembly = function() {
  return /** @type{?proto.GlobalAssembly} */ (
    jspb.Message.getWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {?proto.GlobalAssembly|undefined} value
 * @return {!proto.UpdateGlobalAssemblyRequest} returns this
*/
proto.UpdateGlobalAssemblyRequest.prototype.setGlobalassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateGlobalAssemblyRequest} returns this
 */
proto.UpdateGlobalAssemblyRequest.prototype.clearGlobalassembly = function() {
  return this.setGlobalassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateGlobalAssemblyRequest.prototype.hasGlobalassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.UpdateGlobalAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateGlobalAssemblyRequest} returns this
 */
proto.UpdateGlobalAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateGlobalAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateGlobalAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateGlobalAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGlobalAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    globalassembly: (f = msg.getGlobalassembly()) && proto.GlobalAssembly.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateGlobalAssemblyResponse}
 */
proto.UpdateGlobalAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateGlobalAssemblyResponse;
  return proto.UpdateGlobalAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateGlobalAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateGlobalAssemblyResponse}
 */
proto.UpdateGlobalAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.setGlobalassembly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateGlobalAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateGlobalAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateGlobalAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateGlobalAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * optional GlobalAssembly globalAssembly = 1;
 * @return {?proto.GlobalAssembly}
 */
proto.UpdateGlobalAssemblyResponse.prototype.getGlobalassembly = function() {
  return /** @type{?proto.GlobalAssembly} */ (
    jspb.Message.getWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {?proto.GlobalAssembly|undefined} value
 * @return {!proto.UpdateGlobalAssemblyResponse} returns this
*/
proto.UpdateGlobalAssemblyResponse.prototype.setGlobalassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateGlobalAssemblyResponse} returns this
 */
proto.UpdateGlobalAssemblyResponse.prototype.clearGlobalassembly = function() {
  return this.setGlobalassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateGlobalAssemblyResponse.prototype.hasGlobalassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteGlobalAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteGlobalAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteGlobalAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGlobalAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    folderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteGlobalAssemblyRequest}
 */
proto.DeleteGlobalAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteGlobalAssemblyRequest;
  return proto.DeleteGlobalAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteGlobalAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteGlobalAssemblyRequest}
 */
proto.DeleteGlobalAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFolderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteGlobalAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteGlobalAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteGlobalAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGlobalAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFolderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string folderId = 1;
 * @return {string}
 */
proto.DeleteGlobalAssemblyRequest.prototype.getFolderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteGlobalAssemblyRequest} returns this
 */
proto.DeleteGlobalAssemblyRequest.prototype.setFolderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteGlobalAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteGlobalAssemblyRequest} returns this
 */
proto.DeleteGlobalAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteGlobalAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteGlobalAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteGlobalAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGlobalAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteGlobalAssemblyResponse}
 */
proto.DeleteGlobalAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteGlobalAssemblyResponse;
  return proto.DeleteGlobalAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteGlobalAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteGlobalAssemblyResponse}
 */
proto.DeleteGlobalAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteGlobalAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteGlobalAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteGlobalAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteGlobalAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteGlobalAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteGlobalAssemblyResponse} returns this
 */
proto.DeleteGlobalAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAssembliesByFileIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAssembliesByFileIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAssembliesByFileIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAssembliesByFileIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAssembliesByFileIdRequest}
 */
proto.GetAssembliesByFileIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAssembliesByFileIdRequest;
  return proto.GetAssembliesByFileIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAssembliesByFileIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAssembliesByFileIdRequest}
 */
proto.GetAssembliesByFileIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAssembliesByFileIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAssembliesByFileIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAssembliesByFileIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAssembliesByFileIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string fileId = 1;
 * @return {string}
 */
proto.GetAssembliesByFileIdRequest.prototype.getFileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAssembliesByFileIdRequest} returns this
 */
proto.GetAssembliesByFileIdRequest.prototype.setFileid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.GetAssembliesByFileIdRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAssembliesByFileIdRequest} returns this
 */
proto.GetAssembliesByFileIdRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetAssembliesByFileIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAssembliesByFileIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAssembliesByFileIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAssembliesByFileIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAssembliesByFileIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assembliesList: jspb.Message.toObjectList(msg.getAssembliesList(),
    proto.GlobalAssembly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAssembliesByFileIdResponse}
 */
proto.GetAssembliesByFileIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAssembliesByFileIdResponse;
  return proto.GetAssembliesByFileIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAssembliesByFileIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAssembliesByFileIdResponse}
 */
proto.GetAssembliesByFileIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GlobalAssembly;
      reader.readMessage(value,proto.GlobalAssembly.deserializeBinaryFromReader);
      msg.addAssemblies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAssembliesByFileIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAssembliesByFileIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAssembliesByFileIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAssembliesByFileIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssembliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GlobalAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GlobalAssembly assemblies = 1;
 * @return {!Array<!proto.GlobalAssembly>}
 */
proto.GetAssembliesByFileIdResponse.prototype.getAssembliesList = function() {
  return /** @type{!Array<!proto.GlobalAssembly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GlobalAssembly, 1));
};


/**
 * @param {!Array<!proto.GlobalAssembly>} value
 * @return {!proto.GetAssembliesByFileIdResponse} returns this
*/
proto.GetAssembliesByFileIdResponse.prototype.setAssembliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GlobalAssembly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GlobalAssembly}
 */
proto.GetAssembliesByFileIdResponse.prototype.addAssemblies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GlobalAssembly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetAssembliesByFileIdResponse} returns this
 */
proto.GetAssembliesByFileIdResponse.prototype.clearAssembliesList = function() {
  return this.setAssembliesList([]);
};


goog.object.extend(exports, proto);
