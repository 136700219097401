import { Alert, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import { useUnityBuildStore } from "../states/store";
import { useFetchProjects } from "../hooks/useFetchProjects";
import { useStore } from "zustand";
import { CreateProject } from "./CreateProject";
import { GetProjectResponse } from "../api/protosCompiled/project/project_pb";

export function ProjectList() {
  const {
    data: projectList,
    error,
    isLoading,
    isFetching,
  } = useFetchProjects();
  const errorMessage = (error as Error)?.message;
  const [searchTerm, setSearchTerm] = useState("");
  const { setIsViewingEstimateList, setSelectedProject, setShowSidebarMenu } =
    useStore(useUnityBuildStore);

  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [isUpdatingProject, setIsUpdatingProject] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");

  const handleLoadProject = async (project: GetProjectResponse.AsObject) => {
    if (!project.project) return;
    setSelectedProject(project.project);
    setIsViewingEstimateList(true);
  };

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
    setShowSidebarMenu(false);
  };

  const filteredProjects = projectList?.filter(
    (project) =>
      project.project!.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project
        .project!.description.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      project.project!.address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditProject = (e: any, project: GetProjectResponse.AsObject) => {
    e.stopPropagation();
    if (!project.project) return;
    setIsUpdatingProject(true);
    setSelectedProjectId(project.project.id);
  };

  const handleCloseDialog = () => {
    setIsCreatingProject(false);
    setIsUpdatingProject(false);
  };

  return (
    <>
      <input
        placeholder="Search Projects..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <button color="inherit" onClick={() => setIsCreatingProject(true)}>
        Create Project
      </button>
      <div className="scrollableTableContainer">
        <table>
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Project Description</th>
              <th>Project Address</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProjects?.map((project) => (
              <tr
                className="hoverable-row"
                key={project.project!.id}
                onClick={() => handleLoadProject(project)}
              >
                <td>{project.project!.name}</td>
                <td>{project.project!.description}</td>
                <td>{project.project!.address}</td>
                <td>
                  {new Date(project.project!.startdate).toLocaleDateString(
                    "en-US"
                  )}
                </td>
                <td>
                  {new Date(project.project!.enddate).toLocaleDateString(
                    "en-US"
                  )}
                </td>

                <td style={{ pointerEvents: "none" }}>
                  <button
                    style={{ pointerEvents: "auto" }}
                    onClick={(e) => handleEditProject(e, project)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {(isLoading || isFetching) && <CircularProgress />}
      {error ? <Alert severity="error">Error: {errorMessage}</Alert> : null}
      <Dialog
        open={isCreatingProject || isUpdatingProject}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <CreateProject
            create={isCreatingProject}
            update={isUpdatingProject}
            updateProjectId={selectedProjectId}
            setIsCreatingProject={setIsCreatingProject}
            setIsUpdatingProject={setIsUpdatingProject}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
