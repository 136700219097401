import { useMutation, useQuery, useQueryClient } from "react-query";
import { ObjectService } from "../api/ObjectService";
import { useUnityBuildStore } from "../states/store";
import { useStore } from "zustand";
import {
  GenerateGETSignedUrlRequest,
  Object as ProtoObject,
} from "../api/protosCompiled/object/object_pb";

//TODO: provide the right client for the GCS get and put methods
export function useFetchObjects() {
  const { sessionToken } = useStore(useUnityBuildStore);
  const { selectedProject } = useStore(useUnityBuildStore);
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery(
    ["objects", selectedProject.id],
    async () => {
      if (selectedProject.id) {
        const response = await ObjectService.getObjectListByProjectId(
          selectedProject.id
        );

        const objects = await Promise.all(
          response.objectsList.map(async (obj) => {
            const req = new GenerateGETSignedUrlRequest();
            req.setMethod("GET");
            req.setGcsobjectname(obj.gcsobjectname);
            req.setSessiontoken(sessionToken);

            try {
              const previewUrl =
                await ObjectService.handleGETGCSRequest_Preview(
                  req,
                  obj.objectname
                );
              return {
                ...obj,
                previewUrl, // Add the fetched preview URL to the object
              };
            } catch (error) {
              console.error(
                `Error fetching preview for ${obj.objectname}:`,
                error
              );
              return {
                ...obj,
                previewUrl: "", // Fallback to an empty string on error
              };
            }
          })
        );

        return objects.sort((a, b) => a.objectname.localeCompare(b.objectname));
      }
      throw new Error("No valid ID available for fetching objects");
    },
    {
      enabled: !!selectedProject.id,
      onError: (error) => {
        console.error("Error fetching objects:", error);
      },
    }
  );

  const refetchObjects = () => {
    queryClient.invalidateQueries(["objects", selectedProject.id]);
  };

  return { data, error, isLoading, refetchObjects };
}

//update object name
export function useUpdateObjectName() {
  const { selectedProject } = useStore(useUnityBuildStore);
  const queryClient = useQueryClient();
  return useMutation(
    (data: FilteredProtoObject) =>
      ObjectService.updateObjectName(data.id, data.objectname),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["objects", selectedProject.id]);
      },
      onError: (error) => {
        console.error("Error updating object name:", error);
      },
    }
  );
}

type FilteredProtoObject = Omit<
  ProtoObject.AsObject,
  | "projectid"
  | "gcsobjectname"
  | "userscalesList"
  | "openaifileid"
  | "hastakeoff"
>;
