import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AuditTrail } from "./AuditTrail";
import { ChatWindow } from "../ChatWindow";
import { AiCountManager } from "./AiCountManager";
import { CustomTabPanel, a11yProps } from "../../customTabs/CustomTabPanel";
import { FolderNodes } from "../assembliesAndItems/folderManager/FolderNodes";
import { useFetchAssemblyFolders } from "../../hooks/useFetchFolders";
import { GetFoldersResponse } from "../../api/protosCompiled/folder/folder_pb";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import Breakouts from "./Breakouts";
import { BreakoutSelector } from "./BreakoutSelector";

export default function TakeoffPallette() {
  const [value, setValue] = React.useState(0);
  const { selectedTakeoffAssembly } = useStore(useUnityBuildStore);

  const { data: folderData } = useFetchAssemblyFolders();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scrollDown = () => {};

  return (
    <>
      <div style={{ borderBottom: 1, borderColor: "divider" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%", // Ensure the parent div has a defined width
          }}
        >
          Counting:
          <br />
          <strong>{selectedTakeoffAssembly?.assemblyname}</strong>
          <br />
          Selected Breakout: <BreakoutSelector />
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Audit Trail" {...a11yProps(0)} />
          <Tab label="Assemblies" {...a11yProps(1)} />
          <Tab label="Breakouts" {...a11yProps(2)} />
          <Tab label="AI Count (Beta)" {...a11yProps(3)} />
          <Tab label="AI Chat" {...a11yProps(4)} onClick={() => scrollDown()} />
        </Tabs>
      </div>
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <CustomTabPanel value={value} index={0}>
          <AuditTrail />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <FolderNodes
            context="secondary"
            folderType="assembly"
            folderData={folderData as GetFoldersResponse.AsObject}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Breakouts />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <AiCountManager />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <ChatWindow scrollDown={() => scrollDown()} />
        </CustomTabPanel>
      </div>
    </>
  );
}
