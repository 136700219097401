import { useEffect, useState } from "react";

import { GenericItemNameInput } from "./GenericItemNameInput";
import { UnitOfMeasureSelector } from "./UnitOfMeasureSelector";
import { AttributeValuesCheckboxes } from "./AttributeValuesCheckboxes";
import { AttributeGroupSelector } from "./AttributeGroupSelector";
import { Grid } from "@mui/material";
import { UPCDetailsCard } from "./UPCDetailsCard";

import { useStore } from "zustand";
import {
  AllowedVariable,
  AttributeValue,
  AttributeVariable,
  GlobalItem,
  ItemCost,
} from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { useFetchAttributeGroups } from "../../../hooks/useFetchAttributeGroups";
import { useGetMasterFormatDivision } from "../../../hooks/useGetMasterFormatDivision";
import { useGlobalItemStore } from "../../../states/globalItemStore";
import { useFolderStore } from "../../../states/folderStore";
import {
  useCreateOrUpdateItem,
  useFetchItemsByFileId,
} from "../../../hooks/useFetchItemsByFileId";

export type CreateItemProps = {
  items: GlobalItem.AsObject[];
};

export const CreateItem = ({ items }: CreateItemProps) => {
  //zustand states
  const { currentItem, isCreatingItem } = useStore(useGlobalItemStore);
  const { currentFolder } = useStore(useFolderStore);

  //react-query queries
  const { data: masterFormatDivision } = useGetMasterFormatDivision();
  const { data: attributeGroups } = useFetchAttributeGroups();
  const { mutate: createOrUpdateItem } = useCreateOrUpdateItem();
  const { refetch } = useFetchItemsByFileId(
    currentFolder?.entitytype === "item" ? currentFolder?.folderid : ""
  );

  //local state
  const [itemName, setItemName] = useState(currentItem?.name || "");
  const [selectedUom, setSelectedUom] = useState<string | null>(null);
  const [selectedAttributeGroupId, setSelectedAttributeGroupId] = useState<
    string | null
  >(null);
  const [selectedAttributes, setSelectedAttributes] = useState<any>({});
  const [filterDivision, setFilterDivision] = useState<string>("");

  //effect to set the form to the current item
  useEffect(() => {
    setItemName(currentItem?.name || ""); // Update item name
    setSelectedUom(currentItem?.costsList[0]?.uomid?.toString() || ""); // Update UOM
    setSelectedAttributeGroupId(
      currentItem?.attributesList[0]?.attributegroupid?.toString() || ""
    );
    setSelectedAttributes(
      currentItem?.attributesList.reduce((acc, attr) => {
        const groupId = attr.attributegroupid.toString();
        if (!acc[groupId]) acc[groupId] = [];
        acc[groupId].push(attr.attributevalueid.toString());
        return acc;
      }, {} as Record<string, string[]>)
    ); // Update attributes
  }, [currentItem]);

  const handleCreateItem = async () => {
    try {
      const newItem = new GlobalItem();

      newItem.setFolderid(isCreatingItem ? "" : currentItem?.folderid || "");
      newItem.setName(itemName);
      newItem.setIsactive(true);
      newItem.setLastupdated(""); //set in the backend
      newItem.setParentid(
        isCreatingItem ? currentFolder?.folderid : currentItem?.parentid || ""
      );
      newItem.setLevel(
        isCreatingItem ? currentFolder.level + 1 : currentItem?.level || 2
      );
      newItem.setEntitytype("item");
      newItem.setIsfile(true);

      // Populate attributesList
      const attributesList = Object.keys(selectedAttributes).flatMap(
        (groupId) =>
          selectedAttributes[groupId].map((valueId: string) => {
            const originalAttribute = items
              .flatMap((item) => item.attributesList || [])
              .find(
                (attr) =>
                  attr.attributegroupid.toString() === groupId &&
                  attr.attributevalueid.toString() === valueId
              );

            const attribute = new AttributeValue();
            attribute.setAttributevalueid(Number(valueId));
            attribute.setAttributegroupid(Number(groupId));
            attribute.setAttributevaluename(
              originalAttribute?.attributevaluename || ""
            );
            attribute.setUpc(originalAttribute?.upc || "");
            attribute.setItemattributevaluemapid(
              originalAttribute?.itemattributevaluemapid || 0
            );

            // Map AttributeVariables
            attribute.setAttributevariablesList(
              (originalAttribute?.attributevariablesList || []).map(
                (variable) => {
                  const attrVar = new AttributeVariable();
                  attrVar.setItemattributevaluevariablemapid(
                    variable.itemattributevaluevariablemapid || 0
                  );
                  attrVar.setVariableid(variable.variableid || 0);
                  if (variable.variable) {
                    const allowedVariable = new AllowedVariable();
                    // Set AllowedVariable properties if needed
                    attrVar.setVariable(allowedVariable);
                  }
                  attrVar.setVariablevalue(variable.variablevalue || 0);
                  return attrVar;
                }
              )
            );
            return attribute;
          })
      );
      newItem.setAttributesList(attributesList);

      // Populate costsList based on selected attributes and UoM
      const costsList = Object.keys(selectedAttributes).flatMap((groupId) =>
        selectedAttributes[groupId].map((valueId: string) => {
          const cost = new ItemCost();
          const uomId = Number(selectedUom);
          cost.setItemid(""); // Optional: Set dynamically if available
          cost.setItemcostid(0); // Set dynamically if editing
          cost.setItemunitcost(0); // Default cost
          cost.setHourlyproductionrate(0); // Default production rate
          cost.setItemattributevalueid(Number(valueId));
          cost.setUomid(uomId);
          return cost;
        })
      );
      newItem.setCostsList(costsList);

      // Submit the GlobalItem
      createOrUpdateItem(newItem.toObject(), {
        onSuccess: () => {
          console.log("Item created/updated successfully!");
          refetch();
        },
        onError: (error) => {
          console.error("Error creating/updating item:", error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckboxChange = (groupId: string, valueId: string) => {
    setSelectedAttributes((prevSelected: any) => {
      const groupValues = prevSelected[groupId] || [];
      const updatedValues = groupValues.includes(valueId)
        ? groupValues.filter((id: string) => id !== valueId)
        : [...groupValues, valueId];

      return {
        ...prevSelected,
        [groupId]: updatedValues,
      };
    });
  };
  return (
    <div>
      {/* Grid Layout */}
      <h4>
        {!currentFolder
          ? "Select folder"
          : isCreatingItem
          ? "Creating item in: "
          : "Updating item in: "}{" "}
        {currentFolder?.name || ""}
      </h4>
      <Grid container spacing={3}>
        {/* Generic Item Name */}
        <Grid item xs={12} sm={6} style={{ justifyContent: "center" }}>
          <GenericItemNameInput value={itemName} onChange={setItemName} />
          <UnitOfMeasureSelector
            value={selectedUom}
            onChange={setSelectedUom}
          />
          <AttributeGroupSelector
            filterDivision={filterDivision}
            setFilterDivision={setFilterDivision}
            selectedAttributeGroupId={selectedAttributeGroupId}
            setSelectedAttributeGroupId={setSelectedAttributeGroupId}
            attributeGroups={attributeGroups}
            masterFormatDivision={masterFormatDivision || []}
          />
          <AttributeValuesCheckboxes
            selectedAttributeGroupId={selectedAttributeGroupId}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={setSelectedAttributes}
            attributeGroups={attributeGroups}
            handleCheckboxChange={handleCheckboxChange}
            itemName={itemName}
            setItemName={setItemName}
            selectedUom={selectedUom || ""}
            setSelectedUom={setSelectedUom}
            handleCreateItem={handleCreateItem}
          />
        </Grid>

        {/* Unit of Measure Selector */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            maxHeight: "100%", // Set maximum height
            overflowY: "auto", // Enable vertical scrolling
            overflowX: "hidden", // Hide horizontal overflow
            padding: "8px", // Optional: Add padding for better presentation
            boxSizing: "border-box", // Ensure padding is included in the height calculation
          }}
        >
          <UPCDetailsCard />
        </Grid>
      </Grid>
    </div>
  );
};
