import { defaultProject, useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useStore } from "zustand";
import { useGlobalAssemblyStore } from "../states/globalAssemblyStore";
import { useCloseoutStore } from "../states/closeoutStore";
import { useInferenceStore } from "../states/inferenceStore";
import { useGlobalItemStore } from "../states/globalItemStore";
import { useFolderStore } from "../states/folderStore";

export const useHandleGoHome = () => {
  const unityBuildStore = useStore(useUnityBuildStore);
  const globalAssemblyStore = useStore(useGlobalAssemblyStore);
  const store2 = useStore(useCloseoutStore);
  const store3 = useStore(useInferenceStore);
  const { setSelectedProject, setIsViewingProjectDashboard } =
    useStore(useUnityBuildStore);
  const itemStore = useStore(useGlobalItemStore);
  const folderStore = useStore(useFolderStore);

  return (bool: boolean) => {
    ResetStates.resetMainMenuStates(unityBuildStore, globalAssemblyStore);
    setSelectedProject(defaultProject);
    ResetStates.resetTakeoffStates(unityBuildStore, globalAssemblyStore);
    ResetStates.resetMasterProjectViewStates(
      unityBuildStore,
      globalAssemblyStore,
      store3
    );
    ResetStates.resetCreateAssemblyStates(
      globalAssemblyStore,
      itemStore,
      folderStore,
      unityBuildStore
    );
    setIsViewingProjectDashboard(bool);
    ResetStates.resetCloseoutStates(store2);
    unityBuildStore.setCountedAssemblies(null);
  };
};
