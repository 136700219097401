import {
  Box,
  Button,
  Popover,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useStore } from "zustand";
import { useHandleGoHome } from "../hooks/useHandleGoHome";
import { useHandleSetCreateStates } from "../hooks/useHandleSetCreateStates";
import { useHandleSetViewingState } from "../hooks/useUseHandleSetViewingState";
import { useUnityBuildStore } from "../states/store";
import { UserPicture } from "./UserPicture";
import { Settings } from "./settings/Settings";
import LogIn from "./LogIn";
import { useFetchUser } from "../hooks/useFetchUsers";
import PersonIcon from "@mui/icons-material/Person";
import { useGlobalAssemblyStore } from "../states/globalAssemblyStore";
import { AssemblyOptions } from "../types/FolderTypes";
import { CreateAssembly } from "./assembliesAndItems/assemblies/CreateAssembly";

export function MainMenu() {
  const {
    userBackgroundColor,
    isFetchingToken,
    isLoggedIn,
    selectedProject,
    isOpeningViewport,
    isViewingExtension,
    selectedEstimate,
    selectedContract,
    selectedChangeOrder,
    setIsViewingEstimateList,
    setIsViewingExtension,
  } = useStore(useUnityBuildStore);

  const {
    setIsViewingAssemblyForm,
    assemblyOption,
    setAssemblyOption,
    isViewingAssemblyForm,
    setIsCreatingAssembly,
  } = useStore(useGlobalAssemblyStore);

  const { data: user, isLoading: isLoadingUser } = useFetchUser();

  const handleGoHome = useHandleGoHome();
  const handleSetViewingState = useHandleSetViewingState();
  const handleSetCreateStates = useHandleSetCreateStates();

  const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);

  const handleOpenLoginDialog = () => {
    setLoginDialogOpen(true);
  };

  const handleCloseLoginDialog = () => {
    setLoginDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorEl(null);
  };

  const handleSetIsViewingAssemblyForm = (
    e: React.MouseEvent<HTMLButtonElement>,
    bool: boolean
  ) => {
    e.stopPropagation();
    setAssemblyOption("global");
    setIsCreatingAssembly(true);
    setIsViewingAssemblyForm(bool);
  };

  const open = Boolean(anchorEl);
  const id = open ? "settings-popover" : undefined;

  return (
    <Box
      sx={{
        backgroundColor: userBackgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between", // Pushes elements to edges
        padding: "8px", // Optional: Adjust padding to your liking
      }}
    >
      {/* Render CreateAssembly when selectedTakeoffAssembly is set and menuOption is "Edit Assembly" */}
      <Dialog
        open={isViewingAssemblyForm && assemblyOption === "global"}
        fullScreen={true}
        style={{
          height: "100vh",
          backgroundColor: userBackgroundColor,
        }}
      >
        <DialogContent>
          <CreateAssembly assemblyOption={assemblyOption as AssemblyOptions} />
        </DialogContent>
      </Dialog>
      {isLoggedIn ? (
        <>
          {isLoadingUser ? <CircularProgress /> : null}
          {/* Left Section: Icon and Buttons */}
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button onClick={handleOpenSettings}>
              {!isLoadingUser && user && user?.user?.userphotourl ? (
                <UserPicture user={user as any} />
              ) : (
                <PersonIcon />
              )}
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseSettings}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Settings
                onCloseMenu={handleCloseSettings}
                onLogout={() => setLoginDialogOpen(false)} // Ensure the dialog closes on logout
              />
            </Popover>
            <Button
              onClick={(e) => handleSetIsViewingAssemblyForm(e, true)}
              color="inherit"
              sx={{
                border: "1px solid black",
                margin: "2px",
                position: "relative",
              }}
            >
              Items & Assemblies
            </Button>
          </div>

          {selectedProject.id && (
            <Button
              color="inherit"
              sx={{ border: "1px solid black", margin: "2px" }}
              onClick={() => handleGoHome(true)}
            >
              Project List
            </Button>
          )}
          {selectedProject.id && (
            <>
              {(selectedEstimate.estimateid ||
                selectedChangeOrder.changeorderid ||
                selectedContract.contractid) && (
                <Button
                  color="inherit"
                  sx={{ border: "1px solid black", margin: "2px" }}
                  onClick={() =>
                    handleSetViewingState(setIsViewingEstimateList, true)
                  }
                >
                  Takeoff Lists
                </Button>
              )}
              {isOpeningViewport && (
                <Button
                  color="inherit"
                  sx={{ border: "1px solid black", margin: "2px" }}
                  onClick={() =>
                    handleSetCreateStates(
                      setIsViewingExtension,
                      !isViewingExtension
                    )
                  }
                >
                  Closeout
                </Button>
              )}
              {/* Right Section: Project/Estimate Info */}
              <div style={{ marginLeft: "auto", alignSelf: "center" }}>
                {selectedProject.name && (
                  <>Project: {selectedProject.name} / </>
                )}
                {selectedEstimate.name && (
                  <>Estimate: {selectedEstimate.name}</>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {isFetchingToken ? (
            <Typography>Loading...</Typography>
          ) : (
            <Button
              type="submit"
              variant="contained"
              onClick={handleOpenLoginDialog}
            >
              Login
            </Button>
          )}
          <Dialog
            open={loginDialogOpen}
            onClose={handleCloseLoginDialog}
            aria-labelledby="login-dialog-title"
          >
            <DialogContent>
              <LogIn />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
}
