import {
  DirectLaborType,
  EquipmentType,
  ExtensionType,
  GeneralExpenseType,
  IncidentalLaborType,
  IndirectLaborType,
  LaborFactoringType,
  SubcontractType,
  QuoteType,
  TotalFinalRowData,
  TotalRowPricingData,
} from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";

export const defaultQuotedItem: QuoteType.AsObject = {
  quotegroup: "",
  quotedcost: 0,
  adjustedpercent: 0,
  totalcost: 0,
  isdefaulttype: true,
  quoteitemsList: [],
};

export interface ExtentionRowProps {
  item: ExtensionType.AsObject;
  index: number;
  handleFieldUpdate: (index: number, field: string, value: number) => void;
}

export interface DirectLaborRowProps {
  labor: DirectLaborType.AsObject;
  totalLaborHours: number;
  handleDistributionChange: (labor: DirectLaborType.AsObject) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface LaborFactoringRowProps {
  labor: LaborFactoringType.AsObject;
  totalLaborHours: number;
  handleFieldUpdate: (field: string, value: number, index: number) => void;
}

export interface IncidentalLaborRowProps {
  labor: IncidentalLaborType.AsObject;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface IndirectLaborRowProps {
  labor: IndirectLaborType.AsObject;
  totalLaborHours: number;
  handleDistributionChange: (labor: IndirectLaborType.AsObject) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface EquipmentRowProps {
  equipment: EquipmentType.AsObject;
  handleDelete: (name: string) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface GeneralExpenseRowProps {
  generalExpense: GeneralExpenseType.AsObject;
  handleDelete: (name: string) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface SubcontractRowProps {
  subcontract: SubcontractType.AsObject;
  handleDelete: (name: string) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface QuoteRowProps {
  quote: QuoteType.AsObject;
  extension?: ExtensionType.AsObject;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface TotalPricingRowProps {
  name: string;
  rowData: TotalRowPricingData.AsObject;
  handleFieldUpdate: (field: string, name: string, value: number) => void;
}

//Temporary data for the closeout, will replace with the actual data from the database

export const totalPricingDefaults: TotalFinalRowData.AsObject = {
  totalfinaldata: {
    totalcost: 0,
    totaloverhead: 0,
    totalmarkup: 0,
    totalfinalprice: 0,
  },
  rowtypesList: [
    {
      name: "Materials Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0.07,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Quotes Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0.07,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Direct Labor Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Labor Factoring Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Incidental Labor Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Indirect Labor Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Equipment Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0.07,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "General Expense Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Subcontract Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
  ],
};
