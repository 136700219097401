import React, { useState } from "react";

import { Box, Button, TextField, Divider, Paper } from "@mui/material";
import { useGoogle_Login } from "../hooks/useGoogle_Login";
import { useUnityBuildStore } from "../states/store";
import { GetUserTokenRequest } from "../api/protosCompiled/userToken/userToken_pb";
import { clients } from "../clients/grpcClients";

export const LogIn: React.FC = () => {
  const googleLogin = useGoogle_Login();
  const {
    setIsFetchingToken,
    setSessionToken,
    setIsLoggedIn,
    setProjectListIsMounted,
  } = useUnityBuildStore();

  // State for manual login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleManualLogin = async () => {
    setIsFetchingToken(true);
    setError(null); // Clear previous errors
    try {
      // Create a new request object
      const request = new GetUserTokenRequest();
      request.setEmail(email);
      request.setPassword(password);

      // Make the gRPC call
      const response = await clients.userTokenServiceClient.getToken(request);

      if (response && response.getSessionToken()) {
        // Successful login
        setSessionToken(response.getSessionToken());
        setIsLoggedIn(true);
        setProjectListIsMounted(true);
      } else {
        // Handle login failure
        const responseError = response.getError();
        if (responseError) {
          setError(responseError); // Explicitly set the error from the response
        } else {
          setError("Invalid email or password."); // Fallback error message
        }
        setIsLoggedIn(false);
        setProjectListIsMounted(false);
      }
    } catch (err: any) {
      console.error("Error during manual login:", err);
      setError(
        err.message || "An error occurred during login. Please try again."
      );
    } finally {
      setIsFetchingToken(false);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        maxWidth: 400,
        width: "100%",
        padding: 3,
        borderRadius: 2,
        backgroundColor: "white",
      }}
    >
      {/* Display error prominently at the top */}
      {error && (
        <Box
          sx={{
            color: "red",
            marginBottom: 2,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {error}
        </Box>
      )}

      {/* Login Options */}
      <Box component="div" sx={{ marginBottom: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={googleLogin}
          fullWidth
          sx={{
            marginBottom: 2,
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          Log in with Google
        </Button>
        <Divider sx={{ marginY: 2 }}>OR</Divider>
      </Box>

      {/* Manual Login Form */}
      <Box component="form" noValidate autoComplete="off">
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleManualLogin}
          fullWidth
          sx={{ textTransform: "none", fontWeight: "bold", marginTop: 2 }}
        >
          Log In
        </Button>
      </Box>
    </Paper>
  );
};

export default LogIn;
