import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useFetchUoMs } from "../../../hooks/useFetchUoMs";
import { useGlobalItemStore } from "../../../states/globalItemStore";
import { useStore } from "zustand";

interface UnitOfMeasureSelectorProps {
  value: string | null;
  onChange: (value: string) => void;
}

export const UnitOfMeasureSelector: React.FC<UnitOfMeasureSelectorProps> = ({
  value,
  onChange,
}) => {
  const { currentItem } = useStore(useGlobalItemStore);
  const { data: uoms } = useFetchUoMs();

  return (
    <Box mb={2}>
      <FormHelperText>
        <Tooltip title="Choose the unit in which the item will be measured or sold (e.g., 'Feet', 'Pieces'). Note: This cannot be changed after it is set.">
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </FormHelperText>
      <FormControl fullWidth>
        <InputLabel id="uom-select-label">Unit of Measure (UOM)</InputLabel>
        <Select
          labelId="uom-select-label"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          disabled={
            currentItem
              ? currentItem?.costsList &&
                currentItem.costsList.length > 0 &&
                currentItem.costsList[0].uomid !== 0
              : true
          }
        >
          <MenuItem value="" disabled>
            Select UOM
          </MenuItem>
          {uoms &&
            uoms?.map((uom) => (
              <MenuItem key={uom.id} value={uom.id}>
                {uom.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};
