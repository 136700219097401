import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import {
  Breakout,
  CreateEstimateBreakoutResponse,
  CreateEstimateBreakoutRequest,
  GetEstimateBreakoutsResponse,
  GetEstimateBreakoutsRequest,
  UpdateEstimateBreakoutResponse,
  UpdateEstimateBreakoutRequest,
  DeleteEstimateBreakoutResponse,
  DeleteEstimateBreakoutRequest,
  DeleteChangeOrderBreakoutRequest,
  DeleteChangeOrderBreakoutResponse,
  UpdateChangeOrderBreakoutRequest,
  UpdateChangeOrderBreakoutResponse,
  CreateChangeOrderBreakoutResponse,
  CreateChangeOrderBreakoutRequest,
  GetChangeOrderBreakoutsRequest,
  GetChangeOrderBreakoutsResponse,
} from "./protosCompiled/breakout/breakout_pb";

export class BreakoutService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static createEstimateBreakout = (
    data: Breakout.AsObject
  ): Promise<CreateEstimateBreakoutResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const b = new Breakout();
      b.setTakeoffid(data.takeoffid);
      b.setBreakoutname(data.breakoutname);
      b.setMultiplier(data?.multiplier || 1);

      const req = new CreateEstimateBreakoutRequest();
      req.setBreakout(b);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.breakoutServiceClient.createEstimateBreakout(
        req,
        {},
        (err, response: CreateEstimateBreakoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static getEstimateBreakouts = (
    estimateId: string
  ): Promise<GetEstimateBreakoutsResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetEstimateBreakoutsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.breakoutServiceClient.getEstimateBreakouts(
        req,
        {},
        (err, response: GetEstimateBreakoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateEstimateBreakout = (
    data: Breakout.AsObject
  ): Promise<UpdateEstimateBreakoutResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const b = new Breakout();
      b.setId(data.id);
      b.setTakeoffid(data.takeoffid);
      b.setBreakoutname(data.breakoutname);
      b.setMultiplier(data?.multiplier || 1);

      const req = new UpdateEstimateBreakoutRequest();
      req.setBreakout(b);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.breakoutServiceClient.updateEstimateBreakout(
        req,
        {},
        (err, response: UpdateEstimateBreakoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static deleteEstimateBreakout = (
    breakoutId: string
  ): Promise<DeleteEstimateBreakoutResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new DeleteEstimateBreakoutRequest();
      req.setId(breakoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.breakoutServiceClient.deleteEstimateBreakout(
        req,
        {},
        (err, response: DeleteEstimateBreakoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static createChangeOrderBreakout = (
    data: Breakout.AsObject
  ): Promise<CreateChangeOrderBreakoutResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const b = new Breakout();
      b.setTakeoffid(data.takeoffid);
      b.setBreakoutname(data.breakoutname);
      b.setMultiplier(data?.multiplier || 1);

      const req = new CreateChangeOrderBreakoutRequest();
      req.setBreakout(b);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.breakoutServiceClient.createChangeOrderBreakout(
        req,
        {},
        (err, response: CreateChangeOrderBreakoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static getChangeOrderBreakouts = (
    changeOrderId: string
  ): Promise<GetChangeOrderBreakoutsResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetChangeOrderBreakoutsRequest();
      req.setChangeorderid(changeOrderId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.breakoutServiceClient.getChangeOrderBreakouts(
        req,
        {},
        (err, response: GetChangeOrderBreakoutsResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateChangeOrderBreakout = (
    data: Breakout.AsObject
  ): Promise<UpdateChangeOrderBreakoutResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const b = new Breakout();
      b.setId(data.id);
      b.setTakeoffid(data.takeoffid);
      b.setBreakoutname(data.breakoutname);
      b.setMultiplier(data?.multiplier || 1);

      const req = new UpdateChangeOrderBreakoutRequest();
      req.setBreakout(b);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.breakoutServiceClient.updateChangeOrderBreakout(
        req,
        {},
        (err, response: UpdateChangeOrderBreakoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static deleteChangeOrderBreakout = (
    breakoutId: string
  ): Promise<DeleteChangeOrderBreakoutResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new DeleteChangeOrderBreakoutRequest();
      req.setId(breakoutId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.breakoutServiceClient.deleteChangeOrderBreakout(
        req,
        {},
        (err, response: DeleteChangeOrderBreakoutResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
