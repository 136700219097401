import {
  ChangeOrderAssembly,
  ChangeOrderAssemblyItemAttribute,
  ChangeOrderBreakoutMap,
  ChangeOrderItemQtyFormula,
} from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import {
  EstimateAssembly,
  EstimateAssemblyItemAttribute,
  EstimateBreakoutMap,
  EstimateItemQtyFormula,
} from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ItemQtyFormula } from "../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { Breakout } from "../api/protosCompiled/breakout/breakout_pb";

export namespace AssemblyItemType {
  export interface AssemblyAsObject
    extends EstimateAssembly.AsObject,
      ChangeOrderAssembly.AsObject {}

  export const measurementTypes = ["count", "length", "area"] as const;
  export const takeoffVariableTypes = [
    "point",
    "segment",
    "length",
    "area",
  ] as const;

  export type MeasurementType = (typeof measurementTypes)[number];

  export type TakeoffVariableType = (typeof takeoffVariableTypes)[number];

  export interface TakeoffVariableQty {
    /**
     * A point is a single unit of measurement for included points.
     */
    point: number;
    /**
     * A segment is the space between two points for included segments / points.
     */
    segment: number;
    /**
     * A length is the distance between all points that are included in an annotation.
     */
    length: number;
    /**
     * Volume is how much an item can hold for included items.
     */
    volume: number;
    /**
     * Weight is how much an item weighs for included items.
     */
    weight: number;
  }

  export interface ItemQtyFormulaType extends ItemQtyFormula.AsObject {}
}

export type TakeoffAssembly =
  | EstimateAssembly.AsObject
  | ChangeOrderAssembly.AsObject;

export type TakeoffItemQtyFormula =
  | EstimateItemQtyFormula.AsObject
  | ChangeOrderItemQtyFormula.AsObject;

export type TakeoffAssemblyAttribute =
  | EstimateAssemblyItemAttribute.AsObject
  | ChangeOrderAssemblyItemAttribute.AsObject;

export type BreakOut = Breakout.AsObject;

export type BreakoutMap =
  | EstimateBreakoutMap.AsObject
  | ChangeOrderBreakoutMap.AsObject;
