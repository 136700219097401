import { CircularProgress } from "@mui/material";
import { ItemTableProps } from "../../../types/FolderTypes";
import { useStore } from "zustand";
import {
  defaultGlobalItem,
  useGlobalItemStore,
} from "../../../states/globalItemStore";
import { GlobalItem } from "../../../api/protosCompiled/globalItem/globalItem_pb";
import {
  useDeleteItem,
  useFetchItemsByFileId,
} from "../../../hooks/useFetchItemsByFileId";
import { useGlobalAssemblyStore } from "../../../states/globalAssemblyStore";
import { useGetAttributeGroupName } from "./attributes/helper";
import { useHandleMoveItemOrAssemblyFile } from "../folderManager/helpers";
import { useFolderStore } from "../../../states/folderStore";

export const ItemTable: React.FC<ItemTableProps> = ({ folder }) => {
  const { isMoveMode, setIsMoveMode } = useStore(useFolderStore);
  //react-query states
  const { data: globalItems, isLoading } = useFetchItemsByFileId(
    folder.folderid
  );
  const {
    isViewingItemForm,
    setCurrentItem,
    setIsViewingItemForm,
    setIsCreatingItem,
  } = useStore(useGlobalItemStore);

  //local states
  const { currentAssembly, setCurrentAssembly } = useStore(
    useGlobalAssemblyStore
  );

  //hooks
  const { mutate: deleteItem } = useDeleteItem();
  const getAttributeGroupName = useGetAttributeGroupName();
  const moveCurrentItemFile = useHandleMoveItemOrAssemblyFile();

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsCreatingItem(false);
    setIsMoveMode(false);
  };

  // Track unique attribute/item combinations
  const processedCombinations = new Set<string>();

  if (!globalItems || isLoading) {
    return (
      <tr>
        <td colSpan={4}>
          <CircularProgress />
        </td>
      </tr>
    );
  }

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    item: GlobalItem.AsObject
  ) => {
    e.stopPropagation();
    setIsViewingItemForm(true);
    setIsCreatingItem(false);
    setCurrentItem(item);
    handleCancel(e);
  };

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    item: GlobalItem.AsObject
  ) => {
    e.stopPropagation();
    deleteItem(item.folderid);
    setCurrentItem(defaultGlobalItem);
    handleCancel(e);
  };

  const handleAddItemToAssembly = (item: GlobalItem.AsObject) => {
    setIsViewingItemForm(false);
    setCurrentAssembly({
      ...currentAssembly,
      itemqtyformulasList: [
        ...currentAssembly.itemqtyformulasList,
        {
          itemid: item.folderid,
          itemname: item.name,
          itemqty: 1,
          forevery: 1,
          takeoffvariabletype: "point",
          and: false,
          or: false,
          isquoted: false,
          assemblyname: currentAssembly.assemblyname,
          attributegroupname: getAttributeGroupName(
            item.attributesList[0].attributegroupid
          ),
          attributegroupid: item.attributesList[0].attributegroupid,
          quotegroup: currentAssembly.itemqtyformulasList[0]?.quotegroup || "",
          // Transform item.attributesList -> AssemblyItemAttribute.AsObject[]
          attributesList: item.attributesList.map((attr) => ({
            // attributevalueid, attributegroupid, etc., come directly from the attr
            attributevalueid: attr.attributevalueid,
            attributegroupid: attr.attributegroupid,
            attributevaluename: attr.attributevaluename,
            lastupdated: new Date().toISOString(),
            attributegroupname: getAttributeGroupName(attr.attributegroupid),
          })),
        },
      ],
    });
  };

  return (
    <>
      {globalItems.map((item: GlobalItem.AsObject) =>
        item.attributesList.map((attribute) => {
          const uniqueKey = `${item.name}-${attribute.attributevaluename}`;
          if (processedCombinations.has(uniqueKey)) {
            // Skip duplicate combinations
            return null;
          }
          processedCombinations.add(uniqueKey);

          return (
            <tr key={uniqueKey} className="hoverable-row">
              <td>{attribute.attributevaluename || "N/A"}</td>
              <td>{item.name}</td>
              <td>{item.costsList[0]?.uomname || "N/A"}</td>
              <td>
                {isMoveMode && (
                  <button className="button" onClick={(e) => handleCancel(e)}>
                    Cancel Move Folder
                  </button>
                )}
                {!isViewingItemForm ? (
                  <button
                    className="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddItemToAssembly(item);
                    }}
                  >
                    <span style={{ color: "blue" }}>Add Item to Assembly</span>
                  </button>
                ) : null}

                <button
                  className="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(e, item);
                  }}
                >
                  Edit
                </button>

                <button
                  className="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    moveCurrentItemFile({
                      e: e,
                      item: item,
                      entitytype: "item",
                    });
                  }}
                >
                  Move
                </button>

                <button
                  className="button"
                  onClick={(e) => handleDelete(e, item)}
                  style={{ color: "red" }}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })
      )}
    </>
  );
};
