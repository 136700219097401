import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { useFetchAssemblyStats } from "../../hooks/useFetchCountedAssemblies";
import { useEffect, useState } from "react";
import { SOVHelper } from "./sovHelper";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableFooter,
} from "@mui/material";

export const ScheduleOfValues = () => {
  const { data: assemblyStats } = useFetchAssemblyStats();
  const {
    includedExtentionTypes,
    includedDirectLaborTypes,
    includedTotalPricingTypes,
  } = useStore(useCloseoutStore);
  const [aggregatedExtensionData, setAggregatedExtensionData] = useState<any[]>(
    []
  );

  const totalLaborCost = SOVHelper.totalLaborCost;
  const calculateItemMetrics = SOVHelper.calculateItemMetrics;
  const aggregateMetricsByBreakout = SOVHelper.aggregateMetricsByBreakout;
  const laborPercentofTotal = SOVHelper.laborPercentofTotal;

  const reduceTotalLaborHours = (extensionTypes: any[]) => {
    return extensionTypes.reduce(
      (sum, ext) => sum + (ext.totallaborhours || 0),
      0
    );
  };

  useEffect(() => {
    if (assemblyStats && includedExtentionTypes) {
      const metrics = calculateItemMetrics(
        assemblyStats,
        includedExtentionTypes
      );
      const aggregated = aggregateMetricsByBreakout(metrics);
      setAggregatedExtensionData(aggregated);
    }
    //eslint-disable-next-line
  }, [assemblyStats, includedExtentionTypes]);

  // Calculate totals for the footer
  const footerTotals = {
    totalBreakoutCost: aggregatedExtensionData.reduce(
      (sum, data) =>
        sum +
        data.totalLaborHours * totalLaborCost(includedDirectLaborTypes) +
        data.totalCost,
      0
    ),
    totalMaterialCost: aggregatedExtensionData.reduce(
      (sum, data) => sum + data.totalCost,
      0
    ),
    totalLaborHours: aggregatedExtensionData.reduce(
      (sum, data) => sum + data.totalLaborHours,
      0
    ),
    totalDirectLaborCost: aggregatedExtensionData.reduce(
      (sum, data) =>
        sum + data.totalLaborHours * totalLaborCost(includedDirectLaborTypes),
      0
    ),
  };

  return (
    <div style={{ padding: "16px" }}>
      <Typography variant="h6" align="center" gutterBottom>
        Allocation method = % of labor
        <Typography variant="body2" align="center" gutterBottom>
          {" "}
          (COMPONENT IS IN PROGRESS, IT DOES NOT YET ACCOUNT FOR EVERY CLOSEOUT
          EXPENSE)
        </Typography>
      </Typography>
      <TableContainer component={Paper} style={{ marginTop: "16px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Breakout Name
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Total Breakout Cost
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Material Cost
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Direct Labor Hours
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Direct Labor %
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Direct Labor Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aggregatedExtensionData
              .sort((a, b) => a.breakoutName.localeCompare(b.breakoutName))
              .map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{data.breakoutName}</TableCell>
                  <TableCell>
                    $
                    {(
                      data.totalLaborHours *
                        totalLaborCost(includedDirectLaborTypes) +
                      data.totalCost
                    ).toFixed(2)}
                  </TableCell>
                  <TableCell>${data.totalCost.toFixed(2)}</TableCell>
                  <TableCell>{data.totalLaborHours.toFixed(2)}</TableCell>
                  <TableCell>
                    %
                    {laborPercentofTotal({
                      rowLaborHours: data.totalLaborHours,
                      totalLaborHours: reduceTotalLaborHours(
                        includedExtentionTypes
                      ),
                    }).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    $
                    {(
                      data.totalLaborHours *
                      totalLaborCost(includedDirectLaborTypes)
                    ).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Totals</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                ${footerTotals.totalBreakoutCost.toFixed(2)}
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                ${footerTotals.totalMaterialCost.toFixed(2)}
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                {footerTotals.totalLaborHours.toFixed(2)}
              </TableCell>
              <TableCell></TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                ${footerTotals.totalDirectLaborCost.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
