import React from "react";
import { EstimateAssembly } from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { useUnityBuildStore } from "../../states/store";
import { AssemblyStyle, SVGShape } from "./SVGShape";
import { ChangeOrderAssembly } from "../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { useStore } from "zustand";
import { Point } from "../../types/Point";
import { TRANSPARENT_COLOR } from "../../utils/constants";

export interface SVGPointsProps {
  cursorPositionRef: any;
}

export const SVGPoints: React.FC<SVGPointsProps> = ({ cursorPositionRef }) => {
  const {
    selectedTakeoffAssembly,
    filteredCountedAssemblies,
    isDragging,
    isEditingPointBorderColor,
    isEditingPointFillColor,
    isEditingLineColor,
    selectedObjectId,
    setIsEditingPoint,
  } = useStore(useUnityBuildStore);

  return (
    <>
      {selectedObjectId &&
        filteredCountedAssemblies?.map(
          (
            assembly: EstimateAssembly.AsObject | ChangeOrderAssembly.AsObject,
            assemblyIndex: number
          ) =>
            assembly.pointsList.map((point: Point, index: any) => {
              const isFaint = point.isincludedincount === false;

              const assemblyStyle: AssemblyStyle = {
                pointType: assembly?.pointtype,
                pointSize: Number(assembly?.pointsize),
                pointBorderColor: isFaint
                  ? TRANSPARENT_COLOR
                  : assembly?.pointbordercolor,
                pointFillColor: isFaint
                  ? TRANSPARENT_COLOR
                  : assembly?.pointfillcolor,
                lineDashArray: assembly?.linedasharray,
                lineSize: Number(assembly?.linesize),
                lineColor: assembly?.linecolor,
                verticallength: assembly?.pointsList[0].verticallength,
                isSelected:
                  assembly.takeoffid === selectedTakeoffAssembly?.takeoffid,
              };

              return (
                <SVGShape
                  key={point.pointid}
                  point={point}
                  pointIndex={index}
                  assemblyIndex={assemblyIndex}
                  takeoffId={assembly.takeoffid}
                  assemblyStyle={assemblyStyle}
                  cursorPositionRef={cursorPositionRef}
                  verticalLength={point.verticallength || 0}
                />
              );
            })
        )}

      {/* Render shapes for the selected assembly's points */}
      {!isDragging &&
        !isEditingPointFillColor &&
        !isEditingPointBorderColor &&
        !isEditingLineColor &&
        selectedTakeoffAssembly?.pointsList?.map((point: any, index: any) => {
          const isFaint = point.isincludedincount === false;
          const halfSize = selectedTakeoffAssembly.pointsize
            ? Number(selectedTakeoffAssembly.pointsize) / 2
            : 5;

          return selectedTakeoffAssembly.pointtype === "circle" ? (
            <circle
              key={`selected-${index}-${point.x}-${point.y}`}
              cx={point.x}
              cy={point.y}
              r={selectedTakeoffAssembly.pointsize || 5}
              fill={
                isFaint
                  ? TRANSPARENT_COLOR
                  : selectedTakeoffAssembly.pointfillcolor || "red"
              }
              stroke={
                isFaint
                  ? TRANSPARENT_COLOR
                  : selectedTakeoffAssembly.pointbordercolor || "black"
              }
              onContextMenu={(e) => {
                e.preventDefault();
                setIsEditingPoint({
                  isEditingPoint: true,
                  x: e.clientX,
                  y: e.clientY,
                  pointIndex: index, // This is the "pointIndex"
                  lineIndex: null,
                });
              }}
            />
          ) : (
            <rect
              key={`selected-${index}-${point.x}-${point.y}`}
              x={point.x - halfSize}
              y={point.y - halfSize}
              width={selectedTakeoffAssembly.pointsize || 10}
              height={selectedTakeoffAssembly.pointsize || 10}
              fill={
                isFaint
                  ? TRANSPARENT_COLOR
                  : selectedTakeoffAssembly.pointfillcolor || "red"
              }
              stroke={
                isFaint
                  ? TRANSPARENT_COLOR
                  : selectedTakeoffAssembly.pointbordercolor || "black"
              }
            />
          );
        })}
    </>
  );
};
