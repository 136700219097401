import {
  Box,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Consistent styles for labels and notes
export const createItemstyle: React.CSSProperties = {
  display: "block",
  marginBottom: "6px",
  marginLeft: "8px", // space out labels from inputs
  marginRight: "8px", // space out labels from inputs
  width: "50%", // make labels full width
};

// Consistent full-width input
export const createItemstyleFW: React.CSSProperties = {
  display: "block",
  width: "100%",
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginBottom: "16px",
};

interface GenericItemNameInputProps {
  value: string;
  onChange: (newValue: string) => void;
}

export const GenericItemNameInput: React.FC<GenericItemNameInputProps> = ({
  value,
  onChange,
}) => {
  return (
    <Box mb={2}>
      <TextField
        label="Generic Item Name"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter generic item name"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Use a general descriptor for the item, such as 'EMT' or 'Wirenut'. Avoid including specific attributes like size or color in this field; those should be set as attributes.">
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
