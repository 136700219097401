import { useQuery } from "react-query";
import { ItemService } from "../api/GlobalItemService";
import { useUnityBuildStore } from "../states/store";

export const useFetchAllowedVariables = () => {
  const { data, error, isLoading } = useQuery(
    "allowedVariables",
    async () => {
      const response = await ItemService.getAllowedVariables();
      return response.allowedvariablesList;
    },
    {
      enabled: !!useUnityBuildStore.getState().sessionToken,
    }
  );
  return { data, error, isLoading };
};
