export interface LocalExtensionType {
  attributegroupname: string;
  hourlyproductionrate: number;
  isquoted: boolean;
  itemId: string;
  itemqty: number;
  itemtotalcost: number;
  itemunitcost: number;
  itemuom: string;
  itemuomvalue: number;
  name: string;
  overrideproductionrate: boolean;
  overrideunitcost: boolean;
  quotegroup: string;
  totalQuantity: number;
  totalcost: number;
  totallaborhours: number;
}

export class SOVHelper {
  static normalizeName = (name: string) => {
    return name.replace(/\s+/g, "").replace(/"/g, "").toLowerCase();
  };

  static aggregateMetricsByBreakout = (metrics: any[]) => {
    const aggregated: { [key: string]: any } = {};

    metrics.forEach((metric) => {
      const { breakoutName, totalQuantity, totalCost, totalLaborHours } =
        metric;

      if (!aggregated[breakoutName]) {
        aggregated[breakoutName] = {
          breakoutName,
          totalQuantity: 0,
          totalCost: 0,
          totalLaborHours: 0,
        };
      }

      aggregated[breakoutName].totalQuantity += totalQuantity || 0;
      aggregated[breakoutName].totalCost += totalCost || 0;
      aggregated[breakoutName].totalLaborHours += totalLaborHours || 0;
    });

    return Object.values(aggregated);
  };

  static calculateItemMetrics = (
    assemblyStats: any[],
    includedExtentionTypes: any[]
  ) => {
    const results: any[] = [];
    const processedNames = new Set<string>(); // Track processed names globally

    assemblyStats?.forEach((assembly) => {
      assembly.globalitemsList.forEach((item: any) => {
        const breakoutName =
          item.itemqtyformulasList?.[0]?.breakoutsList?.[0]?.breakoutname || "";

        const assemblyItemName =
          item.itemqtyformulasList?.[0]?.attributesList?.[0]
            ?.attributevaluename +
          "-" +
          item.itemname;

        const normalizedAssemblyName =
          SOVHelper.normalizeName(assemblyItemName);

        // Check if the item is quoted
        const isQuoted = item.itemqtyformulasList?.[0]?.isquoted || false;

        // Match based on whether the item is quoted
        const matchingExtensions = includedExtentionTypes.filter((ext: any) =>
          isQuoted
            ? SOVHelper.normalizeName(ext.name) ===
              SOVHelper.normalizeName(
                item.itemqtyformulasList?.[0]?.assemblyname
              )
            : SOVHelper.normalizeName(ext.name) === normalizedAssemblyName
        );

        matchingExtensions.forEach((matchingExtension) => {
          // Ensure each unique name is processed only once
          console.log("matchingExtension", matchingExtension);
          if (processedNames.has(matchingExtension.name)) {
            return;
          }

          const itemQuantity = item.itemqtyformulasList?.[0]?.itemqty || 0;
          const multiplier =
            item.itemqtyformulasList?.[0]?.breakoutsList?.[0]?.multiplier || 1;
          const totalQuantity = itemQuantity * multiplier;
          const totalCost = matchingExtension.totalcost;
          const totalLaborHours = matchingExtension.totallaborhours;

          results.push({
            breakoutName,
            totalQuantity,
            totalCost,
            totalLaborHours,
          });

          // Mark the name as processed
          processedNames.add(matchingExtension.name);
        });
      });
    });

    return results;
  };

  static totalLaborCost = (
    labor: { totalcost: number; allocatedhours: number }[]
  ) => {
    if (!labor || labor.length === 0) return 0; // Handle empty or undefined input

    // Calculate the average labor rate based on total cost and allocated hours
    const totalRate = labor.reduce((sum, l) => {
      const rate = l.totalcost / l.allocatedhours;
      return sum + rate;
    }, 0);
    return totalRate / labor.length;
  };

  static laborPercentofTotal = (labor: {
    rowLaborHours: number;
    totalLaborHours: number;
  }): number => {
    return (labor.rowLaborHours / labor.totalLaborHours) * 100;
  };
}
