import { Collapse } from "@mui/material";
import { useState } from "react";
import { FolderIcon } from "../../../icons/folderIcon";
import { ItemTable } from "../items/ItemTable";
import React from "react";
import {
  CollapsibleRowProps,
  defaultFolder,
  FolderNode,
} from "../../../types/FolderTypes";
import {
  buildFolderTree,
  deleteFolderHelper,
  createFolderHelper,
  mapFoldersToFolderNodes,
  renameFolderHelper,
  isCreatingInFolder,
  useHandleMoveCurrentFolder,
} from "./helpers";
import { AssemblyTable } from "../assemblies/AssemblyTable";
import { useStore } from "zustand";
import { useGlobalAssemblyStore } from "../../../states/globalAssemblyStore";
import {
  useUpdateProjectFolder,
  useUpdateAssemblyFolder,
  useUpdateItemFolder,
  useCreateAssemblyFolderMutation,
  useCreateItemFolderMutation,
  useCreateProjectFolderMutation,
  useDeleteAssemblyFolderMutation,
  useDeleteItemFolderMutation,
  useDeleteProjectFolderMutation,
} from "../../../hooks/useFetchFolders";
import { useGlobalItemStore } from "../../../states/globalItemStore";
import { useFolderStore } from "../../../states/folderStore";

/**
 * FolderNodes is responsible for rendering the folders from the FolderService
 * the data we pass to it is dependent on the queryies entity type
 */
export const FolderNodes = ({
  context,
  folderType,
  folderData,
}: CollapsibleRowProps) => {
  //zustand states
  const { isMoveMode, setIsMoveMode } = useStore(useFolderStore);
  const { isCreatingAssembly } = useStore(useGlobalAssemblyStore);
  const { isViewingItemForm, isCreatingItem } = useStore(useGlobalItemStore);
  const {
    currentFolder,
    setCurrentFolder,
    openRows,
    toggleRow,
    editingFolderId,
    setEditingFolderId,
  } = useStore(useFolderStore);

  const [tempFolderName, setTempFolderName] = useState<string>("");
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);

  //react-query mutations
  const { mutate: createProjectFolder } = useCreateProjectFolderMutation();
  const { mutate: createAssemblyFolder } = useCreateAssemblyFolderMutation();
  const { mutate: createItemFolder } = useCreateItemFolderMutation();

  const { mutate: updateProjectFolder } = useUpdateProjectFolder();
  const { mutate: updateAssemblyFolder } = useUpdateAssemblyFolder();
  const { mutate: updateItemFolder } = useUpdateItemFolder();

  const { mutate: deleteProjectFolder } = useDeleteProjectFolderMutation();
  const { mutate: deleteAssemblyFolder } = useDeleteAssemblyFolderMutation();
  const { mutate: deleteItemFolder } = useDeleteItemFolderMutation();

  //hooks
  const moveCurrentFolder = useHandleMoveCurrentFolder();

  //cancels actions for the buttons
  const cancelOperation = () => {
    setIsMoveMode(false);
    setCurrentFolder(defaultFolder);
  };

  //builds the folder tree -- seems that we could sent this built from the server --- but this is fine for now
  const folderTree: FolderNode[] = folderData?.foldersList?.length
    ? buildFolderTree(
        folderType,
        mapFoldersToFolderNodes(folderData.foldersList)
      )
    : [];

  //handles the move mode and sets the current folder that we will be moving
  const handleSetMoveModeforCurrentFolder = (
    e: React.MouseEvent<HTMLButtonElement>,
    folder: FolderNode
  ) => {
    e.stopPropagation();
    if (isMoveMode && currentFolder) {
      console.warn("Already in move mode for another folder.");
      return;
    }
    setIsMoveMode(true);
    setCurrentFolder(folder);
  };

  //handles the create folder action
  const handleCreateFolder = (
    e: React.MouseEvent<HTMLButtonElement>,
    folder: FolderNode
  ) => {
    e.stopPropagation();
    createFolderHelper(
      e,
      folder,
      createProjectFolder,
      createAssemblyFolder,
      createItemFolder
    );
  };

  const handleRenameFolder = (
    e: React.KeyboardEvent<HTMLInputElement>,
    folder: FolderNode
  ) => {
    e.stopPropagation();
    renameFolderHelper(
      e,
      folder,
      updateProjectFolder,
      updateAssemblyFolder,
      updateItemFolder
    );
  };

  //handles the delete folder action
  const deleteFolder = (
    e: React.MouseEvent<HTMLButtonElement>,
    folder: FolderNode
  ) => {
    e.stopPropagation();
    deleteFolderHelper(
      e,
      folder,
      deleteProjectFolder,
      deleteAssemblyFolder,
      deleteItemFolder
    );
  };

  //renders the folder rows
  const renderFolderRow = (folder: FolderNode, nestedDepth: number) => {
    const isOpen = openRows[folder.folderid] || false;

    const handleEditStart = () => {
      setEditingFolderId(folder.folderid);
      setTempFolderName(folder.name);
    };

    const handleEditFinish = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleRenameFolder(e, folder);
        setEditingFolderId(null);
      }
    };

    const handleEditCancel = () => {
      setEditingFolderId(null);
      setTempFolderName("");
    };

    // Handles click events for toggling or renaming
    const handleFolderClick = (
      e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      folder: FolderNode
    ) => {
      if ((e.target as HTMLElement).tagName === "BUTTON") {
        return;
      }
      e.stopPropagation();

      if (clickTimeout) {
        // If there's an active timeout, it means this is a double-click
        clearTimeout(clickTimeout);
        setClickTimeout(null);
        handleEditStart();
      } else {
        // Set a timeout for single-click to handle toggle
        const timeout = setTimeout(() => {
          if (isMoveMode) {
            moveCurrentFolder({
              e: e,
              isMoveMode: isMoveMode,
              setIsMoveMode: setIsMoveMode,
              destinationFolder: folder,
              folderBeingMoved: currentFolder,
            });
          } else {
            toggleRow(folder.folderid);
          }
          setClickTimeout(null);
        }, 250); // 250ms delay to distinguish between single and double click
        setClickTimeout(timeout);
        if (isCreatingAssembly && folder.entitytype === "assembly") {
          setCurrentFolder(folder);
        } else if (isCreatingItem && folder.entitytype === "item") {
          setCurrentFolder(folder);
        }
      }
    };

    // Render the folder row
    const folderRow = (
      <tr
        key={folder.folderid}
        onClick={(e) => handleFolderClick(e, folder)}
        style={{
          cursor: "pointer",
          backgroundColor:
            !isMoveMode && isCreatingInFolder(folder, isViewingItemForm)
              ? "lightGreen"
              : "",
        }}
      >
        <td
          style={{
            textAlign: "left",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            paddingLeft: `${nestedDepth * 20}px`,
          }}
          onDoubleClick={handleEditStart}
        >
          <span style={{ marginRight: "10px" }}>
            <FolderIcon isOpen={isOpen} />
          </span>
          {editingFolderId === folder.folderid ? (
            <input
              type="text"
              value={tempFolderName}
              onChange={(e) => setTempFolderName(e.target.value)}
              onKeyDown={handleEditFinish}
              onBlur={handleEditCancel}
              autoFocus
              style={{
                width: "100%",
                padding: "4px",
                fontSize: "inherit",
              }}
            />
          ) : (
            folder.name
          )}
        </td>
        <td style={{ textAlign: "right" }}>
          {isMoveMode && currentFolder?.folderid === folder.folderid && (
            <strong>Select the destination folder</strong>
          )}
          {isMoveMode && currentFolder?.folderid === folder.folderid && (
            <button onClick={cancelOperation}>Cancel</button>
          )}
          {folder.parentid && context === "primary" && (
            <>
              <button
                className="button"
                style={{ color: "blue" }}
                onClick={(e) => handleSetMoveModeforCurrentFolder(e, folder)}
                disabled={
                  isMoveMode && currentFolder?.folderid === folder.folderid
                }
              >
                Move Folder
              </button>
            </>
          )}
          {context === "primary" && folder.level !== 5 && (
            <button
              className="button"
              style={{ color: "green" }}
              onClick={(e) => handleCreateFolder(e, folder)}
            >
              Create Folder
            </button>
          )}
          {folder.parentid && context === "primary" && (
            <>
              <button
                className="button"
                onClick={(e) => deleteFolder(e, folder)}
              >
                <span style={{ color: "red" }}>Delete</span>
              </button>
            </>
          )}
        </td>
      </tr>
    );

    // Render children if the folder is open
    const childRows = isOpen ? (
      <tr>
        <td colSpan={2} style={{ padding: 0 }}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <table key={Math.random()} style={{ width: "100%" }}>
              <tbody>{renderChildRows(folder.children, nestedDepth + 1)}</tbody>
            </table>
          </Collapse>
        </td>
      </tr>
    ) : null;

    return (
      <React.Fragment key={folder.folderid}>
        {folderRow}
        {childRows}
      </React.Fragment>
    );
  };

  const renderChildRows = (
    children: (FolderNode | undefined)[],
    nestedDepth: number
  ) => {
    const definedChildren = children.filter(
      (child): child is FolderNode => child !== undefined
    );

    const assemblyFiles = definedChildren.filter(
      (child) => child.isfile && child.entitytype === "assembly"
    );
    const itemFiles = definedChildren.filter(
      (child) => child.isfile && child.entitytype === "item"
    );
    const otherChildren = definedChildren.filter((child) => !child.isfile);

    const rows = [];

    // Render non-item and non-assembly folders
    otherChildren.forEach((child) => {
      rows.push(renderFolderRow(child, nestedDepth));
    });

    // Render grouped item files under a single header
    if (itemFiles.length > 0) {
      rows.push(
        <tr key={`item-header-${nestedDepth}`}>
          <td colSpan={2} style={{ paddingLeft: `${nestedDepth * 20}px` }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>Attribute</th>
                  <th>Description</th>
                  <th style={{ width: "15%" }}>UOM</th>
                  <th style={{ width: "15%" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {itemFiles?.map((item, index) => (
                  <ItemTable
                    context={context}
                    key={item.folderid || index}
                    folder={item}
                  />
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      );
    }

    // Render grouped assembly files under a single header
    if (assemblyFiles.length > 0) {
      rows.push(
        <tr key={`assembly-header-${nestedDepth}`}>
          <td colSpan={2} style={{ paddingLeft: `${nestedDepth * 20}px` }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Assembly Name (Click to takeoff)</th>
                  <th>Measurement Type</th>
                  {context === "primary" ? <th>Actions</th> : null}
                </tr>
              </thead>
              <tbody>
                {assemblyFiles.map((assemblyFolder) => (
                  <AssemblyTable
                    context={context}
                    key={assemblyFolder.folderid}
                    folder={assemblyFolder}
                  />
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      );
    }

    return rows;
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Folder Tree */}
      <table style={{ width: "100%" }}>
        <tbody>{folderTree.map((folder) => renderFolderRow(folder, 0))}</tbody>
      </table>
    </div>
  );
};
