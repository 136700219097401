import React, { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { Breakout } from "../../api/protosCompiled/breakout/breakout_pb";
import {
  useFetchChangeOrderBreakouts,
  useFetchEstimateBreakouts,
} from "../../hooks/useFetchBreakout";
import { TakeoffAssembly } from "../../types/AssemblyItemType";

type BreakoutSelectorProps = {
  takeoffAssembly?: TakeoffAssembly;
  context?: contextType;
};

type contextType = "takeoffPallette" | "createAssembly";

export const BreakoutSelector = ({
  takeoffAssembly,
  context = "takeoffPallette",
}: BreakoutSelectorProps) => {
  const { selectedEstimate, selectedChangeOrder, setSelectedBreakout } =
    useStore(useUnityBuildStore);
  const [localBreakouts, setLocalBreakouts] = useState<Breakout.AsObject[]>([]);
  const [selectedBreakoutId, setSelectedBreakoutId] = useState<string>("");

  // Fetch breakouts from estimate or change order
  const { data: estimateBreakouts } = useFetchEstimateBreakouts(
    selectedEstimate.estimateid ?? ""
  );
  const { data: changeOrderBreakouts } = useFetchChangeOrderBreakouts(
    selectedChangeOrder.contractid ?? ""
  );

  useEffect(() => {
    if (estimateBreakouts || changeOrderBreakouts) {
      setLocalBreakouts(
        estimateBreakouts?.breakoutsList ??
          changeOrderBreakouts?.breakoutsList ??
          []
      );
    }
  }, [estimateBreakouts, changeOrderBreakouts]);

  // Set the selected breakout from the takeoffAssembly when it is provided
  useEffect(() => {
    try {
      if (
        takeoffAssembly?.itemqtyformulasList[0]?.breakoutsList?.length &&
        context === "createAssembly"
      ) {
        const currentBreakoutId =
          takeoffAssembly.itemqtyformulasList[0].breakoutsList[0]?.breakoutid;
        setSelectedBreakoutId(currentBreakoutId);
        const currentBreakout = localBreakouts.find(
          (breakout) => breakout.id === currentBreakoutId
        );
        if (currentBreakout) {
          setSelectedBreakout(currentBreakout);
        }
      }
    } catch (error) {
      console.error("Error setting selected breakout:", error);
    }
  }, [takeoffAssembly, localBreakouts, setSelectedBreakout, context]);

  // Handler to update the selected breakout
  const handleSetSelectedBreakout = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const breakoutId = e.target.value;
    setSelectedBreakoutId(breakoutId);
    const selectedBreakout = localBreakouts.find(
      (breakout) => breakout.id === breakoutId
    );
    if (selectedBreakout) {
      setSelectedBreakout(selectedBreakout);
    } else {
      // setSelectedBreakout(null);
    }
  };

  return (
    <select
      onChange={handleSetSelectedBreakout}
      value={selectedBreakoutId} // Controlled value
      style={{
        maxWidth: "90%",
        width: "50%",
        boxSizing: "border-box",
      }}
    >
      <option value="">Select Breakout</option>
      {localBreakouts.map((breakout) => (
        <option key={breakout.id} value={breakout.id}>
          {`${breakout.breakoutname} (${breakout.multiplier}x)`}
        </option>
      ))}
    </select>
  );
};
