import { useState } from "react";
import { DefaultCloseoutTypeKey } from "./CloseoutTypes";
import { DEFAULT_CLOSEOUT_TYPE_OPTIONS } from "./CloseoutTypes";
import { renderTable } from "./closetoutHelpers";

export const CloseoutSettings = () => {
  const [selectedType, setSelectedType] =
    useState<DefaultCloseoutTypeKey>("directlaborList");
  const [isClearingFocus, setIsClearingFocus] = useState(false);

  const clearFocus = () => {
    setIsClearingFocus(true);
    const activeElement = document.activeElement;
    if (activeElement instanceof HTMLElement) {
      activeElement.blur(); // Clear focus from the currently active element
    }
    setTimeout(() => setIsClearingFocus(false), 0); // Reset after clearing
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value as DefaultCloseoutTypeKey;
    setSelectedType(newValue);
  };

  return (
    <>
      <select
        value={selectedType}
        onFocus={clearFocus} // Clear focus when the dropdown gains focus
        onChange={handleChange}
      >
        {DEFAULT_CLOSEOUT_TYPE_OPTIONS.map((opt) => (
          <option key={opt.key} value={opt.key}>
            {opt.label}
          </option>
        ))}
      </select>
      <table style={{ width: "100%" }}>
        <tbody>{renderTable(selectedType, clearFocus, isClearingFocus)}</tbody>
      </table>
    </>
  );
};
