import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import { Email, SendEmailRequest } from "./protosCompiled/email/email_pb";

const client = clients.emailServiceClient;

export class EmailService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static async sendEmail(emailObj: Email.AsObject): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const req = new SendEmailRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);

      const emailMessage = new Email();
      emailMessage.setFrom(emailObj.from);
      emailMessage.setToList(emailObj.toList);
      emailMessage.setSubject(emailObj.subject);
      emailMessage.setPlaintextcontent(emailObj.plaintextcontent);
      emailMessage.setHtmlcontent(emailObj.htmlcontent);

      req.setEmail(emailMessage);

      client.sendEmail(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }

        const resObj = response.toObject();
        if (resObj.success) {
          resolve(true);
        } else {
          console.error("Failed to send email:", resObj.errorMessage);
          resolve(false);
        }
      });
    });
  }
}
