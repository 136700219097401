import {
  useCreateGlobalAssemblyMutation,
  useDeleteGlobalAssemblyMutation,
  useFetchAssembliesByFileId,
} from "../../../hooks/useFetchAssembliesByFileId";
import { useSetSelectedAssemblyFromAssemblyPicker } from "../../../hooks/useSetSelectedAssembly";
import { CircularProgress } from "@mui/material";
import {
  AssemblyOptions,
  AssemblyTableProps,
} from "../../../types/FolderTypes";
import { GlobalAssembly } from "../../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { useStore } from "zustand";
import { useGlobalAssemblyStore } from "../../../states/globalAssemblyStore";
import { useGlobalItemStore } from "../../../states/globalItemStore";
import { useHandleMoveItemOrAssemblyFile } from "../folderManager/helpers";
import { useFolderStore } from "../../../states/folderStore";

export type EditAssemblyProps = {
  e: React.MouseEvent<HTMLButtonElement>;
  assembly: GlobalAssembly.AsObject;
  assemblyOption: AssemblyOptions;
  setCurrentAssembly: React.Dispatch<
    React.SetStateAction<GlobalAssembly.AsObject | null>
  >;
  setIsViewingAssemblyForm: React.Dispatch<React.SetStateAction<boolean>>;
  setIsViewingItemForm: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreatingItem: React.Dispatch<React.SetStateAction<boolean>>;
  // setAssemblyOption: React.Dispatch<React.SetStateAction<AssemblyOptions>>;
  handleCancel?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const useEditAssembly = () => {
  const { setAssemblyOption, setIsCreatingAssembly } = useStore(
    useGlobalAssemblyStore
  );
  const editAssembly = (_: EditAssemblyProps) => {
    _.e.stopPropagation();
    _.setCurrentAssembly(_.assembly);
    _.setIsViewingAssemblyForm(true);
    _.setIsViewingItemForm(false);
    _.setIsCreatingItem(false);
    setIsCreatingAssembly(false);
    setAssemblyOption(_.assemblyOption);
    if (_.handleCancel) {
      _.handleCancel(_.e);
    }
  };
  return editAssembly;
};

export function AssemblyTable({ context, folder }: AssemblyTableProps) {
  //zustand states
  const { isMoveMode, setIsMoveMode } = useStore(useFolderStore);
  const { setIsViewingItemForm, setIsCreatingItem } =
    useStore(useGlobalItemStore);

  const { setIsViewingAssemblyForm, isCreatingAssembly } = useStore(
    useGlobalAssemblyStore
  );

  const { setCurrentAssembly, setIsCreatingAssembly } = useStore(
    useGlobalAssemblyStore
  );

  const moveCurrentAssemblyFile = useHandleMoveItemOrAssemblyFile();
  //react-query states
  const { data: globalAssemblies, isLoading } = useFetchAssembliesByFileId(
    folder.folderid
  );
  const { mutate: createGlobalAssembly } = useCreateGlobalAssemblyMutation();

  //hooks
  //this setter is for selecting a global assembly and appending to it the default values required for takeoff assemblies
  const setSelectedAssembly = useSetSelectedAssemblyFromAssemblyPicker();
  const { mutate: deleteGlobalAssembly } = useDeleteGlobalAssemblyMutation();

  const handleSetSelectedAssembly = (
    e: React.MouseEvent<HTMLElement>,
    assembly: GlobalAssembly.AsObject
  ) => {
    e.stopPropagation();
    setSelectedAssembly(assembly);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsCreatingAssembly(false);
    setIsMoveMode(false);
  };

  const editAssembly = useEditAssembly();

  const handleEditAssembly = (d: EditAssemblyProps) => {
    d.e.stopPropagation();
    editAssembly(d);
    setIsMoveMode(false);
  };

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    assembly: GlobalAssembly.AsObject
  ) => {
    e.stopPropagation();
    deleteGlobalAssembly(assembly);
    handleCancel(e);
  };

  const handleCopy = (
    e: React.MouseEvent<HTMLButtonElement>,
    assembly: GlobalAssembly.AsObject
  ) => {
    e.stopPropagation();
    const updatedAssembly = {
      ...assembly,
      assemblyname: assembly.assemblyname + " (Copy)",
    };
    createGlobalAssembly(updatedAssembly);
    setIsMoveMode(false);
  };

  if (!globalAssemblies || isLoading) {
    return (
      <tr>
        <td colSpan={3}>
          <CircularProgress />
        </td>
      </tr>
    );
  }

  return (
    <>
      {globalAssemblies.map((assembly: GlobalAssembly.AsObject) => (
        <tr
          key={assembly.globalassemblyid}
          onClick={(e) => {
            if (!isCreatingAssembly) {
              handleSetSelectedAssembly(e, assembly);
            }
          }}
          className="hoverable-row"
        >
          <td>
            {assembly.itemqtyformulasList.length === 0 ? (
              <span style={{ color: "red" }}>No Items! </span>
            ) : null}
            {assembly.assemblyname}
          </td>
          <td>{assembly.assemblymeasurementtype}</td>
          {context === "primary" && (
            <>
              <td>
                {isMoveMode && (
                  <button className="button" onClick={(e) => handleCancel(e)}>
                    Cancel Move Folder
                  </button>
                )}
                <button
                  className="button"
                  onClick={(e) =>
                    handleEditAssembly({
                      e: e,
                      assembly: assembly,
                      setCurrentAssembly: setCurrentAssembly as any,
                      setIsViewingAssemblyForm: setIsViewingAssemblyForm as any,
                      setIsViewingItemForm: setIsViewingItemForm as any,
                      setIsCreatingItem: setIsCreatingItem as any,
                      assemblyOption: "global",
                      handleCancel: handleCancel,
                    })
                  }
                >
                  Edit Assembly
                </button>{" "}
                <button
                  className="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    moveCurrentAssemblyFile({
                      e: e,
                      assembly: assembly,
                      entitytype: "assembly",
                    });
                  }}
                >
                  Move
                </button>
                <button
                  className="button"
                  onClick={(e) => handleCopy(e, assembly)}
                >
                  Copy
                </button>
                <button
                  className="button"
                  onClick={(e) => handleDelete(e, assembly)}
                  style={{ color: "red" }}
                >
                  Delete
                </button>
              </td>
            </>
          )}
        </tr>
      ))}
    </>
  );
}
