import { useMutation, useQueryClient } from "react-query";

import { UB_AccountUser } from "../api/protosCompiled/ub_users/ub_users_pb";
import { UbUserService } from "../api/UbUserService";

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UB_AccountUser.AsObject) => UbUserService.createUser(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
      onError: (error) => {
        console.error("Error creating user", error);
      },
    }
  );
};

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UB_AccountUser.AsObject) => UbUserService.updateUser(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
      onError: (error) => {
        console.error("Error creating user", error);
      },
    }
  );
};
