import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import { UploadDocument } from "./UploadDocument";
import { DocumentManager } from "./DocumentManager ";

export const DocumentManagerToggle = ({ onClose }: { onClose: () => void }) => {
  const [view, setView] = useState<"upload" | "manage">("manage");

  useEffect(() => {
    // Disable scrolling on the body when modal is open
    document.body.style.overflow = "hidden";
    document.body.style.margin = "0";

    return () => {
      // Restore body scrolling when modal is closed
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Box
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim background
        zIndex: 1300,
      }}
    >
      <Box
        style={{
          width: "90%", // Use most of the screen width
          height: "90%", // Use most of the screen height
          backgroundColor: "white",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        {/* Header with Buttons */}
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Button
            variant={view === "upload" ? "contained" : "outlined"}
            onClick={() => setView("upload")}
          >
            Upload Document
          </Button>
          <Button
            variant={view === "manage" ? "contained" : "outlined"}
            onClick={() => setView("manage")}
          >
            Manage Documents
          </Button>
          <Button onClick={onClose} style={{ marginLeft: "auto" }}>
            Close
          </Button>
        </Box>

        {/* Toggled Content Area */}
        <Box
          style={{
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden",
            padding: "16px",
          }}
        >
          {view === "upload" && <UploadDocument />}
          {view === "manage" && <DocumentManager />}
        </Box>
      </Box>
    </Box>
  );
};
