import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { CircularProgress } from "@mui/material";
import { useStore } from "zustand";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import chatBotImage from "../images/ChatBot.png";
import { useUnityBuildStore } from "../states/store";
import { useListMessagesOnThread } from "../hooks/useListMessagesOnThread";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AssistantService } from "../api/AssistantService";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import { useFetchUser } from "../hooks/useFetchUsers";

type Who = "user" | "assistant";
type ChatMessage = {
  who: Who;
  text: string;
  imageUrl?: string;
};
type ChatWindowProps = {
  scrollDown: () => void;
};

const MemoizedChatMessage = React.memo(
  ({ message }: { message: ChatMessage }) => {
    return (
      <ListItem>
        <Paper
          elevation={10}
          sx={{
            backgroundColor:
              message.who === "user" ? "lightblue" : "lightyellow",
            padding: 1,
            borderRadius: 2,
            maxWidth: "80%",
            wordWrap: "break-word",
            marginLeft: message.who === "user" ? "auto" : 0,
          }}
        >
          {message.imageUrl && (
            <img
              src={message.imageUrl}
              alt={message.who === "user" ? "user" : "assistant"}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          )}
          <br />
          {renderBlock(message.text)}
        </Paper>
      </ListItem>
    );
  }
);

export function ChatWindow({ scrollDown }: ChatWindowProps) {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [userInput, setUserInput] = useState("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const { selectedProject } = useStore(useUnityBuildStore);
  const { data: messageData, isLoading } = useListMessagesOnThread(
    selectedProject.id
  );

  const { data: user } = useFetchUser();

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  // Fetch messages and map them to the ChatMessage type
  useEffect(() => {
    if (!messageData) return;
    const fetchMessages = async () => {
      try {
        const mappedMessages: ChatMessage[] = messageData.map((message) => {
          const who = message.role === "assistant" ? "assistant" : "user";
          let text = "";
          if (message.contentList && message.contentList.length > 0) {
            const content = message.contentList[0];
            if (content.type === "text" && content.text?.value) {
              text = content.text.value;
            }
          }
          const imageUrl =
            who === "assistant" ? chatBotImage : user?.user?.userphotourl;
          return { who, text, imageUrl };
        });
        setMessages(mappedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    if (selectedProject.id) {
      fetchMessages();
    }
  }, [selectedProject.id, messageData, user]);

  // Scroll to the bottom whenever messages are updated
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const appendMessageDisplay = useCallback(
    (who: Who, text: string, imageUrl?: string) => {
      setMessages((prevMessages) => {
        if (
          prevMessages.length > 0 &&
          prevMessages[prevMessages.length - 1].who === who
        ) {
          const lastMessage = prevMessages[prevMessages.length - 1];
          return [
            ...prevMessages.slice(0, prevMessages.length - 1),
            { ...lastMessage, text: lastMessage.text + text },
          ];
        }
        return [...prevMessages, { who, text, imageUrl }];
      });
    },
    [setMessages]
  );

  async function sendMessage(userInput: string) {
    if (userInput.trim() !== "") {
      appendMessageDisplay("user", userInput.trim(), user?.user?.userphotourl);
      try {
        await AssistantService.streamMessage({
          usermessage: userInput.trim(),
          projectid: selectedProject.id,
          onMessage: (streamedMessage: string) => {
            appendMessageDisplay("assistant", streamedMessage, chatBotImage);
          },
        });
      } catch (error) {
        console.error("Error sending message:", error);
      }
      setUserInput("");
    }
  }

  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (userInput.trim() !== "") {
        sendMessage(userInput.trim());
      }
    }
  };

  const renderedMessages = useMemo(
    () =>
      messages.map((message, index) => (
        <MemoizedChatMessage key={index} message={message} />
      )),
    [messages]
  );

  return (
    <Box className="ChatWindowContainer">
      <Box className="ChatMessagesContainer">
        {isLoading ? <CircularProgress /> : <List>{renderedMessages}</List>}
        <div ref={messagesEndRef} />
      </Box>
      <Box className="ChatInputContainer">
        <TextField
          multiline
          rows={4}
          placeholder="Type a message..."
          value={userInput}
          onChange={handleUserInput}
          onKeyDown={handleKeyDown}
          variant="outlined"
          fullWidth
        />
      </Box>
    </Box>
  );
}

const renderBlock = (text: string) => {
  const parts = text.split(/(```[\s\S]*?```)/g);
  return (
    <>
      {parts.map((part, index) => {
        const codeMatch = part.match(/```([\s\S]*?)```/);
        if (codeMatch) {
          const codeContent = codeMatch[1].trim();
          const [title, ...codeLines] = codeContent.split("\n");
          const formattedCode = codeLines.join("\n").trim();
          return (
            <>
              <>{title}:</>
              <Box key={index} position="relative" mb={2}>
                <SyntaxHighlighter language="javascript" style={docco}>
                  {formattedCode}
                </SyntaxHighlighter>
                <IconButton
                  onClick={() => navigator.clipboard.writeText(formattedCode)}
                  sx={{ position: "absolute", top: 5, right: 5 }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </>
          );
        } else {
          return (
            <ReactMarkdown
              key={index}
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeKatex]}
            >
              {part}
            </ReactMarkdown>
          );
        }
      })}
    </>
  );
};
