// src/utils/permissions.ts

import { UB_AccountUser } from "../../api/protosCompiled/ub_users/ub_users_pb";

type FieldName =
  | "name"
  | "email"
  | "roleid"
  | "isprimaryadmin"
  | "isactive"
  | "Add New User"
  | "isloggedin"
  | "password";

/**
 * Determines if a form field should be disabled based on user roles and context.
 *
 * @param currentUser - The currently logged-in user.
 * @param userBeingEdited - The user being created or edited.
 * @param fieldName - The name of the form field.
 * @returns A boolean indicating whether the field should be disabled.
 */
export function isFieldDisabled(
  currentUser?: UB_AccountUser.AsObject,
  userBeingEdited?: UB_AccountUser.AsObject,
  fieldName?: FieldName
): boolean {
  if (!currentUser) {
    // Disable if no user is logged in
    return true;
  }

  const isPrimaryAdmin = currentUser.isprimaryadmin;
  const isOwnAccount = userBeingEdited?.user?.id === currentUser.user?.id;

  // Return true to disable, false to enable
  switch (fieldName) {
    case "roleid":
    case "isprimaryadmin":
    case "isactive":
    case "Add New User":
    case "isloggedin":
      return !isPrimaryAdmin;

    case "name":
    case "email":
    case "password":
      if (isPrimaryAdmin || isOwnAccount) {
        return false;
      }
      return true;

    default:
      // Disable any unspecified fields by default
      return true;
  }
}

/**
 * Determines if the Save button should be enabled based on user roles and context.
 *
 * @param currentUser - The currently logged-in user.
 * @param userBeingEdited - The user being created or edited.
 * @returns A boolean indicating whether the Save button should be enabled.
 */
export function canSave(
  currentUser?: UB_AccountUser.AsObject,
  userBeingEdited?: UB_AccountUser.AsObject
): boolean {
  if (!currentUser || !userBeingEdited) return false;

  const isPrimaryAdmin = currentUser.isprimaryadmin;
  const loggedInUserRole = currentUser.rolename;
  const isLevel1 = loggedInUserRole === "Level 1";
  const isLevel2 = loggedInUserRole === "Level 2";
  const isOwnAccount = userBeingEdited.user!.id === currentUser.user!.id;

  if (isPrimaryAdmin || isLevel1) {
    return true;
  }

  if (isLevel2 && isOwnAccount) {
    return true;
  }

  return false;
}
