//useFetchAccount.ts
import { useQuery } from "react-query";
import { UbAccountService as acc } from "../api/UbAccountService";
import { useUnityBuildStore } from "../states/store";

export function useFetchAccount() {
  const { data, error, isLoading, refetch } = useQuery(
    "account",
    async () => {
      const response = await acc.getAccount();
      return response;
    },
    {
      enabled: !!useUnityBuildStore.getState().sessionToken,
    }
  );
  return { data, error, isLoading, refetch };
}
