import { clients } from "../clients/grpcClients";
import {
  GetAccountUserResponse,
  GetAccountUsersRequest,
  GetAccountUsersResponse,
  UB_AccountUser,
  UB_CreateUserRequest,
  UB_CreateUserResponse,
  UB_DeleteUserRequest,
  UB_DeleteUserResponse,
  UB_UpdateUserRequest,
  UB_UpdateUserResponse,
  UB_User,
} from "./protosCompiled/ub_users/ub_users_pb";
import { BaseAPIService } from "./BaseAPIService";
import { useUnityBuildStore } from "../states/store";

export class UbUserService extends BaseAPIService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static getAccountUser =
    (): Promise<GetAccountUserResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const getUserRequest = new GetAccountUsersRequest();
        getUserRequest.setSessiontoken(this.getMetadata().sessionToken);

        clients.ub_userServiceClient.getAccountUser(
          getUserRequest,
          {},
          (err, response) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(response.toObject());
          }
        );
      });
    };

  public static getAccountUsers =
    (): Promise<GetAccountUsersResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const getAccountUsersRequest = new GetAccountUsersRequest();

        getAccountUsersRequest.setSessiontoken(this.getMetadata().sessionToken);

        clients.ub_userServiceClient.getAccountUsers(
          getAccountUsersRequest,
          {},
          (err, response) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(response.toObject());
          }
        );
      });
    };

  public static createUser = (
    userRequest: UB_AccountUser.AsObject
  ): Promise<UB_CreateUserResponse.AsObject> => {
    if (!userRequest) return Promise.reject("User is undefined");

    return new Promise((resolve, reject) => {
      // Check if user field is defined
      if (!userRequest.user) {
        return reject("User details are missing in the request.");
      }

      // Create the user
      const userMsg = new UB_User();
      userMsg.setName(userRequest.user.name);
      userMsg.setEmail(userRequest.user.email);
      userMsg.setUserphotourl(userRequest.user.userphotourl);

      // Create the account user
      const createAccountUserRequest = new UB_AccountUser();
      createAccountUserRequest.setUbaccountid(userRequest.ubaccountid);
      createAccountUserRequest.setRoleid(userRequest.roleid);
      createAccountUserRequest.setIsprimaryadmin(userRequest.isprimaryadmin);
      createAccountUserRequest.setIsactive(userRequest.isactive);
      createAccountUserRequest.setIsloggedin(userRequest.isloggedin);
      createAccountUserRequest.setPassword(userRequest.password);
      createAccountUserRequest.setUser(userMsg);

      // Create the create user request
      const createUserRequest = new UB_CreateUserRequest();
      createUserRequest.setUser(userMsg);
      createUserRequest.setAccountuser(createAccountUserRequest);
      createUserRequest.setSessiontoken(this.getMetadata().sessionToken);

      clients.ub_userServiceClient.createUser(
        createUserRequest,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateUser = (
    userRequest: UB_AccountUser.AsObject
  ): Promise<UB_UpdateUserResponse.AsObject> => {
    if (!userRequest) return Promise.reject("User is undefined");

    return new Promise((resolve, reject) => {
      // Check if user field is defined
      if (!userRequest.user) {
        return reject("User details are missing in the request.");
      }

      // Update the user
      const userMsg = new UB_User();
      userMsg.setId(userRequest.user.id);
      userMsg.setName(userRequest.user.name);
      userMsg.setEmail(userRequest.user.email);
      userMsg.setUserphotourl(userRequest.user.userphotourl);

      // Update the account user
      const updateAccountUserRequest = new UB_AccountUser();
      updateAccountUserRequest.setUbaccountid(userRequest.ubaccountid);
      updateAccountUserRequest.setRoleid(userRequest.roleid);
      updateAccountUserRequest.setIsprimaryadmin(userRequest.isprimaryadmin);
      updateAccountUserRequest.setUser(userMsg);
      updateAccountUserRequest.setIsactive(userRequest.isactive);
      updateAccountUserRequest.setIsloggedin(userRequest.isloggedin);
      updateAccountUserRequest.setPassword(userRequest.password);

      // Create the update user request
      const updateUserRequest = new UB_UpdateUserRequest();
      updateUserRequest.setUser(userMsg);
      updateUserRequest.setAccountuser(updateAccountUserRequest);
      updateUserRequest.setSessiontoken(this.getMetadata().sessionToken);

      clients.ub_userServiceClient.updateUser(
        updateUserRequest,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static deleteUser = (
    id: string
  ): Promise<UB_DeleteUserResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const deleteUserRequest = new UB_DeleteUserRequest();
      deleteUserRequest.setId(id);

      clients.ub_userServiceClient.deleteUser(
        deleteUserRequest,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
