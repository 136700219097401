import { useUnityBuildStore } from "../states/store";
import { useSetIsAssemblyMeasurementType } from "./useSetTakeoffTypeToTrueOrFalse";
import { useStore } from "zustand";
import { EstimateAssembly } from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderAssembly } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { GlobalAssembly } from "../api/protosCompiled/globalAssembly/globalAssembly_pb";

export function useSetSelectedAssemblyFromAssemblyPicker() {
  const {
    selectedProject,
    selectedEstimate,
    selectedContract,
    selectedChangeOrder,
    selectedObjectId,
    currentPage,
    setIsUpdatingBreakout,
    setIsMeasuring,
    setMeasuredPoints,
    setTemporaryPoints,
    setSelectedTakeoffAssembly,
    setIsAnnotating,
    setIsCreatingTakeoffAssembly,
    setIsUpdatingTakeoffAssembly,
  } = useStore(useUnityBuildStore);

  const handleSetIsAssemblyMeasurementType = useSetIsAssemblyMeasurementType();

  const useHandleSetSelectedTakeoffAssembly = (
    assembly:
      | EstimateAssembly.AsObject
      | ChangeOrderAssembly.AsObject
      | GlobalAssembly.AsObject
  ) => {
    if (!selectedObjectId) return;
    if ("pointsList" in assembly) {
      // assembly is EstimateAssembly.AsObject or ChangeOrderAssembly.AsObject
      setIsAnnotating(true);
      setIsUpdatingTakeoffAssembly(true);
      setSelectedTakeoffAssembly(
        assembly as EstimateAssembly.AsObject | ChangeOrderAssembly.AsObject
      );
      handleSetIsAssemblyMeasurementType(assembly.assemblymeasurementtype);
      setIsMeasuring(false);
      setMeasuredPoints([]);
    } else {
      // assembly is GlobalAssembly.AsObject
      setIsUpdatingBreakout(true);
      setTemporaryPoints([]);
      setIsAnnotating(true);
      setIsCreatingTakeoffAssembly(true);
      handleSetIsAssemblyMeasurementType(assembly.assemblymeasurementtype);

      const baseAssembly = {
        ...assembly,
        takeoffid: "", // Assign appropriate values
        objectid: selectedObjectId,
        segmentlength: 0,
        pointsList: [],
        pointcount: 0,
        segmentcount: 0,
        pointtype: "circle",
        pointsize: 5,
        pointbordercolor: "black",
        pointfillcolor: "blue",
        linedasharray: "solid",
        linesize: 3,
        linecolor: "blue",
        userscale: 0,
        pagenumber: currentPage || 1,
        datecreated: "",
        lastupdated: "",
        sqft: 0,
      };

      if (selectedProject.id && selectedEstimate.estimateid) {
        setSelectedTakeoffAssembly({
          ...baseAssembly,
          projectid: selectedProject.id,
          estimateid: selectedEstimate.estimateid,
        } as any);
      } else if (
        selectedContract.contractid &&
        selectedChangeOrder.changeorderid
      ) {
        setSelectedTakeoffAssembly({
          ...baseAssembly,
          contractid: selectedContract.contractid,
          changeorderid: selectedChangeOrder.changeorderid,
        } as any);
      } else {
        console.error("No project or contract selected");
      }
    }
  };
  return useHandleSetSelectedTakeoffAssembly;
}
