import React from "react";
import { TotalPricingRow } from "./TotalPricingRow";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import {
  updatedTotalPricingTypes,
  updatedTotalFinalData,
} from "./closeoutHelpers";

export const TotalPricingHeader = () => {
  const { includedTotalPricingTypes, setIncludedTotalPricingTypes } =
    useStore(useCloseoutStore);

  const handleFieldUpdate = (field: string, name: string, value: number) => {
    const updatedTotalPricingTypesData = updatedTotalPricingTypes(
      includedTotalPricingTypes,
      field,
      name,
      value
    );
    const totalfinaldata = updatedTotalFinalData(updatedTotalPricingTypesData);

    setIncludedTotalPricingTypes({
      rowtypesList: updatedTotalPricingTypesData,
      totalfinaldata,
    });
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <div style={{ overflowX: "auto" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          <thead style={{ backgroundColor: "#f4f4f4", textAlign: "left" }}>
            <tr>
              <th style={{ padding: "10px" }}>Cost Description</th>
              <th style={{ padding: "10px" }}>Extended Cost</th>
              <th style={{ padding: "10px" }}>% Tax / Adjust</th>
              <th style={{ padding: "10px" }}>Total Tax / Adjust</th>
              <th style={{ padding: "10px" }}>% Overhead</th>
              <th style={{ padding: "10px" }}>Total Overhead</th>
              <th style={{ padding: "10px" }}>% Markup</th>
              <th style={{ padding: "10px" }}>Total Markup</th>
              <th style={{ padding: "10px" }}>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {includedTotalPricingTypes.rowtypesList.map((pricing, index) => (
              <TotalPricingRow
                key={index}
                name={pricing.name}
                rowData={pricing.rowdata as any}
                handleFieldUpdate={handleFieldUpdate}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "20px",
        }}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <strong>Total Cost:</strong>
          <p style={{ fontSize: "1.2em", margin: "10px 0" }}>
            $
            {includedTotalPricingTypes.totalfinaldata?.totalcost?.toFixed(2) ||
              "0.00"}
          </p>
        </div>

        <div
          style={{
            padding: "20px",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <strong>Total Overhead:</strong>
          <p style={{ fontSize: "1.2em", margin: "10px 0" }}>
            $
            {includedTotalPricingTypes.totalfinaldata?.totaloverhead?.toFixed(
              2
            ) || "0.00"}
          </p>
        </div>

        <div
          style={{
            padding: "20px",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <strong>Total Profit:</strong>
          <p style={{ fontSize: "1.2em", margin: "10px 0" }}>
            $
            {includedTotalPricingTypes.totalfinaldata?.totalmarkup?.toFixed(
              2
            ) || "0.00"}
          </p>
        </div>

        <div
          style={{
            padding: "20px",
            backgroundColor: "lightgreen",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <strong>Total Estimated Selling Price:</strong>
          <p
            style={{
              fontSize: "1.5em",
              margin: "10px 0",
              fontWeight: "bold",
            }}
          >
            $
            {includedTotalPricingTypes.totalfinaldata?.totalfinalprice?.toFixed(
              2
            ) || "0.00"}
          </p>
        </div>
      </div>
    </div>
  );
};
