import { create } from "zustand";
import { GlobalItem } from "../api/protosCompiled/globalItem/globalItem_pb";
import { GlobalAssembly } from "../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { AssemblyOptions } from "../types/FolderTypes";

export type GlobalAssemblyStore = {
  filteredItems: GlobalItem.AsObject[];
  setFilteredItems: (items: GlobalItem.AsObject[]) => void;

  assemblyItems: GlobalItem.AsObject[];
  setAssemblyItems: (items: GlobalItem.AsObject[]) => void;

  currentAssembly: GlobalAssembly.AsObject;
  setCurrentAssembly: (assembly: GlobalAssembly.AsObject) => void;

  isViewingAssemblyForm: boolean;
  setIsViewingAssemblyForm: (isViewingAssemblyForm: boolean) => void;

  isCreatingAssembly: boolean;
  setIsCreatingAssembly: (isCreatingAssembly: boolean) => void;

  assemblyOption: AssemblyOptions | "";
  setAssemblyOption: (assemblyOptions: AssemblyOptions | "") => void;
};

export const useGlobalAssemblyStore = create<GlobalAssemblyStore>((set) => ({
  filteredItems: [],
  setFilteredItems: (items) => set({ filteredItems: items }),

  assemblyItems: [],
  setAssemblyItems: (items) => set({ assemblyItems: items }),

  currentAssembly: {
    folderid: "",
    globalassemblyid: "",
    assemblyname: "",
    isactive: true,
    lastupdated: "",
    parentid: "",
    level: 0,
    entitytype: "",
    isfile: false,
    assemblymeasurementtype: "",
    itemqtyformulasList: [],
    imageclassificationid: 0,
  },
  setCurrentAssembly: (assembly) =>
    set((state) => ({
      currentAssembly: {
        ...state.currentAssembly,
        ...assembly,
        itemqtyformulasList:
          assembly.itemqtyformulasList ??
          state.currentAssembly.itemqtyformulasList,
      },
    })),

  isViewingAssemblyForm: false,
  setIsViewingAssemblyForm: (isViewingAssemblyForm) =>
    set({ isViewingAssemblyForm }),

  isCreatingAssembly: false,
  setIsCreatingAssembly: (isCreatingAssembly) => set({ isCreatingAssembly }),

  assemblyOption: "",
  setAssemblyOption: (assemblyOption) => set({ assemblyOption }),
}));
