import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { DefaultQuoteType } from "../../../api/protosCompiled/defaultCloseout/defaultCloseout_pb";
import {
  useGetDefaultQuoteExpenseTypes,
  useCreateOrUpdateDefaultQuoteExpenseType,
  useDeleteDefaultQuoteExpenseType,
} from "../../../hooks/useDefaultCloseoutHooks";
import { CloseoutProps } from "../CloseoutTypes";

export const DefaultQuote: React.FC<CloseoutProps> = ({ isClearingFocus }) => {
  const {
    data: rows = [],
    isLoading,
    isError,
    isFetching,
  } = useGetDefaultQuoteExpenseTypes();

  const createOrUpdateMutation = useCreateOrUpdateDefaultQuoteExpenseType();
  const deleteMutation = useDeleteDefaultQuoteExpenseType();
  const [maxAddRows, setMaxAddRows] = useState(0);

  const [localRows, setLocalRows] = useState<DefaultQuoteType.AsObject[]>([]);
  const [focusedCell, setFocusedCell] = useState<{
    rowId: string;
    field: keyof DefaultQuoteType.AsObject;
  } | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rows) {
      setLocalRows(rows.map((row) => formatRow(row)));
    }
  }, [rows]);

  useEffect(() => {
    if (!isFetching && focusedCell && tableRef.current) {
      const cell = tableRef.current.querySelector<HTMLInputElement>(
        `input[data-row-id="${focusedCell.rowId}"][data-field="${focusedCell.field}"]`
      );
      cell?.focus();
    }
  }, [isFetching, focusedCell]);

  const handleAddRow = () => {
    const newRow: DefaultQuoteType.AsObject = {
      id: "",
      quotegroup: "",
    };
    setLocalRows((prev) => [...prev, formatRow(newRow)]);
    setMaxAddRows(1);
  };

  const formatRow = (row: DefaultQuoteType.AsObject) => ({
    ...row,
    quotegroup: row.quotegroup || "", // Ensure quotegroup is a string
  });

  const handleFieldChange = (
    rowId: string,
    field: keyof DefaultQuoteType.AsObject,
    value: string
  ) => {
    setLocalRows((prev) =>
      prev.map((row) =>
        row.id === rowId
          ? { ...row, [field]: field === "quotegroup" ? value : row[field] }
          : row
      )
    );
  };

  const handleFieldBlur = (
    rowId: string,
    field: keyof DefaultQuoteType.AsObject
  ) => {
    if (isClearingFocus) return;
    const rowToSubmit = localRows.find((row) => row.id === rowId);
    if (rowToSubmit && rowToSubmit.quotegroup.trim()) {
      createOrUpdateMutation.mutate(rowToSubmit, {
        onSuccess: (data: any) => {
          if (rowToSubmit.id === "") {
            setLocalRows((prev) =>
              prev.map((row) =>
                row.id === rowToSubmit.id ? { ...row, id: data.id } : row
              )
            );
          }
          setMaxAddRows(0);
        },
      });
    }
  };

  const handleDeleteRow = (rowId: string) => {
    setLocalRows((prev) => prev.filter((row) => row.id !== rowId));

    if (rowId) {
      deleteMutation.mutate(rowId);
    }
  };

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: string,
    field: keyof DefaultQuoteType.AsObject
  ) => {
    setFocusedCell({ rowId, field });
    (event.target as HTMLInputElement).select();
  };

  if (isLoading) {
    return <div>Loading quote expense types...</div>;
  }

  if (isError) {
    return <div>Error loading quote expense types!</div>;
  }

  return (
    <div ref={tableRef} style={{ display: "flex", flexDirection: "column" }}>
      <Button
        variant="outlined"
        onClick={handleAddRow}
        disabled={maxAddRows > 0}
      >
        + Add Row
      </Button>

      <div
        style={{
          flex: 1,
          overflowY: "auto",
          maxHeight: "750px",
          border: "1px solid #ddd",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Quote Group</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <TextField
                    value={row.quotegroup}
                    onChange={(e) =>
                      handleFieldChange(row.id, "quotegroup", e.target.value)
                    }
                    onBlur={() => handleFieldBlur(row.id, "quotegroup")}
                    onFocus={(e) => handleFocus(e, row.id, "quotegroup")}
                    inputProps={{
                      "data-row-id": row.id,
                      "data-field": "quotegroup",
                    }}
                    disabled={isFetching}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    color="error"
                    onClick={() => handleDeleteRow(row.id)}
                    disabled={isFetching}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {isFetching && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
