import React, { useEffect, useState } from "react";
import {
  defaultGlobalAssembly,
  useUnityBuildStore,
} from "../../../states/store";
import { useStore } from "zustand";
import { ResetStates } from "../../../states/resetStates";
import { Inference } from "../../../hooks/inferenceHooks";
import { FormulaHeader } from "./FormulaHeader";

import {
  CreateAssemblyProps,
  defaultFolder,
  Foldertype,
} from "../../../types/FolderTypes";
import {
  useCreateGlobalAssemblyMutation,
  useFetchAssembliesByFileId,
  useUpdateGlobalAssemblyMutation,
} from "../../../hooks/useFetchAssembliesByFileId";
import {
  convertSelectedBreakoutToMap,
  useSubmitTakeoffAssembly,
} from "../../../hooks/useSubmitTakeoffAssembly";
import { EstimateAssembly } from "../../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderAssembly } from "../../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { useFetchUser } from "../../../hooks/useFetchUsers";
import { userIsDevAccount } from "../../../utils/accessControl";
import { UB_Account_User } from "../../../api/protosCompiled/ub_account/ub_account_pb";

import {
  defaultGlobalItem,
  useGlobalItemStore,
} from "../../../states/globalItemStore";
import { useGlobalAssemblyStore } from "../../../states/globalAssemblyStore";
import { useFolderStore } from "../../../states/folderStore";
import { GlobalAssembly } from "../../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import ItemTab from "../items/ItemTab";
import { CreateItemTab } from "../items/CreateItemTab";
import { AddedItemRow } from "../items/AddedItemRow";
import { BreakoutSelector } from "../../takeoffPallette/BreakoutSelector";

export function CreateAssembly({
  takeoffAssembly, //when we pass this in when it is our selected takeoff assembly
}: CreateAssemblyProps) {
  //zustand states
  const {
    selectedEstimate,
    selectedChangeOrder,
    userBackgroundColor,
    setIsUpdatingBreakout,
  } = useStore(useUnityBuildStore);

  const {
    isCreatingAssembly,
    currentAssembly,
    assemblyOption,
    setIsCreatingAssembly,
    setCurrentAssembly,
  } = useStore(useGlobalAssemblyStore);

  const {
    isViewingItemForm,
    isCreatingItem,
    setIsCreatingItem,
    setIsViewingItemForm,
    setCurrentItem,
  } = useStore(useGlobalItemStore);

  const assemblyStore = useStore(useGlobalAssemblyStore);
  const itemStore = useStore(useGlobalItemStore);
  const folderStore = useStore(useFolderStore);
  const unityBuildStore = useStore(useUnityBuildStore);
  const { currentFolder, setCurrentFolder } = useStore(useFolderStore);
  const { selectedBreakout } = useStore(useUnityBuildStore);

  //react-query states
  const { data: user } = useFetchUser();
  const { data: classifications } = Inference.useFetchClass();
  const { data: globalAssemblies } = useFetchAssembliesByFileId(
    currentAssembly?.folderid || ""
  );

  const [shouldSubmit, setShouldSubmit] = useState(false);

  const submitAssembly = useSubmitTakeoffAssembly();

  // createGlobalAssemblyMutation
  const { mutate: createGlobalAssembly } = useCreateGlobalAssemblyMutation();

  // updateGlobalAssemblyMutation
  const { mutate: updateGlobalAssembly } = useUpdateGlobalAssemblyMutation();

  useEffect(() => {
    if (assemblyOption === "global" && !isCreatingAssembly) {
      if (currentAssembly?.isfile === false) return;
      //find the globalAssembly with the same folderid
      const matchingGlobalAssembly = globalAssemblies?.find(
        (globalAssembly) =>
          globalAssembly.folderid === currentAssembly?.folderid
      );

      if (matchingGlobalAssembly) {
        setCurrentAssembly({
          ...currentAssembly,
          folderid: matchingGlobalAssembly.folderid,
          globalassemblyid: matchingGlobalAssembly.globalassemblyid,
          assemblyname: matchingGlobalAssembly.assemblyname,
          entitytype: matchingGlobalAssembly.entitytype,
          parentid: matchingGlobalAssembly.parentid,
          level: matchingGlobalAssembly.level,
          assemblymeasurementtype:
            matchingGlobalAssembly.assemblymeasurementtype,
          imageclassificationid: matchingGlobalAssembly.imageclassificationid,
          isfile: true,
          itemqtyformulasList: matchingGlobalAssembly.itemqtyformulasList,
        });
      }
    } else if (takeoffAssembly && assemblyOption === "takeoff") {
      setCurrentAssembly({
        ...currentAssembly,
        folderid: takeoffAssembly.takeoffid,
        assemblyname: takeoffAssembly.assemblyname,
        assemblymeasurementtype: takeoffAssembly.assemblymeasurementtype,
        imageclassificationid: takeoffAssembly.imageclassificationid,
        isfile: true,
        itemqtyformulasList: takeoffAssembly.itemqtyformulasList,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemOrAssemblyMode = () => {
    if (isCreatingAssembly) {
      setIsCreatingAssembly(false);
      setIsCreatingItem(true);
    } else {
      setIsCreatingAssembly(true);
      setIsCreatingItem(false);
    }
    setIsViewingItemForm(!isViewingItemForm);
    setCurrentFolder(defaultFolder);
    setCurrentAssembly(defaultGlobalAssembly);
    setCurrentItem(defaultGlobalItem);
  };

  const handleSetAssemblyName = (assemblyname: string) => {
    setCurrentAssembly({
      ...currentAssembly,
      assemblyname: assemblyname,
      itemqtyformulasList: currentAssembly.itemqtyformulasList.map(
        (formula) => {
          if (formula.isquoted) {
            return {
              ...formula,
              assemblyname: assemblyname,
            };
          }
          return formula;
        }
      ),
    });
  };

  const handleMeasurementTypeChange = (e: any) => {
    setCurrentAssembly({
      ...currentAssembly,
      assemblymeasurementtype: e.target.value,
    });
  };

  const handleClassificationChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCurrentAssembly({
      ...currentAssembly,
      imageclassificationid: Number(e.target.value),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!shouldSubmit) return;
    try {
      /* If isCreatingAssembly is false then we are editing an existing assembly */
      if (!isCreatingAssembly) {
        if (assemblyOption === "global") {
          // We trigger the update mutation and pass onSuccess/onError:
          updateGlobalAssembly(currentAssembly);
        } else if (takeoffAssembly && assemblyOption === "takeoff") {
          // Map over each formula to add or override the breakoutsList
          const updatedItemQtyFormulasList =
            currentAssembly.itemqtyformulasList.map((formula) => ({
              ...formula,
              breakoutsList: [
                convertSelectedBreakoutToMap({
                  selectedBreakout: selectedBreakout,
                  selectedEstimate: selectedEstimate ?? null,
                  selectedChangeOrder: selectedChangeOrder ?? null,
                  assembly: takeoffAssembly,
                  isUpdatingBreakout: true,
                  estimateOrChangorderId: selectedEstimate
                    ? selectedEstimate.estimateid
                    : selectedChangeOrder?.changeorderid,
                }),
              ],
            }));

          const updatedAssembly:
            | EstimateAssembly.AsObject
            | ChangeOrderAssembly.AsObject = {
            ...takeoffAssembly,
            assemblyname: currentAssembly.assemblyname,
            assemblymeasurementtype: currentAssembly.assemblymeasurementtype,
            imageclassificationid: currentAssembly.imageclassificationid,
            itemqtyformulasList: updatedItemQtyFormulasList,
          };
          setIsUpdatingBreakout(true);
          await submitAssembly({
            assembly: updatedAssembly,
            isUpdating: true,
          });
        }
      } else if (currentAssembly) {
        const entityType: Foldertype = "assembly";

        const newAssembly: GlobalAssembly.AsObject = {
          ...currentAssembly,
          assemblymeasurementtype:
            currentAssembly.assemblymeasurementtype || "count",
          isfile: true,
          imageclassificationid: currentAssembly.imageclassificationid || 1,
          entitytype: entityType,
          parentid: currentFolder?.folderid || "",
          level: currentFolder ? currentFolder?.level + 1 : 0,
        };
        // Trigger create mutation
        createGlobalAssembly(newAssembly);
      }
    } catch (error) {
      console.error("Error creating or updating assembly:", error);
    }
    ResetStates.resetCreateAssemblyStates(
      assemblyStore,
      itemStore,
      folderStore,
      unityBuildStore
    );
  };

  const handleClickSubmit = () => {
    setShouldSubmit(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    ResetStates.resetCreateAssemblyStatesAndClose(
      assemblyStore,
      itemStore,
      folderStore,
      unityBuildStore
    );
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          backgroundColor: userBackgroundColor,
        }}
      >
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          className="assemblyForm"
        >
          {/* Input and Select Fields */}
          <div>
            <input
              placeholder="Assembly Name"
              value={currentAssembly.assemblyname}
              onChange={(e) => handleSetAssemblyName(e.target.value)}
              style={{ width: "20%" }}
              required
            />
            <> Takeoff Type: </>
            <select
              value={currentAssembly.assemblymeasurementtype}
              onChange={handleMeasurementTypeChange}
            >
              <option value={"count"}>Count</option>
              <option value={"length"}>Length</option>
              <option value={"area"}>Area</option>
            </select>
            {user &&
              !userIsDevAccount(
                user?.ubaccountid,
                user?.user as UB_Account_User.AsObject
              ) && (
                <>
                  Class Name:
                  <select
                    value={
                      currentAssembly.imageclassificationid?.toString() || ""
                    }
                    onChange={handleClassificationChange}
                  >
                    {/* Add "N/A" option at the top */}
                    <option value="">N/A</option>

                    {/* Render the rest of the classifications */}
                    {classifications?.map((classification, index) => (
                      <option value={classification.id} key={index}>
                        {classification.classname}
                      </option>
                    ))}
                  </select>
                </>
              )}
            {/*breakout selection*/}
            {takeoffAssembly && (
              <BreakoutSelector
                takeoffAssembly={takeoffAssembly}
                context="createAssembly"
              />
            )}

            <div style={{ display: "flex" }}>
              <button
                type="button"
                onClick={handleClose}
                style={{ width: "100px", height: "40px" }}
              >
                Close
              </button>
              <button
                type="submit"
                onClick={handleClickSubmit}
                style={{ width: "100px", height: "40px" }}
              >
                Save Assembly
              </button>
              <button
                type="button"
                onClick={handleItemOrAssemblyMode}
                style={{ width: "200px", height: "40px" }}
              >
                {isViewingItemForm
                  ? "Create Item Mode"
                  : "Create Assembly Mode"}
              </button>
              <span>
                {currentFolder &&
                isCreatingAssembly &&
                currentFolder?.entitytype === "assembly"
                  ? `Creating an assembly in folder: ${currentFolder.name}`
                  : isCreatingItem && currentFolder?.entitytype === "item"
                  ? `Creating an item in folder: ${currentFolder.name}`
                  : ""}
              </span>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", height: "95%" }}>
            <div
              className="scrollableTableContainer"
              style={{
                width: "50%",
                overflowY: "auto",
                height: "99%",
                border: "1px solid black",
              }}
            >
              <ItemTab />
            </div>

            {/* Conditionally show CreateItemTab OR ItemQtyFormulas */}
            {isViewingItemForm ? (
              // If the user wants to create/edit an item, show <CreateItemTab> in the right pane
              <div
                className="scrollableTableContainer"
                style={{
                  width: "50%",
                  overflowY: "auto",
                  height: "99%",
                  border: "1px solid black",
                }}
              >
                <CreateItemTab />
              </div>
            ) : (
              <div
                className="scrollableTableContainer"
                style={{
                  width: "50%",
                  overflowY: "auto",
                  height: "100%",
                  border: "1px solid black",
                }}
              >
                <table>
                  <thead>
                    <FormulaHeader />
                  </thead>
                  <tbody>
                    {currentAssembly &&
                    currentAssembly.itemqtyformulasList.length > 0 ? (
                      currentAssembly.itemqtyformulasList.map((item, index) => (
                        <AddedItemRow
                          index={index}
                          key={index + item.itemid}
                          item={item}
                        />
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={6}
                          style={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Select "Add Item to Assembly" from the Items tab to
                          add items to the assembly
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
