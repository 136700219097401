import { useMutation, useQuery, useQueryClient } from "react-query";
import { ItemService } from "../api/GlobalItemService";
import {
  AttributeValue,
  GlobalItem,
  ItemCost,
} from "../api/protosCompiled/globalItem/globalItem_pb";
import { FolderService } from "../api/FolderService";

export function useFetchItemsByFileId(fileId: string) {
  return useQuery<GlobalItem.AsObject[], Error>(
    ["itemsByFileId", fileId],
    async () => {
      const res = await ItemService.getItemsByFileId(fileId);
      return res.itemsList || [];
    },
    {
      enabled: !!fileId,
      refetchOnWindowFocus: false,
    }
  );
}

export function useCreateOrUpdateItem() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, GlobalItem.AsObject>(
    async (data: GlobalItem.AsObject) => {
      const item = new GlobalItem();
      item.setFolderid(data.folderid);
      item.setName(data.name);
      item.setIsactive(data.isactive);
      item.setLastupdated(data.lastupdated);
      item.setParentid(data.parentid);
      item.setLevel(data.level);
      item.setEntitytype(data.entitytype);
      item.setIsfile(data.isfile);
      item.setAttributesList(
        data.attributesList.map((attr) => {
          const attribute = new AttributeValue();
          attribute.setAttributevalueid(attr.attributevalueid);
          attribute.setAttributegroupid(attr.attributegroupid);
          attribute.setAttributevaluename(attr.attributevaluename);
          attribute.setAttributegroupname(attr.attributegroupname);
          attribute.setUpc(attr.upc);
          attribute.setItemattributevaluemapid(attr.itemattributevaluemapid);
          return attribute;
        })
      );
      item.setCostsList(
        data.costsList.map((cost) => {
          const itemCost = new ItemCost();
          itemCost.setItemid(cost.itemid);
          itemCost.setItemcostid(cost.itemcostid);
          itemCost.setItemunitcost(cost.itemunitcost);
          itemCost.setHourlyproductionrate(cost.hourlyproductionrate);
          itemCost.setItemattributevalueid(cost.itemattributevalueid);
          itemCost.setUomid(cost.uomid);
          return itemCost;
        })
      );
      await ItemService.createOrUpdateGlobalItem(item);
    },
    {
      onSuccess: (data, variables) => {
        const queryKey = ["itemsByFileId", variables.parentid];
        // Refetch items for the parent folder
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries("itemFolders");
      },
      onError: (err) => {
        console.error("Mutation error:", err);
      },
    }
  );
}
export function useDeleteItem() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(
    async (itemId: string) => {
      await FolderService.deleteFolder(itemId); // Replace this with your actual service method
    },
    {
      onSuccess: (data, itemId) => {
        // Refetch relevant queries
        queryClient.invalidateQueries("itemsByFileId");
        queryClient.invalidateQueries("itemFolders");
      },
      onError: (err) => {
        console.error("Error deleting item:", err);
      },
    }
  );
}
