import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { DefaultIndirectLaborType } from "../../../api/protosCompiled/defaultCloseout/defaultCloseout_pb";
import {
  useGetDefaultIndirectLaborTypes,
  useCreateOrUpdateDefaultIndirectLaborType,
  useDeleteDefaultIndirectLaborType,
} from "../../../hooks/useDefaultCloseoutHooks";
import {
  formatToTwoDecimalPlaces,
  validateDecimalInput,
} from "../closetoutHelpers";
import { CloseoutProps } from "../CloseoutTypes";

export const DefaultIndirectLabor: React.FC<CloseoutProps> = ({
  isClearingFocus,
}) => {
  const {
    data: rows = [],
    isLoading,
    isError,
    isFetching,
  } = useGetDefaultIndirectLaborTypes();

  const createOrUpdateMutation = useCreateOrUpdateDefaultIndirectLaborType();
  const deleteMutation = useDeleteDefaultIndirectLaborType();
  const [maxAddRows, setMaxAddRows] = useState(0);

  const [localRows, setLocalRows] = useState<
    DefaultIndirectLaborType.AsObject[]
  >([]);
  const [focusedCell, setFocusedCell] = useState<{
    rowId: string;
    field: keyof DefaultIndirectLaborType.AsObject;
  } | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rows) {
      setLocalRows(rows.map((row) => formatRow(row)));
    }
  }, [rows]);

  useEffect(() => {
    if (!isFetching && focusedCell && tableRef.current) {
      const cell = tableRef.current.querySelector<HTMLInputElement>(
        `input[data-row-id="${focusedCell.rowId}"][data-field="${focusedCell.field}"]`
      );
      cell?.focus();
    }
  }, [isFetching, focusedCell]);

  const handleAddRow = () => {
    const newRow: DefaultIndirectLaborType.AsObject = {
      id: "",
      name: "",
      laborrate: 0,
      burdenpercent: 0,
      fringe: 0,
    };
    setLocalRows((prev) => [...prev, formatRow(newRow)]);
    setMaxAddRows(1);
  };

  const formatRow = (row: DefaultIndirectLaborType.AsObject) => ({
    ...row,
    laborrate: parseFloat(formatToTwoDecimalPlaces(row.laborrate)),
    burdenpercent: parseFloat(formatToTwoDecimalPlaces(row.burdenpercent)),
    fringe: parseFloat(formatToTwoDecimalPlaces(row.fringe)),
  });

  const handleFieldChange = (
    rowId: string,
    field: keyof DefaultIndirectLaborType.AsObject,
    value: string
  ) => {
    setLocalRows((prev) =>
      prev.map((row) =>
        row.id === rowId
          ? {
              ...row,
              [field]:
                field === "name"
                  ? value
                  : validateDecimalInput(value)
                  ? value
                  : row[field],
            }
          : row
      )
    );
  };

  const handleFieldBlur = (
    rowId: string,
    field: keyof DefaultIndirectLaborType.AsObject
  ) => {
    if (isClearingFocus) return;
    setLocalRows((prev) =>
      prev.map((row) =>
        row.id === rowId
          ? {
              ...row,
              [field]:
                field !== "name"
                  ? parseFloat(
                      formatToTwoDecimalPlaces(row[field] as string | number)
                    )
                  : row[field],
            }
          : row
      )
    );

    const rowToSubmit = localRows.find((row) => row.id === rowId);

    if (rowToSubmit && rowToSubmit.name.trim()) {
      createOrUpdateMutation.mutate(rowToSubmit, {
        onSuccess: (data: any) => {
          if (rowToSubmit.id === "") {
            setLocalRows((prev) =>
              prev.map((row) =>
                row.id === rowToSubmit.id ? { ...row, id: data.id } : row
              )
            );
          }
          setMaxAddRows(0);
        },
      });
    }
  };

  const handleDeleteRow = (rowId: string) => {
    setLocalRows((prev) => prev.filter((row) => row.id !== rowId));

    if (rowId) {
      deleteMutation.mutate(rowId);
    }
  };

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: string,
    field: keyof DefaultIndirectLaborType.AsObject
  ) => {
    setFocusedCell({ rowId, field });
    (event.target as HTMLInputElement).select();
  };

  if (isLoading) {
    return <div>Loading indirect labor types...</div>;
  }

  if (isError) {
    return <div>Error loading indirect labor types!</div>;
  }

  return (
    <div ref={tableRef} style={{ display: "flex", flexDirection: "column" }}>
      <Button
        variant="outlined"
        onClick={handleAddRow}
        disabled={maxAddRows > 0}
      >
        + Add Row
      </Button>

      <div
        style={{
          flex: 1,
          overflowY: "auto",
          maxHeight: "750px",
          border: "1px solid #ddd",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Labor Rate</TableCell>
              <TableCell>Burden %</TableCell>
              <TableCell>Fringe</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <TextField
                    value={row.name}
                    onChange={(e) =>
                      handleFieldChange(row.id, "name", e.target.value)
                    }
                    onBlur={() => handleFieldBlur(row.id, "name")}
                    onFocus={(e) => handleFocus(e, row.id, "name")}
                    inputProps={{ "data-row-id": row.id, "data-field": "name" }}
                    disabled={isFetching}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    value={row.laborrate.toString()}
                    onChange={(e) =>
                      handleFieldChange(row.id, "laborrate", e.target.value)
                    }
                    onBlur={() => handleFieldBlur(row.id, "laborrate")}
                    onFocus={(e) => handleFocus(e, row.id, "laborrate")}
                    inputProps={{
                      "data-row-id": row.id,
                      "data-field": "laborrate",
                    }}
                    disabled={isFetching}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    value={row.burdenpercent.toString()}
                    onChange={(e) =>
                      handleFieldChange(row.id, "burdenpercent", e.target.value)
                    }
                    onBlur={() => handleFieldBlur(row.id, "burdenpercent")}
                    onFocus={(e) => handleFocus(e, row.id, "burdenpercent")}
                    inputProps={{
                      "data-row-id": row.id,
                      "data-field": "burdenpercent",
                    }}
                    disabled={isFetching}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    value={row.fringe.toString()}
                    onChange={(e) =>
                      handleFieldChange(row.id, "fringe", e.target.value)
                    }
                    onBlur={() => handleFieldBlur(row.id, "fringe")}
                    onFocus={(e) => handleFocus(e, row.id, "fringe")}
                    inputProps={{
                      "data-row-id": row.id,
                      "data-field": "fringe",
                    }}
                    disabled={isFetching}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    color="error"
                    onClick={() => handleDeleteRow(row.id)}
                    disabled={isFetching}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {isFetching && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
