import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import {
  GetMasterFormatDivisionRequest,
  GetMasterFormatDivisionResponse,
} from "./protosCompiled/masterFormatDivision/masterFormatDivision_pb";

const client = clients.masterFormatDivisionServiceClient;

export class MasterFormatDivisionService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static getMasterFormatDivision =
    (): Promise<GetMasterFormatDivisionResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetMasterFormatDivisionRequest();
        request.setSessiontoken(this.getMetadata().sessionToken);

        client.getMasterFormatDivision(
          request,
          {},
          (err, response: GetMasterFormatDivisionResponse) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(response.toObject());
          }
        );
      });
    };
}
