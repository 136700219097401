import React, { useState, useEffect } from "react";
import { ExtensionType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import {
  sxProductionCostOverrideValue,
  sxUnitCostOverrideValue,
} from "./closeoutHelpers";

interface ExtentionRowProps {
  item: ExtensionType.AsObject;
  handleFieldUpdate: (index: number, field: string, value: number) => void;
  index: number;
  onOpenCreateItemTab: (item: ExtensionType.AsObject) => void;
}

export const ExtentionRow: React.FC<ExtentionRowProps> = ({
  item,
  handleFieldUpdate,
  index,
  onOpenCreateItemTab,
}) => {
  const [unitcostDisplay, setUnitCostDisplay] = useState<string>(
    item.itemunitcost.toFixed(2)
  );
  const [productionRateDisplay, setProductionRateDisplay] = useState<string>(
    item.hourlyproductionrate.toFixed(2)
  );

  useEffect(() => {
    setUnitCostDisplay(item.itemunitcost.toFixed(2));
    setProductionRateDisplay(item.hourlyproductionrate.toFixed(2));
  }, [item.itemunitcost, item.hourlyproductionrate]);

  const handleFieldChange = (field: string, value: string) => {
    // Allow only numbers and the decimal point
    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
    if (!regex.test(value) || Number(value) > 99999999.99) return;
    switch (field) {
      case "overrideunitcost":
        setUnitCostDisplay(value);
        break;
      case "hourlyoverrideproductionrate":
        setProductionRateDisplay(value);
        break;
      default:
        break;
    }
  };

  const handleBlur = (field: string, value: string, prevValue: number) => {
    const floatValue = parseFloat(value);
    if (floatValue === prevValue) return;

    // Pass updated values to the handler
    switch (field) {
      case "overrideunitcost":
        handleFieldUpdate(index, "overrideunitcost", floatValue);
        setUnitCostDisplay(floatValue.toFixed(2));
        break;
      case "hourlyoverrideproductionrate":
        handleFieldUpdate(index, "hourlyoverrideproductionrate", floatValue);
        setProductionRateDisplay(floatValue.toFixed(2));
        break;
      default:
        break;
    }
  };

  return (
    <tr>
      <td>{item.name}</td>
      <td>{item.attributegroupname}</td>
      <td>{item.itemqty}</td>
      <td>{item.itemuom}</td>
      <td>
        {/* Unit Cost */}
        $
        <input
          type="text"
          value={unitcostDisplay}
          onChange={(e) =>
            handleFieldChange("overrideunitcost", e.target.value)
          }
          onBlur={(e) =>
            handleBlur("overrideunitcost", e.target.value, item.itemunitcost)
          }
          style={sxUnitCostOverrideValue(item)}
          disabled={item.isquoted}
        />
      </td>
      {/* Total Material Cost */}
      <td>${item.totalcost.toFixed(2)}</td>
      {/* Hourly Production Rate */}
      <td>
        <input
          type="text"
          value={productionRateDisplay}
          onChange={(e) =>
            handleFieldChange("hourlyoverrideproductionrate", e.target.value)
          }
          onBlur={(e) =>
            handleBlur(
              "hourlyoverrideproductionrate",
              e.target.value,
              item.hourlyproductionrate
            )
          }
          style={sxProductionCostOverrideValue(item)}
        />
      </td>
      {/* Total Hours */}
      <td>{item.totallaborhours.toFixed(2)}</td>
      {/* NEW: Button to open the CreateItemTab (for now just logs) */}
      <td>
        <button onClick={() => onOpenCreateItemTab(item)}>Edit Item</button>
      </td>
    </tr>
  );
};
