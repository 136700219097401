/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: ub_users.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as ub_users_pb from './ub_users_pb'; // proto import: "ub_users.proto"


export class UB_UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateUser = new grpcWeb.MethodDescriptor(
    '/UB_UserService/CreateUser',
    grpcWeb.MethodType.UNARY,
    ub_users_pb.UB_CreateUserRequest,
    ub_users_pb.UB_CreateUserResponse,
    (request: ub_users_pb.UB_CreateUserRequest) => {
      return request.serializeBinary();
    },
    ub_users_pb.UB_CreateUserResponse.deserializeBinary
  );

  createUser(
    request: ub_users_pb.UB_CreateUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_users_pb.UB_CreateUserResponse>;

  createUser(
    request: ub_users_pb.UB_CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_CreateUserResponse) => void): grpcWeb.ClientReadableStream<ub_users_pb.UB_CreateUserResponse>;

  createUser(
    request: ub_users_pb.UB_CreateUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_CreateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_UserService/CreateUser',
        request,
        metadata || {},
        this.methodDescriptorCreateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_UserService/CreateUser',
    request,
    metadata || {},
    this.methodDescriptorCreateUser);
  }

  methodDescriptorGetUser = new grpcWeb.MethodDescriptor(
    '/UB_UserService/GetUser',
    grpcWeb.MethodType.UNARY,
    ub_users_pb.UB_GetUserRequest,
    ub_users_pb.UB_GetUserResponse,
    (request: ub_users_pb.UB_GetUserRequest) => {
      return request.serializeBinary();
    },
    ub_users_pb.UB_GetUserResponse.deserializeBinary
  );

  getUser(
    request: ub_users_pb.UB_GetUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_users_pb.UB_GetUserResponse>;

  getUser(
    request: ub_users_pb.UB_GetUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_GetUserResponse) => void): grpcWeb.ClientReadableStream<ub_users_pb.UB_GetUserResponse>;

  getUser(
    request: ub_users_pb.UB_GetUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_GetUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_UserService/GetUser',
        request,
        metadata || {},
        this.methodDescriptorGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_UserService/GetUser',
    request,
    metadata || {},
    this.methodDescriptorGetUser);
  }

  methodDescriptorgetAllUsers = new grpcWeb.MethodDescriptor(
    '/UB_UserService/getAllUsers',
    grpcWeb.MethodType.UNARY,
    ub_users_pb.UB_GetAllUsersRequest,
    ub_users_pb.UB_GetAllUsersResponse,
    (request: ub_users_pb.UB_GetAllUsersRequest) => {
      return request.serializeBinary();
    },
    ub_users_pb.UB_GetAllUsersResponse.deserializeBinary
  );

  getAllUsers(
    request: ub_users_pb.UB_GetAllUsersRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_users_pb.UB_GetAllUsersResponse>;

  getAllUsers(
    request: ub_users_pb.UB_GetAllUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_GetAllUsersResponse) => void): grpcWeb.ClientReadableStream<ub_users_pb.UB_GetAllUsersResponse>;

  getAllUsers(
    request: ub_users_pb.UB_GetAllUsersRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_GetAllUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_UserService/getAllUsers',
        request,
        metadata || {},
        this.methodDescriptorgetAllUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_UserService/getAllUsers',
    request,
    metadata || {},
    this.methodDescriptorgetAllUsers);
  }

  methodDescriptorUpdateUser = new grpcWeb.MethodDescriptor(
    '/UB_UserService/UpdateUser',
    grpcWeb.MethodType.UNARY,
    ub_users_pb.UB_UpdateUserRequest,
    ub_users_pb.UB_UpdateUserResponse,
    (request: ub_users_pb.UB_UpdateUserRequest) => {
      return request.serializeBinary();
    },
    ub_users_pb.UB_UpdateUserResponse.deserializeBinary
  );

  updateUser(
    request: ub_users_pb.UB_UpdateUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_users_pb.UB_UpdateUserResponse>;

  updateUser(
    request: ub_users_pb.UB_UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_UpdateUserResponse) => void): grpcWeb.ClientReadableStream<ub_users_pb.UB_UpdateUserResponse>;

  updateUser(
    request: ub_users_pb.UB_UpdateUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_UpdateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_UserService/UpdateUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_UserService/UpdateUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateUser);
  }

  methodDescriptorDeleteUser = new grpcWeb.MethodDescriptor(
    '/UB_UserService/DeleteUser',
    grpcWeb.MethodType.UNARY,
    ub_users_pb.UB_DeleteUserRequest,
    ub_users_pb.UB_DeleteUserResponse,
    (request: ub_users_pb.UB_DeleteUserRequest) => {
      return request.serializeBinary();
    },
    ub_users_pb.UB_DeleteUserResponse.deserializeBinary
  );

  deleteUser(
    request: ub_users_pb.UB_DeleteUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_users_pb.UB_DeleteUserResponse>;

  deleteUser(
    request: ub_users_pb.UB_DeleteUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_DeleteUserResponse) => void): grpcWeb.ClientReadableStream<ub_users_pb.UB_DeleteUserResponse>;

  deleteUser(
    request: ub_users_pb.UB_DeleteUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_users_pb.UB_DeleteUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_UserService/DeleteUser',
        request,
        metadata || {},
        this.methodDescriptorDeleteUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_UserService/DeleteUser',
    request,
    metadata || {},
    this.methodDescriptorDeleteUser);
  }

  methodDescriptorGetAccountUser = new grpcWeb.MethodDescriptor(
    '/UB_UserService/GetAccountUser',
    grpcWeb.MethodType.UNARY,
    ub_users_pb.GetAccountUserRequest,
    ub_users_pb.GetAccountUserResponse,
    (request: ub_users_pb.GetAccountUserRequest) => {
      return request.serializeBinary();
    },
    ub_users_pb.GetAccountUserResponse.deserializeBinary
  );

  getAccountUser(
    request: ub_users_pb.GetAccountUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_users_pb.GetAccountUserResponse>;

  getAccountUser(
    request: ub_users_pb.GetAccountUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_users_pb.GetAccountUserResponse) => void): grpcWeb.ClientReadableStream<ub_users_pb.GetAccountUserResponse>;

  getAccountUser(
    request: ub_users_pb.GetAccountUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_users_pb.GetAccountUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_UserService/GetAccountUser',
        request,
        metadata || {},
        this.methodDescriptorGetAccountUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_UserService/GetAccountUser',
    request,
    metadata || {},
    this.methodDescriptorGetAccountUser);
  }

  methodDescriptorGetAccountUsers = new grpcWeb.MethodDescriptor(
    '/UB_UserService/GetAccountUsers',
    grpcWeb.MethodType.UNARY,
    ub_users_pb.GetAccountUsersRequest,
    ub_users_pb.GetAccountUsersResponse,
    (request: ub_users_pb.GetAccountUsersRequest) => {
      return request.serializeBinary();
    },
    ub_users_pb.GetAccountUsersResponse.deserializeBinary
  );

  getAccountUsers(
    request: ub_users_pb.GetAccountUsersRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_users_pb.GetAccountUsersResponse>;

  getAccountUsers(
    request: ub_users_pb.GetAccountUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_users_pb.GetAccountUsersResponse) => void): grpcWeb.ClientReadableStream<ub_users_pb.GetAccountUsersResponse>;

  getAccountUsers(
    request: ub_users_pb.GetAccountUsersRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_users_pb.GetAccountUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_UserService/GetAccountUsers',
        request,
        metadata || {},
        this.methodDescriptorGetAccountUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_UserService/GetAccountUsers',
    request,
    metadata || {},
    this.methodDescriptorGetAccountUsers);
  }

  methodDescriptorLogAccountUserInorOut = new grpcWeb.MethodDescriptor(
    '/UB_UserService/LogAccountUserInorOut',
    grpcWeb.MethodType.UNARY,
    ub_users_pb.LogAccountUserInorOutRequest,
    ub_users_pb.LogAccountUserInorOutResponse,
    (request: ub_users_pb.LogAccountUserInorOutRequest) => {
      return request.serializeBinary();
    },
    ub_users_pb.LogAccountUserInorOutResponse.deserializeBinary
  );

  logAccountUserInorOut(
    request: ub_users_pb.LogAccountUserInorOutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_users_pb.LogAccountUserInorOutResponse>;

  logAccountUserInorOut(
    request: ub_users_pb.LogAccountUserInorOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_users_pb.LogAccountUserInorOutResponse) => void): grpcWeb.ClientReadableStream<ub_users_pb.LogAccountUserInorOutResponse>;

  logAccountUserInorOut(
    request: ub_users_pb.LogAccountUserInorOutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_users_pb.LogAccountUserInorOutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_UserService/LogAccountUserInorOut',
        request,
        metadata || {},
        this.methodDescriptorLogAccountUserInorOut,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_UserService/LogAccountUserInorOut',
    request,
    metadata || {},
    this.methodDescriptorLogAccountUserInorOut);
  }

}

