import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import {
  // Direct Labor
  DefaultDirectLaborType,
  CreateOrUpdateDefaultDirectLaborTypeRequest,
  GetDefaultDirectLaborTypesRequest,
  DeleteDefaultDirectLaborTypeRequest,
  // Labor Factoring
  DefaultLaborFactoringType,
  CreateOrUpdateDefaultLaborFactoringTypeRequest,
  GetDefaultLaborFactoringTypesRequest,
  DeleteDefaultLaborFactoringTypeRequest,
  // Indirect Labor
  DefaultIndirectLaborType,
  CreateOrUpdateDefaultIndirectLaborTypeRequest,
  GetDefaultIndirectLaborTypesRequest,
  DeleteDefaultIndirectLaborTypeRequest,
  // Incidental
  DefaultIncidentalLaborType,
  CreateOrUpdateDefaultIncidentalLaborTypeRequest,
  GetDefaultIncidentalLaborTypesRequest,
  DeleteDefaultIncidentalLaborTypeRequest,
  // Equipment
  DefaultEquipmentType,
  CreateOrUpdateDefaultEquipmentExpenseTypeRequest,
  GetDefaultEquipmentExpenseTypesRequest,
  DeleteDefaultEquipmentExpenseTypeRequest,
  // General
  DefaultGeneralExpenseType,
  CreateOrUpdateDefaultGeneralExpenseTypeRequest,
  GetDefaultGeneralExpenseTypesRequest,
  DeleteDefaultGeneralExpenseTypeRequest,
  // Subcontract
  DefaultSubcontractType,
  CreateOrUpdateDefaultSubcontractExpenseTypeRequest,
  GetDefaultSubcontractExpenseTypesRequest,
  DeleteDefaultSubcontractExpenseTypeRequest,
  // Quote
  DefaultQuoteType,
  CreateOrUpdateDefaultQuoteExpenseTypeRequest,
  GetDefaultQuoteExpenseTypesRequest,
  DeleteDefaultQuoteExpenseTypeRequest,
  GetDefaultDirectLaborTypesResponse,
  GetDefaultEquipmentExpenseTypesResponse,
  GetDefaultGeneralExpenseTypesResponse,
  GetDefaultSubcontractExpenseTypesResponse,
  GetDefaultQuoteExpenseTypesResponse,
  GetDefaultIncidentalLaborTypesResponse,
  GetDefaultLaborFactoringTypesResponse,
  GetDefaultIndirectLaborTypesResponse,
} from "./protosCompiled/defaultCloseout/defaultCloseout_pb";

const client = clients.defaultCloseoutServiceClient;

export class DefaultCloseoutService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  // ------------------------------
  //    Direct Labor
  // ------------------------------
  public static async createOrUpdateDefaultDirectLaborType(
    data: DefaultDirectLaborType.AsObject
  ) {
    return new Promise((resolve, reject) => {
      // 1) Convert AsObject -> actual proto message
      const protoMsg = new DefaultDirectLaborType();
      protoMsg.setId(data.id);
      protoMsg.setName(data.name);
      protoMsg.setLaborrate(data.laborrate);
      protoMsg.setBurdenpercent(data.burdenpercent);
      protoMsg.setFringe(data.fringe);
      protoMsg.setDistributionpercent(data.distributionpercent);

      // 2) Create request, set the proto and sessionToken
      const request = new CreateOrUpdateDefaultDirectLaborTypeRequest();
      // Note the method name is auto-lowercased by protoc-gen TS
      request.setDefaultdirectlabortype(protoMsg);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.createOrUpdateDefaultDirectLaborType(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static getDefaultDirectLaborTypes =
    (): Promise<GetDefaultDirectLaborTypesResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetDefaultDirectLaborTypesRequest();
        request.setSessiontoken(this.getMetadata().sessionToken);

        client.getDefaultDirectLaborTypes(
          request,
          {},
          (err: any, resp: any) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(resp.toObject());
          }
        );
      });
    };

  public static async deleteDefaultDirectLaborType(id: string) {
    return new Promise((resolve, reject) => {
      // If your proto uses `string name = 1;` for the delete request
      // not `id`
      const request = new DeleteDefaultDirectLaborTypeRequest();
      request.setId(id);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteDefaultDirectLaborType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  // ------------------------------
  //    Labor Factoring
  // ------------------------------
  public static async createOrUpdateDefaultLaborFactoringType(
    data: DefaultLaborFactoringType.AsObject
  ) {
    return new Promise((resolve, reject) => {
      const protoMsg = new DefaultLaborFactoringType();
      protoMsg.setId(data.id);
      protoMsg.setName(data.name);
      protoMsg.setLaborrate(data.laborrate);
      protoMsg.setBurdenpercent(data.burdenpercent);
      protoMsg.setFringe(data.fringe);
      protoMsg.setImpactpercent(data.impactpercent);
      protoMsg.setLaborpercent(data.laborpercent);

      const request = new CreateOrUpdateDefaultLaborFactoringTypeRequest();
      // Must match auto-lowered method name:
      request.setDefaultlaborfactoringtype(protoMsg);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.createOrUpdateDefaultLaborFactoringType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  public static getDefaultLaborFactoringTypes =
    (): Promise<GetDefaultLaborFactoringTypesResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetDefaultLaborFactoringTypesRequest();
        request.setSessiontoken(this.getMetadata().sessionToken);

        client.getDefaultLaborFactoringTypes(
          request,
          {},
          (err: any, resp: any) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(resp.toObject());
          }
        );
      });
    };

  public static async deleteDefaultLaborFactoringType(id: string) {
    return new Promise((resolve, reject) => {
      const request = new DeleteDefaultLaborFactoringTypeRequest();
      request.setId(id);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteDefaultLaborFactoringType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  // ------------------------------
  //    Indirect Labor
  // ------------------------------
  public static async createOrUpdateDefaultIndirectLaborType(
    data: DefaultIndirectLaborType.AsObject
  ) {
    return new Promise((resolve, reject) => {
      const protoMsg = new DefaultIndirectLaborType();
      protoMsg.setId(data.id);
      protoMsg.setName(data.name);
      protoMsg.setLaborrate(data.laborrate);
      protoMsg.setBurdenpercent(data.burdenpercent);
      protoMsg.setFringe(data.fringe);

      const request = new CreateOrUpdateDefaultIndirectLaborTypeRequest();
      // Must match auto-lowered method name:
      request.setDefaultindirectlabortype(protoMsg);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.createOrUpdateDefaultIndirectLaborType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  public static getDefaultIndirectLaborTypes =
    (): Promise<GetDefaultIndirectLaborTypesResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetDefaultIndirectLaborTypesRequest();
        request.setSessiontoken(this.getMetadata().sessionToken);

        client.getDefaultIndirectLaborTypes(
          request,
          {},
          (err: any, resp: any) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(resp.toObject());
          }
        );
      });
    };

  public static async deleteDefaultIndirectLaborType(id: string) {
    return new Promise((resolve, reject) => {
      const request = new DeleteDefaultIndirectLaborTypeRequest();
      request.setId(id);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteDefaultIndirectLaborType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  // ------------------------------
  //    Incidental Labor
  // ------------------------------
  public static async createOrUpdateDefaultIncidentalLaborType(
    data: DefaultIncidentalLaborType.AsObject
  ) {
    return new Promise((resolve, reject) => {
      const protoMsg = new DefaultIncidentalLaborType();
      protoMsg.setId(data.id);
      protoMsg.setName(data.name);
      protoMsg.setLaborrate(data.laborrate);
      protoMsg.setBurdenpercent(data.burdenpercent);
      protoMsg.setFringe(data.fringe);

      const request = new CreateOrUpdateDefaultIncidentalLaborTypeRequest();
      request.setDefaultincidentallabortype(protoMsg); // auto-lowered
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.createOrUpdateDefaultIncidentalLaborType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  public static getDefaultIncidentalLaborTypes =
    (): Promise<GetDefaultIncidentalLaborTypesResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetDefaultIncidentalLaborTypesRequest();
        request.setSessiontoken(this.getMetadata().sessionToken);

        client.getDefaultIncidentalLaborTypes(
          request,
          {},
          (err: any, resp: any) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(resp.toObject());
          }
        );
      });
    };

  public static async deleteDefaultIncidentalLaborType(id: string) {
    return new Promise((resolve, reject) => {
      const request = new DeleteDefaultIncidentalLaborTypeRequest();
      request.setId(id);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteDefaultIncidentalLaborType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  // ------------------------------
  //    Equipment
  // ------------------------------
  public static async createOrUpdateDefaultEquipmentExpenseType(
    data: DefaultEquipmentType.AsObject
  ) {
    return new Promise((resolve, reject) => {
      const protoMsg = new DefaultEquipmentType();
      protoMsg.setId(data.id);
      protoMsg.setName(data.name);
      protoMsg.setUnitcost(data.unitcost);

      const request = new CreateOrUpdateDefaultEquipmentExpenseTypeRequest();
      request.setDefaultequipmentexpensetype(protoMsg); // auto-lowered
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.createOrUpdateDefaultEquipmentExpenseType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  public static getDefaultEquipmentExpenseTypes =
    (): Promise<GetDefaultEquipmentExpenseTypesResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetDefaultEquipmentExpenseTypesRequest();
        request.setSessiontoken(this.getMetadata().sessionToken);

        client.getDefaultEquipmentExpenseTypes(
          request,
          {},
          (err: any, resp: any) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(resp.toObject());
          }
        );
      });
    };

  public static async deleteDefaultEquipmentExpenseType(id: string) {
    return new Promise((resolve, reject) => {
      const request = new DeleteDefaultEquipmentExpenseTypeRequest();
      request.setId(id);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteDefaultEquipmentExpenseType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  // ------------------------------
  //    General Expense
  // ------------------------------
  public static async createOrUpdateDefaultGeneralExpenseType(
    data: DefaultGeneralExpenseType.AsObject
  ) {
    return new Promise((resolve, reject) => {
      const protoMsg = new DefaultGeneralExpenseType();
      protoMsg.setId(data.id);
      protoMsg.setName(data.name);
      protoMsg.setUnitcost(data.unitcost);

      const request = new CreateOrUpdateDefaultGeneralExpenseTypeRequest();
      request.setDefaultgeneralexpensetype(protoMsg); // auto-lowered
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.createOrUpdateDefaultGeneralExpenseType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  public static getDefaultGeneralExpenseTypes =
    (): Promise<GetDefaultGeneralExpenseTypesResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetDefaultGeneralExpenseTypesRequest();
        request.setSessiontoken(this.getMetadata().sessionToken);

        client.getDefaultGeneralExpenseTypes(
          request,
          {},
          (err: any, resp: any) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(resp.toObject());
          }
        );
      });
    };

  public static async deleteDefaultGeneralExpenseType(id: string) {
    return new Promise((resolve, reject) => {
      const request = new DeleteDefaultGeneralExpenseTypeRequest();
      request.setId(id);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteDefaultGeneralExpenseType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  // ------------------------------
  //    Subcontract
  // ------------------------------
  public static async createOrUpdateDefaultSubcontractExpenseType(
    data: DefaultSubcontractType.AsObject
  ) {
    return new Promise((resolve, reject) => {
      const protoMsg = new DefaultSubcontractType();
      protoMsg.setId(data.id);
      protoMsg.setName(data.name);
      protoMsg.setQuotedcost(data.quotedcost);
      protoMsg.setAdjustedpercent(data.adjustedpercent);

      const request = new CreateOrUpdateDefaultSubcontractExpenseTypeRequest();
      request.setDefaultsubcontractexpensetype(protoMsg);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.createOrUpdateDefaultSubcontractExpenseType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  public static getDefaultSubcontractExpenseTypes =
    (): Promise<GetDefaultSubcontractExpenseTypesResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetDefaultSubcontractExpenseTypesRequest();
        request.setSessiontoken(this.getMetadata().sessionToken);

        client.getDefaultSubcontractExpenseTypes(
          request,
          {},
          (err: any, resp: any) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(resp.toObject());
          }
        );
      });
    };

  public static async deleteDefaultSubcontractExpenseType(id: string) {
    return new Promise((resolve, reject) => {
      const request = new DeleteDefaultSubcontractExpenseTypeRequest();
      request.setId(id);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteDefaultSubcontractExpenseType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  // ------------------------------
  //    Quote
  // ------------------------------
  public static async createOrUpdateDefaultQuoteExpenseType(
    data: DefaultQuoteType.AsObject
  ) {
    return new Promise((resolve, reject) => {
      // Convert AsObject -> proto
      const protoMsg = new DefaultQuoteType();
      protoMsg.setId(data.id);
      protoMsg.setQuotegroup(data.quotegroup);

      const request = new CreateOrUpdateDefaultQuoteExpenseTypeRequest();
      request.setDefaultquoteexpensetype(protoMsg); // auto-lowered
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.createOrUpdateDefaultQuoteExpenseType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }

  public static getDefaultQuoteExpenseTypes =
    (): Promise<GetDefaultQuoteExpenseTypesResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetDefaultQuoteExpenseTypesRequest();
        request.setSessiontoken(this.getMetadata().sessionToken);

        client.getDefaultQuoteExpenseTypes(
          request,
          {},
          (err: any, resp: any) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(resp.toObject());
          }
        );
      });
    };

  public static async deleteDefaultQuoteExpenseType(id: string) {
    return new Promise((resolve, reject) => {
      const request = new DeleteDefaultQuoteExpenseTypeRequest();
      request.setId(id);
      request.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteDefaultQuoteExpenseType(
        request,
        {},
        (err: any, resp: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(resp.toObject());
        }
      );
    });
  }
}
