import { useQuery } from "react-query";
import { useUnityBuildStore } from "../states/store";
import { RoleService as rs } from "../api/RoleService";
import { useStore } from "zustand";

export function useFetchRoles() {
  const { setRoles } = useStore(useUnityBuildStore);

  const { data, error, isLoading } = useQuery(
    "roles",
    async () => {
      const response = await rs.fetchRoles();
      return response.rolesList;
    },
    {
      onSuccess: (roles) => {
        setRoles(roles);
      },
    }
  );

  return { data, error, isLoading };
}
