/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: globalItem.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as globalItem_pb from './globalItem_pb'; // proto import: "globalItem.proto"


export class GlobalItemServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateOrUpdateItem = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/CreateOrUpdateItem',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.CreateOrUpdateItemRequest,
    globalItem_pb.CreateOrUpdateItemResponse,
    (request: globalItem_pb.CreateOrUpdateItemRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.CreateOrUpdateItemResponse.deserializeBinary
  );

  createOrUpdateItem(
    request: globalItem_pb.CreateOrUpdateItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.CreateOrUpdateItemResponse>;

  createOrUpdateItem(
    request: globalItem_pb.CreateOrUpdateItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.CreateOrUpdateItemResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.CreateOrUpdateItemResponse>;

  createOrUpdateItem(
    request: globalItem_pb.CreateOrUpdateItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.CreateOrUpdateItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/CreateOrUpdateItem',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/CreateOrUpdateItem',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateItem);
  }

  methodDescriptorGetGlobalItems = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/GetGlobalItems',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.GetItemsRequest,
    globalItem_pb.GetItemsResponse,
    (request: globalItem_pb.GetItemsRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.GetItemsResponse.deserializeBinary
  );

  getGlobalItems(
    request: globalItem_pb.GetItemsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.GetItemsResponse>;

  getGlobalItems(
    request: globalItem_pb.GetItemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetItemsResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.GetItemsResponse>;

  getGlobalItems(
    request: globalItem_pb.GetItemsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetItemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/GetGlobalItems',
        request,
        metadata || {},
        this.methodDescriptorGetGlobalItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/GetGlobalItems',
    request,
    metadata || {},
    this.methodDescriptorGetGlobalItems);
  }

  methodDescriptorGetAllGlobalItems = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/GetAllGlobalItems',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.GetAllGlobalItemsRequest,
    globalItem_pb.GetAllGlobalItemsResponse,
    (request: globalItem_pb.GetAllGlobalItemsRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.GetAllGlobalItemsResponse.deserializeBinary
  );

  getAllGlobalItems(
    request: globalItem_pb.GetAllGlobalItemsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.GetAllGlobalItemsResponse>;

  getAllGlobalItems(
    request: globalItem_pb.GetAllGlobalItemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAllGlobalItemsResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.GetAllGlobalItemsResponse>;

  getAllGlobalItems(
    request: globalItem_pb.GetAllGlobalItemsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAllGlobalItemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/GetAllGlobalItems',
        request,
        metadata || {},
        this.methodDescriptorGetAllGlobalItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/GetAllGlobalItems',
    request,
    metadata || {},
    this.methodDescriptorGetAllGlobalItems);
  }

  methodDescriptorDeleteGlobalItem = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/DeleteGlobalItem',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.DeleteGlobalItemRequest,
    globalItem_pb.DeleteGlobalItemResponse,
    (request: globalItem_pb.DeleteGlobalItemRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.DeleteGlobalItemResponse.deserializeBinary
  );

  deleteGlobalItem(
    request: globalItem_pb.DeleteGlobalItemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.DeleteGlobalItemResponse>;

  deleteGlobalItem(
    request: globalItem_pb.DeleteGlobalItemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteGlobalItemResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.DeleteGlobalItemResponse>;

  deleteGlobalItem(
    request: globalItem_pb.DeleteGlobalItemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteGlobalItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/DeleteGlobalItem',
        request,
        metadata || {},
        this.methodDescriptorDeleteGlobalItem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/DeleteGlobalItem',
    request,
    metadata || {},
    this.methodDescriptorDeleteGlobalItem);
  }

  methodDescriptorCreateOrUpdateUoM = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/CreateOrUpdateUoM',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.CreateOrUpdateUoMRequest,
    globalItem_pb.CreateOrUpdateUoMResponse,
    (request: globalItem_pb.CreateOrUpdateUoMRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.CreateOrUpdateUoMResponse.deserializeBinary
  );

  createOrUpdateUoM(
    request: globalItem_pb.CreateOrUpdateUoMRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.CreateOrUpdateUoMResponse>;

  createOrUpdateUoM(
    request: globalItem_pb.CreateOrUpdateUoMRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.CreateOrUpdateUoMResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.CreateOrUpdateUoMResponse>;

  createOrUpdateUoM(
    request: globalItem_pb.CreateOrUpdateUoMRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.CreateOrUpdateUoMResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/CreateOrUpdateUoM',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateUoM,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/CreateOrUpdateUoM',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateUoM);
  }

  methodDescriptorGetUoMs = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/GetUoMs',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.GetUoMsRequest,
    globalItem_pb.GetUoMsResponse,
    (request: globalItem_pb.GetUoMsRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.GetUoMsResponse.deserializeBinary
  );

  getUoMs(
    request: globalItem_pb.GetUoMsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.GetUoMsResponse>;

  getUoMs(
    request: globalItem_pb.GetUoMsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetUoMsResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.GetUoMsResponse>;

  getUoMs(
    request: globalItem_pb.GetUoMsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetUoMsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/GetUoMs',
        request,
        metadata || {},
        this.methodDescriptorGetUoMs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/GetUoMs',
    request,
    metadata || {},
    this.methodDescriptorGetUoMs);
  }

  methodDescriptorDeleteUoM = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/DeleteUoM',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.DeleteUoMRequest,
    globalItem_pb.DeleteUoMResponse,
    (request: globalItem_pb.DeleteUoMRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.DeleteUoMResponse.deserializeBinary
  );

  deleteUoM(
    request: globalItem_pb.DeleteUoMRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.DeleteUoMResponse>;

  deleteUoM(
    request: globalItem_pb.DeleteUoMRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteUoMResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.DeleteUoMResponse>;

  deleteUoM(
    request: globalItem_pb.DeleteUoMRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteUoMResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/DeleteUoM',
        request,
        metadata || {},
        this.methodDescriptorDeleteUoM,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/DeleteUoM',
    request,
    metadata || {},
    this.methodDescriptorDeleteUoM);
  }

  methodDescriptorCreateOrUpdateAttributeGroup = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/CreateOrUpdateAttributeGroup',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.CreateOrUpdateAttributeGroupRequest,
    globalItem_pb.CreateOrUpdateAttributeGroupResponse,
    (request: globalItem_pb.CreateOrUpdateAttributeGroupRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.CreateOrUpdateAttributeGroupResponse.deserializeBinary
  );

  createOrUpdateAttributeGroup(
    request: globalItem_pb.CreateOrUpdateAttributeGroupRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.CreateOrUpdateAttributeGroupResponse>;

  createOrUpdateAttributeGroup(
    request: globalItem_pb.CreateOrUpdateAttributeGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.CreateOrUpdateAttributeGroupResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.CreateOrUpdateAttributeGroupResponse>;

  createOrUpdateAttributeGroup(
    request: globalItem_pb.CreateOrUpdateAttributeGroupRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.CreateOrUpdateAttributeGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/CreateOrUpdateAttributeGroup',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateAttributeGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/CreateOrUpdateAttributeGroup',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateAttributeGroup);
  }

  methodDescriptorGetAttributeGroups = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/GetAttributeGroups',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.GetAttributeGroupsRequest,
    globalItem_pb.GetAttributeGroupsResponse,
    (request: globalItem_pb.GetAttributeGroupsRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.GetAttributeGroupsResponse.deserializeBinary
  );

  getAttributeGroups(
    request: globalItem_pb.GetAttributeGroupsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.GetAttributeGroupsResponse>;

  getAttributeGroups(
    request: globalItem_pb.GetAttributeGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAttributeGroupsResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.GetAttributeGroupsResponse>;

  getAttributeGroups(
    request: globalItem_pb.GetAttributeGroupsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAttributeGroupsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/GetAttributeGroups',
        request,
        metadata || {},
        this.methodDescriptorGetAttributeGroups,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/GetAttributeGroups',
    request,
    metadata || {},
    this.methodDescriptorGetAttributeGroups);
  }

  methodDescriptorDeleteAttributeGroup = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/DeleteAttributeGroup',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.DeleteAttributeGroupRequest,
    globalItem_pb.DeleteAttributeGroupResponse,
    (request: globalItem_pb.DeleteAttributeGroupRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.DeleteAttributeGroupResponse.deserializeBinary
  );

  deleteAttributeGroup(
    request: globalItem_pb.DeleteAttributeGroupRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.DeleteAttributeGroupResponse>;

  deleteAttributeGroup(
    request: globalItem_pb.DeleteAttributeGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteAttributeGroupResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.DeleteAttributeGroupResponse>;

  deleteAttributeGroup(
    request: globalItem_pb.DeleteAttributeGroupRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteAttributeGroupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/DeleteAttributeGroup',
        request,
        metadata || {},
        this.methodDescriptorDeleteAttributeGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/DeleteAttributeGroup',
    request,
    metadata || {},
    this.methodDescriptorDeleteAttributeGroup);
  }

  methodDescriptorCreateOrUpdateAttributeValue = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/CreateOrUpdateAttributeValue',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.CreateOrUpdateAttributeValueRequest,
    globalItem_pb.CreateOrUpdateAttributeValueResponse,
    (request: globalItem_pb.CreateOrUpdateAttributeValueRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.CreateOrUpdateAttributeValueResponse.deserializeBinary
  );

  createOrUpdateAttributeValue(
    request: globalItem_pb.CreateOrUpdateAttributeValueRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.CreateOrUpdateAttributeValueResponse>;

  createOrUpdateAttributeValue(
    request: globalItem_pb.CreateOrUpdateAttributeValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.CreateOrUpdateAttributeValueResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.CreateOrUpdateAttributeValueResponse>;

  createOrUpdateAttributeValue(
    request: globalItem_pb.CreateOrUpdateAttributeValueRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.CreateOrUpdateAttributeValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/CreateOrUpdateAttributeValue',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateAttributeValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/CreateOrUpdateAttributeValue',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateAttributeValue);
  }

  methodDescriptorGetAttributeValues = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/GetAttributeValues',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.GetAttributeValuesRequest,
    globalItem_pb.GetAttributeValuesResponse,
    (request: globalItem_pb.GetAttributeValuesRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.GetAttributeValuesResponse.deserializeBinary
  );

  getAttributeValues(
    request: globalItem_pb.GetAttributeValuesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.GetAttributeValuesResponse>;

  getAttributeValues(
    request: globalItem_pb.GetAttributeValuesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAttributeValuesResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.GetAttributeValuesResponse>;

  getAttributeValues(
    request: globalItem_pb.GetAttributeValuesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAttributeValuesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/GetAttributeValues',
        request,
        metadata || {},
        this.methodDescriptorGetAttributeValues,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/GetAttributeValues',
    request,
    metadata || {},
    this.methodDescriptorGetAttributeValues);
  }

  methodDescriptorDeleteAttributeValue = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/DeleteAttributeValue',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.DeleteAttributeValueRequest,
    globalItem_pb.DeleteAttributeValueResponse,
    (request: globalItem_pb.DeleteAttributeValueRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.DeleteAttributeValueResponse.deserializeBinary
  );

  deleteAttributeValue(
    request: globalItem_pb.DeleteAttributeValueRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.DeleteAttributeValueResponse>;

  deleteAttributeValue(
    request: globalItem_pb.DeleteAttributeValueRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteAttributeValueResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.DeleteAttributeValueResponse>;

  deleteAttributeValue(
    request: globalItem_pb.DeleteAttributeValueRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteAttributeValueResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/DeleteAttributeValue',
        request,
        metadata || {},
        this.methodDescriptorDeleteAttributeValue,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/DeleteAttributeValue',
    request,
    metadata || {},
    this.methodDescriptorDeleteAttributeValue);
  }

  methodDescriptorDeleteAttributeVariables = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/DeleteAttributeVariables',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.DeleteAttributeVariablesRequest,
    globalItem_pb.DeleteAttributeVariablesResponse,
    (request: globalItem_pb.DeleteAttributeVariablesRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.DeleteAttributeVariablesResponse.deserializeBinary
  );

  deleteAttributeVariables(
    request: globalItem_pb.DeleteAttributeVariablesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.DeleteAttributeVariablesResponse>;

  deleteAttributeVariables(
    request: globalItem_pb.DeleteAttributeVariablesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteAttributeVariablesResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.DeleteAttributeVariablesResponse>;

  deleteAttributeVariables(
    request: globalItem_pb.DeleteAttributeVariablesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.DeleteAttributeVariablesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/DeleteAttributeVariables',
        request,
        metadata || {},
        this.methodDescriptorDeleteAttributeVariables,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/DeleteAttributeVariables',
    request,
    metadata || {},
    this.methodDescriptorDeleteAttributeVariables);
  }

  methodDescriptorGetAllowedVariables = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/GetAllowedVariables',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.GetAllowedVariablesRequest,
    globalItem_pb.GetAllowedVariablesResponse,
    (request: globalItem_pb.GetAllowedVariablesRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.GetAllowedVariablesResponse.deserializeBinary
  );

  getAllowedVariables(
    request: globalItem_pb.GetAllowedVariablesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.GetAllowedVariablesResponse>;

  getAllowedVariables(
    request: globalItem_pb.GetAllowedVariablesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAllowedVariablesResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.GetAllowedVariablesResponse>;

  getAllowedVariables(
    request: globalItem_pb.GetAllowedVariablesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAllowedVariablesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/GetAllowedVariables',
        request,
        metadata || {},
        this.methodDescriptorGetAllowedVariables,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/GetAllowedVariables',
    request,
    metadata || {},
    this.methodDescriptorGetAllowedVariables);
  }

  methodDescriptorGetItemAttributes = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/GetItemAttributes',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.GetItemAttributesRequest,
    globalItem_pb.GetItemAttributesResponse,
    (request: globalItem_pb.GetItemAttributesRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.GetItemAttributesResponse.deserializeBinary
  );

  getItemAttributes(
    request: globalItem_pb.GetItemAttributesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.GetItemAttributesResponse>;

  getItemAttributes(
    request: globalItem_pb.GetItemAttributesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetItemAttributesResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.GetItemAttributesResponse>;

  getItemAttributes(
    request: globalItem_pb.GetItemAttributesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetItemAttributesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/GetItemAttributes',
        request,
        metadata || {},
        this.methodDescriptorGetItemAttributes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/GetItemAttributes',
    request,
    metadata || {},
    this.methodDescriptorGetItemAttributes);
  }

  methodDescriptorGetItemsByFileId = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/GetItemsByFileId',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.GetItemsByFileIdRequest,
    globalItem_pb.GetItemsByFileIdResponse,
    (request: globalItem_pb.GetItemsByFileIdRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.GetItemsByFileIdResponse.deserializeBinary
  );

  getItemsByFileId(
    request: globalItem_pb.GetItemsByFileIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.GetItemsByFileIdResponse>;

  getItemsByFileId(
    request: globalItem_pb.GetItemsByFileIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetItemsByFileIdResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.GetItemsByFileIdResponse>;

  getItemsByFileId(
    request: globalItem_pb.GetItemsByFileIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetItemsByFileIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/GetItemsByFileId',
        request,
        metadata || {},
        this.methodDescriptorGetItemsByFileId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/GetItemsByFileId',
    request,
    metadata || {},
    this.methodDescriptorGetItemsByFileId);
  }

  methodDescriptorGetAttributes = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/GetAttributes',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.GetAttributesRequest,
    globalItem_pb.GetAttributesResponse,
    (request: globalItem_pb.GetAttributesRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.GetAttributesResponse.deserializeBinary
  );

  getAttributes(
    request: globalItem_pb.GetAttributesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.GetAttributesResponse>;

  getAttributes(
    request: globalItem_pb.GetAttributesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAttributesResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.GetAttributesResponse>;

  getAttributes(
    request: globalItem_pb.GetAttributesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.GetAttributesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/GetAttributes',
        request,
        metadata || {},
        this.methodDescriptorGetAttributes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/GetAttributes',
    request,
    metadata || {},
    this.methodDescriptorGetAttributes);
  }

  methodDescriptorUpdateItemCost = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/UpdateItemCost',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.UpdateItemCostRequest,
    globalItem_pb.UpdateItemCostResponse,
    (request: globalItem_pb.UpdateItemCostRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.UpdateItemCostResponse.deserializeBinary
  );

  updateItemCost(
    request: globalItem_pb.UpdateItemCostRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.UpdateItemCostResponse>;

  updateItemCost(
    request: globalItem_pb.UpdateItemCostRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.UpdateItemCostResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.UpdateItemCostResponse>;

  updateItemCost(
    request: globalItem_pb.UpdateItemCostRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.UpdateItemCostResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/UpdateItemCost',
        request,
        metadata || {},
        this.methodDescriptorUpdateItemCost,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/UpdateItemCost',
    request,
    metadata || {},
    this.methodDescriptorUpdateItemCost);
  }

  methodDescriptorUpdateUPC = new grpcWeb.MethodDescriptor(
    '/GlobalItemService/UpdateUPC',
    grpcWeb.MethodType.UNARY,
    globalItem_pb.UpdateUPCRequest,
    globalItem_pb.UpdateUPCResponse,
    (request: globalItem_pb.UpdateUPCRequest) => {
      return request.serializeBinary();
    },
    globalItem_pb.UpdateUPCResponse.deserializeBinary
  );

  updateUPC(
    request: globalItem_pb.UpdateUPCRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalItem_pb.UpdateUPCResponse>;

  updateUPC(
    request: globalItem_pb.UpdateUPCRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalItem_pb.UpdateUPCResponse) => void): grpcWeb.ClientReadableStream<globalItem_pb.UpdateUPCResponse>;

  updateUPC(
    request: globalItem_pb.UpdateUPCRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalItem_pb.UpdateUPCResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalItemService/UpdateUPC',
        request,
        metadata || {},
        this.methodDescriptorUpdateUPC,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalItemService/UpdateUPC',
    request,
    metadata || {},
    this.methodDescriptorUpdateUPC);
  }

}

