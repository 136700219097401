import React from "react";

const permissionsData = [
  { action: "Edit own user settings", admin: true, level1: true, level2: true },
  {
    action: "Create items and assemblies",
    admin: true,
    level1: true,
    level2: false,
  },
  {
    action: "Full access to all features",
    admin: true,
    level1: false,
    level2: false,
  },
  { action: "Manage users", admin: true, level1: false, level2: false },
  { action: "Manage subscriptions", admin: true, level1: false, level2: false },
];

export const PermissionsChart: React.FC = () => {
  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: "20px",
      }}
    >
      <thead>
        <tr>
          <th
            style={{
              textAlign: "left",
              borderBottom: "2px solid #ccc",
              padding: "8px",
            }}
          >
            Action
          </th>
          <th
            style={{
              textAlign: "center",
              borderBottom: "2px solid #ccc",
              padding: "8px",
            }}
          >
            Account Admin
          </th>
          <th
            style={{
              textAlign: "center",
              borderBottom: "2px solid #ccc",
              padding: "8px",
            }}
          >
            Level 1
          </th>
          <th
            style={{
              textAlign: "center",
              borderBottom: "2px solid #ccc",
              padding: "8px",
            }}
          >
            Level 2
          </th>
        </tr>
      </thead>
      <tbody>
        {permissionsData.map((permission, index) => (
          <tr key={index}>
            <td
              style={{
                padding: "8px",
                borderBottom: "1px solid #eee",
              }}
            >
              {permission.action}
            </td>
            <td
              style={{
                textAlign: "center",
                padding: "8px",
                borderBottom: "1px solid #eee",
              }}
            >
              {permission.admin ? "✅" : "❌"}
            </td>
            <td
              style={{
                textAlign: "center",
                padding: "8px",
                borderBottom: "1px solid #eee",
              }}
            >
              {permission.level1 ? "✅" : "❌"}
            </td>
            <td
              style={{
                textAlign: "center",
                padding: "8px",
                borderBottom: "1px solid #eee",
              }}
            >
              {permission.level2 ? "✅" : "❌"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
