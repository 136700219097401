import { useStore } from "zustand";
import { useNavigate } from "react-router-dom";
import { useUnityBuildStore, defaultProject } from "../../states/store";
import { useGlobalAssemblyStore } from "../../states/globalAssemblyStore";
import { useInferenceStore } from "../../states/inferenceStore";
import { ResetStates } from "../../states/resetStates";
import { useFetchUser } from "../../hooks/useFetchUsers";
import { UbAccountService } from "../../api/UbAccountService";
import { SettingsProps } from "./Settings";

export function useHandleLogOut({ onCloseMenu, onLogout }: SettingsProps) {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useGlobalAssemblyStore);
  const store3 = useStore(useInferenceStore);
  const {
    setIsLoggedIn,
    setProjectListIsMounted,
    setSessionToken,
    setSelectedProject,
  } = useStore(useUnityBuildStore);
  const navigate = useNavigate();
  const { data: user } = useFetchUser();

  const handleLogOut = async () => {
    try {
      if (user && user.user) {
        // Log out user on backend
        await UbAccountService.logAccountUserInorOut(user.user.id, false);
      }
      ResetStates.resetMasterProjectViewStates(store, store1, store3);

      // Reset local state
      setIsLoggedIn(false);
      setSessionToken("");
      setSelectedProject(defaultProject);
      setProjectListIsMounted(false);

      // Close menus and navigate away
      onCloseMenu();
      onLogout();
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return handleLogOut;
}
