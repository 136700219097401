import {
  CreateOrUpdateEstimateCloseoutRequest,
  DirectLaborType,
  EquipmentType,
  EstimateCloseout,
  ExtensionType,
  GeneralExpenseType,
  IncidentalLaborType,
  IndirectLaborType,
  LaborFactoringType,
  QuoteType,
  SubcontractType,
  TotalFinalRowData,
  TotalPricingType,
  TotalRowPricingData,
} from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { Calculations as c } from "../../utils/calculations";
import { CloseoutService } from "../../api/CloseoutService";
import { totalPricingDefaults } from "./closeoutTypes";
import {
  ChangeOrderCloseout,
  CreateOrUpdateChangeOrderCloseoutRequest,
} from "../../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";

import { TakeoffStats as EstimateAssemblyStats } from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { TakeoffStats as ChangeOrderAssemblyStats } from "../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

export const formatCloseoutData = (
  id: string,
  isEstimate: boolean,
  extention: ExtensionType.AsObject[],
  directLabor: DirectLaborType.AsObject[],
  incidentalLabor: IncidentalLaborType.AsObject[],
  laborFactoring: LaborFactoringType.AsObject[],
  indirectLabor: IndirectLaborType.AsObject[],
  equipmentExpenses: EquipmentType.AsObject[],
  generalExpenses: GeneralExpenseType.AsObject[],
  subcontractExpenses: SubcontractType.AsObject[],
  quoteExpenses: QuoteType.AsObject[],
  totalFinalRowData: TotalFinalRowData.AsObject
) => {
  directLabor.forEach((labor) => {
    labor.totalcost = c.calculateFullLaborCost(labor);
  });

  laborFactoring.forEach((labor) => {
    labor.totalcost = c.calculateFullLaborCost(labor);
  });

  incidentalLabor.forEach((labor) => {
    labor.totalcost = c.calculateFullLaborCost(labor);
  });

  indirectLabor.forEach((labor) => {
    labor.totalcost = c.calculateFullLaborCost(labor);
  });

  equipmentExpenses.forEach((equipment) => {
    equipment.totalcost = c.calculateEquipmentTotal(equipment);
  });

  generalExpenses.forEach((genExpense) => {
    genExpense.totalcost = c.calculateGenExpenseTotal(genExpense);
  });

  subcontractExpenses.forEach((subcontract) => {
    subcontract.totalcost = c.calculateSubcontractTotal(subcontract);
  });

  quoteExpenses.forEach((quote) => {
    quote.totalcost = c.calculateQuoteTotal(quote);
  });

  const protoExtensionList = CloseoutService.mapToExtensionType(extention);
  const protoDirectLaborList =
    CloseoutService.mapToDirectLaborType(directLabor);
  const protoIncidentalLaborList =
    CloseoutService.mapToIncidentalLaborType(incidentalLabor);
  const protoLaborFactoringList =
    CloseoutService.mapToLaborFactoringType(laborFactoring);
  const protoIndirectLaborList =
    CloseoutService.mapToIndirectLaborType(indirectLabor);
  const protoEquipmentList =
    CloseoutService.mapToEquipmentType(equipmentExpenses);
  const protoGeneralExpenseList =
    CloseoutService.mapToGeneralExpenseType(generalExpenses);
  const protoSubcontractList =
    CloseoutService.mapToSubcontractType(subcontractExpenses);
  const protoQuoteList = CloseoutService.mapToQuoteType(quoteExpenses);
  const protoTotalFinalRowData =
    CloseoutService.mapToTotalFinalRowData(totalFinalRowData);

  const data = {
    extensionList: protoExtensionList.map((item) => item.toObject()),
    directlaborList: protoDirectLaborList.map((item) => item.toObject()),
    incidentallaborList: protoIncidentalLaborList.map((item) =>
      item.toObject()
    ),
    laborfactoringList: protoLaborFactoringList.map((item) => item.toObject()),
    indirectlaborList: protoIndirectLaborList.map((item) => item.toObject()),
    equipmentexpenseList: protoEquipmentList.map((item) => item.toObject()),
    generalexpenseList: protoGeneralExpenseList.map((item) => item.toObject()),
    subcontractexpenseList: protoSubcontractList.map((item) => item.toObject()),
    quoteexpenseList: protoQuoteList.map((item) => item.toObject()),
    totalfinalprice: protoTotalFinalRowData.toObject(),
  };

  // Return the appropriate request based on whether it's an estimate or a change order
  return isEstimate
    ? ({
        ...data,
        estimateid: id,
      } as CreateOrUpdateEstimateCloseoutRequest.AsObject)
    : ({
        ...data,
        changeorderid: id,
      } as CreateOrUpdateChangeOrderCloseoutRequest.AsObject);
};

export const aggregatedItemTotals = (
  assemblyStats:
    | EstimateAssemblyStats.AsObject[]
    | ChangeOrderAssemblyStats.AsObject[]
) => {
  const aggregatedItems = assemblyStats
    ? c.aggregateItemQuantities(assemblyStats)
    : [];

  const totalItemCost = aggregatedItems.reduce(
    (total, item) =>
      total + item.calculatedQuantity * item.costsList[0].itemunitcost,
    0
  );
  const totalLaborHours = aggregatedItems.reduce(
    (total, item) =>
      total +
      c.safeDivide(
        item.calculatedQuantity,
        item.costsList[0].hourlyproductionrate
      ),
    0
  );
  return {
    totalItemCost,
    totalLaborHours,
    aggregatedItems,
  };
};

export const calculateTotalPricingData = (
  items: any[]
): TotalRowPricingData.AsObject => {
  const extendedcost = items?.reduce((sum, item) => sum + item.totalcost, 0);

  const adjustedpercent = items?.reduce(
    (sum, item) => sum + item.adjustedpercent,
    0
  );

  const adjustedcost = items?.reduce((sum, item) => sum + item.adjustedcost, 0);

  const overheadpercent = items?.reduce(
    (sum, item) => sum + item.overheadpercent,
    0
  );

  const totaloverhead = items?.reduce(
    (sum, item) => sum + item.totaloverhead,
    0
  );

  const markuppercent = items?.reduce(
    (sum, item) => sum + item.markuppercent,
    0
  );

  const totalmarkup = items?.reduce((sum, item) => sum + item.totalmarkup, 0);

  const totalcost = items?.reduce((sum, item) => sum + item.totalcost, 0);

  return {
    extendedcost,
    adjustedpercent: adjustedpercent,
    adjustedcost: adjustedcost,
    overheadpercent: overheadpercent,
    totaloverhead: totaloverhead,
    markuppercent: markuppercent,
    totalmarkup: totalmarkup,
    totalcost: totalcost,
  };
};

export const aggregateTotalPricingTypes = (
  extensionList: ExtensionType.AsObject[],
  directLaborList: DirectLaborType.AsObject[],
  laborFactoringList: LaborFactoringType.AsObject[],
  incidentalLaborList: IncidentalLaborType.AsObject[],
  indirectLaborList: IndirectLaborType.AsObject[],
  equipmentExpenseList: EquipmentType.AsObject[],
  generalExpenseList: GeneralExpenseType.AsObject[],
  subcontractExpenseList: SubcontractType.AsObject[],
  quoteExpenseList: QuoteType.AsObject[]
): TotalPricingType.AsObject[] => {
  const totalPricing: TotalPricingType.AsObject[] = [
    { name: "Materials", rowdata: calculateTotalPricingData(extensionList) },
    { name: "Quotes", rowdata: calculateTotalPricingData(quoteExpenseList) },
    {
      name: "Direct Labor",
      rowdata: calculateTotalPricingData(directLaborList),
    },
    {
      name: "Labor Factoring",
      rowdata: calculateTotalPricingData(laborFactoringList),
    },
    {
      name: "Incidental Labor",
      rowdata: calculateTotalPricingData(incidentalLaborList),
    },
    {
      name: "Indirect Labor",
      rowdata: calculateTotalPricingData(indirectLaborList),
    },
    {
      name: "Equipment",
      rowdata: calculateTotalPricingData(equipmentExpenseList),
    },
    {
      name: "General Expenses",
      rowdata: calculateTotalPricingData(generalExpenseList),
    },
    {
      name: "Subcontracts",
      rowdata: calculateTotalPricingData(subcontractExpenseList),
    },
  ];
  return totalPricing;
};

export const updatedTotalPricingTypes = (
  includedTotalPricingTypes: TotalFinalRowData.AsObject,
  field: string,
  name: string,
  value: number
) => {
  return includedTotalPricingTypes.rowtypesList.map(
    (item: TotalPricingType.AsObject) => {
      if (item.name === name) {
        const updatedData: TotalRowPricingData.AsObject = {
          adjustedcost: item.rowdata?.adjustedcost || 0,
          adjustedpercent: item.rowdata?.adjustedpercent || 0,
          extendedcost: item.rowdata?.extendedcost || 0,
          markuppercent: item.rowdata?.markuppercent || 0,
          overheadpercent: item.rowdata?.overheadpercent || 0,
          totalcost: item.rowdata?.totalcost || 0,
          totalmarkup: item.rowdata?.totalmarkup || 0,
          totaloverhead: item.rowdata?.totaloverhead || 0,
          [field]: value,
        };

        const adjustedCost =
          updatedData.extendedcost * (1 + updatedData.adjustedpercent);
        updatedData.adjustedcost = adjustedCost;

        const overheadValue = adjustedCost * updatedData.overheadpercent;
        updatedData.totaloverhead = overheadValue;

        const markupValue = adjustedCost * updatedData.markuppercent;
        updatedData.totalmarkup = markupValue;

        updatedData.totalcost = adjustedCost + overheadValue + markupValue;

        return { ...item, rowdata: updatedData };
      }
      return item;
    }
  );
};

export const updatedTotalFinalData = (
  updatedTotalPricingTypesData: TotalPricingType.AsObject[]
) => {
  return updatedTotalPricingTypesData.reduce(
    (acc, curr) => {
      acc.totalcost += curr.rowdata?.adjustedcost || 0;
      acc.totaloverhead += curr.rowdata?.totaloverhead || 0;
      acc.totalmarkup += curr.rowdata?.totalmarkup || 0;
      acc.totalfinalprice =
        acc.totalcost + acc.totaloverhead + acc.totalmarkup || 0;
      return acc;
    },
    { totalcost: 0, totaloverhead: 0, totalmarkup: 0, totalfinalprice: 0 }
  );
};

export const sxUnitCostOverrideValue = (item: ExtensionType.AsObject) => {
  if (item.isquoted) {
    return {
      backgroundColor: "lightgrey",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
  if (item.overrideunitcost) {
    return {
      backgroundColor: "yellow",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
  if (item.itemunitcost === 0) {
    return {
      backgroundColor: "red",
      border: "1px solid black",
      borderRadius: "5px",
    };
  } else {
    return {
      backgroundColor: "lightgreen",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
};

export const sxProductionCostOverrideValue = (item: ExtensionType.AsObject) => {
  if (item.overrideproductionrate) {
    return {
      backgroundColor: "yellow",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
  if (item.hourlyproductionrate === 0) {
    return {
      backgroundColor: "red",
      border: "1px solid black",
      borderRadius: "5px",
    };
  } else {
    return {
      backgroundColor: "lightgreen",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
};

export const recalculateDirectLabor = (
  labor: DirectLaborType.AsObject[],
  totalLaborHours: number,
  setIncludedDirectLaborTypes: (types: DirectLaborType.AsObject[]) => void
) => {
  const updatedLaborList = labor.map((laborType) => ({
    ...laborType,
    allocatedhours: totalLaborHours * (laborType.distributionpercent || 0),
  }));

  const hasChanges = updatedLaborList.some((newItem, index) => {
    const currentItem = labor[index];
    return (
      currentItem.allocatedhours !== newItem.allocatedhours ||
      currentItem.distributionpercent !== newItem.distributionpercent
    );
  });

  if (hasChanges) {
    setIncludedDirectLaborTypes(updatedLaborList);
  }
};

export const recalculateLaborFactoring = (
  labor: LaborFactoringType.AsObject[],
  totalLaborHours: number,
  setIncludedLaborFactoringTypes: (types: LaborFactoringType.AsObject[]) => void
) => {
  const updatedLaborList = labor.map((laborType) => ({
    ...laborType,
    allocatedhours:
      totalLaborHours *
      (laborType.laborpercent || 0) *
      (laborType.impactpercent || 0),
  }));

  // Check if the new calculated values differ from the current state
  const hasChanges = updatedLaborList.some((newItem, index) => {
    const currentItem = labor[index];
    return (
      currentItem.allocatedhours !== newItem.allocatedhours ||
      currentItem.laborpercent !== newItem.laborpercent ||
      currentItem.impactpercent !== newItem.impactpercent
    );
  });

  if (hasChanges) {
    setIncludedLaborFactoringTypes(updatedLaborList);
  }
};

export const recalculateIndirectLabor = (
  labor: IndirectLaborType.AsObject[],
  totalLaborHours: number,
  setIncludedIndirectLaborTypes: (types: IndirectLaborType.AsObject[]) => void
) => {
  const updatedLaborList = labor.map((laborType) => ({
    ...laborType,
    allocatedhours: c.calculateAllocatedHours(
      totalLaborHours,
      laborType.distributionpercent || 0
    ),
  }));

  const hasChanges = updatedLaborList.some((newItem, index) => {
    const currentItem = labor[index];
    return (
      currentItem.allocatedhours !== newItem.allocatedhours ||
      currentItem.distributionpercent !== newItem.distributionpercent
    );
  });

  if (hasChanges) {
    setIncludedIndirectLaborTypes(updatedLaborList);
  }
};

export const recalculateTotalPricing = (
  estimateCloseout: EstimateCloseout.AsObject | ChangeOrderCloseout.AsObject,
  setIncludedTotalPricingTypes: (data: TotalFinalRowData.AsObject) => void
) => {
  if (!estimateCloseout) return;

  const aggregatedTotalPricingTypes: TotalPricingType.AsObject[] =
    aggregateTotalPricingTypes(
      estimateCloseout.extensionList,
      estimateCloseout.directlaborList,
      estimateCloseout.laborfactoringList,
      estimateCloseout.incidentallaborList,
      estimateCloseout.indirectlaborList,
      estimateCloseout.equipmentexpenseList,
      estimateCloseout.generalexpenseList,
      estimateCloseout.subcontractexpenseList,
      estimateCloseout.quoteexpenseList
    );

  const existingPricing =
    estimateCloseout.totalfinalprice || totalPricingDefaults;

  const updatedRowTypes: TotalPricingType.AsObject[] =
    aggregatedTotalPricingTypes.map((aggregatedItem) => {
      const existingItem = existingPricing.rowtypesList.find(
        (item) => item.name === aggregatedItem.name
      );

      const extendedCost = aggregatedItem.rowdata?.extendedcost ?? 0;
      const adjustedPercent =
        existingItem?.rowdata?.adjustedpercent ??
        aggregatedItem.rowdata?.adjustedpercent ??
        0;
      const adjustedCost = extendedCost * (1 + adjustedPercent);

      const overheadPercent =
        existingItem?.rowdata?.overheadpercent ??
        aggregatedItem.rowdata?.overheadpercent ??
        0;
      const totalOverhead = adjustedCost * overheadPercent;

      const markupPercent =
        existingItem?.rowdata?.markuppercent ??
        aggregatedItem.rowdata?.markuppercent ??
        0;
      const totalMarkup = adjustedCost * markupPercent;

      const totalCost = adjustedCost + totalOverhead + totalMarkup;

      return {
        name: aggregatedItem.name,
        rowdata: {
          extendedcost: extendedCost,
          adjustedpercent: adjustedPercent,
          adjustedcost: adjustedCost,
          overheadpercent: overheadPercent,
          totaloverhead: totalOverhead,
          markuppercent: markupPercent,
          totalmarkup: totalMarkup,
          totalcost: totalCost,
        },
      };
    });

  const totalfinaldata = updatedTotalFinalData(updatedRowTypes);

  setIncludedTotalPricingTypes({
    rowtypesList: updatedRowTypes,
    totalfinaldata,
  });
};

// function to order the extenstion list by isQuoted first, then by attribute group name
export const orderExtension = (filteredItems: ExtensionType.AsObject[]) => {
  return filteredItems.sort((a, b) => {
    // Sort items with isquoted = true to the top
    if (a.isquoted && !b.isquoted) return -1;
    if (!a.isquoted && b.isquoted) return 1;

    // If both have the same isquoted status, sort by attributegroupname
    if (a.attributegroupname < b.attributegroupname) return -1;
    if (a.attributegroupname > b.attributegroupname) return 1;

    return 0; // Preserve original order if both isquoted and attributegroupname are the same
  });
};
