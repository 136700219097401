import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import {
  CreateAccountRequest,
  CreateAccountResponse,
  GetAccountRequest,
  GetAccountResponse,
  UB_Account,
  UpdateAccountRequest,
  UpdateAccountResponse,
} from "./protosCompiled/ub_account/ub_account_pb";
import {
  LogAccountUserInorOutRequest,
  LogAccountUserInorOutResponse,
} from "./protosCompiled/ub_users/ub_users_pb";

export class UbAccountService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static createAccount = (
    data: UB_Account.AsObject
  ): Promise<CreateAccountResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      // Create the UB_Account object and populate it with data
      const account = new UB_Account();
      account.setName(data.name);
      account.setEmail(data.email);
      account.setPhone(data.phone);
      account.setNumusers(data.numusers);

      // Create the request object and set the account
      const request = new CreateAccountRequest();
      request.setAccount(account);

      // Make the gRPC call to create the account
      clients.ubAccountServiceClient.createAccount(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateAccount = (
    data: UB_Account.AsObject
  ): Promise<UpdateAccountResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      // Create the UB_Account object and populate it with data
      const account = new UB_Account();
      account.setName(data.name);
      account.setEmail(data.email);
      account.setPhone(data.phone);
      account.setNumusers(data.numusers);

      // Create the request object and set the account
      const request = new UpdateAccountRequest();
      request.setAccount(account); // Set the account object

      // Make the gRPC call to update the account
      clients.ubAccountServiceClient.updateAccount(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static logAccountUserInorOut = (
    userId: string,
    isLoggedIn: boolean
  ): Promise<LogAccountUserInorOutResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      // Create the request object and set the userId and isLoggedIn
      const request = new LogAccountUserInorOutRequest();
      request.setUserid(userId);
      request.setIsloggedin(isLoggedIn);
      request.setSessiontoken(this.getMetadata().sessionToken);

      // Make the gRPC call to log the user in or out
      clients.ub_userServiceClient.logAccountUserInorOut(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static getAccount = () => {
    return new Promise<GetAccountResponse.AsObject>((resolve, reject) => {
      const getAccountRequest = new GetAccountRequest();
      getAccountRequest.setSessiontoken(this.getMetadata().sessionToken);
      clients.ubAccountServiceClient.getAccount(
        getAccountRequest,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
