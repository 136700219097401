import React, { useState, useEffect, useRef } from "react";
import {
  List,
  ListItem,
  CircularProgress,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFetchObjects } from "../../hooks/objectHooks";
import { ObjectService } from "../../api/ObjectService";
import { useIntersection } from "./useIntersection";

// Use local PDF worker
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

/** Container dimensions */
const PREVIEW_WIDTH = 410;
const PREVIEW_HEIGHT = 280;

export const DocumentManager = () => {
  const {
    data: documents,
    isLoading,
    error,
    refetchObjects,
  } = useFetchObjects();

  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [updatedName, setUpdatedName] = useState<string>("");

  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]); // Track selected documents

  // Cache PDF preview URLs so we only fetch them once
  const cachedPreviews = useRef<Record<string, string>>({});

  // Keep references to TransformWrapper if needed
  const transformRefs = useRef<Record<string, ReactZoomPanPinchRef>>({});

  useEffect(() => {
    if (documents) {
      documents.forEach((doc) => {
        if (!cachedPreviews.current[doc.id]) {
          cachedPreviews.current[doc.id] = doc.previewUrl;
        }
      });
    }
  }, [documents]);

  const handleDelete = async (objectId: string) => {
    setDeletingId(objectId);
    try {
      await ObjectService.deleteObject(objectId);
      refetchObjects();
    } catch (err) {
      console.error("Failed to delete document:", err);
    } finally {
      setDeletingId(null);
    }
  };

  const handleDeleteSelected = async () => {
    const deletableIds = selectedDocumentIds.filter(
      (id) => !documents?.find((doc) => doc.id === id)?.hastakeoff
    );

    for (const id of deletableIds) {
      try {
        setDeletingId(id);
        await ObjectService.deleteObject(id);
      } catch (err) {
        console.error(`Failed to delete document with ID ${id}:`, err);
      } finally {
        setDeletingId(null);
      }
    }

    setSelectedDocumentIds([]);
    refetchObjects();
  };

  const handleSelectAll = () => {
    if (selectedDocumentIds.length === documents?.length) {
      setSelectedDocumentIds([]);
    } else {
      setSelectedDocumentIds(documents?.map((doc) => doc.id) || []);
    }
  };

  const toggleDocumentSelection = (docId: string) => {
    setSelectedDocumentIds((prevSelected) =>
      prevSelected.includes(docId)
        ? prevSelected.filter((id) => id !== docId)
        : [...prevSelected, docId]
    );
  };

  const handleEdit = (docId: string, currentName: string) => {
    setEditingId(docId);
    setUpdatedName(currentName);
  };

  const handleSave = async (docId: string) => {
    try {
      await ObjectService.updateObjectName(docId, updatedName);
      refetchObjects();
    } catch (err) {
      console.error("Failed to update document name:", err);
    } finally {
      setEditingId(null);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error) {
    return (
      <Typography color="error">
        Error fetching documents: {(error as Error).message || "Unknown error."}
      </Typography>
    );
  }
  if (!documents || documents.length === 0) {
    return <Typography>No documents available. Please upload some.</Typography>;
  }

  return (
    <Box sx={{ width: "100%", height: "100%", overflowY: "auto", p: 2 }}>
      {/* Header Controls */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedDocumentIds.length === documents.length}
              indeterminate={
                selectedDocumentIds.length > 0 &&
                selectedDocumentIds.length < documents.length
              }
              onChange={handleSelectAll}
            />
          }
          label="Select All"
        />
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteSelected}
          disabled={selectedDocumentIds.length === 0}
        >
          Delete Selected
        </Button>
      </Box>

      <List>
        {documents.map((doc) => (
          <DocumentRow
            key={doc.id}
            doc={doc}
            deletingId={deletingId}
            editingId={editingId}
            updatedName={updatedName}
            setDeletingId={setDeletingId}
            setUpdatedName={setUpdatedName}
            handleEdit={handleEdit}
            handleSave={handleSave}
            handleDelete={handleDelete}
            cachedPreviews={cachedPreviews}
            transformRefs={transformRefs}
            isSelected={selectedDocumentIds.includes(doc.id)}
            toggleDocumentSelection={toggleDocumentSelection}
          />
        ))}
      </List>
    </Box>
  );
};

function DocumentRow({
  doc,
  deletingId,
  editingId,
  updatedName,
  setDeletingId,
  setUpdatedName,
  handleEdit,
  handleSave,
  handleDelete,
  cachedPreviews,
  transformRefs,
  isSelected,
  toggleDocumentSelection,
}: {
  doc: {
    id: string;
    objectname: string;
    previewUrl: string;
    hastakeoff: boolean;
  };
  deletingId: string | null;
  editingId: string | null;
  updatedName: string;
  setDeletingId: React.Dispatch<React.SetStateAction<string | null>>;
  setUpdatedName: React.Dispatch<React.SetStateAction<string>>;
  handleEdit: (docId: string, currentName: string) => void;
  handleSave: (docId: string) => void;
  handleDelete: (docId: string) => void;
  cachedPreviews: React.MutableRefObject<Record<string, string>>;
  transformRefs: React.MutableRefObject<Record<string, ReactZoomPanPinchRef>>;
  isSelected: boolean;
  toggleDocumentSelection: (docId: string) => void;
}) {
  const rowRef = useRef<HTMLLIElement>(null);
  const isVisible = useIntersection(rowRef, {
    rootMargin: "100px",
    threshold: 0.1,
  });

  return (
    <ListItem
      ref={rowRef}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        p: 2,
        borderBottom: "1px solid #ddd",
      }}
    >
      <Checkbox
        checked={isSelected}
        onChange={() => toggleDocumentSelection(doc.id)}
      />

      {isVisible ? (
        <PDFPreview
          doc={doc}
          cachedPreviews={cachedPreviews}
          transformRefs={transformRefs}
        />
      ) : (
        <Box
          sx={{
            width: PREVIEW_WIDTH,
            height: PREVIEW_HEIGHT,
            mr: 2,
            border: "1px solid #ccc",
            borderRadius: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fafafa",
            color: "#999",
          }}
        >
          <Typography variant="caption">
            PDF preview unloaded (scroll into view)
          </Typography>
        </Box>
      )}

      <Box sx={{ flex: 1, ml: 2 }}>
        {editingId === doc.id ? (
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <TextField
              value={updatedName}
              onChange={(e) => setUpdatedName(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              sx={{ mr: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSave(doc.id)}
              sx={{ mr: 1 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleEdit(doc.id, doc.objectname)}
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <Typography
            variant="body1"
            sx={{
              mb: 1,
              cursor: "pointer",
              userSelect: "none",
            }}
            onDoubleClick={() => handleEdit(doc.id, doc.objectname)}
          >
            {doc.objectname}
          </Typography>
        )}

        <IconButton
          edge="end"
          onClick={() => handleDelete(doc.id)}
          disabled={deletingId === doc.id || doc.hastakeoff}
        >
          {deletingId === doc.id ? (
            <CircularProgress size={24} />
          ) : (
            <DeleteIcon sx={{ fontSize: 24 }} />
          )}
        </IconButton>
      </Box>
    </ListItem>
  );
}

function PDFPreview({
  doc,
  cachedPreviews,
  transformRefs,
}: {
  doc: { id: string; objectname: string; previewUrl: string };
  cachedPreviews: React.MutableRefObject<Record<string, string>>;
  transformRefs: React.MutableRefObject<Record<string, ReactZoomPanPinchRef>>;
}) {
  return (
    <Box
      sx={{
        width: PREVIEW_WIDTH,
        height: PREVIEW_HEIGHT,
        mr: 2,
        overflow: "hidden",
        border: "1px solid #ccc",
        borderRadius: 2,
        backgroundColor: "#f9f9f9",
        position: "relative",
      }}
    >
      <TransformWrapper
        initialScale={0.15}
        minScale={0.05}
        maxScale={5}
        limitToBounds={false}
        centerOnInit
        doubleClick={{ disabled: false }}
        wheel={{
          step: 0.05,
          wheelDisabled: false,
          touchPadDisabled: false,
          activationKeys: [],
        }}
        panning={{ velocityDisabled: false }}
        onInit={(instance) => {
          transformRefs.current[doc.id] = instance;
        }}
      >
        <TransformComponent>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Document
              file={cachedPreviews.current[doc.id]}
              loading={<CircularProgress size={24} />}
              onLoadError={(e) => console.error("Error loading PDF:", e)}
            >
              <Page pageNumber={1} scale={1} renderMode="canvas" />
            </Document>
          </Box>
        </TransformComponent>
      </TransformWrapper>
    </Box>
  );
}
