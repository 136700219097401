/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: defaultCloseout.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as defaultCloseout_pb from './defaultCloseout_pb'; // proto import: "defaultCloseout.proto"


export class DefaultCloseoutServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateOrUpdateDefaultDirectLaborType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/CreateOrUpdateDefaultDirectLaborType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeRequest,
    defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeResponse,
    (request: defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeResponse.deserializeBinary
  );

  createOrUpdateDefaultDirectLaborType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeResponse>;

  createOrUpdateDefaultDirectLaborType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeResponse>;

  createOrUpdateDefaultDirectLaborType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultDirectLaborTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/CreateOrUpdateDefaultDirectLaborType',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateDefaultDirectLaborType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/CreateOrUpdateDefaultDirectLaborType',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateDefaultDirectLaborType);
  }

  methodDescriptorGetDefaultDirectLaborTypes = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/GetDefaultDirectLaborTypes',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.GetDefaultDirectLaborTypesRequest,
    defaultCloseout_pb.GetDefaultDirectLaborTypesResponse,
    (request: defaultCloseout_pb.GetDefaultDirectLaborTypesRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.GetDefaultDirectLaborTypesResponse.deserializeBinary
  );

  getDefaultDirectLaborTypes(
    request: defaultCloseout_pb.GetDefaultDirectLaborTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.GetDefaultDirectLaborTypesResponse>;

  getDefaultDirectLaborTypes(
    request: defaultCloseout_pb.GetDefaultDirectLaborTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultDirectLaborTypesResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.GetDefaultDirectLaborTypesResponse>;

  getDefaultDirectLaborTypes(
    request: defaultCloseout_pb.GetDefaultDirectLaborTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultDirectLaborTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/GetDefaultDirectLaborTypes',
        request,
        metadata || {},
        this.methodDescriptorGetDefaultDirectLaborTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/GetDefaultDirectLaborTypes',
    request,
    metadata || {},
    this.methodDescriptorGetDefaultDirectLaborTypes);
  }

  methodDescriptorDeleteDefaultDirectLaborType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/DeleteDefaultDirectLaborType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.DeleteDefaultDirectLaborTypeRequest,
    defaultCloseout_pb.DeleteDefaultDirectLaborTypeResponse,
    (request: defaultCloseout_pb.DeleteDefaultDirectLaborTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.DeleteDefaultDirectLaborTypeResponse.deserializeBinary
  );

  deleteDefaultDirectLaborType(
    request: defaultCloseout_pb.DeleteDefaultDirectLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.DeleteDefaultDirectLaborTypeResponse>;

  deleteDefaultDirectLaborType(
    request: defaultCloseout_pb.DeleteDefaultDirectLaborTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultDirectLaborTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.DeleteDefaultDirectLaborTypeResponse>;

  deleteDefaultDirectLaborType(
    request: defaultCloseout_pb.DeleteDefaultDirectLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultDirectLaborTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/DeleteDefaultDirectLaborType',
        request,
        metadata || {},
        this.methodDescriptorDeleteDefaultDirectLaborType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/DeleteDefaultDirectLaborType',
    request,
    metadata || {},
    this.methodDescriptorDeleteDefaultDirectLaborType);
  }

  methodDescriptorCreateOrUpdateDefaultLaborFactoringType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/CreateOrUpdateDefaultLaborFactoringType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeRequest,
    defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeResponse,
    (request: defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeResponse.deserializeBinary
  );

  createOrUpdateDefaultLaborFactoringType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeResponse>;

  createOrUpdateDefaultLaborFactoringType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeResponse>;

  createOrUpdateDefaultLaborFactoringType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultLaborFactoringTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/CreateOrUpdateDefaultLaborFactoringType',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateDefaultLaborFactoringType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/CreateOrUpdateDefaultLaborFactoringType',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateDefaultLaborFactoringType);
  }

  methodDescriptorGetDefaultLaborFactoringTypes = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/GetDefaultLaborFactoringTypes',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.GetDefaultLaborFactoringTypesRequest,
    defaultCloseout_pb.GetDefaultLaborFactoringTypesResponse,
    (request: defaultCloseout_pb.GetDefaultLaborFactoringTypesRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.GetDefaultLaborFactoringTypesResponse.deserializeBinary
  );

  getDefaultLaborFactoringTypes(
    request: defaultCloseout_pb.GetDefaultLaborFactoringTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.GetDefaultLaborFactoringTypesResponse>;

  getDefaultLaborFactoringTypes(
    request: defaultCloseout_pb.GetDefaultLaborFactoringTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultLaborFactoringTypesResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.GetDefaultLaborFactoringTypesResponse>;

  getDefaultLaborFactoringTypes(
    request: defaultCloseout_pb.GetDefaultLaborFactoringTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultLaborFactoringTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/GetDefaultLaborFactoringTypes',
        request,
        metadata || {},
        this.methodDescriptorGetDefaultLaborFactoringTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/GetDefaultLaborFactoringTypes',
    request,
    metadata || {},
    this.methodDescriptorGetDefaultLaborFactoringTypes);
  }

  methodDescriptorDeleteDefaultLaborFactoringType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/DeleteDefaultLaborFactoringType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.DeleteDefaultLaborFactoringTypeRequest,
    defaultCloseout_pb.DeleteDefaultLaborFactoringTypeResponse,
    (request: defaultCloseout_pb.DeleteDefaultLaborFactoringTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.DeleteDefaultLaborFactoringTypeResponse.deserializeBinary
  );

  deleteDefaultLaborFactoringType(
    request: defaultCloseout_pb.DeleteDefaultLaborFactoringTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.DeleteDefaultLaborFactoringTypeResponse>;

  deleteDefaultLaborFactoringType(
    request: defaultCloseout_pb.DeleteDefaultLaborFactoringTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultLaborFactoringTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.DeleteDefaultLaborFactoringTypeResponse>;

  deleteDefaultLaborFactoringType(
    request: defaultCloseout_pb.DeleteDefaultLaborFactoringTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultLaborFactoringTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/DeleteDefaultLaborFactoringType',
        request,
        metadata || {},
        this.methodDescriptorDeleteDefaultLaborFactoringType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/DeleteDefaultLaborFactoringType',
    request,
    metadata || {},
    this.methodDescriptorDeleteDefaultLaborFactoringType);
  }

  methodDescriptorCreateOrUpdateDefaultIndirectLaborType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/CreateOrUpdateDefaultIndirectLaborType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeRequest,
    defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeResponse,
    (request: defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeResponse.deserializeBinary
  );

  createOrUpdateDefaultIndirectLaborType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeResponse>;

  createOrUpdateDefaultIndirectLaborType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeResponse>;

  createOrUpdateDefaultIndirectLaborType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultIndirectLaborTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/CreateOrUpdateDefaultIndirectLaborType',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateDefaultIndirectLaborType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/CreateOrUpdateDefaultIndirectLaborType',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateDefaultIndirectLaborType);
  }

  methodDescriptorGetDefaultIndirectLaborTypes = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/GetDefaultIndirectLaborTypes',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.GetDefaultIndirectLaborTypesRequest,
    defaultCloseout_pb.GetDefaultIndirectLaborTypesResponse,
    (request: defaultCloseout_pb.GetDefaultIndirectLaborTypesRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.GetDefaultIndirectLaborTypesResponse.deserializeBinary
  );

  getDefaultIndirectLaborTypes(
    request: defaultCloseout_pb.GetDefaultIndirectLaborTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.GetDefaultIndirectLaborTypesResponse>;

  getDefaultIndirectLaborTypes(
    request: defaultCloseout_pb.GetDefaultIndirectLaborTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultIndirectLaborTypesResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.GetDefaultIndirectLaborTypesResponse>;

  getDefaultIndirectLaborTypes(
    request: defaultCloseout_pb.GetDefaultIndirectLaborTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultIndirectLaborTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/GetDefaultIndirectLaborTypes',
        request,
        metadata || {},
        this.methodDescriptorGetDefaultIndirectLaborTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/GetDefaultIndirectLaborTypes',
    request,
    metadata || {},
    this.methodDescriptorGetDefaultIndirectLaborTypes);
  }

  methodDescriptorDeleteDefaultIndirectLaborType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/DeleteDefaultIndirectLaborType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.DeleteDefaultIndirectLaborTypeRequest,
    defaultCloseout_pb.DeleteDefaultIndirectLaborTypeResponse,
    (request: defaultCloseout_pb.DeleteDefaultIndirectLaborTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.DeleteDefaultIndirectLaborTypeResponse.deserializeBinary
  );

  deleteDefaultIndirectLaborType(
    request: defaultCloseout_pb.DeleteDefaultIndirectLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.DeleteDefaultIndirectLaborTypeResponse>;

  deleteDefaultIndirectLaborType(
    request: defaultCloseout_pb.DeleteDefaultIndirectLaborTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultIndirectLaborTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.DeleteDefaultIndirectLaborTypeResponse>;

  deleteDefaultIndirectLaborType(
    request: defaultCloseout_pb.DeleteDefaultIndirectLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultIndirectLaborTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/DeleteDefaultIndirectLaborType',
        request,
        metadata || {},
        this.methodDescriptorDeleteDefaultIndirectLaborType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/DeleteDefaultIndirectLaborType',
    request,
    metadata || {},
    this.methodDescriptorDeleteDefaultIndirectLaborType);
  }

  methodDescriptorCreateOrUpdateDefaultIncidentalLaborType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/CreateOrUpdateDefaultIncidentalLaborType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeRequest,
    defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeResponse,
    (request: defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeResponse.deserializeBinary
  );

  createOrUpdateDefaultIncidentalLaborType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeResponse>;

  createOrUpdateDefaultIncidentalLaborType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeResponse>;

  createOrUpdateDefaultIncidentalLaborType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultIncidentalLaborTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/CreateOrUpdateDefaultIncidentalLaborType',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateDefaultIncidentalLaborType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/CreateOrUpdateDefaultIncidentalLaborType',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateDefaultIncidentalLaborType);
  }

  methodDescriptorGetDefaultIncidentalLaborTypes = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/GetDefaultIncidentalLaborTypes',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.GetDefaultIncidentalLaborTypesRequest,
    defaultCloseout_pb.GetDefaultIncidentalLaborTypesResponse,
    (request: defaultCloseout_pb.GetDefaultIncidentalLaborTypesRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.GetDefaultIncidentalLaborTypesResponse.deserializeBinary
  );

  getDefaultIncidentalLaborTypes(
    request: defaultCloseout_pb.GetDefaultIncidentalLaborTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.GetDefaultIncidentalLaborTypesResponse>;

  getDefaultIncidentalLaborTypes(
    request: defaultCloseout_pb.GetDefaultIncidentalLaborTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultIncidentalLaborTypesResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.GetDefaultIncidentalLaborTypesResponse>;

  getDefaultIncidentalLaborTypes(
    request: defaultCloseout_pb.GetDefaultIncidentalLaborTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultIncidentalLaborTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/GetDefaultIncidentalLaborTypes',
        request,
        metadata || {},
        this.methodDescriptorGetDefaultIncidentalLaborTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/GetDefaultIncidentalLaborTypes',
    request,
    metadata || {},
    this.methodDescriptorGetDefaultIncidentalLaborTypes);
  }

  methodDescriptorDeleteDefaultIncidentalLaborType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/DeleteDefaultIncidentalLaborType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeRequest,
    defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeResponse,
    (request: defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeResponse.deserializeBinary
  );

  deleteDefaultIncidentalLaborType(
    request: defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeResponse>;

  deleteDefaultIncidentalLaborType(
    request: defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeResponse>;

  deleteDefaultIncidentalLaborType(
    request: defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultIncidentalLaborTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/DeleteDefaultIncidentalLaborType',
        request,
        metadata || {},
        this.methodDescriptorDeleteDefaultIncidentalLaborType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/DeleteDefaultIncidentalLaborType',
    request,
    metadata || {},
    this.methodDescriptorDeleteDefaultIncidentalLaborType);
  }

  methodDescriptorCreateOrUpdateDefaultEquipmentExpenseType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/CreateOrUpdateDefaultEquipmentExpenseType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeRequest,
    defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeResponse,
    (request: defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.deserializeBinary
  );

  createOrUpdateDefaultEquipmentExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeResponse>;

  createOrUpdateDefaultEquipmentExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeResponse>;

  createOrUpdateDefaultEquipmentExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultEquipmentExpenseTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/CreateOrUpdateDefaultEquipmentExpenseType',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateDefaultEquipmentExpenseType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/CreateOrUpdateDefaultEquipmentExpenseType',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateDefaultEquipmentExpenseType);
  }

  methodDescriptorGetDefaultEquipmentExpenseTypes = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/GetDefaultEquipmentExpenseTypes',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.GetDefaultEquipmentExpenseTypesRequest,
    defaultCloseout_pb.GetDefaultEquipmentExpenseTypesResponse,
    (request: defaultCloseout_pb.GetDefaultEquipmentExpenseTypesRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.GetDefaultEquipmentExpenseTypesResponse.deserializeBinary
  );

  getDefaultEquipmentExpenseTypes(
    request: defaultCloseout_pb.GetDefaultEquipmentExpenseTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.GetDefaultEquipmentExpenseTypesResponse>;

  getDefaultEquipmentExpenseTypes(
    request: defaultCloseout_pb.GetDefaultEquipmentExpenseTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultEquipmentExpenseTypesResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.GetDefaultEquipmentExpenseTypesResponse>;

  getDefaultEquipmentExpenseTypes(
    request: defaultCloseout_pb.GetDefaultEquipmentExpenseTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultEquipmentExpenseTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/GetDefaultEquipmentExpenseTypes',
        request,
        metadata || {},
        this.methodDescriptorGetDefaultEquipmentExpenseTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/GetDefaultEquipmentExpenseTypes',
    request,
    metadata || {},
    this.methodDescriptorGetDefaultEquipmentExpenseTypes);
  }

  methodDescriptorDeleteDefaultEquipmentExpenseType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/DeleteDefaultEquipmentExpenseType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeRequest,
    defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeResponse,
    (request: defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeResponse.deserializeBinary
  );

  deleteDefaultEquipmentExpenseType(
    request: defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeResponse>;

  deleteDefaultEquipmentExpenseType(
    request: defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeResponse>;

  deleteDefaultEquipmentExpenseType(
    request: defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultEquipmentExpenseTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/DeleteDefaultEquipmentExpenseType',
        request,
        metadata || {},
        this.methodDescriptorDeleteDefaultEquipmentExpenseType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/DeleteDefaultEquipmentExpenseType',
    request,
    metadata || {},
    this.methodDescriptorDeleteDefaultEquipmentExpenseType);
  }

  methodDescriptorCreateOrUpdateDefaultGeneralExpenseType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/CreateOrUpdateDefaultGeneralExpenseType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeRequest,
    defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeResponse,
    (request: defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeResponse.deserializeBinary
  );

  createOrUpdateDefaultGeneralExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeResponse>;

  createOrUpdateDefaultGeneralExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeResponse>;

  createOrUpdateDefaultGeneralExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultGeneralExpenseTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/CreateOrUpdateDefaultGeneralExpenseType',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateDefaultGeneralExpenseType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/CreateOrUpdateDefaultGeneralExpenseType',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateDefaultGeneralExpenseType);
  }

  methodDescriptorGetDefaultGeneralExpenseTypes = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/GetDefaultGeneralExpenseTypes',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.GetDefaultGeneralExpenseTypesRequest,
    defaultCloseout_pb.GetDefaultGeneralExpenseTypesResponse,
    (request: defaultCloseout_pb.GetDefaultGeneralExpenseTypesRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.GetDefaultGeneralExpenseTypesResponse.deserializeBinary
  );

  getDefaultGeneralExpenseTypes(
    request: defaultCloseout_pb.GetDefaultGeneralExpenseTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.GetDefaultGeneralExpenseTypesResponse>;

  getDefaultGeneralExpenseTypes(
    request: defaultCloseout_pb.GetDefaultGeneralExpenseTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultGeneralExpenseTypesResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.GetDefaultGeneralExpenseTypesResponse>;

  getDefaultGeneralExpenseTypes(
    request: defaultCloseout_pb.GetDefaultGeneralExpenseTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultGeneralExpenseTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/GetDefaultGeneralExpenseTypes',
        request,
        metadata || {},
        this.methodDescriptorGetDefaultGeneralExpenseTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/GetDefaultGeneralExpenseTypes',
    request,
    metadata || {},
    this.methodDescriptorGetDefaultGeneralExpenseTypes);
  }

  methodDescriptorDeleteDefaultGeneralExpenseType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/DeleteDefaultGeneralExpenseType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeRequest,
    defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeResponse,
    (request: defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeResponse.deserializeBinary
  );

  deleteDefaultGeneralExpenseType(
    request: defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeResponse>;

  deleteDefaultGeneralExpenseType(
    request: defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeResponse>;

  deleteDefaultGeneralExpenseType(
    request: defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultGeneralExpenseTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/DeleteDefaultGeneralExpenseType',
        request,
        metadata || {},
        this.methodDescriptorDeleteDefaultGeneralExpenseType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/DeleteDefaultGeneralExpenseType',
    request,
    metadata || {},
    this.methodDescriptorDeleteDefaultGeneralExpenseType);
  }

  methodDescriptorCreateOrUpdateDefaultSubcontractExpenseType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/CreateOrUpdateDefaultSubcontractExpenseType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeRequest,
    defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeResponse,
    (request: defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.deserializeBinary
  );

  createOrUpdateDefaultSubcontractExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeResponse>;

  createOrUpdateDefaultSubcontractExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeResponse>;

  createOrUpdateDefaultSubcontractExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultSubcontractExpenseTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/CreateOrUpdateDefaultSubcontractExpenseType',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateDefaultSubcontractExpenseType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/CreateOrUpdateDefaultSubcontractExpenseType',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateDefaultSubcontractExpenseType);
  }

  methodDescriptorGetDefaultSubcontractExpenseTypes = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/GetDefaultSubcontractExpenseTypes',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.GetDefaultSubcontractExpenseTypesRequest,
    defaultCloseout_pb.GetDefaultSubcontractExpenseTypesResponse,
    (request: defaultCloseout_pb.GetDefaultSubcontractExpenseTypesRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.GetDefaultSubcontractExpenseTypesResponse.deserializeBinary
  );

  getDefaultSubcontractExpenseTypes(
    request: defaultCloseout_pb.GetDefaultSubcontractExpenseTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.GetDefaultSubcontractExpenseTypesResponse>;

  getDefaultSubcontractExpenseTypes(
    request: defaultCloseout_pb.GetDefaultSubcontractExpenseTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultSubcontractExpenseTypesResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.GetDefaultSubcontractExpenseTypesResponse>;

  getDefaultSubcontractExpenseTypes(
    request: defaultCloseout_pb.GetDefaultSubcontractExpenseTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultSubcontractExpenseTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/GetDefaultSubcontractExpenseTypes',
        request,
        metadata || {},
        this.methodDescriptorGetDefaultSubcontractExpenseTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/GetDefaultSubcontractExpenseTypes',
    request,
    metadata || {},
    this.methodDescriptorGetDefaultSubcontractExpenseTypes);
  }

  methodDescriptorDeleteDefaultSubcontractExpenseType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/DeleteDefaultSubcontractExpenseType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeRequest,
    defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeResponse,
    (request: defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeResponse.deserializeBinary
  );

  deleteDefaultSubcontractExpenseType(
    request: defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeResponse>;

  deleteDefaultSubcontractExpenseType(
    request: defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeResponse>;

  deleteDefaultSubcontractExpenseType(
    request: defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultSubcontractExpenseTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/DeleteDefaultSubcontractExpenseType',
        request,
        metadata || {},
        this.methodDescriptorDeleteDefaultSubcontractExpenseType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/DeleteDefaultSubcontractExpenseType',
    request,
    metadata || {},
    this.methodDescriptorDeleteDefaultSubcontractExpenseType);
  }

  methodDescriptorCreateOrUpdateDefaultQuoteExpenseType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/CreateOrUpdateDefaultQuoteExpenseType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeRequest,
    defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeResponse,
    (request: defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeResponse.deserializeBinary
  );

  createOrUpdateDefaultQuoteExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeResponse>;

  createOrUpdateDefaultQuoteExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeResponse>;

  createOrUpdateDefaultQuoteExpenseType(
    request: defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.CreateOrUpdateDefaultQuoteExpenseTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/CreateOrUpdateDefaultQuoteExpenseType',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateDefaultQuoteExpenseType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/CreateOrUpdateDefaultQuoteExpenseType',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateDefaultQuoteExpenseType);
  }

  methodDescriptorGetDefaultQuoteExpenseTypes = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/GetDefaultQuoteExpenseTypes',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.GetDefaultQuoteExpenseTypesRequest,
    defaultCloseout_pb.GetDefaultQuoteExpenseTypesResponse,
    (request: defaultCloseout_pb.GetDefaultQuoteExpenseTypesRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.GetDefaultQuoteExpenseTypesResponse.deserializeBinary
  );

  getDefaultQuoteExpenseTypes(
    request: defaultCloseout_pb.GetDefaultQuoteExpenseTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.GetDefaultQuoteExpenseTypesResponse>;

  getDefaultQuoteExpenseTypes(
    request: defaultCloseout_pb.GetDefaultQuoteExpenseTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultQuoteExpenseTypesResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.GetDefaultQuoteExpenseTypesResponse>;

  getDefaultQuoteExpenseTypes(
    request: defaultCloseout_pb.GetDefaultQuoteExpenseTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.GetDefaultQuoteExpenseTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/GetDefaultQuoteExpenseTypes',
        request,
        metadata || {},
        this.methodDescriptorGetDefaultQuoteExpenseTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/GetDefaultQuoteExpenseTypes',
    request,
    metadata || {},
    this.methodDescriptorGetDefaultQuoteExpenseTypes);
  }

  methodDescriptorDeleteDefaultQuoteExpenseType = new grpcWeb.MethodDescriptor(
    '/DefaultCloseoutService/DeleteDefaultQuoteExpenseType',
    grpcWeb.MethodType.UNARY,
    defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeRequest,
    defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeResponse,
    (request: defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeRequest) => {
      return request.serializeBinary();
    },
    defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeResponse.deserializeBinary
  );

  deleteDefaultQuoteExpenseType(
    request: defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeResponse>;

  deleteDefaultQuoteExpenseType(
    request: defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeResponse) => void): grpcWeb.ClientReadableStream<defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeResponse>;

  deleteDefaultQuoteExpenseType(
    request: defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: defaultCloseout_pb.DeleteDefaultQuoteExpenseTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/DefaultCloseoutService/DeleteDefaultQuoteExpenseType',
        request,
        metadata || {},
        this.methodDescriptorDeleteDefaultQuoteExpenseType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/DefaultCloseoutService/DeleteDefaultQuoteExpenseType',
    request,
    metadata || {},
    this.methodDescriptorDeleteDefaultQuoteExpenseType);
  }

}

