import {
  CreateEstimateCloseoutRequest,
  CreateEstimateCloseoutResponse,
  CreateOrUpdateEstimateCloseoutRequest,
  CreateOrUpdateEstimateCloseoutResponse,
  GetEstimateCloseoutRequest,
  GetEstimateCloseoutResponse,
  GetEstimateCloseoutsRequest,
  GetEstimateCloseoutsResponse,
  UpdateEstimateCloseoutRequest,
  UpdateEstimateCloseoutResponse,
  DeleteEstimateCloseoutRequest,
  DeleteEstimateCloseoutResponse,
  ExtensionType,
  DirectLaborType,
  EquipmentType,
  GeneralExpenseType,
  SubcontractType,
  QuoteType,
  LaborFactoringType,
  IncidentalLaborType,
  IndirectLaborType,
  TotalPricingType,
  TotalRowPricingData,
  TotalFinalRowData,
  TotalFinalPricingData,
  QuoteItem,
} from "./protosCompiled/estimateCloseout/estimateCloseout_pb";
import { clients } from "../clients/grpcClients";
import {
  GetChangeOrderCloseoutResponse,
  GetChangeOrderCloseoutRequest,
  GetChangeOrderCloseoutsResponse,
  GetChangeOrderCloseoutsRequest,
  UpdateChangeOrderCloseoutRequest,
  UpdateChangeOrderCloseoutResponse,
  DeleteChangeOrderCloseoutResponse,
  DeleteChangeOrderCloseoutRequest,
  CreateChangeOrderCloseoutRequest,
  CreateChangeOrderCloseoutResponse,
  CreateOrUpdateChangeOrderCloseoutRequest,
  CreateOrUpdateChangeOrderCloseoutResponse,
} from "./protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";
import { useUnityBuildStore } from "../states/store";

const estClient = clients.estimateCloseoutServiceClient;
const coClient = clients.changeOrderCloseoutServiceClient;

export class CloseoutService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static mapToExtensionType(
    data: ExtensionType.AsObject[]
  ): ExtensionType[] {
    return data.map((item) => {
      const extension = new ExtensionType();
      extension.setName(item.name);
      extension.setItemqty(item.itemqty);
      extension.setItemunitcost(item.itemunitcost);
      extension.setOverrideunitcost(item.overrideunitcost);
      extension.setHourlyproductionrate(item.hourlyproductionrate);
      extension.setOverrideproductionrate(item.overrideproductionrate);
      extension.setTotalcost(item.totalcost);
      extension.setTotallaborhours(item.totallaborhours);
      extension.setItemuom(item.itemuom);
      extension.setItemuomvalue(item.itemuomvalue);
      extension.setIsquoted(item.isquoted);
      extension.setQuotegroup(item.quotegroup || "Default");
      return extension;
    });
  }

  public static mapToDirectLaborType(
    data: DirectLaborType.AsObject[]
  ): DirectLaborType[] {
    return data.map((item) => {
      const labor = new DirectLaborType();
      labor.setName(item.name);
      labor.setAllocatedhours(item.allocatedhours);
      labor.setLaborrate(item.laborrate);
      labor.setBurdenpercent(item.burdenpercent);
      labor.setFringe(item.fringe);
      labor.setDistributionpercent(item.distributionpercent);
      labor.setTotalcost(item.totalcost);
      return labor;
    });
  }

  public static mapToIncidentalLaborType(
    data: IncidentalLaborType.AsObject[]
  ): IncidentalLaborType[] {
    return data.map((item) => {
      const labor = new IncidentalLaborType();
      labor.setName(item.name);
      labor.setAllocatedhours(item.allocatedhours);
      labor.setLaborrate(item.laborrate);
      labor.setBurdenpercent(item.burdenpercent);
      labor.setFringe(item.fringe);
      labor.setTotalcost(item.totalcost);
      return labor;
    });
  }

  public static mapToLaborFactoringType(
    data: LaborFactoringType.AsObject[]
  ): LaborFactoringType[] {
    return data.map((item) => {
      const labor = new LaborFactoringType();
      labor.setName(item.name);
      labor.setAllocatedhours(item.allocatedhours);
      labor.setLaborrate(item.laborrate);
      labor.setBurdenpercent(item.burdenpercent);
      labor.setFringe(item.fringe);
      labor.setImpactpercent(item.impactpercent);
      labor.setLaborpercent(item.laborpercent);
      labor.setDistributionpercent(item.distributionpercent);
      labor.setTotalcost(item.totalcost);
      return labor;
    });
  }

  public static mapToIndirectLaborType(
    data: IndirectLaborType.AsObject[]
  ): IndirectLaborType[] {
    return data.map((item) => {
      const labor = new IndirectLaborType();
      labor.setName(item.name);
      labor.setAllocatedhours(item.allocatedhours);
      labor.setLaborrate(item.laborrate);
      labor.setBurdenpercent(item.burdenpercent);
      labor.setFringe(item.fringe);
      labor.setDistributionpercent(item.distributionpercent);
      labor.setTotalcost(item.totalcost);
      return labor;
    });
  }

  public static mapToEquipmentType(
    data: EquipmentType.AsObject[]
  ): EquipmentType[] {
    return data.map((item) => {
      const equipment = new EquipmentType();
      equipment.setName(item.name);
      equipment.setQuantity(item.quantity);
      equipment.setDuration(item.duration);
      equipment.setUnitcost(item.unitcost);
      equipment.setTotalcost(item.totalcost);
      return equipment;
    });
  }

  public static mapToGeneralExpenseType(
    data: GeneralExpenseType.AsObject[]
  ): GeneralExpenseType[] {
    return data.map((item) => {
      const generalExpense = new GeneralExpenseType();
      generalExpense.setName(item.name);
      generalExpense.setQuantity(item.quantity);
      generalExpense.setDuration(item.duration);
      generalExpense.setDurationmultiplier(item.durationmultiplier);
      generalExpense.setDurationmultipliername(item.durationmultipliername);
      generalExpense.setUnitcost(item.unitcost);
      generalExpense.setTotalcost(item.totalcost);
      return generalExpense;
    });
  }

  public static mapToSubcontractType(
    data: SubcontractType.AsObject[]
  ): SubcontractType[] {
    return data.map((item) => {
      const subcontract = new SubcontractType();
      subcontract.setName(item.name);
      subcontract.setQuotedcost(item.quotedcost);
      subcontract.setAdjustedpercent(item.adjustedpercent);
      subcontract.setTotalcost(item.totalcost);
      return subcontract;
    });
  }

  public static mapToQuoteType(data: QuoteType.AsObject[]): QuoteType[] {
    return data.map((item) => {
      const quoteItems = item.quoteitemsList.map((quoteItem) => {
        const quoteItemType = new QuoteItem();
        quoteItemType.setItemname(quoteItem.itemname);
        quoteItemType.setItemqty(quoteItem.itemqty);
        quoteItemType.setItemcost(quoteItem.itemcost);
        return quoteItemType;
      });

      const quote = new QuoteType();
      quote.setQuotegroup(item.quotegroup);
      quote.setQuotedcost(item.quotedcost);
      quote.setAdjustedpercent(item.adjustedpercent);
      quote.setTotalcost(item.totalcost);
      quote.setIsdefaulttype(item.isdefaulttype);
      quote.setQuoteitemsList(quoteItems);
      return quote;
    });
  }

  private static mapToProtoTotalPricingType(
    items: TotalPricingType.AsObject[]
  ): TotalPricingType[] {
    return items.map((item) => {
      const protoItem = new TotalPricingType();
      protoItem.setName(item.name);

      const rowData = new TotalRowPricingData();
      rowData.setExtendedcost(item.rowdata?.extendedcost || 0);
      rowData.setAdjustedpercent(item.rowdata?.adjustedpercent || 0);
      rowData.setAdjustedcost(item.rowdata?.adjustedcost || 0);
      rowData.setOverheadpercent(item.rowdata?.overheadpercent || 0);
      rowData.setTotaloverhead(item.rowdata?.totaloverhead || 0);
      rowData.setMarkuppercent(item.rowdata?.markuppercent || 0);
      rowData.setTotalmarkup(item.rowdata?.totalmarkup || 0);
      rowData.setTotalcost(item.rowdata?.totalcost || 0);

      protoItem.setRowdata(rowData);
      return protoItem;
    });
  }

  public static mapToTotalFinalRowData(data: any): TotalFinalRowData {
    const totalFinalRowData = new TotalFinalRowData();
    const totalFinalData = new TotalFinalPricingData();

    if (data.totalfinaldata) {
      totalFinalData.setTotalcost(data.totalfinaldata.totalcost || 0);
      totalFinalData.setTotaloverhead(data.totalfinaldata.totaloverhead || 0);
      totalFinalData.setTotalmarkup(data.totalfinaldata.totalmarkup || 0);
      totalFinalData.setTotalfinalprice(
        data.totalfinaldata.totalfinalprice || 0
      );
    }

    totalFinalRowData.setTotalfinaldata(totalFinalData);
    totalFinalRowData.setRowtypesList(
      this.mapToProtoTotalPricingType(data.rowtypesList || [])
    );
    return totalFinalRowData;
  }

  public static createEstimateCloseout(
    data: CreateEstimateCloseoutRequest.AsObject
  ): Promise<CreateEstimateCloseoutResponse.AsObject> {
    if (!data.estimateid) throw new Error("estimateId is required");
    const request = new CreateEstimateCloseoutRequest();
    request.setEstimateid(data.estimateid);
    request.setExtensionList(this.mapToExtensionType(data.extensionList));
    request.setDirectlaborList(this.mapToDirectLaborType(data.directlaborList));
    request.setLaborfactoringList(
      this.mapToLaborFactoringType(data.laborfactoringList)
    );
    request.setIncidentallaborList(
      this.mapToIncidentalLaborType(data.incidentallaborList)
    );
    request.setIndirectlaborList(
      this.mapToIndirectLaborType(data.indirectlaborList)
    );
    request.setEquipmentexpenseList(
      this.mapToEquipmentType(data.equipmentexpenseList)
    );
    request.setGeneralexpenseList(
      this.mapToGeneralExpenseType(data.generalexpenseList)
    );
    request.setSubcontractexpenseList(
      this.mapToSubcontractType(data.subcontractexpenseList)
    );
    request.setQuoteexpenseList(this.mapToQuoteType(data.quoteexpenseList));
    request.setTotalfinalprice(
      this.mapToTotalFinalRowData(data.totalfinalprice)
    );

    return new Promise((resolve, reject) => {
      estClient.createEstimateCloseout(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static createOrUpdateEstimateCloseout(
    data: CreateOrUpdateEstimateCloseoutRequest.AsObject
  ): Promise<CreateOrUpdateEstimateCloseoutResponse.AsObject> {
    if (!data.estimateid) throw new Error("estimateId is required");
    const request = new CreateOrUpdateEstimateCloseoutRequest();
    request.setEstimateid(data.estimateid);
    request.setExtensionList(this.mapToExtensionType(data.extensionList));
    request.setDirectlaborList(this.mapToDirectLaborType(data.directlaborList));
    request.setLaborfactoringList(
      this.mapToLaborFactoringType(data.laborfactoringList)
    );
    request.setIncidentallaborList(
      this.mapToIncidentalLaborType(data.incidentallaborList)
    );
    request.setIndirectlaborList(
      this.mapToIndirectLaborType(data.indirectlaborList)
    );
    request.setEquipmentexpenseList(
      this.mapToEquipmentType(data.equipmentexpenseList)
    );
    request.setGeneralexpenseList(
      this.mapToGeneralExpenseType(data.generalexpenseList)
    );
    request.setSubcontractexpenseList(
      this.mapToSubcontractType(data.subcontractexpenseList)
    );
    request.setQuoteexpenseList(this.mapToQuoteType(data.quoteexpenseList));
    request.setTotalfinalprice(
      this.mapToTotalFinalRowData(data.totalfinalprice)
    );
    return new Promise((resolve, reject) => {
      estClient.createOrUpdateEstimateCloseout(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static getEstimateCloseout(
    estimateId: string
  ): Promise<GetEstimateCloseoutResponse.AsObject> {
    const request = new GetEstimateCloseoutRequest();
    request.setEstimateid(estimateId);

    return new Promise((resolve, reject) => {
      estClient.getEstimateCloseout(request, {}, (err: any, response: any) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static getEstimateCloseouts(
    pageNumber: number,
    pageSize: number
  ): Promise<GetEstimateCloseoutsResponse.AsObject> {
    const request = new GetEstimateCloseoutsRequest();
    request.setPagenumber(pageNumber);
    request.setPagesize(pageSize);

    return new Promise((resolve, reject) => {
      estClient.getEstimateCloseouts(request, {}, (err: any, response: any) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static updateEstimateCloseout(
    data: UpdateEstimateCloseoutRequest.AsObject
  ): Promise<UpdateEstimateCloseoutResponse.AsObject> {
    if (!data.estimateid) throw new Error("estimateId is required");
    const request = new UpdateEstimateCloseoutRequest();
    request.setEstimateid(data.estimateid);
    request.setExtensionList(this.mapToExtensionType(data.extensionList));
    request.setDirectlaborList(this.mapToDirectLaborType(data.directlaborList));
    request.setLaborfactoringList(
      this.mapToLaborFactoringType(data.laborfactoringList)
    );
    request.setIncidentallaborList(
      this.mapToIncidentalLaborType(data.incidentallaborList)
    );
    request.setIndirectlaborList(
      this.mapToIndirectLaborType(data.indirectlaborList)
    );
    request.setEquipmentexpenseList(
      this.mapToEquipmentType(data.equipmentexpenseList)
    );
    request.setGeneralexpenseList(
      this.mapToGeneralExpenseType(data.generalexpenseList)
    );
    request.setSubcontractexpenseList(
      this.mapToSubcontractType(data.subcontractexpenseList)
    );
    request.setQuoteexpenseList(this.mapToQuoteType(data.quoteexpenseList));
    request.setTotalfinalprice(
      this.mapToTotalFinalRowData(data.totalfinalprice)
    );

    return new Promise((resolve, reject) => {
      estClient.updateEstimateCloseout(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static deleteEstimateCloseout(
    estimateId: string
  ): Promise<DeleteEstimateCloseoutResponse.AsObject> {
    const request = new DeleteEstimateCloseoutRequest();
    request.setEstimateid(estimateId);

    return new Promise((resolve, reject) => {
      estClient.deleteEstimateCloseout(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static createChangeOrderCloseout(
    data: CreateChangeOrderCloseoutRequest.AsObject
  ): Promise<CreateChangeOrderCloseoutResponse.AsObject> {
    if (!data.changeorderid) throw new Error("changeOrderId is required");
    const request = new CreateChangeOrderCloseoutRequest();
    request.setChangeorderid(data.changeorderid);
    request.setExtensionList(this.mapToExtensionType(data.extensionList));
    request.setDirectlaborList(this.mapToDirectLaborType(data.directlaborList));
    request.setIncidentallaborList(
      this.mapToIncidentalLaborType(data.incidentallaborList)
    );
    request.setLaborfactoringList(
      this.mapToLaborFactoringType(data.laborfactoringList)
    );
    request.setIndirectlaborList(
      this.mapToIndirectLaborType(data.indirectlaborList)
    );
    request.setEquipmentexpenseList(
      this.mapToEquipmentType(data.equipmentexpenseList)
    );
    request.setGeneralexpenseList(
      this.mapToGeneralExpenseType(data.generalexpenseList)
    );
    request.setSubcontractexpenseList(
      this.mapToSubcontractType(data.subcontractexpenseList)
    );
    request.setQuoteexpenseList(this.mapToQuoteType(data.quoteexpenseList));
    request.setTotalfinalprice(
      this.mapToTotalFinalRowData(data.totalfinalprice)
    );

    return new Promise((resolve, reject) => {
      coClient.createChangeOrderCloseout(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static createOrUpdateChangeOrderCloseout(
    data: CreateOrUpdateChangeOrderCloseoutRequest.AsObject
  ): Promise<CreateOrUpdateChangeOrderCloseoutResponse.AsObject> {
    if (!data.changeorderid) throw new Error("changeOrderId is required");
    const request = new CreateOrUpdateChangeOrderCloseoutRequest();
    request.setChangeorderid(data.changeorderid);
    request.setExtensionList(this.mapToExtensionType(data.extensionList));
    request.setDirectlaborList(this.mapToDirectLaborType(data.directlaborList));
    request.setIncidentallaborList(
      this.mapToIncidentalLaborType(data.incidentallaborList)
    );
    request.setLaborfactoringList(
      this.mapToLaborFactoringType(data.laborfactoringList)
    );
    request.setIndirectlaborList(
      this.mapToIndirectLaborType(data.indirectlaborList)
    );
    request.setEquipmentexpenseList(
      this.mapToEquipmentType(data.equipmentexpenseList)
    );
    request.setGeneralexpenseList(
      this.mapToGeneralExpenseType(data.generalexpenseList)
    );
    request.setSubcontractexpenseList(
      this.mapToSubcontractType(data.subcontractexpenseList)
    );
    request.setQuoteexpenseList(this.mapToQuoteType(data.quoteexpenseList));
    request.setTotalfinalprice(
      this.mapToTotalFinalRowData(data.totalfinalprice)
    );

    return new Promise((resolve, reject) => {
      coClient.createOrUpdateChangeOrderCloseout(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static getChangeOrderCloseout(
    changeOrderId: string
  ): Promise<GetChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const request = new GetChangeOrderCloseoutRequest();
      request.setChangeorderid(changeOrderId);

      coClient.getChangeOrderCloseout(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static getChangeOrderCloseouts(
    pageNumber: number,
    pageSize: number
  ): Promise<GetChangeOrderCloseoutsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const request = new GetChangeOrderCloseoutsRequest();
      request.setPagenumber(pageNumber);
      request.setPagesize(pageSize);

      coClient.getChangeOrderCloseouts(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static updateChangeOrderCloseout(
    data: UpdateChangeOrderCloseoutRequest.AsObject
  ): Promise<UpdateChangeOrderCloseoutResponse.AsObject> {
    if (!data.changeorderid) throw new Error("changeOrderId is required");
    const request = new UpdateChangeOrderCloseoutRequest();
    request.setChangeorderid(data.changeorderid);
    request.setExtensionList(this.mapToExtensionType(data.extensionList));
    request.setDirectlaborList(this.mapToDirectLaborType(data.directlaborList));
    request.setIncidentallaborList(
      this.mapToIncidentalLaborType(data.incidentallaborList)
    );
    request.setLaborfactoringList(
      this.mapToLaborFactoringType(data.laborfactoringList)
    );
    request.setIndirectlaborList(
      this.mapToIndirectLaborType(data.indirectlaborList)
    );
    request.setEquipmentexpenseList(
      this.mapToEquipmentType(data.equipmentexpenseList)
    );
    request.setGeneralexpenseList(
      this.mapToGeneralExpenseType(data.generalexpenseList)
    );
    request.setSubcontractexpenseList(
      this.mapToSubcontractType(data.subcontractexpenseList)
    );
    request.setQuoteexpenseList(this.mapToQuoteType(data.quoteexpenseList));
    request.setTotalfinalprice(
      this.mapToTotalFinalRowData(data.totalfinalprice)
    );

    return new Promise((resolve, reject) => {
      coClient.updateChangeOrderCloseout(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }

  public static deleteChangeOrderCloseout(
    changeOrderId: string
  ): Promise<DeleteChangeOrderCloseoutResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const request = new DeleteChangeOrderCloseoutRequest();
      request.setChangeorderid(changeOrderId);

      coClient.deleteChangeOrderCloseout(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  }
}
