import { useState } from "react";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { GeneralExpenseRow } from "./GeneralExpenseRow";
import { Calculations as c } from "../../utils/calculations";
import { GeneralExpenseType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { useGetDefaultGeneralExpenseTypes } from "../../hooks/useDefaultCloseoutHooks";

export const GeneralExpenseHeader = ({
  generalExpenses,
}: {
  generalExpenses: GeneralExpenseType.AsObject[];
}) => {
  const { setIncludedGenExpenseTypes, updateGenExpenseType } =
    useStore(useCloseoutStore);

  const { data: generalExpensesDefaults } = useGetDefaultGeneralExpenseTypes();

  const [selectedGenExpenseType, setSelectedGenExpenseType] =
    useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value as string;

    // Ensure generalExpensesDefaults is defined
    if (!generalExpensesDefaults) {
      console.warn("General expense defaults are undefined.");
      return;
    }

    const defaultGenExpenseType = generalExpensesDefaults.find(
      (genExpenseType) => genExpenseType.name === newValue
    );

    if (defaultGenExpenseType) {
      // Merge DefaultGeneralExpenseType and GeneralExpenseType
      const newGenExpenseType: GeneralExpenseType.AsObject = {
        name: defaultGenExpenseType.name,
        quantity: 0, // Default to 0 for new entries
        duration: 0, // Default to 0 for new entries
        durationmultiplier: 1, // Default to 1 for new entries
        durationmultipliername: "Multiplier", // Default name
        unitcost: defaultGenExpenseType.unitcost || 0,
        totalcost: c.calculateGenExpenseTotal({
          quantity: 0,
          duration: 0,
          durationmultiplier: 1,
          unitcost: defaultGenExpenseType.unitcost || 0,
        }),
      };

      // Prevent duplicate entries
      if (!generalExpenses.some((expense) => expense.name === newValue)) {
        const newIncludedGenExpenseTypes = [
          ...generalExpenses,
          newGenExpenseType,
        ];
        setIncludedGenExpenseTypes(newIncludedGenExpenseTypes);
        setSelectedGenExpenseType("");
      }
    }
  };

  const handleDelete = (laborName: string) => {
    const newIncludedGenExpenseTypes = generalExpenses.filter(
      (l) => l.name !== laborName
    );
    setIncludedGenExpenseTypes(newIncludedGenExpenseTypes);
  };

  const handleFieldUpdate = (field: string, value: any, index: number) => {
    const updatedGenExpense = {
      ...generalExpenses[index],
      [field]: value,
    };

    if (field === "quantity") {
      updatedGenExpense.quantity = value;
    } else if (field === "duration") {
      updatedGenExpense.duration = value;
    } else if (field === "unitcost") {
      updatedGenExpense.unitcost = value;
    }

    updateGenExpenseType(index, updatedGenExpense);
  };

  return (
    <>
      <select value={selectedGenExpenseType} onChange={handleChange}>
        <option value="" disabled selected hidden>
          Select general expense type
        </option>
        {generalExpensesDefaults &&
          generalExpensesDefaults
            .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
            .map((generalExpensesType, index) => (
              <option value={generalExpensesType.name} key={index}>
                {generalExpensesType.name}
              </option>
            ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th>Quantity Multiplier</th>
              <th>Unit Cost</th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {generalExpenses.map((generalExpense, index) => (
              <GeneralExpenseRow
                key={index}
                generalExpense={generalExpense}
                handleDelete={handleDelete}
                handleFieldUpdate={(field, value) =>
                  handleFieldUpdate(field, value, index)
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
