// transform state * TransformWrapper constants
export const INITIAL_SCALE = 0.5;
export const DEFAULT_POS_X = 500;
export const DEFAULT_POS_Y = 500;
export const MIN_SCALE = 0.25;
export const MAX_SCALE = 5;
export const INIT_POS = 0;
export const IMG_CAPTURE_SIZE = 150;
export const HIGH_RES_SCALE = 3;
export const GCS_BUCKET_NAME = "unity_build_dev_object_storage";
export const TRANSPARENT_COLOR = "rgba(201, 191, 191, 0.2)"; // Faint gray color for lines with `allowTrailingLine` = false
//TODO: We will need the user ot be able to calibrate the drawing to a custom scale
// if we have a value for availableObjects[0].userScale, we can use that to set the userScale
// otherwise, we can use the default value of undefined
// We need the user to always set a scale, so we can use that to set the default value
export const SCALE_OPTIONS = [
  { label: '1/32" = 1ft', value: 32 },
  { label: '1/16" = 1ft', value: 16 },
  { label: '1/8" = 1ft', value: 8 },
  { label: '1/4" = 1ft', value: 4 },
  { label: '1/2" = 1ft', value: 2 },
  { label: '3/16" = 1ft', value: 5.333 },
  { label: '3/32" = 1ft', value: 10.667 },
  { label: '3/64" = 1ft', value: 21.333 },
  { label: '3/8" = 1ft', value: 2.667 },
  { label: '3/4" = 1ft', value: 1.333 },
  { label: '1" = 1ft', value: 1 },
  { label: '1" = 10ft', value: 10 },
  { label: '1" = 20ft', value: 20 },
  { label: '1" = 30ft', value: 30 },
  { label: '1" = 40ft', value: 40 },
  { label: '1" = 50ft', value: 50 },
  { label: '1" = 60ft', value: 60 },
  { label: '1" = 80ft', value: 80 },
  { label: '1" = 100ft', value: 100 },
  { label: "No scale set", value: 0 },
  // { label: "Custom Scale", value: "Custom Scale" },
];

export const UOM = [
  { label: "EA", value: "EA" },
  { label: "C", value: "C" },
  { label: "M", value: "M" },
  { label: "IN", value: "IN" },
  { label: "LF", value: "LF" },
  { label: "CLF", value: "CLF" },
  { label: "MLF", value: "MLF" },
  { label: "BOX", value: "BOX" },
  { label: "SQIN", value: "SQIN" },
  { label: "SF", value: "SF" },
  { label: "CUIN", value: "CUIN" },
  { label: "GAL", value: "GAL" },
  { label: "LBS", value: "LBS" },
  { label: "OZ", value: "OZ" },
  { label: "DAY", value: "DAY" },
  { label: "MO", value: "MO" },
];

export const qtyOfItemHelper = `
  This is the quantity of the item that we want for every X quantity of the takeoff variable.<br/>
  <br/>
  For example:<br/>
  <br/>
  If we want 1 coupling for every 10' of length, we would enter 1 here, set "For every" to "10", and set the "Takeoff variable" to "length".<br/>
  <br/>
  Or if we want 2 connectors for every segment, we would enter 2 here, set "For every" to "1", and set the "Takeoff variable" to "segment".
`;

export const forEveryHelper = `
  This is the portion of the takeoff variable that we want to use to calculate the quantity of the item.<br/>
  <br/>
  For example:<br/>
  <br/>
  If we want 1 coupling for every 10' of length, we would enter 10 here, set "Qty of item" to "1", and set the "Takeoff variable" to "length".<br/>
  <br/>
  Or if we want 2 connectors for every segment, we would enter 1 here, set "Qty of item" to "2", and set the "Takeoff variable" to "segment".
`;

export const takeoffVariableHelper = `
  These choices represent variables that are associated with a measurement type annotation.<br/><br/>
  "Point" means, each circle, square, rectangle etc that is included in an annotation.<br/><br/>
  "Segment" means, the space between two points that is included in an annotation.<br/><br/>
  "Length" means, the distance between all points that are included in an annotation.<br/>
`;
