import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Container,
  Alert,
} from "@mui/material";
import { UB_AccountUser } from "../../api/protosCompiled/ub_users/ub_users_pb";
import { UbUserService } from "../../api/UbUserService";
import { useFetchRoles } from "../../hooks/useFetchRoles";

type CreateUserProps = {
  accountId: string;
  onNextStep: () => void;
};

export const CreateUser: React.FC<CreateUserProps> = ({
  accountId,
  onNextStep,
}) => {
  const { data: role, isLoading: isLoadingRoles } = useFetchRoles();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    return userName.trim() && email.trim() && password.trim();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      setSubmissionError(null);

      try {
        const userData: UB_AccountUser.AsObject = {
          user: {
            id: "", // Set by the database
            name: userName,
            email: email,
            userphotourl: "",
          },
          ubaccountid: accountId,
          roleid: role ? role[0].id : "user",
          rolename: "",
          isprimaryadmin: false,
          isloggedin: false,
          password: password,
          isactive: true,
        };

        const response = await UbUserService.createUser(userData);
        if (response.message === "User created successfully") {
          onNextStep(); // Proceed to the next step
        } else {
          setSubmissionError("Failed to create user. Please try again.");
        }
      } catch (error) {
        console.error("User creation error:", error);
        setSubmissionError("An error occurred while creating the user.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Container maxWidth="xs">
      {isLoadingRoles && <p>Loading roles...</p>}
      <Box sx={{ mt: 4, mb: 2, textAlign: "center" }}>
        <Typography variant="h5" component="h1">
          Create User
        </Typography>

        <Typography variant="h6" component="h2">
          This will be the primary user for your Unity-Build account. You will
          use this email and password to log in to your account.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="userName"
              label="User Name"
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="email"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          {submissionError && (
            <Grid item xs={12}>
              <Alert severity="error">{submissionError}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !validateForm()}
            >
              {isSubmitting ? "Creating User..." : "Create User"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
