export type DefaultCloseoutTypeKey =
  | "directlaborList"
  | "laborfactoringList"
  | "incidentallaborList"
  | "indirectlaborList"
  | "equipmentexpenseList"
  | "generalexpenseList"
  | "subcontractexpenseList"
  | "quoteexpenseList";

export const DEFAULT_CLOSEOUT_TYPE_OPTIONS: Array<{
  key: DefaultCloseoutTypeKey;
  label: string;
}> = [
  { key: "directlaborList", label: "Direct Labor" },
  { key: "laborfactoringList", label: "Labor Factoring" },
  { key: "incidentallaborList", label: "Incidental Labor" },
  { key: "indirectlaborList", label: "Indirect Labor" },
  { key: "equipmentexpenseList", label: "Equipment" },
  { key: "generalexpenseList", label: "General Expenses" },
  { key: "subcontractexpenseList", label: "Subcontracts" },
  { key: "quoteexpenseList", label: "Quotes" },
];

export type CloseoutProps = {
  isClearingFocus: boolean;
};
