import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalAssemblyService as gas } from "../api/GlobalAssemblyService";
import {
  GetAllGlobalAssembliesRequest,
  GlobalAssembly,
} from "../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { useUnityBuildStore } from "../states/store";

export function useGetGlobalAssemblies() {
  const { data, error, isLoading, refetch } = useQuery(
    "assemblies",
    async () => {
      const options: GetAllGlobalAssembliesRequest.AsObject = {
        pagenumber: 1,
        pagesize: 100,
        searchkeyword: "",
        sortby: "name",
        sortdescending: false,
        sessiontoken: useUnityBuildStore.getState().sessionToken,
      };
      const response = await gas.getAllGlobalAssemblies(options);
      return response.assembliesList;
    },
    {}
  );

  return { data, error, isLoading, refetch };
}

export function useFetchAssembliesByFileId(fileId: string) {
  const queryClient = useQueryClient();

  const fetchAssemblies = async () => {
    const res = await gas.getAssembliesByFileId(fileId);
    return res.assembliesList || [];
  };

  const { data, error, isLoading } = useQuery<GlobalAssembly.AsObject[], Error>(
    ["assembliesByFileId", fileId],
    fetchAssemblies,
    {
      enabled: !!fileId,
    }
  );

  const refetchAssemblies = () => {
    queryClient.invalidateQueries(["assembliesByFileId", fileId]);
  };

  // Return the query data, refetch function, and the fetching function to call elsewhere
  return { data, error, isLoading, refetchAssemblies, fetchAssemblies };
}

export function useCreateGlobalAssemblyMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: GlobalAssembly.AsObject) => {
      const response = await gas.createGlobalAssembly(data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assembliesByFileId"]);
        queryClient.invalidateQueries("assemblyFolders");
      },
      onError: (error) => {
        console.error("Error creating or updating assembly:", error);
      },
    }
  );
}

export function useUpdateGlobalAssemblyMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: GlobalAssembly.AsObject) => gas.updateGlobalAssembly(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assembliesByFileId"]);
        queryClient.invalidateQueries("assemblyFolders");
      },
      onError: (error) => {
        console.error("Error creating or updating assembly:", error);
      },
    }
  );
}

export function useDeleteGlobalAssemblyMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: GlobalAssembly.AsObject) => gas.deleteGlobalAssembly(data.folderid),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assembliesByFileId"]);
        queryClient.invalidateQueries("assemblyFolders");
      },
      onError: (error) => {
        console.error("Error creating or updating assembly:", error);
      },
    }
  );
}
