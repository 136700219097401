import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import { LookupUPCRequest } from "./protosCompiled/upcService/upcService_pb";

const client = clients.upcServiceClient;

export class UPCLookupService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static async lookupUPC(upc: string) {
    const metadata = UPCLookupService.getMetadata();
    const request = new LookupUPCRequest();
    request.setUpc(upc);
    request.setSessiontoken(metadata.sessionToken);
    const response = await client.lookupUPC(request);
    return response;
  }
}
