import { useUnityBuildStore } from "../states/store";
import { useCreateProjectEstimateAssemblyMutation } from "./useCreateProjectEstimateAssemblyMutation";
import { useUpdateProjectEstimateAssemblyMutation } from "./useUpdateProjectEstimateAssemblyMutation ";
import { useCreateContractChangeOrderAssemblyMutation } from "./useCreateContractChangeOrderAssemblyMutation";
import { useUpdateContractChangeOrderAssemblyMutation } from "./useUpdateContractChangeOrderAssemblyMutation ";
import { useQueryClient } from "react-query";
import {
  EstimateAssembly,
  EstimateBreakoutMap,
} from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import {
  ChangeOrderAssembly,
  ChangeOrderBreakoutMap,
} from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { Calculations } from "../utils/calculations";
import { useStore } from "zustand";
import {
  calculatePolygonArea,
  convertPointToRealWorld,
} from "../components/SVGelements/takeoffHelper";
import { calculateSegmentCount } from "../api/ProjectEstimateAssemblyService";
import { Breakout } from "../api/protosCompiled/breakout/breakout_pb";
import { Estimate } from "../api/protosCompiled/projectEstimate/projectEstimate_pb";
import { ChangeOrder } from "../api/protosCompiled/changeOrder/changeOrder_pb";

type BreakoutMap =
  | EstimateBreakoutMap.AsObject
  | ChangeOrderBreakoutMap.AsObject;

type ConverBreakoutProps = {
  isUpdatingBreakout: boolean;
  selectedBreakout: Breakout.AsObject | null;
  selectedEstimate: Estimate.AsObject | null;
  selectedChangeOrder: ChangeOrder.AsObject | null;
  assembly:
    | EstimateAssembly.AsObject
    | ChangeOrderAssembly.AsObject
    | undefined;
  estimateOrChangorderId: string;
};

export const convertSelectedBreakoutToMap = (
  data: ConverBreakoutProps
): BreakoutMap => {
  const existingBreakout =
    data.assembly &&
    Array.isArray(data.assembly.itemqtyformulasList) &&
    data.assembly.itemqtyformulasList.length > 0 &&
    data.assembly.itemqtyformulasList[0].breakoutsList &&
    Array.isArray(data.assembly.itemqtyformulasList[0].breakoutsList) &&
    data.assembly.itemqtyformulasList[0].breakoutsList.length > 0
      ? data.assembly.itemqtyformulasList[0].breakoutsList[0]
      : null;

  //If we are updating a breakout, we will update the ...BreakoutMap.AsObject with the values from the selectedBreakout Breakout.AsObject
  //Otherwise, we use the values from the existing ...BreakoutMap.AsObject
  const breakoutId: string = data.isUpdatingBreakout
    ? data.selectedBreakout?.id || ""
    : existingBreakout?.breakoutid || "";

  const breakoutName: string = data.isUpdatingBreakout
    ? data.selectedBreakout?.breakoutname ||
      existingBreakout?.breakoutname ||
      ""
    : "";

  const breakoutMultiplier: number = data.isUpdatingBreakout
    ? data.selectedBreakout?.multiplier || 1
    : existingBreakout?.multiplier || 1;

  return {
    breakoutmapid: existingBreakout?.breakoutmapid || "",
    takeoffid: data.assembly?.takeoffid || "",
    breakoutid: breakoutId,
    breakoutname: breakoutName,
    multiplier: breakoutMultiplier,
    createdbyuserid: "",
    createdbyaccountid: "",
    lastupdated: "",
    estimateid: data.estimateOrChangorderId,
  };
};

export const useSubmitTakeoffAssembly = () => {
  const queryClient = useQueryClient();
  const {
    selectedEstimate,
    selectedContract,
    selectedChangeOrder,
    selectedProject,
    currentPage,
    userScale,
    selectedBreakout,
    isUpdatingBreakout,
    setIsUpdatingBreakout,
  } = useStore(useUnityBuildStore);

  const { mutateAsync: createEstimateAssembly } =
    useCreateProjectEstimateAssemblyMutation();
  const { mutateAsync: updateEstimateAssembly } =
    useUpdateProjectEstimateAssemblyMutation();
  const { mutateAsync: createChangeOrderAssembly } =
    useCreateContractChangeOrderAssemblyMutation();
  const { mutateAsync: updateChangeOrderAssembly } =
    useUpdateContractChangeOrderAssemblyMutation();

  const submitAssembly = async ({
    assembly = {} as EstimateAssembly.AsObject | ChangeOrderAssembly.AsObject,
    isCreating = false,
    isUpdating = false,
  }) => {
    if (isCreating === isUpdating) {
      console.error(
        `isCreating is ${isCreating} and isUpdating is ${isUpdating}, these two values cannot be the same`
      );
      return;
    } else if (!isCreating && !isUpdating) {
      console.error(
        `isCreating is ${isCreating} and isUpdating is ${isUpdating}, one of these values must be true`
      );
      return;
    }

    // Update every formula to set its breakout list from the selected breakout
    const updatedFormulas = assembly.itemqtyformulasList
      ? assembly.itemqtyformulasList.map((formula) => ({
          ...formula,
          // Ensure we pass an array of breakouts rather than a single breakout object.
          breakoutsList: [
            convertSelectedBreakoutToMap({
              selectedBreakout: selectedBreakout,
              selectedEstimate: selectedEstimate,
              selectedChangeOrder: selectedChangeOrder,
              assembly: assembly,
              isUpdatingBreakout: isUpdatingBreakout,
              estimateOrChangorderId: selectedEstimate
                ? selectedEstimate.estimateid
                : selectedChangeOrder?.changeorderid,
            }),
          ],
        }))
      : [];

    // Prepare the assembly with the updated formulas
    const assemblyWithBreakouts = {
      ...assembly,
      itemqtyformulasList: updatedFormulas,
    };

    let calculatedTotalLength = 0;
    if (
      assemblyWithBreakouts.assemblymeasurementtype === "length" ||
      assemblyWithBreakouts.assemblymeasurementtype === "area"
    ) {
      calculatedTotalLength = Calculations.calculateTotalDistance(
        assemblyWithBreakouts.pointsList,
        userScale,
        assemblyWithBreakouts.assemblymeasurementtype
      );
    }

    let sqft = 0;

    if (assemblyWithBreakouts.assemblymeasurementtype === "area") {
      const realWorldPoints = assembly.pointsList.map((point) =>
        convertPointToRealWorld(point, userScale)
      );
      sqft = calculatePolygonArea(realWorldPoints);
    }

    // Calculate pointCount
    const pointCount = assemblyWithBreakouts.pointsList.filter(
      (point) => point.isincludedincount
    ).length;

    const segmentCount = calculateSegmentCount(assembly);
    // Prepare the optimistic update
    const optimisticAssembly:
      | EstimateAssembly.AsObject
      | ChangeOrderAssembly.AsObject = {
      ...assemblyWithBreakouts,
      segmentlength: calculatedTotalLength,
      sqft: sqft,
      userscale: userScale,
      pagenumber: currentPage,
      pointcount: pointCount,
      segmentcount: segmentCount,
    };

    // Begin optimistic update
    await queryClient.cancelQueries(["countedAssemblies"]);
    const previousAssemblies = queryClient.getQueryData(["countedAssemblies"]);

    queryClient.setQueryData(
      ["countedAssemblies"],
      (oldAssemblies: any[] | undefined) => {
        // Ensure oldAssemblies is not undefined
        const old = oldAssemblies || [];

        if (isCreating) {
          // For creation, add the optimisticAssembly to the array
          return [...old, optimisticAssembly];
        } else if (isUpdating) {
          // For updates, find the assembly by its ID and update it
          return old.map((assembly) =>
            assembly.id === optimisticAssembly.takeoffid
              ? optimisticAssembly
              : assembly
          );
        }
        return old; // Return the old state by default
      }
    );

    try {
      if (selectedEstimate.estimateid) {
        if (isCreating && "estimateid" in optimisticAssembly) {
          await createEstimateAssembly({
            ...optimisticAssembly,
            projectid: selectedProject.id,
            estimateid: selectedEstimate.estimateid,
          });
        } else if (isUpdating && "estimateid" in optimisticAssembly) {
          await updateEstimateAssembly({
            ...optimisticAssembly,
            projectid: selectedProject.id,
            estimateid: selectedEstimate.estimateid,
          });
        }
      } else if (selectedContract.contractid) {
        if (isCreating && "changeorderid" in optimisticAssembly) {
          await createChangeOrderAssembly({
            ...optimisticAssembly,
            changeorderid: selectedChangeOrder.changeorderid,
            contractid: selectedContract.contractid,
          });
        } else if (isUpdating && "changeorderid" in optimisticAssembly) {
          await updateChangeOrderAssembly({
            ...optimisticAssembly,
            changeorderid: selectedChangeOrder.changeorderid,
            contractid: selectedContract.contractid,
          });
        }
      }
    } catch (error) {
      queryClient.setQueryData(["countedAssemblies"], previousAssemblies);
      throw error;
    } finally {
      setIsUpdatingBreakout(false);
      queryClient.invalidateQueries(["countedAssemblies"]);
    }
  };

  return submitAssembly;
};
