import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  useFetchEstimateBreakouts,
  useFetchChangeOrderBreakouts,
  useCreateEstimateBreakoutMutation,
  useUpdateEstimateBreakoutMutation,
  useDeleteEstimateBreakoutMutation,
  useCreateChangeOrderBreakoutMutation,
  useDeleteChangeOrderBreakoutMutation,
  useUpdateChangeOrderBreakoutMutation,
} from "../../hooks/useFetchBreakout";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { Breakout } from "../../api/protosCompiled/breakout/breakout_pb";

export default function Breakouts() {
  const { selectedEstimate, selectedChangeOrder } =
    useStore(useUnityBuildStore);

  const estimateQuery = useFetchEstimateBreakouts(
    selectedEstimate?.estimateid ?? ""
  );
  const changeOrderQuery = useFetchChangeOrderBreakouts(
    selectedChangeOrder?.changeorderid ?? ""
  );

  const createEstimateBreakout = useCreateEstimateBreakoutMutation();
  const updateEstimateBreakout = useUpdateEstimateBreakoutMutation();
  const deleteEstimateBreakout = useDeleteEstimateBreakoutMutation();

  const createChangeOrderBreakout = useCreateChangeOrderBreakoutMutation();
  const updateChangeOrderBreakout = useUpdateChangeOrderBreakoutMutation();
  const deleteChangeOrderBreakout = useDeleteChangeOrderBreakoutMutation();

  const isLoading = selectedEstimate?.estimateid
    ? estimateQuery.isLoading
    : changeOrderQuery.isLoading;
  const isError = selectedEstimate?.estimateid
    ? estimateQuery.isError
    : changeOrderQuery.isError;
  const fetchedBreakouts = selectedEstimate?.estimateid
    ? estimateQuery.data?.breakoutsList ?? []
    : changeOrderQuery.data?.breakoutsList ?? [];

  // Use a small local state to manage newly added (unsaved) rows.
  const [newRows, setNewRows] = React.useState<Breakout.AsObject[]>([]);

  // Combine saved and new rows for display.
  const allRows = [...fetchedBreakouts, ...newRows];

  // Handler for when the user clicks the + Add Breakout button.
  // Instead of immediately calling the mutation, we add a new blank row.
  const handleAddNewRow = () => {
    const tempId = uuidv4();

    const newRow: Breakout.AsObject = {
      id: tempId,
      breakoutname: "",
      multiplier: 1,
      takeoffid:
        selectedEstimate?.estimateid ??
        selectedChangeOrder?.changeorderid ??
        "",
      createdbyuserid: "",
      createdbyaccountid: "",
      lastupdated: "",
    };
    setNewRows((prev) => [...prev, newRow]);
  };

  // Handle onBlur for both saved and new rows.
  const handleBlur = async (row: any, isNew: boolean, rowIndex: number) => {
    // Do nothing if the breakout name is still empty.
    if (!row.breakoutname || row.breakoutname.trim() === "") return;

    // For new rows, call create mutation; for existing rows, call update.
    try {
      if (isNew) {
        if (selectedEstimate?.estimateid) {
          await createEstimateBreakout.mutateAsync(row, {
            onSuccess: () => estimateQuery.refetch(),
          });
        } else {
          await createChangeOrderBreakout.mutateAsync(row, {
            onSuccess: () => changeOrderQuery.refetch(),
          });
        }
        // Remove the new row from local state once successfully created.
        setNewRows((prev) => prev.filter((_, idx) => idx !== rowIndex));
      } else {
        if (selectedEstimate?.estimateid) {
          await updateEstimateBreakout.mutateAsync({
            ...row,
            lastupdated: new Date().toISOString(),
          });
        } else {
          await updateChangeOrderBreakout.mutateAsync({
            ...row,
            lastupdated: new Date().toISOString(),
          });
        }
      }
    } catch (err) {
      console.error("Error on blur (create/update breakout):", err);
      // Optionally refetch data to revert optimistic updates.
      if (selectedEstimate?.estimateid) {
        estimateQuery.refetch();
      } else {
        changeOrderQuery.refetch();
      }
    }
  };

  const handleDelete = async (
    id: string,
    isNew: boolean,
    rowIndex?: number
  ) => {
    if (isNew) {
      // Remove from local new rows if not yet created
      setNewRows((prev) => prev.filter((row, idx) => idx !== rowIndex));
    } else {
      try {
        if (selectedEstimate?.estimateid) {
          await deleteEstimateBreakout.mutateAsync(id, {
            onSuccess: () => estimateQuery.refetch(),
          });
        } else {
          await deleteChangeOrderBreakout.mutateAsync(id, {
            onSuccess: () => changeOrderQuery.refetch(),
          });
        }
      } catch (err) {
        console.error("Error deleting breakout:", err);
      }
    }
  };

  if (isLoading) return <div>Loading Breakouts...</div>;
  if (isError) return <div>Error loading Breakouts.</div>;

  return (
    <div style={{ margin: "1rem" }}>
      <h2>
        {selectedEstimate?.estimateid
          ? "Estimate Breakouts"
          : "Change Order Breakouts"}
      </h2>

      <button onClick={handleAddNewRow} style={{ marginBottom: "0.5rem" }}>
        + Add Breakout
      </button>

      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #ccc",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid #ccc", padding: "0.5rem" }}>
              Breakout Name
            </th>
            <th style={{ border: "1px solid #ccc", padding: "0.5rem" }}>
              Multiplier
            </th>
            <th style={{ border: "1px solid #ccc", padding: "0.5rem" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {allRows.length > 0 ? (
            allRows.map((row: any, idx: number) => {
              // Determine if this row is new (exists in newRows) or saved (from the query).
              const isNew = newRows.some((newRow) => newRow.id === row.id);
              return (
                <tr key={row.id}>
                  {/* Breakout Name */}
                  <td style={{ border: "1px solid #ccc", padding: "0.5rem" }}>
                    <input
                      type="text"
                      defaultValue={row.breakoutname}
                      onBlur={(e) =>
                        handleBlur(
                          { ...row, breakoutname: e.target.value },
                          isNew,
                          isNew
                            ? newRows.findIndex((nr) => nr.id === row.id)
                            : idx
                        )
                      }
                      style={{ width: "95%" }}
                    />
                  </td>
                  {/* Multiplier */}
                  <td style={{ border: "1px solid #ccc", padding: "0.5rem" }}>
                    <input
                      type="number"
                      defaultValue={row.multiplier}
                      onBlur={(e) =>
                        handleBlur(
                          {
                            ...row,
                            multiplier: parseFloat(e.target.value) || 1,
                          },
                          isNew,
                          idx
                        )
                      }
                      style={{ width: "95%" }}
                    />
                  </td>
                  {/* Actions */}
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "0.5rem",
                      textAlign: "center",
                    }}
                  >
                    <button
                      onClick={() =>
                        handleDelete(
                          row.id,
                          isNew,
                          isNew
                            ? newRows.findIndex((nr) => nr.id === row.id)
                            : undefined
                        )
                      }
                      style={{ backgroundColor: "red", color: "white" }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={2} style={{ textAlign: "center", padding: "1rem" }}>
                No breakouts found. Click &ldquo;Add Breakout&rdquo; to create
                one.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
