import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetFoldersResponse } from "../../../api/protosCompiled/folder/folder_pb";
import { a11yProps, CustomTabPanel } from "../../../customTabs/CustomTabPanel";
import {
  useFetchItemFolders,
  useFetchAssemblyFolders,
} from "../../../hooks/useFetchFolders";
import { FolderNodes } from "../folderManager/FolderNodes";

export default function ItemTab() {
  //zustand states

  //react-query states
  const { data: itemFolderData } = useFetchItemFolders();
  const { data: assemblyFolderData } = useFetchAssemblyFolders();

  //local state
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Assemblies" {...a11yProps(0)} />
          <Tab label="Items" {...a11yProps(1)} />
        </Tabs>
      </div>
      <CustomTabPanel value={value} index={0}>
        {value === 0 && (
          <FolderNodes
            context="primary"
            folderType="assembly"
            folderData={assemblyFolderData as GetFoldersResponse.AsObject}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {value === 1 && (
          <FolderNodes
            context="primary"
            folderType="item"
            folderData={itemFolderData as GetFoldersResponse.AsObject}
          />
        )}
      </CustomTabPanel>
    </>
  );
}
