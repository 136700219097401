import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useStore } from "zustand";
import { useSubmitTakeoffAssembly } from "../../hooks/useSubmitTakeoffAssembly";
import { ResetStates } from "../../states/resetStates";
import { useUnityBuildStore } from "../../states/store";
import { useSetSelectedAssemblyFromAssemblyPicker } from "../../hooks/useSetSelectedAssembly";
import { useGlobalAssemblyStore } from "../../states/globalAssemblyStore";
import { keepBoxInView } from "../../utils/keepBoxInView";
import { EstimatePoint } from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderPoint } from "../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

/**
 * Combined type for convenience:
 * Represents a single point in either Estimate or ChangeOrder assemblies.
 */
export type Point = EstimatePoint.AsObject | ChangeOrderPoint.AsObject;

export const PointEditor = () => {
  const store = useStore(useUnityBuildStore);
  const globalAssemblyStore = useStore(useGlobalAssemblyStore);

  const {
    assemblyIndex,
    pointIndex,
    editingPointX,
    editingPointY,
    includeAllPoints,
    selectedTakeoffAssembly,
    setIsEditingPoint,
    setIsAnnotating,
    setIncludeAllPoints,
    isAnnotating,
    setIsInsertingPoint,
  } = useStore(useUnityBuildStore);

  const submitAssembly = useSubmitTakeoffAssembly();
  const handleSetSelectedAssembly = useSetSelectedAssemblyFromAssemblyPicker();

  const [verticalLength, setVerticalLength] = useState<number>(0);

  // Local checkbox states
  const [localIsIncludedInCount, setLocalIsIncludedInCount] = useState(false);
  const [localAllowTrailingLine, setLocalAllowTrailingLine] = useState(false);

  // If we have a valid pointIndex => "point editing"
  const isPointEditing = pointIndex !== null && pointIndex !== undefined;

  /**
   * On mount/update:
   *   Read the point's verticalLength, isIncludedInCount, and allowTrailingLine
   */
  useEffect(() => {
    if (
      selectedTakeoffAssembly &&
      selectedTakeoffAssembly.pointsList &&
      isPointEditing
    ) {
      const pt = selectedTakeoffAssembly.pointsList[pointIndex];
      setVerticalLength(pt?.verticallength ?? 0);
      setLocalIsIncludedInCount(pt?.isincludedincount ?? false);
      setLocalAllowTrailingLine(pt?.allowtrailingline ?? false);
    }
  }, [selectedTakeoffAssembly, pointIndex, isPointEditing]);

  // --------------------------
  //    Existing Functionality
  // --------------------------
  const handleAddToRun = (assembly: any) => {
    setIsEditingPoint({ isEditingPoint: false, isEditingStyle: false });
    setIsAnnotating(true);
    handleSetSelectedAssembly(assembly);
  };

  const handleCompleteRun = async () => {
    if (selectedTakeoffAssembly) {
      try {
        if (selectedTakeoffAssembly.takeoffid) {
          await submitAssembly({
            assembly: selectedTakeoffAssembly,
            isUpdating: true,
          });
        } else {
          await submitAssembly({
            assembly: selectedTakeoffAssembly,
            isCreating: true,
          });
        }
        ResetStates.resetTakeoffStates(store, globalAssemblyStore);
      } catch (error) {
        console.error("Error submitting assembly:", error);
      }
    }
    setIsAnnotating(false);
    setIsEditingPoint({ isEditingPoint: false, isEditingStyle: false });
  };

  const handleEditStyle = () => {
    setIsEditingPoint({ isEditingPoint: false, isEditingStyle: true });
  };

  const handleDeletePoint = async (assemblyIdx: number, pIndex: number) => {
    if (!selectedTakeoffAssembly) return;

    let updatedPoints: Point[] = [...selectedTakeoffAssembly.pointsList];

    // If "Include All Points" is checked => remove ALL points
    if (includeAllPoints) {
      updatedPoints = [];
    } else {
      updatedPoints = updatedPoints.filter((_, idx) => idx !== pIndex);
    }

    const updatedAssembly = {
      ...selectedTakeoffAssembly,
      pointsList: updatedPoints,
    };

    await submitAssembly({ assembly: updatedAssembly, isUpdating: true });
    ResetStates.resetTakeoffStates(store, globalAssemblyStore);
  };

  const handleAddVertical = async (pIndex: number) => {
    if (!selectedTakeoffAssembly) return;

    let updatedPoints = [...selectedTakeoffAssembly.pointsList];

    if (includeAllPoints) {
      // apply verticalLength to all
      updatedPoints = updatedPoints.map((pt) => ({
        ...pt,
        verticallength: verticalLength,
      }));
    } else {
      // apply verticalLength only to the selected point
      updatedPoints[pIndex] = {
        ...updatedPoints[pIndex],
        verticallength: verticalLength,
      };
    }

    const updatedAssembly = {
      ...selectedTakeoffAssembly,
      pointsList: updatedPoints,
    };

    await submitAssembly({ assembly: updatedAssembly, isUpdating: true });
    ResetStates.resetTakeoffStates(store, globalAssemblyStore);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && isPointEditing) {
      handleAddVertical(pointIndex!);
    }
  };

  const handleVerticalLengthChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(e.target.value);
    setVerticalLength(isNaN(value) ? 0 : value);
  };

  const handleIncludeAllPointsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIncludeAllPoints(e.target.checked);
  };

  const handleSetInsertPoint = () => {
    setIsInsertingPoint(true);
    setIsEditingPoint({ isEditingPoint: false });
  };

  // -------------
  //  NEW LOGIC
  // -------------
  /** handleToggleIncludeInCount:
   *   Only changes 'isincludedincount' for:
   *   - The single point (pointIndex) if "includeAllPoints" = false
   *   - All points if "includeAllPoints" = true
   */
  const handleToggleIncludeInCount = async (checked: boolean) => {
    if (!selectedTakeoffAssembly) return;
    let updatedPoints = [...selectedTakeoffAssembly.pointsList];

    if (includeAllPoints) {
      updatedPoints = updatedPoints.map((pt) => ({
        ...pt,
        isincludedincount: checked,
      }));
    } else if (isPointEditing) {
      const orig = updatedPoints[pointIndex];
      updatedPoints[pointIndex] = {
        ...orig,
        isincludedincount: checked,
      };
    }

    const updatedAssembly = {
      ...selectedTakeoffAssembly,
      pointsList: updatedPoints,
    };
    await submitAssembly({ assembly: updatedAssembly, isUpdating: true });
    // Update local state so checkbox stays in sync
    setLocalIsIncludedInCount(checked);
  };

  /** handleToggleAllowTrailingLine:
   *   Only changes 'allowtrailingline' for:
   *   - The single point (pointIndex) if "includeAllPoints" = false
   *   - All points if "includeAllPoints" = true
   */
  const handleToggleAllowTrailingLine = async (checked: boolean) => {
    if (!selectedTakeoffAssembly) return;
    let updatedPoints = [...selectedTakeoffAssembly.pointsList];

    if (includeAllPoints) {
      updatedPoints = updatedPoints.map((pt) => ({
        ...pt,
        allowtrailingline: checked,
      }));
    } else if (isPointEditing) {
      // In the new approach, "AllowTrailingLine" is also stored on the same point
      const orig = updatedPoints[pointIndex];
      updatedPoints[pointIndex] = {
        ...orig,
        allowtrailingline: checked,
      };
    }

    const updatedAssembly = {
      ...selectedTakeoffAssembly,
      pointsList: updatedPoints,
    };
    await submitAssembly({ assembly: updatedAssembly, isUpdating: true });
    // Update local state so checkbox stays in sync
    setLocalAllowTrailingLine(checked);
  };

  // --------------------------------
  // Box positioning + rendering
  // --------------------------------
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const boxWidth = 200;
  const boxHeight = 300;

  const { x: adjustedX, y: adjustedY } = keepBoxInView(
    editingPointX,
    editingPointY,
    boxWidth,
    boxHeight,
    windowWidth,
    windowHeight
  );

  return (
    <Paper
      onContextMenu={(e) => e.preventDefault()}
      elevation={10}
      style={{
        position: "absolute",
        top: adjustedY + 5,
        left: adjustedX + 5,
        backgroundColor: store.userBackgroundColor,
        padding: "1rem",
        width: boxWidth,
        height: boxHeight,
        border: "1px solid black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 9999, // ensure it's on top
      }}
    >
      {/* Add to Run / Complete Run */}
      <button
        style={{ cursor: "pointer", width: "100px" }}
        onClick={
          isAnnotating
            ? handleCompleteRun
            : () => handleAddToRun(selectedTakeoffAssembly)
        }
      >
        {isAnnotating ? "Complete Run" : "Add to Run"}
      </button>

      {/* Insert Point for length/area assemblies */}
      {selectedTakeoffAssembly?.assemblymeasurementtype === "length" ||
      selectedTakeoffAssembly?.assemblymeasurementtype === "area" ? (
        <button
          style={{ cursor: "pointer", width: "100px" }}
          onClick={handleSetInsertPoint}
        >
          Insert Point
        </button>
      ) : null}

      {/* Edit Style */}
      <button
        style={{ cursor: "pointer", width: "100px" }}
        onClick={handleEditStyle}
      >
        Edit Style
      </button>

      <div style={{ width: "100%", textAlign: "center" }}>
        _______________________
        {/* Include All Points */}
        <div style={{ marginBottom: "10px" }}>
          <label>
            Include all points:
            <input
              type="checkbox"
              checked={includeAllPoints}
              onChange={handleIncludeAllPointsChange}
              style={{ marginLeft: "10px" }}
            />
          </label>
        </div>
        {/* "Allow Trailing Line" => stored in the same point now */}
        {selectedTakeoffAssembly?.assemblymeasurementtype === "length" && (
          <div style={{ marginBottom: "5px" }}>
            <label style={{ cursor: "pointer" }}>
              <input
                type="checkbox"
                checked={localAllowTrailingLine}
                onChange={(e) =>
                  handleToggleAllowTrailingLine(e.target.checked)
                }
                style={{ marginRight: "5px" }}
              />
              Allow Trailing Line
            </label>
          </div>
        )}
        {/* "Include in Count" => same single point */}
        <div style={{ marginBottom: "5px" }}>
          <label style={{ cursor: "pointer" }}>
            <input
              type="checkbox"
              checked={localIsIncludedInCount}
              onChange={(e) => handleToggleIncludeInCount(e.target.checked)}
              style={{ marginRight: "5px" }}
            />
            Include in Count
          </label>
        </div>
        {/* Delete Point */}
        <button
          style={{ cursor: "pointer", width: "100px", marginBottom: "10px" }}
          onClick={() => handleDeletePoint(assemblyIndex!, pointIndex!)}
        >
          Delete Point
        </button>
        {/* If measurement type is "length", show vertical length input */}
        {selectedTakeoffAssembly?.assemblymeasurementtype === "length" && (
          <div style={{ marginBottom: "10px" }}>
            Add length in feet (enter):
            <input
              type="number"
              value={verticalLength}
              onChange={handleVerticalLengthChange}
              onKeyDown={handleKeyDown}
              style={{ cursor: "pointer", width: "100px", marginLeft: "10px" }}
            />
          </div>
        )}
      </div>
    </Paper>
  );
};
