import { useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useStore } from "zustand";
import { useCloseoutStore } from "../states/closeoutStore";
import { useGlobalAssemblyStore } from "../states/globalAssemblyStore";

export const useHandleSetCreateStates = () => {
  const store1 = useStore(useUnityBuildStore);
  const store2 = useStore(useCloseoutStore);
  const globalAssemblyStore = useStore(useGlobalAssemblyStore);

  return (setStateFunction: Function, bool: boolean) => {
    ResetStates.resetTakeoffStates(store1, globalAssemblyStore);
    ResetStates.resetCloseoutStates(store2);
    setStateFunction(bool);
  };
};
