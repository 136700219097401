import { useQuery } from "react-query";
import { ItemService } from "../api/GlobalItemService";

export const useFetchUoMs = () => {
  const { data, error, isLoading, refetch } = useQuery(
    "uoms",
    async () => {
      const response = await ItemService.getUoMs();
      return response.uomsList;
    },
    {}
  );

  return { data, error, isLoading, refetch };
};
