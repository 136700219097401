import { useState } from "react";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { EquipmentRow } from "./EquipmentRow";
import { Calculations as c } from "../../utils/calculations";
import { EquipmentType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { useGetDefaultEquipmentExpenseTypes } from "../../hooks/useDefaultCloseoutHooks";

export const EquipmentHeader = ({
  equipmentExpenses,
}: {
  equipmentExpenses: EquipmentType.AsObject[];
}) => {
  const { setIncludedEquipmentTypes, updateEquipmentType } =
    useStore(useCloseoutStore);

  const { data: equipmentExpensesDefaults } =
    useGetDefaultEquipmentExpenseTypes();

  const [selectedEquipmentType, setSelectedEquipmentType] =
    useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value as string;

    // Ensure equipmentExpensesDefaults is defined before proceeding
    if (!equipmentExpensesDefaults) {
      console.warn("Equipment expense defaults are undefined.");
      return;
    }

    const defaultEquipmentType = equipmentExpensesDefaults.find(
      (equipmentType) => equipmentType.name === newValue
    );

    if (defaultEquipmentType) {
      // Merge DefaultEquipmentType and EquipmentType
      const newEquipmentExpenseType: EquipmentType.AsObject = {
        name: defaultEquipmentType.name,
        quantity: 0, // Default to 0 for new entries
        duration: 0, // Default to 0 for new entries
        unitcost: defaultEquipmentType.unitcost || 0,
        totalcost: c.calculateEquipmentTotal({
          quantity: 0,
          duration: 0,
          unitcost: defaultEquipmentType.unitcost || 0,
        }),
      };

      if (!equipmentExpenses.some((expense) => expense.name === newValue)) {
        const newIncludedEquipmentExpenseTypes = [
          ...equipmentExpenses,
          newEquipmentExpenseType,
        ];
        setIncludedEquipmentTypes(newIncludedEquipmentExpenseTypes);
        setSelectedEquipmentType("");
      }
    }
  };

  const handleDelete = (laborName: string) => {
    const newIncludedGenExpenseTypes = equipmentExpenses.filter(
      (l) => l.name !== laborName
    );
    setIncludedEquipmentTypes(newIncludedGenExpenseTypes);
  };

  const handleFieldUpdate = (field: string, value: any, index: number) => {
    const updatedGenExpense = {
      ...equipmentExpenses[index],
      [field]: value,
    };

    if (field === "quantity") {
      updatedGenExpense.quantity = value;
    } else if (field === "duration") {
      updatedGenExpense.duration = value;
    } else if (field === "unitcost") {
      updatedGenExpense.unitcost = value;
    }
    updateEquipmentType(index, updatedGenExpense);
  };

  return (
    <>
      <select value={selectedEquipmentType} onChange={handleChange}>
        <option value="" disabled selected hidden>
          Select equipment type
        </option>
        {equipmentExpensesDefaults &&
          equipmentExpensesDefaults
            .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
            .map((equipmentExpensesType, index) => (
              <option value={equipmentExpensesType.name} key={index}>
                {equipmentExpensesType.name}
              </option>
            ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Duration</th>
            <th>Unit Cost</th>
            <th>Total Cost</th>
          </tr>
          {equipmentExpenses.map((equipment, index) => (
            <EquipmentRow
              key={index}
              equipment={equipment}
              handleDelete={handleDelete}
              handleFieldUpdate={(field, value) =>
                handleFieldUpdate(field, value, index)
              }
            />
          ))}
        </table>
      </div>
    </>
  );
};
