import React, { useEffect, useMemo, useState } from "react";
import { DirectLaborRow } from "./DirectLaborRow";
import { Calculations as c } from "../../utils/calculations";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import {
  DirectLaborType,
  EstimateCloseout,
} from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { recalculateDirectLabor } from "./closeoutHelpers";
import { useGetDefaultDirectLaborTypes } from "../../hooks/useDefaultCloseoutHooks";

export const DirectLaborHeader = ({
  estimateCloseout,
  labor,
}: {
  estimateCloseout: EstimateCloseout.AsObject | undefined;
  labor: DirectLaborType.AsObject[];
}) => {
  const {
    includedExtentionTypes,
    setIncludedDirectLaborTypes,
    setIncludedTotalPricingTypes,
    updateDirectLaborType,
  } = useStore(useCloseoutStore);
  const [selectedLaborType, setSelectedLaborType] = useState<string>("");

  const { data: laborDefaults } = useGetDefaultDirectLaborTypes();

  const totalLaborHours = useMemo(() => {
    return includedExtentionTypes.reduce(
      (total, item) => total + item.totallaborhours,
      0
    );
  }, [includedExtentionTypes]);

  useEffect(() => {
    if (!estimateCloseout) {
      return;
    }
    recalculateDirectLabor(labor, totalLaborHours, setIncludedDirectLaborTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    labor,
    totalLaborHours,
    estimateCloseout,
    setIncludedDirectLaborTypes,
    setIncludedTotalPricingTypes,
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    const defaultLaborType = laborDefaults?.find(
      (laborType) => laborType.name === newValue
    );

    if (defaultLaborType) {
      // Map DefaultDirectLaborType to DirectLaborType
      const newLaborType: DirectLaborType.AsObject = {
        name: defaultLaborType.name,
        laborrate: defaultLaborType.laborrate,
        burdenpercent: defaultLaborType.burdenpercent / 100,
        fringe: defaultLaborType.fringe,
        distributionpercent: defaultLaborType.distributionpercent / 100,
        allocatedhours: c.calculateAllocatedHours(
          totalLaborHours,
          defaultLaborType.distributionpercent
        ),
        totalcost: c.calculateFullLaborCost({
          allocatedhours: c.calculateAllocatedHours(
            totalLaborHours,
            defaultLaborType.distributionpercent
          ),
          laborrate: defaultLaborType.laborrate,
          burdenpercent: defaultLaborType.burdenpercent,
          fringe: defaultLaborType.fringe,
        }),
      };

      // Add if not already included
      if (!labor.some((laborType) => laborType.name === newValue)) {
        const newIncludedLaborTypes = [...labor, newLaborType];
        handleLaborDistribution(newIncludedLaborTypes);
        setSelectedLaborType("");
      }
    }
  };

  const handleDelete = (laborName: string) => {
    const newIncludedLaborTypes = labor.filter((l) => l.name !== laborName);
    handleLaborDistribution(newIncludedLaborTypes);
  };

  const handleLaborDistribution = (labor: DirectLaborType.AsObject[]) => {
    let totalDistribution = labor.reduce(
      (sum, laborType) => sum + laborType.distributionpercent,
      0
    );

    if (totalDistribution !== 1) {
      const remainingPercentage = 1 - totalDistribution;
      const remainingLaborCount = labor.length;

      labor.forEach((laborType) => {
        laborType.distributionpercent +=
          remainingPercentage / remainingLaborCount;
      });
    }

    labor.forEach((laborType) => {
      laborType.allocatedhours = c.calculateAllocatedHours(
        totalLaborHours,
        laborType.distributionpercent
      );
    });
    setIncludedDirectLaborTypes([...labor]);
  };

  const handleDistributionChange = (
    updatedLabor: DirectLaborType.AsObject,
    index: number
  ) => {
    const newIncludedLaborTypes = labor.map((laborType, idx) =>
      idx === index ? updatedLabor : laborType
    );

    const totalDistribution = newIncludedLaborTypes.reduce(
      (sum, laborType) => sum + (laborType.distributionpercent || 0),
      0
    );

    // Ensure total distribution does not exceed 1
    if (totalDistribution > 1) {
      const remainingPercentage = 1 - updatedLabor.distributionpercent;
      const otherLaborTypes = newIncludedLaborTypes.filter(
        (laborType, idx) => idx !== index
      );

      if (otherLaborTypes.length > 0) {
        const totalOtherDistribution = otherLaborTypes.reduce(
          (sum, laborType) => sum + (laborType.distributionpercent || 0),
          0
        );

        if (totalOtherDistribution === 0) {
          otherLaborTypes.forEach((laborType) => {
            laborType.distributionpercent = 0;
            laborType.allocatedhours = 0;
          });
        } else {
          otherLaborTypes.forEach((laborType) => {
            laborType.distributionpercent =
              (laborType.distributionpercent / totalOtherDistribution) *
              remainingPercentage;
          });
        }
      }
    }

    newIncludedLaborTypes.forEach((laborType) => {
      laborType.allocatedhours = c.calculateAllocatedHours(
        totalLaborHours,
        laborType.distributionpercent || 0
      );
    });

    setIncludedDirectLaborTypes(newIncludedLaborTypes);
  };

  const handleFieldUpdate = (field: string, value: number, index: number) => {
    const updatedLabor = {
      ...labor[index],
      [field]: value,
    };

    if (field === "distributionpercent") {
      updatedLabor.allocatedhours = c.calculateAllocatedHours(
        totalLaborHours,
        updatedLabor.distributionpercent || 0
      );
    }

    updateDirectLaborType(index, updatedLabor);
  };

  return (
    <>
      <select
        id="labor-select"
        value={selectedLaborType}
        onChange={handleChange}
      >
        <option value="" disabled selected hidden>
          Select labor type
        </option>
        {laborDefaults &&
          laborDefaults
            .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
            .map((laborType, index) => (
              <option value={laborType.name} key={index}>
                {laborType.name}
              </option>
            ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Hours: {totalLaborHours.toFixed(2)}</th>
              <th>Distribution %</th>
              <th>Labor Rate</th>
              <th>Subtotal</th>
              <th>Burden %</th>
              <th>Fringe</th>
              <th>Burden Total</th>
              <th>Fringe Total</th>
              <th>Full Hourly Cost</th>
              <th>Full Cost</th>
            </tr>
          </thead>
          <tbody>
            {labor.map((labor, index) => (
              <DirectLaborRow
                key={index}
                labor={labor}
                totalLaborHours={totalLaborHours}
                handleDelete={handleDelete}
                handleDistributionChange={(updatedLabor) =>
                  handleDistributionChange(updatedLabor, index)
                }
                handleFieldUpdate={(field, value) =>
                  handleFieldUpdate(field, value, index)
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
