import { useState, useEffect } from "react";

/**
 * A custom hook that returns `true` if `ref.current` is in the viewport,
 * else `false`.
 * @param ref A React ref pointing to the element to observe.
 * @param options Options for IntersectionObserver (threshold, rootMargin, etc.).
 */
export function useIntersection(
  ref: React.RefObject<HTMLElement>,
  options?: IntersectionObserverInit
): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const elem = ref.current;
    if (!elem) return;

    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    observer.observe(elem);

    return () => {
      observer.unobserve(elem);
    };
  }, [ref, options]);

  return isIntersecting;
}
