import { useEffect, useState, useMemo } from "react";
import { LaborFactoringRow } from "./LaborFactoringRow";
import { LaborFactoringType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { Calculations as c } from "../../utils/calculations";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { recalculateLaborFactoring } from "./closeoutHelpers";
import { useGetDefaultLaborFactoringTypes } from "../../hooks/useDefaultCloseoutHooks";

export const LaborFactoringHeader = ({
  labor,
}: {
  labor: LaborFactoringType.AsObject[];
}) => {
  const {
    includedExtentionTypes,
    setIncludedLaborFactoringTypes,
    updateLaborFactoringType,
  } = useStore(useCloseoutStore);

  const { data: laborDefaults } = useGetDefaultLaborFactoringTypes();
  const [selectedLaborType, setSelectedLaborType] = useState<string>("");

  const totalLaborHours = useMemo(() => {
    return includedExtentionTypes.reduce(
      (total, item) => total + item.totallaborhours,
      0
    );
  }, [includedExtentionTypes]);

  useEffect(() => {
    recalculateLaborFactoring(
      labor,
      totalLaborHours,
      setIncludedLaborFactoringTypes
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labor, totalLaborHours, setIncludedLaborFactoringTypes]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    const defaultLaborType = laborDefaults?.find(
      (type) => type.name === newValue
    );

    if (defaultLaborType) {
      const newLaborType: LaborFactoringType.AsObject = {
        name: defaultLaborType.name,
        laborpercent: defaultLaborType.laborpercent / 100,
        impactpercent: defaultLaborType.impactpercent / 100,
        allocatedhours:
          totalLaborHours *
          (defaultLaborType.laborpercent / 100) *
          (defaultLaborType.impactpercent / 100),
        laborrate: defaultLaborType.laborrate,
        burdenpercent: defaultLaborType.burdenpercent / 100,
        fringe: defaultLaborType.fringe,
        distributionpercent: 0, // Provide a default value for distributionpercent
        totalcost: c.calculateFullLaborCost({
          allocatedhours:
            totalLaborHours *
            (defaultLaborType.laborpercent / 100) *
            (defaultLaborType.impactpercent / 100),
          laborrate: defaultLaborType.laborrate,
          burdenpercent: defaultLaborType.burdenpercent / 100,
          fringe: defaultLaborType.fringe,
        }),
      };

      if (!labor.some((type) => type.name === newValue)) {
        const newIncludedTypes = [...labor, newLaborType];
        setIncludedLaborFactoringTypes(newIncludedTypes);
        setSelectedLaborType("");
      }
    }
  };

  const handleDelete = (laborName: string) => {
    const newIncludedLaborTypes = labor.filter((l) => l.name !== laborName);
    setIncludedLaborFactoringTypes(newIncludedLaborTypes);
  };

  const handleLaborDistribution = (
    updatedLabor: LaborFactoringType.AsObject,
    index: number
  ) => {
    const updatedLaborList = labor.map((laborType, idx) =>
      idx === index ? updatedLabor : laborType
    );

    updatedLaborList.forEach((laborType, idx) => {
      laborType.allocatedhours =
        totalLaborHours *
        (laborType.laborpercent || 0) *
        (laborType.impactpercent || 0);
    });

    setIncludedLaborFactoringTypes(updatedLaborList);
  };

  const handleFieldUpdate = (field: string, value: number, index: number) => {
    const updatedLabor = {
      ...labor[index],
      [field]: value,
    };

    if (field === "laborpercent" || field === "impactpercent") {
      updatedLabor.allocatedhours =
        totalLaborHours *
        (updatedLabor.laborpercent || 0) *
        (updatedLabor.impactpercent || 0);
    }

    const updatedLaborList = labor.map((laborType, idx) =>
      idx === index ? updatedLabor : laborType
    );

    setIncludedLaborFactoringTypes(updatedLaborList);
    updateLaborFactoringType(index, updatedLabor);
  };

  return (
    <>
      <select value={selectedLaborType} onChange={handleChange}>
        <option value="" disabled hidden>
          Select labor type
        </option>
        {laborDefaults &&
          laborDefaults
            .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
            .map((laborType, index) => (
              <option value={laborType.name} key={index}>
                {laborType.name}
              </option>
            ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Labor %</th>
              <th>Impact %</th>
              <th>Hours: {totalLaborHours.toFixed(2)}</th>
              <th>Labor Rate</th>
              <th>Subtotal</th>
              <th>Burden %</th>
              <th>Fringe</th>
              <th>Burden Total</th>
              <th>Fringe Total</th>
              <th>Full Hourly Cost</th>
              <th>Full Cost</th>
            </tr>
          </thead>
          <tbody>
            {labor.map((labor, index) => (
              <LaborFactoringRow
                key={index}
                labor={labor}
                index={index}
                totalLaborHours={totalLaborHours}
                handleDelete={handleDelete}
                handleFieldUpdate={handleFieldUpdate}
                handleLaborDistribution={handleLaborDistribution}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
