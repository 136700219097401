/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: breakout.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as breakout_pb from './breakout_pb'; // proto import: "breakout.proto"


export class BreakoutServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateEstimateBreakout = new grpcWeb.MethodDescriptor(
    '/BreakoutService/CreateEstimateBreakout',
    grpcWeb.MethodType.UNARY,
    breakout_pb.CreateEstimateBreakoutRequest,
    breakout_pb.CreateEstimateBreakoutResponse,
    (request: breakout_pb.CreateEstimateBreakoutRequest) => {
      return request.serializeBinary();
    },
    breakout_pb.CreateEstimateBreakoutResponse.deserializeBinary
  );

  createEstimateBreakout(
    request: breakout_pb.CreateEstimateBreakoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<breakout_pb.CreateEstimateBreakoutResponse>;

  createEstimateBreakout(
    request: breakout_pb.CreateEstimateBreakoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: breakout_pb.CreateEstimateBreakoutResponse) => void): grpcWeb.ClientReadableStream<breakout_pb.CreateEstimateBreakoutResponse>;

  createEstimateBreakout(
    request: breakout_pb.CreateEstimateBreakoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: breakout_pb.CreateEstimateBreakoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BreakoutService/CreateEstimateBreakout',
        request,
        metadata || {},
        this.methodDescriptorCreateEstimateBreakout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BreakoutService/CreateEstimateBreakout',
    request,
    metadata || {},
    this.methodDescriptorCreateEstimateBreakout);
  }

  methodDescriptorGetEstimateBreakouts = new grpcWeb.MethodDescriptor(
    '/BreakoutService/GetEstimateBreakouts',
    grpcWeb.MethodType.UNARY,
    breakout_pb.GetEstimateBreakoutsRequest,
    breakout_pb.GetEstimateBreakoutsResponse,
    (request: breakout_pb.GetEstimateBreakoutsRequest) => {
      return request.serializeBinary();
    },
    breakout_pb.GetEstimateBreakoutsResponse.deserializeBinary
  );

  getEstimateBreakouts(
    request: breakout_pb.GetEstimateBreakoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<breakout_pb.GetEstimateBreakoutsResponse>;

  getEstimateBreakouts(
    request: breakout_pb.GetEstimateBreakoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: breakout_pb.GetEstimateBreakoutsResponse) => void): grpcWeb.ClientReadableStream<breakout_pb.GetEstimateBreakoutsResponse>;

  getEstimateBreakouts(
    request: breakout_pb.GetEstimateBreakoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: breakout_pb.GetEstimateBreakoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BreakoutService/GetEstimateBreakouts',
        request,
        metadata || {},
        this.methodDescriptorGetEstimateBreakouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BreakoutService/GetEstimateBreakouts',
    request,
    metadata || {},
    this.methodDescriptorGetEstimateBreakouts);
  }

  methodDescriptorUpdateEstimateBreakout = new grpcWeb.MethodDescriptor(
    '/BreakoutService/UpdateEstimateBreakout',
    grpcWeb.MethodType.UNARY,
    breakout_pb.UpdateEstimateBreakoutRequest,
    breakout_pb.UpdateEstimateBreakoutResponse,
    (request: breakout_pb.UpdateEstimateBreakoutRequest) => {
      return request.serializeBinary();
    },
    breakout_pb.UpdateEstimateBreakoutResponse.deserializeBinary
  );

  updateEstimateBreakout(
    request: breakout_pb.UpdateEstimateBreakoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<breakout_pb.UpdateEstimateBreakoutResponse>;

  updateEstimateBreakout(
    request: breakout_pb.UpdateEstimateBreakoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: breakout_pb.UpdateEstimateBreakoutResponse) => void): grpcWeb.ClientReadableStream<breakout_pb.UpdateEstimateBreakoutResponse>;

  updateEstimateBreakout(
    request: breakout_pb.UpdateEstimateBreakoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: breakout_pb.UpdateEstimateBreakoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BreakoutService/UpdateEstimateBreakout',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateBreakout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BreakoutService/UpdateEstimateBreakout',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateBreakout);
  }

  methodDescriptorDeleteEstimateBreakout = new grpcWeb.MethodDescriptor(
    '/BreakoutService/DeleteEstimateBreakout',
    grpcWeb.MethodType.UNARY,
    breakout_pb.DeleteEstimateBreakoutRequest,
    breakout_pb.DeleteEstimateBreakoutResponse,
    (request: breakout_pb.DeleteEstimateBreakoutRequest) => {
      return request.serializeBinary();
    },
    breakout_pb.DeleteEstimateBreakoutResponse.deserializeBinary
  );

  deleteEstimateBreakout(
    request: breakout_pb.DeleteEstimateBreakoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<breakout_pb.DeleteEstimateBreakoutResponse>;

  deleteEstimateBreakout(
    request: breakout_pb.DeleteEstimateBreakoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: breakout_pb.DeleteEstimateBreakoutResponse) => void): grpcWeb.ClientReadableStream<breakout_pb.DeleteEstimateBreakoutResponse>;

  deleteEstimateBreakout(
    request: breakout_pb.DeleteEstimateBreakoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: breakout_pb.DeleteEstimateBreakoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BreakoutService/DeleteEstimateBreakout',
        request,
        metadata || {},
        this.methodDescriptorDeleteEstimateBreakout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BreakoutService/DeleteEstimateBreakout',
    request,
    metadata || {},
    this.methodDescriptorDeleteEstimateBreakout);
  }

  methodDescriptorCreateChangeOrderBreakout = new grpcWeb.MethodDescriptor(
    '/BreakoutService/CreateChangeOrderBreakout',
    grpcWeb.MethodType.UNARY,
    breakout_pb.CreateChangeOrderBreakoutRequest,
    breakout_pb.CreateChangeOrderBreakoutResponse,
    (request: breakout_pb.CreateChangeOrderBreakoutRequest) => {
      return request.serializeBinary();
    },
    breakout_pb.CreateChangeOrderBreakoutResponse.deserializeBinary
  );

  createChangeOrderBreakout(
    request: breakout_pb.CreateChangeOrderBreakoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<breakout_pb.CreateChangeOrderBreakoutResponse>;

  createChangeOrderBreakout(
    request: breakout_pb.CreateChangeOrderBreakoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: breakout_pb.CreateChangeOrderBreakoutResponse) => void): grpcWeb.ClientReadableStream<breakout_pb.CreateChangeOrderBreakoutResponse>;

  createChangeOrderBreakout(
    request: breakout_pb.CreateChangeOrderBreakoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: breakout_pb.CreateChangeOrderBreakoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BreakoutService/CreateChangeOrderBreakout',
        request,
        metadata || {},
        this.methodDescriptorCreateChangeOrderBreakout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BreakoutService/CreateChangeOrderBreakout',
    request,
    metadata || {},
    this.methodDescriptorCreateChangeOrderBreakout);
  }

  methodDescriptorGetChangeOrderBreakouts = new grpcWeb.MethodDescriptor(
    '/BreakoutService/GetChangeOrderBreakouts',
    grpcWeb.MethodType.UNARY,
    breakout_pb.GetChangeOrderBreakoutsRequest,
    breakout_pb.GetChangeOrderBreakoutsResponse,
    (request: breakout_pb.GetChangeOrderBreakoutsRequest) => {
      return request.serializeBinary();
    },
    breakout_pb.GetChangeOrderBreakoutsResponse.deserializeBinary
  );

  getChangeOrderBreakouts(
    request: breakout_pb.GetChangeOrderBreakoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<breakout_pb.GetChangeOrderBreakoutsResponse>;

  getChangeOrderBreakouts(
    request: breakout_pb.GetChangeOrderBreakoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: breakout_pb.GetChangeOrderBreakoutsResponse) => void): grpcWeb.ClientReadableStream<breakout_pb.GetChangeOrderBreakoutsResponse>;

  getChangeOrderBreakouts(
    request: breakout_pb.GetChangeOrderBreakoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: breakout_pb.GetChangeOrderBreakoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BreakoutService/GetChangeOrderBreakouts',
        request,
        metadata || {},
        this.methodDescriptorGetChangeOrderBreakouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BreakoutService/GetChangeOrderBreakouts',
    request,
    metadata || {},
    this.methodDescriptorGetChangeOrderBreakouts);
  }

  methodDescriptorUpdateChangeOrderBreakout = new grpcWeb.MethodDescriptor(
    '/BreakoutService/UpdateChangeOrderBreakout',
    grpcWeb.MethodType.UNARY,
    breakout_pb.UpdateChangeOrderBreakoutRequest,
    breakout_pb.UpdateChangeOrderBreakoutResponse,
    (request: breakout_pb.UpdateChangeOrderBreakoutRequest) => {
      return request.serializeBinary();
    },
    breakout_pb.UpdateChangeOrderBreakoutResponse.deserializeBinary
  );

  updateChangeOrderBreakout(
    request: breakout_pb.UpdateChangeOrderBreakoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<breakout_pb.UpdateChangeOrderBreakoutResponse>;

  updateChangeOrderBreakout(
    request: breakout_pb.UpdateChangeOrderBreakoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: breakout_pb.UpdateChangeOrderBreakoutResponse) => void): grpcWeb.ClientReadableStream<breakout_pb.UpdateChangeOrderBreakoutResponse>;

  updateChangeOrderBreakout(
    request: breakout_pb.UpdateChangeOrderBreakoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: breakout_pb.UpdateChangeOrderBreakoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BreakoutService/UpdateChangeOrderBreakout',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderBreakout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BreakoutService/UpdateChangeOrderBreakout',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderBreakout);
  }

  methodDescriptorDeleteChangeOrderBreakout = new grpcWeb.MethodDescriptor(
    '/BreakoutService/DeleteChangeOrderBreakout',
    grpcWeb.MethodType.UNARY,
    breakout_pb.DeleteChangeOrderBreakoutRequest,
    breakout_pb.DeleteChangeOrderBreakoutResponse,
    (request: breakout_pb.DeleteChangeOrderBreakoutRequest) => {
      return request.serializeBinary();
    },
    breakout_pb.DeleteChangeOrderBreakoutResponse.deserializeBinary
  );

  deleteChangeOrderBreakout(
    request: breakout_pb.DeleteChangeOrderBreakoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<breakout_pb.DeleteChangeOrderBreakoutResponse>;

  deleteChangeOrderBreakout(
    request: breakout_pb.DeleteChangeOrderBreakoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: breakout_pb.DeleteChangeOrderBreakoutResponse) => void): grpcWeb.ClientReadableStream<breakout_pb.DeleteChangeOrderBreakoutResponse>;

  deleteChangeOrderBreakout(
    request: breakout_pb.DeleteChangeOrderBreakoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: breakout_pb.DeleteChangeOrderBreakoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/BreakoutService/DeleteChangeOrderBreakout',
        request,
        metadata || {},
        this.methodDescriptorDeleteChangeOrderBreakout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/BreakoutService/DeleteChangeOrderBreakout',
    request,
    metadata || {},
    this.methodDescriptorDeleteChangeOrderBreakout);
  }

}

