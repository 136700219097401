import { useMutation, useQuery, useQueryClient } from "react-query";
import { FolderService } from "../api/FolderService";
import { Foldertype } from "../types/FolderTypes";
import { Folder } from "../api/protosCompiled/folder/folder_pb";

//-------------------Project Folders------------------------
export function useFetchProjectFolders() {
  const foldertype: Foldertype = "project";
  const { data, error, isLoading } = useQuery(
    ["projectFolders"],
    async () => {
      const response = await FolderService.getFolders({
        foldertype: foldertype,
      });
      return response;
    },
    {
      enabled: true,
    }
  );

  return { data, error, isLoading };
}

export function useCreateProjectFolderMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedFolder: Folder.AsObject) => {
      await FolderService.createFolder(updatedFolder);
    },
    {
      onMutate: async (updatedFolder) => {
        // Optimistically update project folders cache
        await queryClient.cancelQueries(["projectFolders"]);
        const previousProjectFolders = queryClient.getQueryData([
          "projectFolders",
        ]);

        queryClient.setQueryData(["projectFolders"], (old: any) =>
          old?.foldersList?.map((folder: Folder.AsObject) =>
            folder.folderid === updatedFolder.folderid
              ? { ...folder, ...updatedFolder }
              : folder
          )
        );

        return { previousProjectFolders };
      },
      onError: (err, updatedFolder, context) => {
        // Roll back project folders cache on error
        if (context?.previousProjectFolders) {
          queryClient.setQueryData(
            ["projectFolders"],
            context.previousProjectFolders
          );
        }
      },
      onSettled: () => {
        // Invalidate the project folders query to refetch data
        queryClient.invalidateQueries(["projectFolders"]);
      },
    }
  );
}

export function useUpdateProjectFolder() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedFolder: Folder.AsObject) => {
      await FolderService.updateFolder(updatedFolder);
    },
    {
      onMutate: async (updatedFolder) => {
        // Optimistically update project folders cache
        await queryClient.cancelQueries(["projectFolders"]);
        const previousProjectFolders = queryClient.getQueryData([
          "projectFolders",
        ]);

        queryClient.setQueryData(["projectFolders"], (old: any) =>
          old.foldersList.map((folder: Folder.AsObject) =>
            folder.folderid === updatedFolder.folderid
              ? { ...folder, ...updatedFolder }
              : folder
          )
        );

        return { previousProjectFolders };
      },
      onError: (err, updatedFolder, context) => {
        // Roll back project folders cache on error
        if (context?.previousProjectFolders) {
          queryClient.setQueryData(
            ["projectFolders"],
            context.previousProjectFolders
          );
        }
      },
      onSettled: () => {
        // Invalidate the project folders query to refetch data
        queryClient.invalidateQueries(["projectFolders"]);
      },
    }
  );
}

export function useDeleteProjectFolderMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (folderId: string) => {
      await FolderService.deleteFolder(folderId);
    },
    {
      onMutate: async (folderId) => {
        // Optimistically update project folders cache
        await queryClient.cancelQueries(["projectFolders"]);
        const previousProjectFolders = queryClient.getQueryData([
          "projectFolders",
        ]);

        queryClient.setQueryData(["projectFolders"], (old: any) =>
          old.foldersList.filter(
            (folder: Folder.AsObject) => folder.folderid !== folderId
          )
        );

        return { previousProjectFolders };
      },
      onError: (err, folderId, context) => {
        // Roll back project folders cache on error
        if (context?.previousProjectFolders) {
          queryClient.setQueryData(
            ["projectFolders"],
            context.previousProjectFolders
          );
        }
      },
      onSettled: () => {
        // Invalidate the project folders query to refetch data
        queryClient.invalidateQueries(["projectFolders"]);
      },
    }
  );
}

//-------------------Assembly Folders------------------------
export function useFetchAssemblyFolders() {
  const foldertype: Foldertype = "assembly";
  const { data, error, isLoading } = useQuery(
    ["assemblyFolders"],
    async () => {
      const response = await FolderService.getFolders({
        foldertype: foldertype,
      });
      return response;
    },
    {
      enabled: true,
    }
  );
  return { data, error, isLoading };
}

export function useCreateAssemblyFolderMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedFolder: Folder.AsObject) => {
      await FolderService.createFolder(updatedFolder);
    },
    {
      onMutate: async (updatedFolder) => {
        // Optimistically update assembly folders cache
        await queryClient.cancelQueries(["assemblyFolders"]);
        const previousAssemblyFolders = queryClient.getQueryData([
          "assemblyFolders",
        ]);

        queryClient.setQueryData(["assemblyFolders"], (old: any) =>
          old?.foldersList?.map((folder: Folder.AsObject) =>
            folder.folderid === updatedFolder.folderid
              ? { ...folder, ...updatedFolder }
              : folder
          )
        );

        return { previousAssemblyFolders };
      },
      onError: (err, updatedFolder, context) => {
        // Roll back assembly folders cache on error
        if (context?.previousAssemblyFolders) {
          queryClient.setQueryData(
            ["assemblyFolders"],
            context.previousAssemblyFolders
          );
        }
      },
      onSettled: () => {
        // Invalidate the assembly folders query to refetch data
        queryClient.invalidateQueries(["assemblyFolders"]);
      },
    }
  );
}

export function useUpdateAssemblyFolder() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedFolder: Folder.AsObject) => {
      await FolderService.updateFolder(updatedFolder);
    },
    {
      onMutate: async (updatedFolder) => {
        // Optimistically update assembly folders cache
        await queryClient.cancelQueries(["assemblyFolders"]);
        const previousAssemblyFolders = queryClient.getQueryData([
          "assemblyFolders",
        ]);

        queryClient.setQueryData(["assemblyFolders"], (old: any) =>
          old.foldersList.map((folder: Folder.AsObject) =>
            folder.folderid === updatedFolder.folderid
              ? { ...folder, ...updatedFolder }
              : folder
          )
        );

        return { previousAssemblyFolders };
      },
      onError: (err, updatedFolder, context) => {
        // Roll back assembly folders cache on error
        if (context?.previousAssemblyFolders) {
          queryClient.setQueryData(
            ["assemblyFolders"],
            context.previousAssemblyFolders
          );
        }
      },
      onSettled: () => {
        // Invalidate the assembly folders query to refetch data
        queryClient.invalidateQueries(["assemblyFolders"]);
      },
    }
  );
}

export function useDeleteAssemblyFolderMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (folderId: string) => {
      await FolderService.deleteFolder(folderId);
    },
    {
      onMutate: async (folderId) => {
        // Optimistically update assembly folders cache
        await queryClient.cancelQueries(["assemblyFolders"]);
        const previousAssemblyFolders = queryClient.getQueryData([
          "assemblyFolders",
        ]);

        queryClient.setQueryData(["assemblyFolders"], (old: any) =>
          old.foldersList.filter(
            (folder: Folder.AsObject) => folder.folderid !== folderId
          )
        );

        return { previousAssemblyFolders };
      },
      onError: (err, folderId, context) => {
        // Roll back assembly folders cache on error
        if (context?.previousAssemblyFolders) {
          queryClient.setQueryData(
            ["assemblyFolders"],
            context.previousAssemblyFolders
          );
        }
      },
      onSettled: () => {
        // Invalidate the assembly folders query to refetch data
        queryClient.invalidateQueries(["assemblyFolders"]);
      },
    }
  );
}

//-------------------Item Folders------------------------
export function useFetchItemFolders() {
  const foldertype: Foldertype = "item";

  const { data, error, isLoading } = useQuery(
    ["itemFolders"],
    async () => {
      const response = await FolderService.getFolders({
        foldertype: foldertype,
      });
      return response;
    },
    {
      enabled: true,
    }
  );

  return { data, error, isLoading };
}

export function useCreateItemFolderMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedFolder: Folder.AsObject) => {
      await FolderService.createFolder(updatedFolder);
    },
    {
      onMutate: async (updatedFolder) => {
        // Optimistically update item folders cache
        await queryClient.cancelQueries(["itemFolders"]);
        const previousItemFolders = queryClient.getQueryData(["itemFolders"]);

        queryClient.setQueryData(["itemFolders"], (old: any) =>
          old.foldersList.map((folder: Folder.AsObject) =>
            folder.folderid === updatedFolder.folderid
              ? { ...folder, ...updatedFolder }
              : folder
          )
        );

        return { previousItemFolders };
      },
      onError: (err, updatedFolder, context) => {
        // Roll back item folders cache on error
        if (context?.previousItemFolders) {
          queryClient.setQueryData(
            ["itemFolders"],
            context.previousItemFolders
          );
        }
      },
      onSettled: () => {
        // Invalidate the item folders query to refetch data
        queryClient.invalidateQueries(["itemFolders"]);
      },
    }
  );
}

export function useUpdateItemFolder() {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedFolder: Folder.AsObject) => {
      await FolderService.updateFolder(updatedFolder);
    },
    {
      onMutate: async (updatedFolder) => {
        // Optimistically update item folders cache
        await queryClient.cancelQueries(["itemFolders"]);
        const previousItemFolders = queryClient.getQueryData(["itemFolders"]);

        queryClient.setQueryData(["itemFolders"], (old: any) =>
          old.foldersList.map((folder: Folder.AsObject) =>
            folder.folderid === updatedFolder.folderid
              ? { ...folder, ...updatedFolder }
              : folder
          )
        );

        return { previousItemFolders };
      },
      onError: (err, updatedFolder, context) => {
        // Roll back item folders cache on error
        if (context?.previousItemFolders) {
          queryClient.setQueryData(
            ["itemFolders"],
            context.previousItemFolders
          );
        }
      },
      onSettled: () => {
        // Invalidate the item folders query to refetch data
        queryClient.invalidateQueries(["itemFolders"]);
      },
    }
  );
}

export function useDeleteItemFolderMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (folderId: string) => {
      await FolderService.deleteFolder(folderId);
    },
    {
      onMutate: async (folderId) => {
        // Optimistically update item folders cache
        await queryClient.cancelQueries(["itemFolders"]);
        const previousItemFolders = queryClient.getQueryData(["itemFolders"]);

        queryClient.setQueryData(["itemFolders"], (old: any) =>
          old.foldersList.filter(
            (folder: Folder.AsObject) => folder.folderid !== folderId
          )
        );

        return { previousItemFolders };
      },
      onError: (err, folderId, context) => {
        // Roll back item folders cache on error
        if (context?.previousItemFolders) {
          queryClient.setQueryData(
            ["itemFolders"],
            context.previousItemFolders
          );
        }
      },
      onSettled: () => {
        // Invalidate the item folders query to refetch data
        queryClient.invalidateQueries(["itemFolders"]);
      },
    }
  );
}
