import { useMutation, useQuery, useQueryClient } from "react-query";
import { BreakoutService } from "../api/BreakoutService";
import { Breakout } from "../api/protosCompiled/breakout/breakout_pb";

// Fetch hooks
export function useFetchEstimateBreakouts(id: string) {
  return useQuery(
    ["estimateBreakouts", id],
    () => BreakoutService.getEstimateBreakouts(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
}

export function useFetchChangeOrderBreakouts(id: string) {
  return useQuery(
    ["changeOrderBreakouts", id],
    () => BreakoutService.getChangeOrderBreakouts(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
}

// Mutation hooks for estimate breakouts
export function useCreateEstimateBreakoutMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Breakout.AsObject) => BreakoutService.createEstimateBreakout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("estimateBreakouts");
      },
    }
  );
}

export function useUpdateEstimateBreakoutMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Breakout.AsObject) => BreakoutService.updateEstimateBreakout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("estimateBreakouts");
      },
    }
  );
}

export function useDeleteEstimateBreakoutMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => BreakoutService.deleteEstimateBreakout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("estimateBreakouts");
      },
    }
  );
}

// Mutation hooks for change order breakouts
export function useCreateChangeOrderBreakoutMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Breakout.AsObject) =>
      BreakoutService.createChangeOrderBreakout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("changeOrderBreakouts");
      },
    }
  );
}

export function useUpdateChangeOrderBreakoutMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Breakout.AsObject) =>
      BreakoutService.updateChangeOrderBreakout(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("changeOrderBreakouts");
      },
    }
  );
}

export function useDeleteChangeOrderBreakoutMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => BreakoutService.deleteChangeOrderBreakout(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("changeOrderBreakouts");
      },
    }
  );
}
