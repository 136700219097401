import { clients } from "../clients/grpcClients";
import {
  GetRolesRequest,
  GetRolesResponse,
} from "./protosCompiled/role/role_pb";

export class RoleService {
  //gRPC calls
  public static fetchRoles = (): Promise<GetRolesResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const getRolessRequest = new GetRolesRequest();
      clients.roleServiceClient.getAllRoles(
        getRolessRequest,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
