import { UB_Account_User } from "../api/protosCompiled/ub_account/ub_account_pb";

const DEV_ACCOUNT_ID = process.env.REACT_APP_DEV_ACCOUNT_ID;
const DEV_EMAIL1 = process.env.REACT_APP_DEV_EMAIL1;

const isDevAccount = (accountId: string, user: UB_Account_User.AsObject) => {
  if (!user || !user.email) return false;
  return accountId === DEV_ACCOUNT_ID || user.email === DEV_EMAIL1;
};

export const userIsDevAccount = (
  accountId: string | undefined,
  user: UB_Account_User.AsObject | null | undefined
): boolean => {
  if (!user || !accountId) {
    return true;
  }
  const isEnabled = isDevAccount(accountId, user);
  return !isEnabled;
};
