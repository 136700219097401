import { DefaultCloseoutTypeKey } from "./CloseoutTypes";
import {
  DefaultDirectLaborType,
  DefaultLaborFactoringType,
  DefaultIncidentalLaborType,
  DefaultEquipmentType,
  DefaultGeneralExpenseType,
  DefaultSubcontractType,
  DefaultQuoteType,
  DefaultIndirectLaborType,
} from "../../api/protosCompiled/defaultCloseout/defaultCloseout_pb";
import { DefaultDirectLabor } from "./defaultTypeRows/DefaultDirectLabor";
import { DefaultLaborFactoring } from "./defaultTypeRows/DefaultLaborFactoring";
import { DefaultIncidentalLabor } from "./defaultTypeRows/DefaultIncidentalLabor";
import { DefaultEquipment } from "./defaultTypeRows/DefaultEquipment";
import { DefaultGeneralExpense } from "./defaultTypeRows/DefaultGeneralExpense";
import { DefaultSubcontract } from "./defaultTypeRows/DefaultSubcontract";
import { DefaultQuote } from "./defaultTypeRows/DefaultQuote";
import { DefaultIndirectLabor } from "./defaultTypeRows/DefaultIndirectLabor";

export const renderTable = (
  selectedType: DefaultCloseoutTypeKey,
  clearFocus: () => void,
  isClearingFocus: boolean
) => {
  switch (selectedType) {
    case "directlaborList":
      return <DefaultDirectLabor isClearingFocus={isClearingFocus} />;
    case "indirectlaborList":
      return <DefaultIndirectLabor isClearingFocus={isClearingFocus} />;
    case "laborfactoringList":
      return <DefaultLaborFactoring isClearingFocus={isClearingFocus} />;
    case "incidentallaborList":
      return <DefaultIncidentalLabor isClearingFocus={isClearingFocus} />;
    case "equipmentexpenseList":
      return <DefaultEquipment isClearingFocus={isClearingFocus} />;
    case "generalexpenseList":
      return <DefaultGeneralExpense isClearingFocus={isClearingFocus} />;
    case "subcontractexpenseList":
      return <DefaultSubcontract isClearingFocus={isClearingFocus} />;
    case "quoteexpenseList":
      return <DefaultQuote isClearingFocus={isClearingFocus} />;
    default:
      return null;
  }
};

type HandleRemoveRowProps = {
  selectedType: DefaultCloseoutTypeKey;
  rows: any[];
  setRows: (rows: any[]) => void;
};

/**
 * Add a new row to the current array
 */
export const handleAddRow = ({
  selectedType,
  rows,
  setRows,
}: HandleRemoveRowProps) => {
  if (selectedType === "directlaborList") {
    const newRow: DefaultDirectLaborType.AsObject = {
      id: "",
      name: "",
      laborrate: 0,
      burdenpercent: 0,
      fringe: 0,
      distributionpercent: 0,
    };
    rows.push(newRow);
  } else if (selectedType === "laborfactoringList") {
    const newRow: DefaultLaborFactoringType.AsObject = {
      id: "",
      name: "",
      laborrate: 0,
      burdenpercent: 0,
      fringe: 0,
      impactpercent: 0,
      laborpercent: 0,
    };
    rows.push(newRow);
  } else if (selectedType === "incidentallaborList") {
    const newRow: DefaultIncidentalLaborType.AsObject = {
      id: "",
      name: "",
      laborrate: 0,
      burdenpercent: 0,
      fringe: 0,
    };
    rows.push(newRow);
  } else if (selectedType === "indirectlaborList") {
    const newRow: DefaultIndirectLaborType.AsObject = {
      id: "",
      name: "",
      laborrate: 0,
      burdenpercent: 0,
      fringe: 0,
    };
    rows.push(newRow);
  } else if (selectedType === "equipmentexpenseList") {
    const newRow: DefaultEquipmentType.AsObject = {
      id: "",
      name: "",
      unitcost: 0,
    };
    rows.push(newRow);
  } else if (selectedType === "generalexpenseList") {
    const newRow: DefaultGeneralExpenseType.AsObject = {
      id: "",
      name: "",
      unitcost: 0,
    };
    rows.push(newRow);
  } else if (selectedType === "subcontractexpenseList") {
    const newRow: DefaultSubcontractType.AsObject = {
      id: "",
      name: "",
      quotedcost: 0,
      adjustedpercent: 0,
    };
    rows.push(newRow);
  } else if (selectedType === "quoteexpenseList") {
    const newRow: DefaultQuoteType.AsObject = {
      id: "",
      quotegroup: "",
    };
    rows.push(newRow);
  }

  setRows([...rows]);
};

export const handleRemoveRow = (idx: number, rows: any[], setRows: any) => {
  const updated = [...rows];
  updated.splice(idx, 1);
  setRows(updated);
};

export const handleFieldChange = (
  idx: number,
  field: string,
  value: any,
  rows: any[],
  setRows: any
) => {
  const updatedRows = [...rows];
  const rowCopy = { ...updatedRows[idx] };
  (rowCopy as any)[field] = value;
  updatedRows[idx] = rowCopy;
  setRows(updatedRows);
};

export const validateDecimalInput = (value: string): boolean => {
  const decimalRegex = /^[0-9]*\.?[0-9]{0,2}$/;
  return decimalRegex.test(value);
};

export const formatToTwoDecimalPlaces = (value: string | number): string => {
  const numValue = typeof value === "string" ? parseFloat(value) : value;
  return isNaN(numValue) ? "0.00" : numValue.toFixed(2);
};
