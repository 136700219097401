import { useEffect, useMemo, useState } from "react";
import { IndirectLaborRow } from "./IndirectLaborRow";
import { Calculations as c } from "../../utils/calculations";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { IndirectLaborType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { recalculateIndirectLabor } from "./closeoutHelpers";
import { useGetDefaultIndirectLaborTypes } from "../../hooks/useDefaultCloseoutHooks";

export const IndirectLaborHeader = ({
  labor,
}: {
  labor: IndirectLaborType.AsObject[];
}) => {
  const {
    includedExtentionTypes,
    setIncludedIndirectLaborTypes,
    updateIndirectLaborType,
  } = useStore(useCloseoutStore);

  const { data: laborDefaults } = useGetDefaultIndirectLaborTypes();

  const [selectedLaborType, setSelectedLaborType] = useState<string>("");

  const totalLaborHours = useMemo(() => {
    return includedExtentionTypes.reduce(
      (total, item) => total + item.totallaborhours,
      0
    );
  }, [includedExtentionTypes]);

  useEffect(() => {
    recalculateIndirectLabor(
      labor,
      totalLaborHours,
      setIncludedIndirectLaborTypes
    );
  }, [labor, totalLaborHours, setIncludedIndirectLaborTypes]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value as string;

    // Ensure laborDefaults is defined before proceeding
    if (!laborDefaults) {
      console.warn("Labor defaults are undefined.");
      return;
    }

    const defaultLaborType = laborDefaults.find(
      (laborType) => laborType.name === newValue
    );

    if (defaultLaborType) {
      // Merge DefaultIndirectLaborType and IndirectLaborType
      const newLaborType: IndirectLaborType.AsObject = {
        name: defaultLaborType.name,
        laborrate: defaultLaborType.laborrate || 0,
        burdenpercent: defaultLaborType.burdenpercent / 100 || 0,
        fringe: defaultLaborType.fringe || 0,
        distributionpercent: 0, // Default to 0 for new entries
        allocatedhours: c.calculateAllocatedHours(0, 0), // Start with 0 hours
        totalcost: c.calculateFullLaborCost({
          allocatedhours: 0, // Use initial value of 0
          laborrate: defaultLaborType.laborrate || 0,
          burdenpercent: defaultLaborType.burdenpercent / 100 || 0,
          fringe: defaultLaborType.fringe || 0,
        }),
      };

      if (!labor.some((labor) => labor.name === newValue)) {
        const newIncludedLaborTypes = [...labor, newLaborType];
        setIncludedIndirectLaborTypes(newIncludedLaborTypes);
        setSelectedLaborType("");
      }
    }
  };

  const handleDelete = (laborName: string) => {
    const newIncludedLaborTypes = labor.filter((l) => l.name !== laborName);
    setIncludedIndirectLaborTypes(newIncludedLaborTypes);
  };

  const handleLaborDistribution = (
    updatedLabor: IndirectLaborType.AsObject,
    index: number
  ) => {
    const newIncludedLaborTypes = labor.map((laborType, idx) =>
      idx === index ? updatedLabor : laborType
    );

    const updatedLaborType = newIncludedLaborTypes[index];
    updatedLaborType.allocatedhours = c.calculateAllocatedHours(
      totalLaborHours,
      updatedLaborType.distributionpercent || 0
    );

    setIncludedIndirectLaborTypes(newIncludedLaborTypes);
  };

  const handleFieldUpdate = (field: string, value: number, index: number) => {
    const updatedLabor = {
      ...labor[index],
      [field]: value,
    };

    if (field === "distributionpercent") {
      updatedLabor.allocatedhours = c.calculateAllocatedHours(
        totalLaborHours,
        updatedLabor.distributionpercent || 0
      );
    }

    const updatedLaborTypes = [
      ...labor.slice(0, index),
      updatedLabor,
      ...labor.slice(index + 1),
    ];
    setIncludedIndirectLaborTypes(updatedLaborTypes);
    updateIndirectLaborType(index, updatedLabor);
  };

  return (
    <>
      <select value={selectedLaborType} onChange={handleChange}>
        <option value="" disabled selected hidden>
          Select labor type
        </option>
        {laborDefaults &&
          laborDefaults
            .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
            .map((laborType, index) => (
              <option value={laborType.name} key={index}>
                {laborType.name}
              </option>
            ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Hours: {totalLaborHours.toFixed(2)}</th>
              <th>% of Direct Labor</th>
              <th>Labor Rate</th>
              <th>Subtotal</th>
              <th>Burden %</th>
              <th>Fringe</th>
              <th>Burden Total</th>
              <th>Fringe Total</th>
              <th>Full Hourly Cost</th>
              <th>Full Cost</th>
            </tr>
          </thead>
          <tbody>
            {labor.map((labor, index) => (
              <IndirectLaborRow
                key={index}
                labor={labor}
                totalLaborHours={totalLaborHours}
                handleDelete={handleDelete}
                handleDistributionChange={(updatedLabor) =>
                  handleLaborDistribution(updatedLabor, index)
                }
                handleFieldUpdate={(field, value) =>
                  handleFieldUpdate(field, value, index)
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
