import { create } from "zustand";

export type KeyBoardEventStore = {
  ctrlDown: boolean;
  setCtrlDown: (ctrlDown: boolean) => void;

  shiftDown: boolean;
  setShiftDown: (shiftDown: boolean) => void;
};

export const useKeyBoardEventStore = create<KeyBoardEventStore>((set) => ({
  ctrlDown: true,
  setCtrlDown: (ctrlDown) => set({ ctrlDown }),

  shiftDown: true,
  setShiftDown: (shiftDown) => set({ shiftDown }),
}));
