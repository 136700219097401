import React, { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { useSetSelectedAssemblyFromAssemblyPicker } from "../../hooks/useSetSelectedAssembly";
import {
  AssemblyItemType,
  BreakOut,
  TakeoffAssembly,
  TakeoffAssemblyAttribute,
} from "../../types/AssemblyItemType";
import { Dialog, DialogContent } from "@mui/material";
import { ItemQtyFormula } from "../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { useGlobalAssemblyStore } from "../../states/globalAssemblyStore";
import { CreateAssembly } from "../assembliesAndItems/assemblies/CreateAssembly";
import { AssemblyOptions } from "../../types/FolderTypes";
import { useGlobalItemStore } from "../../states/globalItemStore";
import {
  EditAssemblyProps,
  useEditAssembly,
} from "../assembliesAndItems/assemblies/AssemblyTable";
import { TakeoffItemQtyFormula } from "../../types/AssemblyItemType";
import {
  useFetchChangeOrderBreakouts,
  useFetchEstimateBreakouts,
} from "../../hooks/useFetchBreakout";
import { ObjectService } from "../../api/ObjectService";

export const getItemQtyByTakeoffVariable = (
  assembly: TakeoffAssembly,
  formula: ItemQtyFormula.AsObject
): number => {
  let totalQty = 0;
  // Calculate quantity based on the takeoff variable type of the formula
  switch (formula.takeoffvariabletype) {
    case "point":
      totalQty = Math.round(
        assembly.pointcount *
          formula.itemqty *
          assembly.itemqtyformulasList[0]?.breakoutsList?.[0]?.multiplier || 1
      );
      break;

    case "segment":
      totalQty = Math.round(
        assembly.segmentcount *
          formula.itemqty *
          assembly.itemqtyformulasList[0]?.breakoutsList?.[0]?.multiplier || 1
      );
      break;

    case "length":
      totalQty = Math.round(
        (assembly.segmentlength *
          formula.itemqty *
          assembly.itemqtyformulasList[0]?.breakoutsList?.[0]?.multiplier ||
          1) / formula.forevery
      );
      break;
    case "area":
      totalQty = Math.round(
        assembly.sqft *
          formula.itemqty *
          assembly.itemqtyformulasList[0]?.breakoutsList?.[0]?.multiplier || 1
      );
      break;

    default:
      totalQty = 0;
      break;
  }

  if (totalQty < 0) {
    totalQty = 0;
  }

  return totalQty;
};

type RenderTakeoffListProps = {
  searchTerm: string;
  setFilter: (filter: string) => void;
};

export function RenderTakeoffList({
  searchTerm,
  setFilter,
}: RenderTakeoffListProps) {
  const {
    selectedObjectId,
    currentPage,
    selectedEstimate,
    selectedChangeOrder,
    selectedFilter,
    userBackgroundColor,
    countedAssemblies,
    selectedTakeoffAssembly,
    breakoutFilterObject,
    setBreakoutFilterObject,
    setFilteredCountedAssemblies,
  } = useStore(useUnityBuildStore);
  const {
    isViewingAssemblyForm,
    setCurrentAssembly,
    setIsViewingAssemblyForm,
  } = useStore(useGlobalAssemblyStore);

  const { setIsViewingItemForm, setIsCreatingItem } =
    useStore(useGlobalItemStore);

  const { assemblyOption } = useStore(useGlobalAssemblyStore);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const setSelectedAssembly = useSetSelectedAssemblyFromAssemblyPicker();

  const [isRenaming, setIsRenaming] = useState(false);
  const [renameValue, setRenameValue] = useState("");
  // Local breakout filter state.
  // const [breakout, setBreakout] = useState<BreakOut | undefined>();
  const [localBreakouts, setLocalBreakouts] = useState<BreakOut[]>([]);

  const handleSetAssembly = (assembly: any) => {
    setSelectedAssembly(assembly);
  };

  const handleRenameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenameValue(e.target.value);
  };

  const handleRenameSubmit = () => {
    setIsRenaming(false);
  };

  const editAssembly = useEditAssembly();

  const handleEditAssembly = (props: EditAssemblyProps) => {
    setSelectedAssembly(props.assembly);
    editAssembly(props);
  };

  // Toggle expanded rows
  const handleToggleExpandRow = (assemblyId: string) => {
    if (expandedRows.includes(assemblyId)) {
      setExpandedRows(expandedRows.filter((id) => id !== assemblyId));
    } else {
      setExpandedRows([...expandedRows, assemblyId]);
    }
  };

  const hasQuotedItems = (assembly: AssemblyItemType.AssemblyAsObject) =>
    assembly?.itemqtyformulasList.some((formula) => formula.isquoted === true);

  const hasMultiplierOverOne = (assembly: AssemblyItemType.AssemblyAsObject) =>
    assembly?.itemqtyformulasList[0]?.breakoutsList[0]?.multiplier > 1;

  // Fetch breakout lists from your hooks.
  const { data: estimatebreakouts } = useFetchEstimateBreakouts(
    selectedEstimate?.estimateid ?? ""
  );
  const { data: changeOrderBreakouts } = useFetchChangeOrderBreakouts(
    selectedChangeOrder?.changeorderid ?? ""
  );

  useEffect(() => {
    if (estimatebreakouts || changeOrderBreakouts) {
      setLocalBreakouts(
        estimatebreakouts?.breakoutsList ??
          changeOrderBreakouts?.breakoutsList ??
          []
      );
    }
  }, [estimatebreakouts, changeOrderBreakouts]);

  // When no breakout filter is active, reset filteredCountedAssemblies.
  useEffect(() => {
    if (
      !breakoutFilterObject &&
      countedAssemblies &&
      selectedObjectId &&
      currentPage
    ) {
      const assemblies = ObjectService.filterAssemblyByObjectIdandPageNumber(
        countedAssemblies,
        selectedObjectId,
        currentPage
      );
      setFilteredCountedAssemblies(assemblies);
    }
    //eslint-disable-next-line
  }, [breakoutFilterObject]);

  // Calculate displayed assemblies based on the breakout filter.
  // Here we assume that UBStore.allCountedAssemblies holds the complete list.
  const displayedAssemblies = breakoutFilterObject
    ? countedAssemblies?.filter(
        (assembly: any) =>
          assembly.itemqtyformulasList?.[0]?.breakoutsList?.[0]?.breakoutid ===
          breakoutFilterObject.id
      )
    : countedAssemblies;

  // When the breakout filter changes, update the global store's filteredCountedAssemblies.
  const handleSetBreakoutFilter = (filter: string) => {
    const selectedBreakout = localBreakouts.find((b) => b.id === filter);
    setBreakoutFilterObject(selectedBreakout || null);
    // Set the filter text as needed (for example "all" for no filter)
    // setFilter(selectedBreakout ? selectedBreakout.breakoutname : "all");
    // Update the filtered assemblies in global state.
    if (selectedBreakout && countedAssemblies) {
      const assemblies = ObjectService.filterAssemblyByObjectIdandPageNumber(
        countedAssemblies,
        selectedObjectId,
        currentPage
      );
      const filtered = assemblies?.filter(
        (assembly: any) =>
          assembly.itemqtyformulasList?.[0]?.breakoutsList?.[0]?.breakoutid ===
          selectedBreakout.id
      );
      setFilteredCountedAssemblies(filtered as any);
    } else {
      // No breakout filter selected, revert to full list.
      if (countedAssemblies && selectedObjectId && currentPage) {
        const assemblies = ObjectService.filterAssemblyByObjectIdandPageNumber(
          countedAssemblies,
          selectedObjectId,
          currentPage
        );
        setFilteredCountedAssemblies(assemblies);
      }
    }
  };

  return (
    <div
      className="TakeoffPalletteChildBox"
      style={{ backgroundColor: userBackgroundColor }}
    >
      {/* Render CreateAssembly when selectedTakeoffAssembly is set and menuOption is "Edit Assembly" */}
      <Dialog
        open={isViewingAssemblyForm && assemblyOption === "takeoff"}
        fullScreen={true}
        style={{
          height: "100vh",
          backgroundColor: userBackgroundColor,
        }}
      >
        <DialogContent>
          <CreateAssembly
            assemblyOption={assemblyOption as AssemblyOptions}
            takeoffAssembly={
              selectedTakeoffAssembly as AssemblyItemType.AssemblyAsObject
            }
          />
        </DialogContent>
      </Dialog>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <tr style={{ backgroundColor: "#f2f2f2", width: "100%" }}>
          <select
            onChange={(e) => handleSetBreakoutFilter(e.target.value)}
            value={breakoutFilterObject?.id || ""}
            style={{ width: "95%" }}
          >
            <option value="">Select Breakout Filter</option>
            {localBreakouts?.map((breakout) => (
              <option key={breakout.id} value={breakout.id}>
                {breakout.breakoutname}
              </option>
            ))}
          </select>
          <select
            onChange={(e) => setFilter(e.target.value)}
            value={selectedFilter}
            style={{ width: "95%" }}
          >
            <option value="all">Select Takeoff Type Filter</option>
            <option value="count">Count</option>
            <option value="length">Length</option>
            <option value="area">Area</option>
          </select>
        </tr>
      </div>

      {/* Existing table rendering assemblies */}
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "60%" }}>Description</th>
            {selectedFilter === "all" && (
              <>
                <th>Count</th>
                <th>Length</th>
                <th>Area</th>
              </>
            )}
            {selectedFilter === "count" && <th>Count</th>}
            {selectedFilter === "length" && (
              <>
                <th>Count</th>
                <th>Length</th>
              </>
            )}
            {selectedFilter === "area" && (
              <>
                <th>Count</th>
                <th>Length</th>
                <th>Area</th>
              </>
            )}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="TakeoffTableBody">
          {displayedAssemblies
            ?.filter((assembly: TakeoffAssembly) => {
              const matchesFilter =
                (selectedFilter === "all" ||
                  assembly.assemblymeasurementtype === selectedFilter) &&
                assembly.objectid === selectedObjectId;
              const matchesSearch = assembly.assemblyname?.includes(searchTerm);
              return matchesFilter && matchesSearch;
            })
            .map((assembly: any) => {
              const segmentlength = assembly.segmentlength ?? 0;
              const feet = Math.round(segmentlength);
              const isExpanded = expandedRows.includes(assembly.takeoffid);

              return (
                <React.Fragment key={assembly.takeoffid}>
                  <tr className="hoverable-row">
                    <td
                      onClick={() => handleSetAssembly(assembly)}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          assembly?.itemqtyformulasList?.length === 0
                            ? "#ffcccb"
                            : assembly?.itemqtyformulasList?.[0]
                                ?.breakoutsList?.[0]?.multiplier !== 1
                            ? "lightGreen"
                            : selectedTakeoffAssembly?.takeoffid ===
                              assembly.takeoffid
                            ? "#add8e6"
                            : undefined,
                      }}
                    >
                      {isRenaming &&
                      selectedTakeoffAssembly?.takeoffid ===
                        assembly.takeoffid ? (
                        <input
                          type="text"
                          value={renameValue}
                          onChange={handleRenameChange}
                          onBlur={handleRenameSubmit}
                          autoFocus
                        />
                      ) : (
                        `${
                          hasQuotedItems(
                            assembly as AssemblyItemType.AssemblyAsObject
                          )
                            ? "Quoted, "
                            : ""
                        }${
                          hasMultiplierOverOne(assembly)
                            ? `(${assembly.itemqtyformulasList[0].breakoutsList[0].multiplier}x), `
                            : ""
                        }
                        ${assembly.assemblyname}`
                      )}
                    </td>

                    {selectedFilter === "all" && (
                      <>
                        <td>{assembly.pointcount}</td>
                        <td>{`${feet}'`}</td>
                        <td>{assembly.sqft.toFixed(2)}</td>
                      </>
                    )}
                    {selectedFilter === "count" && (
                      <td>{assembly.pointcount}</td>
                    )}
                    {selectedFilter === "length" && (
                      <>
                        <td>{assembly.pointcount}</td>
                        <td>{`${feet}'`}</td>
                      </>
                    )}
                    {selectedFilter === "area" && (
                      <>
                        <td>{assembly.pointcount}</td>
                        <td>{`${feet}'`}</td>
                        <td>{assembly.sqft.toFixed(2)}</td>
                      </>
                    )}
                    <td>
                      <button
                        onClick={() =>
                          handleToggleExpandRow(assembly.takeoffid)
                        }
                      >
                        {isExpanded ? "-" : "+"}
                      </button>
                      <button
                        onClick={(e) =>
                          handleEditAssembly({
                            e: e,
                            assembly: assembly as any,
                            setCurrentAssembly: setCurrentAssembly as any,
                            setIsViewingAssemblyForm:
                              setIsViewingAssemblyForm as any,
                            setIsViewingItemForm: setIsViewingItemForm as any,
                            setIsCreatingItem: setIsCreatingItem as any,
                            assemblyOption: "takeoff",
                          })
                        }
                      >
                        Edit
                      </button>
                    </td>
                  </tr>

                  {/* Expanded Row */}
                  {isExpanded && (
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td colSpan={6}>
                        <table
                          style={{
                            width: "95%",
                            marginLeft: "20px",
                          }}
                        >
                          <thead>
                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                              {`Breakout: ${assembly.itemqtyformulasList[0]?.breakoutsList[0]?.breakoutname}`}
                            </tr>
                            <tr>
                              <th>Item Name</th>
                              <th>QTY</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assembly?.itemqtyformulasList.map(
                              (item: TakeoffItemQtyFormula, idx: number) => (
                                <tr
                                  key={`${assembly.takeoffid}-${idx}`}
                                  className="hoverable-row"
                                >
                                  <td>
                                    {item?.attributesList &&
                                    item.attributesList.length > 0
                                      ? item.attributesList
                                          .map(
                                            (attr: TakeoffAssemblyAttribute) =>
                                              `${attr.attributevaluename} - ${item.itemname}`
                                          )
                                          .join(", ")
                                      : item.itemname}
                                  </td>
                                  {/* Using the updated function to calculate the item quantity for each item */}
                                  <td>
                                    {getItemQtyByTakeoffVariable(
                                      assembly,
                                      item
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
