import React from "react";
import { AttributeGroup } from "../../../../api/protosCompiled/globalItem/globalItem_pb";
import { UB_AccountUser } from "../../../../api/protosCompiled/ub_users/ub_users_pb";

interface AttributesTableProps {
  attributeGroups: AttributeGroup.AsObject[];
  user: UB_AccountUser.AsObject | null;
  onEdit: (attributeGroup: AttributeGroup.AsObject) => void;
  onDelete: (attributeGroupId: number) => void;
}

export const AttributesTable: React.FC<AttributesTableProps> = ({
  attributeGroups,
  user,
  onEdit,
  onDelete,
}) => {
  return (
    <div
      className="scrollableCDiv"
      style={{
        height: "500px",
        border: "1px solid black",
        margin: "5px",
      }}
    >
      <table>
        <thead>
          <tr>
            <th>Attribute Groups</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {user &&
            attributeGroups.map((attributeGroup) => (
              <tr key={attributeGroup.attributegroupid}>
                <td>{attributeGroup.attributegroupname}</td>
                <td>
                  <button
                    onClick={() => onEdit(attributeGroup)}
                    // disabled={userIsDevAccount(
                    //   user?.ubaccountid,
                    //   user?.user as UB_Account_User.AsObject
                    // )}
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => onDelete(attributeGroup.attributegroupid)}
                    // disabled={userIsDevAccount(
                    //   user?.ubaccountid,
                    //   user?.user as UB_Account_User.AsObject
                    // )}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
