import { useQuery } from "react-query";
import { useUnityBuildStore } from "../states/store";

import { useStore } from "zustand";
import { UbUserService } from "../api/UbUserService";

export function useFetchUsers() {
  const { setUserList } = useStore(useUnityBuildStore);

  const { data, error, isLoading } = useQuery(
    "users",
    async () => {
      const response = await UbUserService.getAccountUsers();
      return response.usersList;
    },
    {
      onSuccess: (users) => {
        setUserList(users);
      },
    }
  );

  return { data, error, isLoading };
}

export function useFetchUser() {
  const { data, error, isLoading } = useQuery(
    "user",
    async () => {
      const response = await UbUserService.getAccountUser();
      return response.user;
    },
    {
      enabled: !!useUnityBuildStore.getState().sessionToken,
    }
  );
  return { data, error, isLoading };
}
