// source: estimateCloseout.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.CreateEstimateCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateEstimateCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateOrUpdateEstimateCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateEstimateCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteEstimateCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteEstimateCloseoutResponse', null, global);
goog.exportSymbol('proto.DirectLaborType', null, global);
goog.exportSymbol('proto.EquipmentType', null, global);
goog.exportSymbol('proto.EstimateCloseout', null, global);
goog.exportSymbol('proto.ExtensionType', null, global);
goog.exportSymbol('proto.GeneralExpenseType', null, global);
goog.exportSymbol('proto.GetEstimateCloseoutRequest', null, global);
goog.exportSymbol('proto.GetEstimateCloseoutResponse', null, global);
goog.exportSymbol('proto.GetEstimateCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetEstimateCloseoutsResponse', null, global);
goog.exportSymbol('proto.IncidentalLaborType', null, global);
goog.exportSymbol('proto.IndirectLaborType', null, global);
goog.exportSymbol('proto.LaborFactoringType', null, global);
goog.exportSymbol('proto.QuoteItem', null, global);
goog.exportSymbol('proto.QuoteType', null, global);
goog.exportSymbol('proto.SubcontractType', null, global);
goog.exportSymbol('proto.TotalFinalPricingData', null, global);
goog.exportSymbol('proto.TotalFinalRowData', null, global);
goog.exportSymbol('proto.TotalPricingType', null, global);
goog.exportSymbol('proto.TotalRowPricingData', null, global);
goog.exportSymbol('proto.UpdateEstimateCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateEstimateCloseoutResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExtensionType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ExtensionType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExtensionType.displayName = 'proto.ExtensionType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DirectLaborType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DirectLaborType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DirectLaborType.displayName = 'proto.DirectLaborType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LaborFactoringType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LaborFactoringType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LaborFactoringType.displayName = 'proto.LaborFactoringType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IncidentalLaborType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IncidentalLaborType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IncidentalLaborType.displayName = 'proto.IncidentalLaborType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndirectLaborType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IndirectLaborType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndirectLaborType.displayName = 'proto.IndirectLaborType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EquipmentType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EquipmentType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EquipmentType.displayName = 'proto.EquipmentType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GeneralExpenseType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GeneralExpenseType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GeneralExpenseType.displayName = 'proto.GeneralExpenseType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubcontractType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SubcontractType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubcontractType.displayName = 'proto.SubcontractType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QuoteItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.QuoteItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QuoteItem.displayName = 'proto.QuoteItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QuoteType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.QuoteType.repeatedFields_, null);
};
goog.inherits(proto.QuoteType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QuoteType.displayName = 'proto.QuoteType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TotalRowPricingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TotalRowPricingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TotalRowPricingData.displayName = 'proto.TotalRowPricingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TotalPricingType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TotalPricingType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TotalPricingType.displayName = 'proto.TotalPricingType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TotalFinalPricingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TotalFinalPricingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TotalFinalPricingData.displayName = 'proto.TotalFinalPricingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TotalFinalRowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TotalFinalRowData.repeatedFields_, null);
};
goog.inherits(proto.TotalFinalRowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TotalFinalRowData.displayName = 'proto.TotalFinalRowData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EstimateCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EstimateCloseout.repeatedFields_, null);
};
goog.inherits(proto.EstimateCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EstimateCloseout.displayName = 'proto.EstimateCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateEstimateCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateEstimateCloseoutRequest.repeatedFields_, null);
};
goog.inherits(proto.CreateEstimateCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateEstimateCloseoutRequest.displayName = 'proto.CreateEstimateCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateEstimateCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateEstimateCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateEstimateCloseoutResponse.displayName = 'proto.CreateEstimateCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateEstimateCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateOrUpdateEstimateCloseoutRequest.repeatedFields_, null);
};
goog.inherits(proto.CreateOrUpdateEstimateCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateEstimateCloseoutRequest.displayName = 'proto.CreateOrUpdateEstimateCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateEstimateCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateEstimateCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateEstimateCloseoutResponse.displayName = 'proto.CreateOrUpdateEstimateCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetEstimateCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetEstimateCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetEstimateCloseoutRequest.displayName = 'proto.GetEstimateCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetEstimateCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetEstimateCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetEstimateCloseoutResponse.displayName = 'proto.GetEstimateCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetEstimateCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetEstimateCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetEstimateCloseoutsRequest.displayName = 'proto.GetEstimateCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetEstimateCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetEstimateCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetEstimateCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetEstimateCloseoutsResponse.displayName = 'proto.GetEstimateCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateEstimateCloseoutRequest.repeatedFields_, null);
};
goog.inherits(proto.UpdateEstimateCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateCloseoutRequest.displayName = 'proto.UpdateEstimateCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateEstimateCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateEstimateCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateEstimateCloseoutResponse.displayName = 'proto.UpdateEstimateCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteEstimateCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteEstimateCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteEstimateCloseoutRequest.displayName = 'proto.DeleteEstimateCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteEstimateCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteEstimateCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteEstimateCloseoutResponse.displayName = 'proto.DeleteEstimateCloseoutResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExtensionType.prototype.toObject = function(opt_includeInstance) {
  return proto.ExtensionType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExtensionType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtensionType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    itemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overrideunitcost: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    overrideproductionrate: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totallaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    itemuom: jspb.Message.getFieldWithDefault(msg, 9, ""),
    itemuomvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    quotegroup: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExtensionType}
 */
proto.ExtensionType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExtensionType;
  return proto.ExtensionType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExtensionType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExtensionType}
 */
proto.ExtensionType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemunitcost(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverrideunitcost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyproductionrate(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverrideproductionrate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotallaborhours(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemuom(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemuomvalue(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExtensionType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExtensionType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExtensionType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtensionType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getItemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverrideunitcost();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getOverrideproductionrate();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTotallaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getItemuom();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getItemuomvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ExtensionType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float itemQty = 2;
 * @return {number}
 */
proto.ExtensionType.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float itemUnitCost = 3;
 * @return {number}
 */
proto.ExtensionType.prototype.getItemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setItemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool overrideUnitCost = 4;
 * @return {boolean}
 */
proto.ExtensionType.prototype.getOverrideunitcost = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setOverrideunitcost = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional float hourlyProductionRate = 5;
 * @return {number}
 */
proto.ExtensionType.prototype.getHourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setHourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool overrideProductionRate = 6;
 * @return {boolean}
 */
proto.ExtensionType.prototype.getOverrideproductionrate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setOverrideproductionrate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional float totalCost = 7;
 * @return {number}
 */
proto.ExtensionType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float totalLaborHours = 8;
 * @return {number}
 */
proto.ExtensionType.prototype.getTotallaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setTotallaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string itemUom = 9;
 * @return {string}
 */
proto.ExtensionType.prototype.getItemuom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setItemuom = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional float itemUomValue = 10;
 * @return {number}
 */
proto.ExtensionType.prototype.getItemuomvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setItemuomvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional bool isQuoted = 11;
 * @return {boolean}
 */
proto.ExtensionType.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string attributeGroupName = 12;
 * @return {string}
 */
proto.ExtensionType.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string quoteGroup = 13;
 * @return {string}
 */
proto.ExtensionType.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExtensionType} returns this
 */
proto.ExtensionType.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DirectLaborType.prototype.toObject = function(opt_includeInstance) {
  return proto.DirectLaborType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DirectLaborType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    distributionpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DirectLaborType}
 */
proto.DirectLaborType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DirectLaborType;
  return proto.DirectLaborType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DirectLaborType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DirectLaborType}
 */
proto.DirectLaborType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistributionpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DirectLaborType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DirectLaborType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DirectLaborType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DirectLaborType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getDistributionpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.DirectLaborType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DirectLaborType} returns this
 */
proto.DirectLaborType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float allocatedHours = 2;
 * @return {number}
 */
proto.DirectLaborType.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborType} returns this
 */
proto.DirectLaborType.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float laborRate = 3;
 * @return {number}
 */
proto.DirectLaborType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborType} returns this
 */
proto.DirectLaborType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float burdenPercent = 4;
 * @return {number}
 */
proto.DirectLaborType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborType} returns this
 */
proto.DirectLaborType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float fringe = 5;
 * @return {number}
 */
proto.DirectLaborType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborType} returns this
 */
proto.DirectLaborType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float distributionPercent = 6;
 * @return {number}
 */
proto.DirectLaborType.prototype.getDistributionpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborType} returns this
 */
proto.DirectLaborType.prototype.setDistributionpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalCost = 7;
 * @return {number}
 */
proto.DirectLaborType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DirectLaborType} returns this
 */
proto.DirectLaborType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LaborFactoringType.prototype.toObject = function(opt_includeInstance) {
  return proto.LaborFactoringType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LaborFactoringType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    impactpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    laborpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    distributionpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LaborFactoringType}
 */
proto.LaborFactoringType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LaborFactoringType;
  return proto.LaborFactoringType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LaborFactoringType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LaborFactoringType}
 */
proto.LaborFactoringType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImpactpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborpercent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistributionpercent(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LaborFactoringType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LaborFactoringType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LaborFactoringType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaborFactoringType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getImpactpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getLaborpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDistributionpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.LaborFactoringType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LaborFactoringType} returns this
 */
proto.LaborFactoringType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float allocatedHours = 2;
 * @return {number}
 */
proto.LaborFactoringType.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringType} returns this
 */
proto.LaborFactoringType.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float laborRate = 3;
 * @return {number}
 */
proto.LaborFactoringType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringType} returns this
 */
proto.LaborFactoringType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float burdenPercent = 4;
 * @return {number}
 */
proto.LaborFactoringType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringType} returns this
 */
proto.LaborFactoringType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float fringe = 5;
 * @return {number}
 */
proto.LaborFactoringType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringType} returns this
 */
proto.LaborFactoringType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float impactPercent = 6;
 * @return {number}
 */
proto.LaborFactoringType.prototype.getImpactpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringType} returns this
 */
proto.LaborFactoringType.prototype.setImpactpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float laborPercent = 7;
 * @return {number}
 */
proto.LaborFactoringType.prototype.getLaborpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringType} returns this
 */
proto.LaborFactoringType.prototype.setLaborpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float distributionPercent = 8;
 * @return {number}
 */
proto.LaborFactoringType.prototype.getDistributionpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringType} returns this
 */
proto.LaborFactoringType.prototype.setDistributionpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.LaborFactoringType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LaborFactoringType} returns this
 */
proto.LaborFactoringType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IncidentalLaborType.prototype.toObject = function(opt_includeInstance) {
  return proto.IncidentalLaborType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IncidentalLaborType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IncidentalLaborType}
 */
proto.IncidentalLaborType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IncidentalLaborType;
  return proto.IncidentalLaborType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IncidentalLaborType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IncidentalLaborType}
 */
proto.IncidentalLaborType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IncidentalLaborType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IncidentalLaborType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IncidentalLaborType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IncidentalLaborType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.IncidentalLaborType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IncidentalLaborType} returns this
 */
proto.IncidentalLaborType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float allocatedHours = 2;
 * @return {number}
 */
proto.IncidentalLaborType.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborType} returns this
 */
proto.IncidentalLaborType.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float laborRate = 3;
 * @return {number}
 */
proto.IncidentalLaborType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborType} returns this
 */
proto.IncidentalLaborType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float burdenPercent = 4;
 * @return {number}
 */
proto.IncidentalLaborType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborType} returns this
 */
proto.IncidentalLaborType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float fringe = 5;
 * @return {number}
 */
proto.IncidentalLaborType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborType} returns this
 */
proto.IncidentalLaborType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float totalCost = 6;
 * @return {number}
 */
proto.IncidentalLaborType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IncidentalLaborType} returns this
 */
proto.IncidentalLaborType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndirectLaborType.prototype.toObject = function(opt_includeInstance) {
  return proto.IndirectLaborType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndirectLaborType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    distributionpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndirectLaborType}
 */
proto.IndirectLaborType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndirectLaborType;
  return proto.IndirectLaborType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndirectLaborType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndirectLaborType}
 */
proto.IndirectLaborType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistributionpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndirectLaborType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndirectLaborType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndirectLaborType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndirectLaborType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getDistributionpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.IndirectLaborType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndirectLaborType} returns this
 */
proto.IndirectLaborType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float allocatedHours = 2;
 * @return {number}
 */
proto.IndirectLaborType.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborType} returns this
 */
proto.IndirectLaborType.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float laborRate = 3;
 * @return {number}
 */
proto.IndirectLaborType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborType} returns this
 */
proto.IndirectLaborType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float burdenPercent = 4;
 * @return {number}
 */
proto.IndirectLaborType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborType} returns this
 */
proto.IndirectLaborType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float fringe = 5;
 * @return {number}
 */
proto.IndirectLaborType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborType} returns this
 */
proto.IndirectLaborType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float distributionPercent = 6;
 * @return {number}
 */
proto.IndirectLaborType.prototype.getDistributionpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborType} returns this
 */
proto.IndirectLaborType.prototype.setDistributionpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalCost = 7;
 * @return {number}
 */
proto.IndirectLaborType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndirectLaborType} returns this
 */
proto.IndirectLaborType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EquipmentType.prototype.toObject = function(opt_includeInstance) {
  return proto.EquipmentType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EquipmentType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EquipmentType}
 */
proto.EquipmentType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EquipmentType;
  return proto.EquipmentType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EquipmentType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EquipmentType}
 */
proto.EquipmentType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EquipmentType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EquipmentType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EquipmentType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EquipmentType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.EquipmentType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EquipmentType} returns this
 */
proto.EquipmentType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float quantity = 2;
 * @return {number}
 */
proto.EquipmentType.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentType} returns this
 */
proto.EquipmentType.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float duration = 3;
 * @return {number}
 */
proto.EquipmentType.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentType} returns this
 */
proto.EquipmentType.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float unitCost = 4;
 * @return {number}
 */
proto.EquipmentType.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentType} returns this
 */
proto.EquipmentType.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float totalCost = 5;
 * @return {number}
 */
proto.EquipmentType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.EquipmentType} returns this
 */
proto.EquipmentType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GeneralExpenseType.prototype.toObject = function(opt_includeInstance) {
  return proto.GeneralExpenseType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GeneralExpenseType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    durationmultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    durationmultipliername: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GeneralExpenseType}
 */
proto.GeneralExpenseType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GeneralExpenseType;
  return proto.GeneralExpenseType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GeneralExpenseType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GeneralExpenseType}
 */
proto.GeneralExpenseType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDurationmultiplier(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDurationmultipliername(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GeneralExpenseType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GeneralExpenseType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GeneralExpenseType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GeneralExpenseType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDurationmultiplier();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDurationmultipliername();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.GeneralExpenseType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseType} returns this
 */
proto.GeneralExpenseType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float quantity = 2;
 * @return {number}
 */
proto.GeneralExpenseType.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseType} returns this
 */
proto.GeneralExpenseType.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float duration = 3;
 * @return {number}
 */
proto.GeneralExpenseType.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseType} returns this
 */
proto.GeneralExpenseType.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float durationMultiplier = 4;
 * @return {number}
 */
proto.GeneralExpenseType.prototype.getDurationmultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseType} returns this
 */
proto.GeneralExpenseType.prototype.setDurationmultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string durationMultiplierName = 5;
 * @return {string}
 */
proto.GeneralExpenseType.prototype.getDurationmultipliername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GeneralExpenseType} returns this
 */
proto.GeneralExpenseType.prototype.setDurationmultipliername = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float unitCost = 6;
 * @return {number}
 */
proto.GeneralExpenseType.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseType} returns this
 */
proto.GeneralExpenseType.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalCost = 7;
 * @return {number}
 */
proto.GeneralExpenseType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GeneralExpenseType} returns this
 */
proto.GeneralExpenseType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SubcontractType.prototype.toObject = function(opt_includeInstance) {
  return proto.SubcontractType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SubcontractType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quotedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubcontractType}
 */
proto.SubcontractType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubcontractType;
  return proto.SubcontractType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubcontractType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubcontractType}
 */
proto.SubcontractType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuotedcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubcontractType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SubcontractType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubcontractType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubcontractType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuotedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.SubcontractType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SubcontractType} returns this
 */
proto.SubcontractType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float quotedCost = 2;
 * @return {number}
 */
proto.SubcontractType.prototype.getQuotedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractType} returns this
 */
proto.SubcontractType.prototype.setQuotedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float adjustedPercent = 3;
 * @return {number}
 */
proto.SubcontractType.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractType} returns this
 */
proto.SubcontractType.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float totalCost = 4;
 * @return {number}
 */
proto.SubcontractType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SubcontractType} returns this
 */
proto.SubcontractType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QuoteItem.prototype.toObject = function(opt_includeInstance) {
  return proto.QuoteItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QuoteItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    itemcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QuoteItem}
 */
proto.QuoteItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QuoteItem;
  return proto.QuoteItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QuoteItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QuoteItem}
 */
proto.QuoteItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QuoteItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QuoteItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QuoteItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getItemcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string itemName = 5;
 * @return {string}
 */
proto.QuoteItem.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteItem} returns this
 */
proto.QuoteItem.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float itemQty = 6;
 * @return {number}
 */
proto.QuoteItem.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteItem} returns this
 */
proto.QuoteItem.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float itemCost = 7;
 * @return {number}
 */
proto.QuoteItem.prototype.getItemcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteItem} returns this
 */
proto.QuoteItem.prototype.setItemcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.QuoteType.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QuoteType.prototype.toObject = function(opt_includeInstance) {
  return proto.QuoteType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QuoteType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteType.toObject = function(includeInstance, msg) {
  var f, obj = {
    quotegroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quotedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    isdefaulttype: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    quoteitemsList: jspb.Message.toObjectList(msg.getQuoteitemsList(),
    proto.QuoteItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QuoteType}
 */
proto.QuoteType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QuoteType;
  return proto.QuoteType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QuoteType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QuoteType}
 */
proto.QuoteType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuotedcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdefaulttype(value);
      break;
    case 6:
      var value = new proto.QuoteItem;
      reader.readMessage(value,proto.QuoteItem.deserializeBinaryFromReader);
      msg.addQuoteitems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QuoteType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QuoteType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QuoteType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuoteType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuotedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getIsdefaulttype();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getQuoteitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.QuoteItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string quotegroup = 1;
 * @return {string}
 */
proto.QuoteType.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.QuoteType} returns this
 */
proto.QuoteType.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float quotedCost = 2;
 * @return {number}
 */
proto.QuoteType.prototype.getQuotedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteType} returns this
 */
proto.QuoteType.prototype.setQuotedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float adjustedPercent = 3;
 * @return {number}
 */
proto.QuoteType.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteType} returns this
 */
proto.QuoteType.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float totalCost = 4;
 * @return {number}
 */
proto.QuoteType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QuoteType} returns this
 */
proto.QuoteType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool isDefaultType = 5;
 * @return {boolean}
 */
proto.QuoteType.prototype.getIsdefaulttype = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.QuoteType} returns this
 */
proto.QuoteType.prototype.setIsdefaulttype = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated QuoteItem quoteItems = 6;
 * @return {!Array<!proto.QuoteItem>}
 */
proto.QuoteType.prototype.getQuoteitemsList = function() {
  return /** @type{!Array<!proto.QuoteItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuoteItem, 6));
};


/**
 * @param {!Array<!proto.QuoteItem>} value
 * @return {!proto.QuoteType} returns this
*/
proto.QuoteType.prototype.setQuoteitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.QuoteItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuoteItem}
 */
proto.QuoteType.prototype.addQuoteitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.QuoteItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.QuoteType} returns this
 */
proto.QuoteType.prototype.clearQuoteitemsList = function() {
  return this.setQuoteitemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TotalRowPricingData.prototype.toObject = function(opt_includeInstance) {
  return proto.TotalRowPricingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TotalRowPricingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalRowPricingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overheadpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totaloverhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    markuppercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalmarkup: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TotalRowPricingData}
 */
proto.TotalRowPricingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TotalRowPricingData;
  return proto.TotalRowPricingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TotalRowPricingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TotalRowPricingData}
 */
proto.TotalRowPricingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setExtendedcost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaloverhead(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMarkuppercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalmarkup(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TotalRowPricingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TotalRowPricingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TotalRowPricingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalRowPricingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverheadpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTotaloverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMarkuppercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotalmarkup();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
};


/**
 * optional float extendedCost = 1;
 * @return {number}
 */
proto.TotalRowPricingData.prototype.getExtendedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalRowPricingData} returns this
 */
proto.TotalRowPricingData.prototype.setExtendedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float adjustedPercent = 2;
 * @return {number}
 */
proto.TotalRowPricingData.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalRowPricingData} returns this
 */
proto.TotalRowPricingData.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float adjustedCost = 3;
 * @return {number}
 */
proto.TotalRowPricingData.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalRowPricingData} returns this
 */
proto.TotalRowPricingData.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overheadPercent = 4;
 * @return {number}
 */
proto.TotalRowPricingData.prototype.getOverheadpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalRowPricingData} returns this
 */
proto.TotalRowPricingData.prototype.setOverheadpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float totalOverhead = 5;
 * @return {number}
 */
proto.TotalRowPricingData.prototype.getTotaloverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalRowPricingData} returns this
 */
proto.TotalRowPricingData.prototype.setTotaloverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float markupPercent = 6;
 * @return {number}
 */
proto.TotalRowPricingData.prototype.getMarkuppercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalRowPricingData} returns this
 */
proto.TotalRowPricingData.prototype.setMarkuppercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalMarkup = 7;
 * @return {number}
 */
proto.TotalRowPricingData.prototype.getTotalmarkup = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalRowPricingData} returns this
 */
proto.TotalRowPricingData.prototype.setTotalmarkup = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float totalCost = 8;
 * @return {number}
 */
proto.TotalRowPricingData.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalRowPricingData} returns this
 */
proto.TotalRowPricingData.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TotalPricingType.prototype.toObject = function(opt_includeInstance) {
  return proto.TotalPricingType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TotalPricingType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalPricingType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowdata: (f = msg.getRowdata()) && proto.TotalRowPricingData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TotalPricingType}
 */
proto.TotalPricingType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TotalPricingType;
  return proto.TotalPricingType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TotalPricingType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TotalPricingType}
 */
proto.TotalPricingType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.TotalRowPricingData;
      reader.readMessage(value,proto.TotalRowPricingData.deserializeBinaryFromReader);
      msg.setRowdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TotalPricingType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TotalPricingType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TotalPricingType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalPricingType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowdata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TotalRowPricingData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.TotalPricingType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TotalPricingType} returns this
 */
proto.TotalPricingType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TotalRowPricingData rowData = 2;
 * @return {?proto.TotalRowPricingData}
 */
proto.TotalPricingType.prototype.getRowdata = function() {
  return /** @type{?proto.TotalRowPricingData} */ (
    jspb.Message.getWrapperField(this, proto.TotalRowPricingData, 2));
};


/**
 * @param {?proto.TotalRowPricingData|undefined} value
 * @return {!proto.TotalPricingType} returns this
*/
proto.TotalPricingType.prototype.setRowdata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalPricingType} returns this
 */
proto.TotalPricingType.prototype.clearRowdata = function() {
  return this.setRowdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalPricingType.prototype.hasRowdata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TotalFinalPricingData.prototype.toObject = function(opt_includeInstance) {
  return proto.TotalFinalPricingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TotalFinalPricingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalFinalPricingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totaloverhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalmarkup: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalfinalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TotalFinalPricingData}
 */
proto.TotalFinalPricingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TotalFinalPricingData;
  return proto.TotalFinalPricingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TotalFinalPricingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TotalFinalPricingData}
 */
proto.TotalFinalPricingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaloverhead(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalmarkup(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TotalFinalPricingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TotalFinalPricingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TotalFinalPricingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalFinalPricingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getTotaloverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTotalmarkup();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTotalfinalprice();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float totalCost = 1;
 * @return {number}
 */
proto.TotalFinalPricingData.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPricingData} returns this
 */
proto.TotalFinalPricingData.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float totalOverhead = 2;
 * @return {number}
 */
proto.TotalFinalPricingData.prototype.getTotaloverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPricingData} returns this
 */
proto.TotalFinalPricingData.prototype.setTotaloverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float totalMarkup = 3;
 * @return {number}
 */
proto.TotalFinalPricingData.prototype.getTotalmarkup = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPricingData} returns this
 */
proto.TotalFinalPricingData.prototype.setTotalmarkup = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float totalFinalPrice = 4;
 * @return {number}
 */
proto.TotalFinalPricingData.prototype.getTotalfinalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TotalFinalPricingData} returns this
 */
proto.TotalFinalPricingData.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TotalFinalRowData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TotalFinalRowData.prototype.toObject = function(opt_includeInstance) {
  return proto.TotalFinalRowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TotalFinalRowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalFinalRowData.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalfinaldata: (f = msg.getTotalfinaldata()) && proto.TotalFinalPricingData.toObject(includeInstance, f),
    rowtypesList: jspb.Message.toObjectList(msg.getRowtypesList(),
    proto.TotalPricingType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TotalFinalRowData}
 */
proto.TotalFinalRowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TotalFinalRowData;
  return proto.TotalFinalRowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TotalFinalRowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TotalFinalRowData}
 */
proto.TotalFinalRowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TotalFinalPricingData;
      reader.readMessage(value,proto.TotalFinalPricingData.deserializeBinaryFromReader);
      msg.setTotalfinaldata(value);
      break;
    case 2:
      var value = new proto.TotalPricingType;
      reader.readMessage(value,proto.TotalPricingType.deserializeBinaryFromReader);
      msg.addRowtypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TotalFinalRowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TotalFinalRowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TotalFinalRowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TotalFinalRowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalfinaldata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TotalFinalPricingData.serializeBinaryToWriter
    );
  }
  f = message.getRowtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.TotalPricingType.serializeBinaryToWriter
    );
  }
};


/**
 * optional TotalFinalPricingData totalFinalData = 1;
 * @return {?proto.TotalFinalPricingData}
 */
proto.TotalFinalRowData.prototype.getTotalfinaldata = function() {
  return /** @type{?proto.TotalFinalPricingData} */ (
    jspb.Message.getWrapperField(this, proto.TotalFinalPricingData, 1));
};


/**
 * @param {?proto.TotalFinalPricingData|undefined} value
 * @return {!proto.TotalFinalRowData} returns this
*/
proto.TotalFinalRowData.prototype.setTotalfinaldata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TotalFinalRowData} returns this
 */
proto.TotalFinalRowData.prototype.clearTotalfinaldata = function() {
  return this.setTotalfinaldata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TotalFinalRowData.prototype.hasTotalfinaldata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TotalPricingType rowTypes = 2;
 * @return {!Array<!proto.TotalPricingType>}
 */
proto.TotalFinalRowData.prototype.getRowtypesList = function() {
  return /** @type{!Array<!proto.TotalPricingType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TotalPricingType, 2));
};


/**
 * @param {!Array<!proto.TotalPricingType>} value
 * @return {!proto.TotalFinalRowData} returns this
*/
proto.TotalFinalRowData.prototype.setRowtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.TotalPricingType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TotalPricingType}
 */
proto.TotalFinalRowData.prototype.addRowtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.TotalPricingType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TotalFinalRowData} returns this
 */
proto.TotalFinalRowData.prototype.clearRowtypesList = function() {
  return this.setRowtypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EstimateCloseout.repeatedFields_ = [2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EstimateCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.EstimateCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EstimateCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EstimateCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extensionList: jspb.Message.toObjectList(msg.getExtensionList(),
    proto.ExtensionType.toObject, includeInstance),
    directlaborList: jspb.Message.toObjectList(msg.getDirectlaborList(),
    proto.DirectLaborType.toObject, includeInstance),
    incidentallaborList: jspb.Message.toObjectList(msg.getIncidentallaborList(),
    proto.IncidentalLaborType.toObject, includeInstance),
    laborfactoringList: jspb.Message.toObjectList(msg.getLaborfactoringList(),
    proto.LaborFactoringType.toObject, includeInstance),
    indirectlaborList: jspb.Message.toObjectList(msg.getIndirectlaborList(),
    proto.IndirectLaborType.toObject, includeInstance),
    equipmentexpenseList: jspb.Message.toObjectList(msg.getEquipmentexpenseList(),
    proto.EquipmentType.toObject, includeInstance),
    generalexpenseList: jspb.Message.toObjectList(msg.getGeneralexpenseList(),
    proto.GeneralExpenseType.toObject, includeInstance),
    subcontractexpenseList: jspb.Message.toObjectList(msg.getSubcontractexpenseList(),
    proto.SubcontractType.toObject, includeInstance),
    quoteexpenseList: jspb.Message.toObjectList(msg.getQuoteexpenseList(),
    proto.QuoteType.toObject, includeInstance),
    totalfinalprice: (f = msg.getTotalfinalprice()) && proto.TotalFinalRowData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EstimateCloseout}
 */
proto.EstimateCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EstimateCloseout;
  return proto.EstimateCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EstimateCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EstimateCloseout}
 */
proto.EstimateCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = new proto.ExtensionType;
      reader.readMessage(value,proto.ExtensionType.deserializeBinaryFromReader);
      msg.addExtension$(value);
      break;
    case 3:
      var value = new proto.DirectLaborType;
      reader.readMessage(value,proto.DirectLaborType.deserializeBinaryFromReader);
      msg.addDirectlabor(value);
      break;
    case 4:
      var value = new proto.IncidentalLaborType;
      reader.readMessage(value,proto.IncidentalLaborType.deserializeBinaryFromReader);
      msg.addIncidentallabor(value);
      break;
    case 5:
      var value = new proto.LaborFactoringType;
      reader.readMessage(value,proto.LaborFactoringType.deserializeBinaryFromReader);
      msg.addLaborfactoring(value);
      break;
    case 6:
      var value = new proto.IndirectLaborType;
      reader.readMessage(value,proto.IndirectLaborType.deserializeBinaryFromReader);
      msg.addIndirectlabor(value);
      break;
    case 7:
      var value = new proto.EquipmentType;
      reader.readMessage(value,proto.EquipmentType.deserializeBinaryFromReader);
      msg.addEquipmentexpense(value);
      break;
    case 8:
      var value = new proto.GeneralExpenseType;
      reader.readMessage(value,proto.GeneralExpenseType.deserializeBinaryFromReader);
      msg.addGeneralexpense(value);
      break;
    case 9:
      var value = new proto.SubcontractType;
      reader.readMessage(value,proto.SubcontractType.deserializeBinaryFromReader);
      msg.addSubcontractexpense(value);
      break;
    case 10:
      var value = new proto.QuoteType;
      reader.readMessage(value,proto.QuoteType.deserializeBinaryFromReader);
      msg.addQuoteexpense(value);
      break;
    case 11:
      var value = new proto.TotalFinalRowData;
      reader.readMessage(value,proto.TotalFinalRowData.deserializeBinaryFromReader);
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EstimateCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EstimateCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EstimateCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EstimateCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ExtensionType.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.DirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.IncidentalLaborType.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.LaborFactoringType.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.IndirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.EquipmentType.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.GeneralExpenseType.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.SubcontractType.serializeBinaryToWriter
    );
  }
  f = message.getQuoteexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.QuoteType.serializeBinaryToWriter
    );
  }
  f = message.getTotalfinalprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.TotalFinalRowData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.EstimateCloseout.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ExtensionType extension = 2;
 * @return {!Array<!proto.ExtensionType>}
 */
proto.EstimateCloseout.prototype.getExtensionList = function() {
  return /** @type{!Array<!proto.ExtensionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtensionType, 2));
};


/**
 * @param {!Array<!proto.ExtensionType>} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setExtensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ExtensionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtensionType}
 */
proto.EstimateCloseout.prototype.addExtension$ = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ExtensionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearExtensionList = function() {
  return this.setExtensionList([]);
};


/**
 * repeated DirectLaborType directLabor = 3;
 * @return {!Array<!proto.DirectLaborType>}
 */
proto.EstimateCloseout.prototype.getDirectlaborList = function() {
  return /** @type{!Array<!proto.DirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DirectLaborType, 3));
};


/**
 * @param {!Array<!proto.DirectLaborType>} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setDirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.DirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DirectLaborType}
 */
proto.EstimateCloseout.prototype.addDirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.DirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearDirectlaborList = function() {
  return this.setDirectlaborList([]);
};


/**
 * repeated IncidentalLaborType incidentalLabor = 4;
 * @return {!Array<!proto.IncidentalLaborType>}
 */
proto.EstimateCloseout.prototype.getIncidentallaborList = function() {
  return /** @type{!Array<!proto.IncidentalLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IncidentalLaborType, 4));
};


/**
 * @param {!Array<!proto.IncidentalLaborType>} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setIncidentallaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.IncidentalLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IncidentalLaborType}
 */
proto.EstimateCloseout.prototype.addIncidentallabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.IncidentalLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearIncidentallaborList = function() {
  return this.setIncidentallaborList([]);
};


/**
 * repeated LaborFactoringType laborFactoring = 5;
 * @return {!Array<!proto.LaborFactoringType>}
 */
proto.EstimateCloseout.prototype.getLaborfactoringList = function() {
  return /** @type{!Array<!proto.LaborFactoringType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LaborFactoringType, 5));
};


/**
 * @param {!Array<!proto.LaborFactoringType>} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setLaborfactoringList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.LaborFactoringType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaborFactoringType}
 */
proto.EstimateCloseout.prototype.addLaborfactoring = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.LaborFactoringType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearLaborfactoringList = function() {
  return this.setLaborfactoringList([]);
};


/**
 * repeated IndirectLaborType indirectLabor = 6;
 * @return {!Array<!proto.IndirectLaborType>}
 */
proto.EstimateCloseout.prototype.getIndirectlaborList = function() {
  return /** @type{!Array<!proto.IndirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IndirectLaborType, 6));
};


/**
 * @param {!Array<!proto.IndirectLaborType>} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setIndirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.IndirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IndirectLaborType}
 */
proto.EstimateCloseout.prototype.addIndirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.IndirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearIndirectlaborList = function() {
  return this.setIndirectlaborList([]);
};


/**
 * repeated EquipmentType equipmentExpense = 7;
 * @return {!Array<!proto.EquipmentType>}
 */
proto.EstimateCloseout.prototype.getEquipmentexpenseList = function() {
  return /** @type{!Array<!proto.EquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentType, 7));
};


/**
 * @param {!Array<!proto.EquipmentType>} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setEquipmentexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.EquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentType}
 */
proto.EstimateCloseout.prototype.addEquipmentexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.EquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearEquipmentexpenseList = function() {
  return this.setEquipmentexpenseList([]);
};


/**
 * repeated GeneralExpenseType generalExpense = 8;
 * @return {!Array<!proto.GeneralExpenseType>}
 */
proto.EstimateCloseout.prototype.getGeneralexpenseList = function() {
  return /** @type{!Array<!proto.GeneralExpenseType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GeneralExpenseType, 8));
};


/**
 * @param {!Array<!proto.GeneralExpenseType>} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setGeneralexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.GeneralExpenseType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GeneralExpenseType}
 */
proto.EstimateCloseout.prototype.addGeneralexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.GeneralExpenseType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearGeneralexpenseList = function() {
  return this.setGeneralexpenseList([]);
};


/**
 * repeated SubcontractType subcontractExpense = 9;
 * @return {!Array<!proto.SubcontractType>}
 */
proto.EstimateCloseout.prototype.getSubcontractexpenseList = function() {
  return /** @type{!Array<!proto.SubcontractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubcontractType, 9));
};


/**
 * @param {!Array<!proto.SubcontractType>} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setSubcontractexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.SubcontractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubcontractType}
 */
proto.EstimateCloseout.prototype.addSubcontractexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.SubcontractType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearSubcontractexpenseList = function() {
  return this.setSubcontractexpenseList([]);
};


/**
 * repeated QuoteType quoteExpense = 10;
 * @return {!Array<!proto.QuoteType>}
 */
proto.EstimateCloseout.prototype.getQuoteexpenseList = function() {
  return /** @type{!Array<!proto.QuoteType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuoteType, 10));
};


/**
 * @param {!Array<!proto.QuoteType>} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setQuoteexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.QuoteType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuoteType}
 */
proto.EstimateCloseout.prototype.addQuoteexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.QuoteType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearQuoteexpenseList = function() {
  return this.setQuoteexpenseList([]);
};


/**
 * optional TotalFinalRowData totalFinalPrice = 11;
 * @return {?proto.TotalFinalRowData}
 */
proto.EstimateCloseout.prototype.getTotalfinalprice = function() {
  return /** @type{?proto.TotalFinalRowData} */ (
    jspb.Message.getWrapperField(this, proto.TotalFinalRowData, 11));
};


/**
 * @param {?proto.TotalFinalRowData|undefined} value
 * @return {!proto.EstimateCloseout} returns this
*/
proto.EstimateCloseout.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EstimateCloseout} returns this
 */
proto.EstimateCloseout.prototype.clearTotalfinalprice = function() {
  return this.setTotalfinalprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EstimateCloseout.prototype.hasTotalfinalprice = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CreateEstimateCloseoutRequest.repeatedFields_ = [2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateEstimateCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateEstimateCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateEstimateCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEstimateCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extensionList: jspb.Message.toObjectList(msg.getExtensionList(),
    proto.ExtensionType.toObject, includeInstance),
    directlaborList: jspb.Message.toObjectList(msg.getDirectlaborList(),
    proto.DirectLaborType.toObject, includeInstance),
    incidentallaborList: jspb.Message.toObjectList(msg.getIncidentallaborList(),
    proto.IncidentalLaborType.toObject, includeInstance),
    laborfactoringList: jspb.Message.toObjectList(msg.getLaborfactoringList(),
    proto.LaborFactoringType.toObject, includeInstance),
    indirectlaborList: jspb.Message.toObjectList(msg.getIndirectlaborList(),
    proto.IndirectLaborType.toObject, includeInstance),
    equipmentexpenseList: jspb.Message.toObjectList(msg.getEquipmentexpenseList(),
    proto.EquipmentType.toObject, includeInstance),
    generalexpenseList: jspb.Message.toObjectList(msg.getGeneralexpenseList(),
    proto.GeneralExpenseType.toObject, includeInstance),
    subcontractexpenseList: jspb.Message.toObjectList(msg.getSubcontractexpenseList(),
    proto.SubcontractType.toObject, includeInstance),
    quoteexpenseList: jspb.Message.toObjectList(msg.getQuoteexpenseList(),
    proto.QuoteType.toObject, includeInstance),
    totalfinalprice: (f = msg.getTotalfinalprice()) && proto.TotalFinalRowData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateEstimateCloseoutRequest}
 */
proto.CreateEstimateCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateEstimateCloseoutRequest;
  return proto.CreateEstimateCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateEstimateCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateEstimateCloseoutRequest}
 */
proto.CreateEstimateCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = new proto.ExtensionType;
      reader.readMessage(value,proto.ExtensionType.deserializeBinaryFromReader);
      msg.addExtension$(value);
      break;
    case 3:
      var value = new proto.DirectLaborType;
      reader.readMessage(value,proto.DirectLaborType.deserializeBinaryFromReader);
      msg.addDirectlabor(value);
      break;
    case 4:
      var value = new proto.IncidentalLaborType;
      reader.readMessage(value,proto.IncidentalLaborType.deserializeBinaryFromReader);
      msg.addIncidentallabor(value);
      break;
    case 5:
      var value = new proto.LaborFactoringType;
      reader.readMessage(value,proto.LaborFactoringType.deserializeBinaryFromReader);
      msg.addLaborfactoring(value);
      break;
    case 6:
      var value = new proto.IndirectLaborType;
      reader.readMessage(value,proto.IndirectLaborType.deserializeBinaryFromReader);
      msg.addIndirectlabor(value);
      break;
    case 7:
      var value = new proto.EquipmentType;
      reader.readMessage(value,proto.EquipmentType.deserializeBinaryFromReader);
      msg.addEquipmentexpense(value);
      break;
    case 8:
      var value = new proto.GeneralExpenseType;
      reader.readMessage(value,proto.GeneralExpenseType.deserializeBinaryFromReader);
      msg.addGeneralexpense(value);
      break;
    case 9:
      var value = new proto.SubcontractType;
      reader.readMessage(value,proto.SubcontractType.deserializeBinaryFromReader);
      msg.addSubcontractexpense(value);
      break;
    case 10:
      var value = new proto.QuoteType;
      reader.readMessage(value,proto.QuoteType.deserializeBinaryFromReader);
      msg.addQuoteexpense(value);
      break;
    case 11:
      var value = new proto.TotalFinalRowData;
      reader.readMessage(value,proto.TotalFinalRowData.deserializeBinaryFromReader);
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateEstimateCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateEstimateCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateEstimateCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEstimateCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ExtensionType.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.DirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.IncidentalLaborType.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.LaborFactoringType.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.IndirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.EquipmentType.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.GeneralExpenseType.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.SubcontractType.serializeBinaryToWriter
    );
  }
  f = message.getQuoteexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.QuoteType.serializeBinaryToWriter
    );
  }
  f = message.getTotalfinalprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.TotalFinalRowData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.CreateEstimateCloseoutRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ExtensionType extension = 2;
 * @return {!Array<!proto.ExtensionType>}
 */
proto.CreateEstimateCloseoutRequest.prototype.getExtensionList = function() {
  return /** @type{!Array<!proto.ExtensionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtensionType, 2));
};


/**
 * @param {!Array<!proto.ExtensionType>} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setExtensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ExtensionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtensionType}
 */
proto.CreateEstimateCloseoutRequest.prototype.addExtension$ = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ExtensionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearExtensionList = function() {
  return this.setExtensionList([]);
};


/**
 * repeated DirectLaborType directLabor = 3;
 * @return {!Array<!proto.DirectLaborType>}
 */
proto.CreateEstimateCloseoutRequest.prototype.getDirectlaborList = function() {
  return /** @type{!Array<!proto.DirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DirectLaborType, 3));
};


/**
 * @param {!Array<!proto.DirectLaborType>} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setDirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.DirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DirectLaborType}
 */
proto.CreateEstimateCloseoutRequest.prototype.addDirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.DirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearDirectlaborList = function() {
  return this.setDirectlaborList([]);
};


/**
 * repeated IncidentalLaborType incidentalLabor = 4;
 * @return {!Array<!proto.IncidentalLaborType>}
 */
proto.CreateEstimateCloseoutRequest.prototype.getIncidentallaborList = function() {
  return /** @type{!Array<!proto.IncidentalLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IncidentalLaborType, 4));
};


/**
 * @param {!Array<!proto.IncidentalLaborType>} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setIncidentallaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.IncidentalLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IncidentalLaborType}
 */
proto.CreateEstimateCloseoutRequest.prototype.addIncidentallabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.IncidentalLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearIncidentallaborList = function() {
  return this.setIncidentallaborList([]);
};


/**
 * repeated LaborFactoringType laborFactoring = 5;
 * @return {!Array<!proto.LaborFactoringType>}
 */
proto.CreateEstimateCloseoutRequest.prototype.getLaborfactoringList = function() {
  return /** @type{!Array<!proto.LaborFactoringType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LaborFactoringType, 5));
};


/**
 * @param {!Array<!proto.LaborFactoringType>} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setLaborfactoringList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.LaborFactoringType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaborFactoringType}
 */
proto.CreateEstimateCloseoutRequest.prototype.addLaborfactoring = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.LaborFactoringType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearLaborfactoringList = function() {
  return this.setLaborfactoringList([]);
};


/**
 * repeated IndirectLaborType indirectLabor = 6;
 * @return {!Array<!proto.IndirectLaborType>}
 */
proto.CreateEstimateCloseoutRequest.prototype.getIndirectlaborList = function() {
  return /** @type{!Array<!proto.IndirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IndirectLaborType, 6));
};


/**
 * @param {!Array<!proto.IndirectLaborType>} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setIndirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.IndirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IndirectLaborType}
 */
proto.CreateEstimateCloseoutRequest.prototype.addIndirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.IndirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearIndirectlaborList = function() {
  return this.setIndirectlaborList([]);
};


/**
 * repeated EquipmentType equipmentExpense = 7;
 * @return {!Array<!proto.EquipmentType>}
 */
proto.CreateEstimateCloseoutRequest.prototype.getEquipmentexpenseList = function() {
  return /** @type{!Array<!proto.EquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentType, 7));
};


/**
 * @param {!Array<!proto.EquipmentType>} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setEquipmentexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.EquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentType}
 */
proto.CreateEstimateCloseoutRequest.prototype.addEquipmentexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.EquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearEquipmentexpenseList = function() {
  return this.setEquipmentexpenseList([]);
};


/**
 * repeated GeneralExpenseType generalExpense = 8;
 * @return {!Array<!proto.GeneralExpenseType>}
 */
proto.CreateEstimateCloseoutRequest.prototype.getGeneralexpenseList = function() {
  return /** @type{!Array<!proto.GeneralExpenseType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GeneralExpenseType, 8));
};


/**
 * @param {!Array<!proto.GeneralExpenseType>} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setGeneralexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.GeneralExpenseType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GeneralExpenseType}
 */
proto.CreateEstimateCloseoutRequest.prototype.addGeneralexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.GeneralExpenseType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearGeneralexpenseList = function() {
  return this.setGeneralexpenseList([]);
};


/**
 * repeated SubcontractType subcontractExpense = 9;
 * @return {!Array<!proto.SubcontractType>}
 */
proto.CreateEstimateCloseoutRequest.prototype.getSubcontractexpenseList = function() {
  return /** @type{!Array<!proto.SubcontractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubcontractType, 9));
};


/**
 * @param {!Array<!proto.SubcontractType>} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setSubcontractexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.SubcontractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubcontractType}
 */
proto.CreateEstimateCloseoutRequest.prototype.addSubcontractexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.SubcontractType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearSubcontractexpenseList = function() {
  return this.setSubcontractexpenseList([]);
};


/**
 * repeated QuoteType quoteExpense = 10;
 * @return {!Array<!proto.QuoteType>}
 */
proto.CreateEstimateCloseoutRequest.prototype.getQuoteexpenseList = function() {
  return /** @type{!Array<!proto.QuoteType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuoteType, 10));
};


/**
 * @param {!Array<!proto.QuoteType>} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setQuoteexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.QuoteType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuoteType}
 */
proto.CreateEstimateCloseoutRequest.prototype.addQuoteexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.QuoteType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearQuoteexpenseList = function() {
  return this.setQuoteexpenseList([]);
};


/**
 * optional TotalFinalRowData totalFinalPrice = 11;
 * @return {?proto.TotalFinalRowData}
 */
proto.CreateEstimateCloseoutRequest.prototype.getTotalfinalprice = function() {
  return /** @type{?proto.TotalFinalRowData} */ (
    jspb.Message.getWrapperField(this, proto.TotalFinalRowData, 11));
};


/**
 * @param {?proto.TotalFinalRowData|undefined} value
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
*/
proto.CreateEstimateCloseoutRequest.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateEstimateCloseoutRequest} returns this
 */
proto.CreateEstimateCloseoutRequest.prototype.clearTotalfinalprice = function() {
  return this.setTotalfinalprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateEstimateCloseoutRequest.prototype.hasTotalfinalprice = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateEstimateCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateEstimateCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateEstimateCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEstimateCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateEstimateCloseoutResponse}
 */
proto.CreateEstimateCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateEstimateCloseoutResponse;
  return proto.CreateEstimateCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateEstimateCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateEstimateCloseoutResponse}
 */
proto.CreateEstimateCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateEstimateCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateEstimateCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateEstimateCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateEstimateCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.CreateEstimateCloseoutResponse.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateEstimateCloseoutResponse} returns this
 */
proto.CreateEstimateCloseoutResponse.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CreateOrUpdateEstimateCloseoutRequest.repeatedFields_ = [2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateEstimateCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateEstimateCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateEstimateCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extensionList: jspb.Message.toObjectList(msg.getExtensionList(),
    proto.ExtensionType.toObject, includeInstance),
    directlaborList: jspb.Message.toObjectList(msg.getDirectlaborList(),
    proto.DirectLaborType.toObject, includeInstance),
    incidentallaborList: jspb.Message.toObjectList(msg.getIncidentallaborList(),
    proto.IncidentalLaborType.toObject, includeInstance),
    laborfactoringList: jspb.Message.toObjectList(msg.getLaborfactoringList(),
    proto.LaborFactoringType.toObject, includeInstance),
    indirectlaborList: jspb.Message.toObjectList(msg.getIndirectlaborList(),
    proto.IndirectLaborType.toObject, includeInstance),
    equipmentexpenseList: jspb.Message.toObjectList(msg.getEquipmentexpenseList(),
    proto.EquipmentType.toObject, includeInstance),
    generalexpenseList: jspb.Message.toObjectList(msg.getGeneralexpenseList(),
    proto.GeneralExpenseType.toObject, includeInstance),
    subcontractexpenseList: jspb.Message.toObjectList(msg.getSubcontractexpenseList(),
    proto.SubcontractType.toObject, includeInstance),
    quoteexpenseList: jspb.Message.toObjectList(msg.getQuoteexpenseList(),
    proto.QuoteType.toObject, includeInstance),
    totalfinalprice: (f = msg.getTotalfinalprice()) && proto.TotalFinalRowData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateEstimateCloseoutRequest;
  return proto.CreateOrUpdateEstimateCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateEstimateCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = new proto.ExtensionType;
      reader.readMessage(value,proto.ExtensionType.deserializeBinaryFromReader);
      msg.addExtension$(value);
      break;
    case 3:
      var value = new proto.DirectLaborType;
      reader.readMessage(value,proto.DirectLaborType.deserializeBinaryFromReader);
      msg.addDirectlabor(value);
      break;
    case 4:
      var value = new proto.IncidentalLaborType;
      reader.readMessage(value,proto.IncidentalLaborType.deserializeBinaryFromReader);
      msg.addIncidentallabor(value);
      break;
    case 5:
      var value = new proto.LaborFactoringType;
      reader.readMessage(value,proto.LaborFactoringType.deserializeBinaryFromReader);
      msg.addLaborfactoring(value);
      break;
    case 6:
      var value = new proto.IndirectLaborType;
      reader.readMessage(value,proto.IndirectLaborType.deserializeBinaryFromReader);
      msg.addIndirectlabor(value);
      break;
    case 7:
      var value = new proto.EquipmentType;
      reader.readMessage(value,proto.EquipmentType.deserializeBinaryFromReader);
      msg.addEquipmentexpense(value);
      break;
    case 8:
      var value = new proto.GeneralExpenseType;
      reader.readMessage(value,proto.GeneralExpenseType.deserializeBinaryFromReader);
      msg.addGeneralexpense(value);
      break;
    case 9:
      var value = new proto.SubcontractType;
      reader.readMessage(value,proto.SubcontractType.deserializeBinaryFromReader);
      msg.addSubcontractexpense(value);
      break;
    case 10:
      var value = new proto.QuoteType;
      reader.readMessage(value,proto.QuoteType.deserializeBinaryFromReader);
      msg.addQuoteexpense(value);
      break;
    case 11:
      var value = new proto.TotalFinalRowData;
      reader.readMessage(value,proto.TotalFinalRowData.deserializeBinaryFromReader);
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateEstimateCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateEstimateCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateEstimateCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ExtensionType.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.DirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.IncidentalLaborType.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.LaborFactoringType.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.IndirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.EquipmentType.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.GeneralExpenseType.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.SubcontractType.serializeBinaryToWriter
    );
  }
  f = message.getQuoteexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.QuoteType.serializeBinaryToWriter
    );
  }
  f = message.getTotalfinalprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.TotalFinalRowData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ExtensionType extension = 2;
 * @return {!Array<!proto.ExtensionType>}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getExtensionList = function() {
  return /** @type{!Array<!proto.ExtensionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtensionType, 2));
};


/**
 * @param {!Array<!proto.ExtensionType>} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setExtensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ExtensionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtensionType}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.addExtension$ = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ExtensionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearExtensionList = function() {
  return this.setExtensionList([]);
};


/**
 * repeated DirectLaborType directLabor = 3;
 * @return {!Array<!proto.DirectLaborType>}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getDirectlaborList = function() {
  return /** @type{!Array<!proto.DirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DirectLaborType, 3));
};


/**
 * @param {!Array<!proto.DirectLaborType>} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setDirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.DirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DirectLaborType}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.addDirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.DirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearDirectlaborList = function() {
  return this.setDirectlaborList([]);
};


/**
 * repeated IncidentalLaborType incidentalLabor = 4;
 * @return {!Array<!proto.IncidentalLaborType>}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getIncidentallaborList = function() {
  return /** @type{!Array<!proto.IncidentalLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IncidentalLaborType, 4));
};


/**
 * @param {!Array<!proto.IncidentalLaborType>} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setIncidentallaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.IncidentalLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IncidentalLaborType}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.addIncidentallabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.IncidentalLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearIncidentallaborList = function() {
  return this.setIncidentallaborList([]);
};


/**
 * repeated LaborFactoringType laborFactoring = 5;
 * @return {!Array<!proto.LaborFactoringType>}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getLaborfactoringList = function() {
  return /** @type{!Array<!proto.LaborFactoringType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LaborFactoringType, 5));
};


/**
 * @param {!Array<!proto.LaborFactoringType>} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setLaborfactoringList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.LaborFactoringType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaborFactoringType}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.addLaborfactoring = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.LaborFactoringType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearLaborfactoringList = function() {
  return this.setLaborfactoringList([]);
};


/**
 * repeated IndirectLaborType indirectLabor = 6;
 * @return {!Array<!proto.IndirectLaborType>}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getIndirectlaborList = function() {
  return /** @type{!Array<!proto.IndirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IndirectLaborType, 6));
};


/**
 * @param {!Array<!proto.IndirectLaborType>} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setIndirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.IndirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IndirectLaborType}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.addIndirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.IndirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearIndirectlaborList = function() {
  return this.setIndirectlaborList([]);
};


/**
 * repeated EquipmentType equipmentExpense = 7;
 * @return {!Array<!proto.EquipmentType>}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getEquipmentexpenseList = function() {
  return /** @type{!Array<!proto.EquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentType, 7));
};


/**
 * @param {!Array<!proto.EquipmentType>} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setEquipmentexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.EquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentType}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.addEquipmentexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.EquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearEquipmentexpenseList = function() {
  return this.setEquipmentexpenseList([]);
};


/**
 * repeated GeneralExpenseType generalExpense = 8;
 * @return {!Array<!proto.GeneralExpenseType>}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getGeneralexpenseList = function() {
  return /** @type{!Array<!proto.GeneralExpenseType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GeneralExpenseType, 8));
};


/**
 * @param {!Array<!proto.GeneralExpenseType>} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setGeneralexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.GeneralExpenseType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GeneralExpenseType}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.addGeneralexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.GeneralExpenseType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearGeneralexpenseList = function() {
  return this.setGeneralexpenseList([]);
};


/**
 * repeated SubcontractType subcontractExpense = 9;
 * @return {!Array<!proto.SubcontractType>}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getSubcontractexpenseList = function() {
  return /** @type{!Array<!proto.SubcontractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubcontractType, 9));
};


/**
 * @param {!Array<!proto.SubcontractType>} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setSubcontractexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.SubcontractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubcontractType}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.addSubcontractexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.SubcontractType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearSubcontractexpenseList = function() {
  return this.setSubcontractexpenseList([]);
};


/**
 * repeated QuoteType quoteExpense = 10;
 * @return {!Array<!proto.QuoteType>}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getQuoteexpenseList = function() {
  return /** @type{!Array<!proto.QuoteType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuoteType, 10));
};


/**
 * @param {!Array<!proto.QuoteType>} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setQuoteexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.QuoteType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuoteType}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.addQuoteexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.QuoteType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearQuoteexpenseList = function() {
  return this.setQuoteexpenseList([]);
};


/**
 * optional TotalFinalRowData totalFinalPrice = 11;
 * @return {?proto.TotalFinalRowData}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.getTotalfinalprice = function() {
  return /** @type{?proto.TotalFinalRowData} */ (
    jspb.Message.getWrapperField(this, proto.TotalFinalRowData, 11));
};


/**
 * @param {?proto.TotalFinalRowData|undefined} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
*/
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateEstimateCloseoutRequest} returns this
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.clearTotalfinalprice = function() {
  return this.setTotalfinalprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateEstimateCloseoutRequest.prototype.hasTotalfinalprice = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateEstimateCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateEstimateCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateEstimateCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateEstimateCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateEstimateCloseoutResponse}
 */
proto.CreateOrUpdateEstimateCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateEstimateCloseoutResponse;
  return proto.CreateOrUpdateEstimateCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateEstimateCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateEstimateCloseoutResponse}
 */
proto.CreateOrUpdateEstimateCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateEstimateCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateEstimateCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateEstimateCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateEstimateCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.CreateOrUpdateEstimateCloseoutResponse.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateEstimateCloseoutResponse} returns this
 */
proto.CreateOrUpdateEstimateCloseoutResponse.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetEstimateCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetEstimateCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetEstimateCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEstimateCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetEstimateCloseoutRequest}
 */
proto.GetEstimateCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetEstimateCloseoutRequest;
  return proto.GetEstimateCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetEstimateCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetEstimateCloseoutRequest}
 */
proto.GetEstimateCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetEstimateCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetEstimateCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetEstimateCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEstimateCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.GetEstimateCloseoutRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetEstimateCloseoutRequest} returns this
 */
proto.GetEstimateCloseoutRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetEstimateCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetEstimateCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetEstimateCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEstimateCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimatecloseout: (f = msg.getEstimatecloseout()) && proto.EstimateCloseout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetEstimateCloseoutResponse}
 */
proto.GetEstimateCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetEstimateCloseoutResponse;
  return proto.GetEstimateCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetEstimateCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetEstimateCloseoutResponse}
 */
proto.GetEstimateCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EstimateCloseout;
      reader.readMessage(value,proto.EstimateCloseout.deserializeBinaryFromReader);
      msg.setEstimatecloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetEstimateCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetEstimateCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetEstimateCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEstimateCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimatecloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.EstimateCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional EstimateCloseout estimateCloseout = 1;
 * @return {?proto.EstimateCloseout}
 */
proto.GetEstimateCloseoutResponse.prototype.getEstimatecloseout = function() {
  return /** @type{?proto.EstimateCloseout} */ (
    jspb.Message.getWrapperField(this, proto.EstimateCloseout, 1));
};


/**
 * @param {?proto.EstimateCloseout|undefined} value
 * @return {!proto.GetEstimateCloseoutResponse} returns this
*/
proto.GetEstimateCloseoutResponse.prototype.setEstimatecloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetEstimateCloseoutResponse} returns this
 */
proto.GetEstimateCloseoutResponse.prototype.clearEstimatecloseout = function() {
  return this.setEstimatecloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetEstimateCloseoutResponse.prototype.hasEstimatecloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetEstimateCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetEstimateCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetEstimateCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEstimateCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagenumber: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    pagesize: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetEstimateCloseoutsRequest}
 */
proto.GetEstimateCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetEstimateCloseoutsRequest;
  return proto.GetEstimateCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetEstimateCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetEstimateCloseoutsRequest}
 */
proto.GetEstimateCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPagenumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetEstimateCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetEstimateCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetEstimateCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEstimateCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagenumber();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float pageNumber = 1;
 * @return {number}
 */
proto.GetEstimateCloseoutsRequest.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetEstimateCloseoutsRequest} returns this
 */
proto.GetEstimateCloseoutsRequest.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float pageSize = 2;
 * @return {number}
 */
proto.GetEstimateCloseoutsRequest.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetEstimateCloseoutsRequest} returns this
 */
proto.GetEstimateCloseoutsRequest.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetEstimateCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetEstimateCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetEstimateCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetEstimateCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEstimateCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimatecloseoutsList: jspb.Message.toObjectList(msg.getEstimatecloseoutsList(),
    proto.EstimateCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetEstimateCloseoutsResponse}
 */
proto.GetEstimateCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetEstimateCloseoutsResponse;
  return proto.GetEstimateCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetEstimateCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetEstimateCloseoutsResponse}
 */
proto.GetEstimateCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EstimateCloseout;
      reader.readMessage(value,proto.EstimateCloseout.deserializeBinaryFromReader);
      msg.addEstimatecloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetEstimateCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetEstimateCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetEstimateCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetEstimateCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimatecloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EstimateCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EstimateCloseout estimateCloseouts = 1;
 * @return {!Array<!proto.EstimateCloseout>}
 */
proto.GetEstimateCloseoutsResponse.prototype.getEstimatecloseoutsList = function() {
  return /** @type{!Array<!proto.EstimateCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EstimateCloseout, 1));
};


/**
 * @param {!Array<!proto.EstimateCloseout>} value
 * @return {!proto.GetEstimateCloseoutsResponse} returns this
*/
proto.GetEstimateCloseoutsResponse.prototype.setEstimatecloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EstimateCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EstimateCloseout}
 */
proto.GetEstimateCloseoutsResponse.prototype.addEstimatecloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EstimateCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetEstimateCloseoutsResponse} returns this
 */
proto.GetEstimateCloseoutsResponse.prototype.clearEstimatecloseoutsList = function() {
  return this.setEstimatecloseoutsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateEstimateCloseoutRequest.repeatedFields_ = [2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extensionList: jspb.Message.toObjectList(msg.getExtensionList(),
    proto.ExtensionType.toObject, includeInstance),
    directlaborList: jspb.Message.toObjectList(msg.getDirectlaborList(),
    proto.DirectLaborType.toObject, includeInstance),
    incidentallaborList: jspb.Message.toObjectList(msg.getIncidentallaborList(),
    proto.IncidentalLaborType.toObject, includeInstance),
    laborfactoringList: jspb.Message.toObjectList(msg.getLaborfactoringList(),
    proto.LaborFactoringType.toObject, includeInstance),
    indirectlaborList: jspb.Message.toObjectList(msg.getIndirectlaborList(),
    proto.IndirectLaborType.toObject, includeInstance),
    equipmentexpenseList: jspb.Message.toObjectList(msg.getEquipmentexpenseList(),
    proto.EquipmentType.toObject, includeInstance),
    generalexpenseList: jspb.Message.toObjectList(msg.getGeneralexpenseList(),
    proto.GeneralExpenseType.toObject, includeInstance),
    subcontractexpenseList: jspb.Message.toObjectList(msg.getSubcontractexpenseList(),
    proto.SubcontractType.toObject, includeInstance),
    quoteexpenseList: jspb.Message.toObjectList(msg.getQuoteexpenseList(),
    proto.QuoteType.toObject, includeInstance),
    totalfinalprice: (f = msg.getTotalfinalprice()) && proto.TotalFinalRowData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateCloseoutRequest}
 */
proto.UpdateEstimateCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateCloseoutRequest;
  return proto.UpdateEstimateCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateCloseoutRequest}
 */
proto.UpdateEstimateCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = new proto.ExtensionType;
      reader.readMessage(value,proto.ExtensionType.deserializeBinaryFromReader);
      msg.addExtension$(value);
      break;
    case 3:
      var value = new proto.DirectLaborType;
      reader.readMessage(value,proto.DirectLaborType.deserializeBinaryFromReader);
      msg.addDirectlabor(value);
      break;
    case 4:
      var value = new proto.IncidentalLaborType;
      reader.readMessage(value,proto.IncidentalLaborType.deserializeBinaryFromReader);
      msg.addIncidentallabor(value);
      break;
    case 5:
      var value = new proto.LaborFactoringType;
      reader.readMessage(value,proto.LaborFactoringType.deserializeBinaryFromReader);
      msg.addLaborfactoring(value);
      break;
    case 6:
      var value = new proto.IndirectLaborType;
      reader.readMessage(value,proto.IndirectLaborType.deserializeBinaryFromReader);
      msg.addIndirectlabor(value);
      break;
    case 7:
      var value = new proto.EquipmentType;
      reader.readMessage(value,proto.EquipmentType.deserializeBinaryFromReader);
      msg.addEquipmentexpense(value);
      break;
    case 8:
      var value = new proto.GeneralExpenseType;
      reader.readMessage(value,proto.GeneralExpenseType.deserializeBinaryFromReader);
      msg.addGeneralexpense(value);
      break;
    case 9:
      var value = new proto.SubcontractType;
      reader.readMessage(value,proto.SubcontractType.deserializeBinaryFromReader);
      msg.addSubcontractexpense(value);
      break;
    case 10:
      var value = new proto.QuoteType;
      reader.readMessage(value,proto.QuoteType.deserializeBinaryFromReader);
      msg.addQuoteexpense(value);
      break;
    case 11:
      var value = new proto.TotalFinalRowData;
      reader.readMessage(value,proto.TotalFinalRowData.deserializeBinaryFromReader);
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ExtensionType.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.DirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.IncidentalLaborType.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.LaborFactoringType.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.IndirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.EquipmentType.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.GeneralExpenseType.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.SubcontractType.serializeBinaryToWriter
    );
  }
  f = message.getQuoteexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.QuoteType.serializeBinaryToWriter
    );
  }
  f = message.getTotalfinalprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.TotalFinalRowData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ExtensionType extension = 2;
 * @return {!Array<!proto.ExtensionType>}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getExtensionList = function() {
  return /** @type{!Array<!proto.ExtensionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExtensionType, 2));
};


/**
 * @param {!Array<!proto.ExtensionType>} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setExtensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ExtensionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExtensionType}
 */
proto.UpdateEstimateCloseoutRequest.prototype.addExtension$ = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ExtensionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearExtensionList = function() {
  return this.setExtensionList([]);
};


/**
 * repeated DirectLaborType directLabor = 3;
 * @return {!Array<!proto.DirectLaborType>}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getDirectlaborList = function() {
  return /** @type{!Array<!proto.DirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DirectLaborType, 3));
};


/**
 * @param {!Array<!proto.DirectLaborType>} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setDirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.DirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DirectLaborType}
 */
proto.UpdateEstimateCloseoutRequest.prototype.addDirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.DirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearDirectlaborList = function() {
  return this.setDirectlaborList([]);
};


/**
 * repeated IncidentalLaborType incidentalLabor = 4;
 * @return {!Array<!proto.IncidentalLaborType>}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getIncidentallaborList = function() {
  return /** @type{!Array<!proto.IncidentalLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IncidentalLaborType, 4));
};


/**
 * @param {!Array<!proto.IncidentalLaborType>} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setIncidentallaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.IncidentalLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IncidentalLaborType}
 */
proto.UpdateEstimateCloseoutRequest.prototype.addIncidentallabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.IncidentalLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearIncidentallaborList = function() {
  return this.setIncidentallaborList([]);
};


/**
 * repeated LaborFactoringType laborFactoring = 5;
 * @return {!Array<!proto.LaborFactoringType>}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getLaborfactoringList = function() {
  return /** @type{!Array<!proto.LaborFactoringType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LaborFactoringType, 5));
};


/**
 * @param {!Array<!proto.LaborFactoringType>} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setLaborfactoringList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.LaborFactoringType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaborFactoringType}
 */
proto.UpdateEstimateCloseoutRequest.prototype.addLaborfactoring = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.LaborFactoringType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearLaborfactoringList = function() {
  return this.setLaborfactoringList([]);
};


/**
 * repeated IndirectLaborType indirectLabor = 6;
 * @return {!Array<!proto.IndirectLaborType>}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getIndirectlaborList = function() {
  return /** @type{!Array<!proto.IndirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IndirectLaborType, 6));
};


/**
 * @param {!Array<!proto.IndirectLaborType>} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setIndirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.IndirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IndirectLaborType}
 */
proto.UpdateEstimateCloseoutRequest.prototype.addIndirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.IndirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearIndirectlaborList = function() {
  return this.setIndirectlaborList([]);
};


/**
 * repeated EquipmentType equipmentExpense = 7;
 * @return {!Array<!proto.EquipmentType>}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getEquipmentexpenseList = function() {
  return /** @type{!Array<!proto.EquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EquipmentType, 7));
};


/**
 * @param {!Array<!proto.EquipmentType>} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setEquipmentexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.EquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EquipmentType}
 */
proto.UpdateEstimateCloseoutRequest.prototype.addEquipmentexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.EquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearEquipmentexpenseList = function() {
  return this.setEquipmentexpenseList([]);
};


/**
 * repeated GeneralExpenseType generalExpense = 8;
 * @return {!Array<!proto.GeneralExpenseType>}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getGeneralexpenseList = function() {
  return /** @type{!Array<!proto.GeneralExpenseType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GeneralExpenseType, 8));
};


/**
 * @param {!Array<!proto.GeneralExpenseType>} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setGeneralexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.GeneralExpenseType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GeneralExpenseType}
 */
proto.UpdateEstimateCloseoutRequest.prototype.addGeneralexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.GeneralExpenseType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearGeneralexpenseList = function() {
  return this.setGeneralexpenseList([]);
};


/**
 * repeated SubcontractType subcontractExpense = 9;
 * @return {!Array<!proto.SubcontractType>}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getSubcontractexpenseList = function() {
  return /** @type{!Array<!proto.SubcontractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubcontractType, 9));
};


/**
 * @param {!Array<!proto.SubcontractType>} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setSubcontractexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.SubcontractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubcontractType}
 */
proto.UpdateEstimateCloseoutRequest.prototype.addSubcontractexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.SubcontractType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearSubcontractexpenseList = function() {
  return this.setSubcontractexpenseList([]);
};


/**
 * repeated QuoteType quoteExpense = 10;
 * @return {!Array<!proto.QuoteType>}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getQuoteexpenseList = function() {
  return /** @type{!Array<!proto.QuoteType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuoteType, 10));
};


/**
 * @param {!Array<!proto.QuoteType>} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setQuoteexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.QuoteType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuoteType}
 */
proto.UpdateEstimateCloseoutRequest.prototype.addQuoteexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.QuoteType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearQuoteexpenseList = function() {
  return this.setQuoteexpenseList([]);
};


/**
 * optional TotalFinalRowData totalFinalPrice = 11;
 * @return {?proto.TotalFinalRowData}
 */
proto.UpdateEstimateCloseoutRequest.prototype.getTotalfinalprice = function() {
  return /** @type{?proto.TotalFinalRowData} */ (
    jspb.Message.getWrapperField(this, proto.TotalFinalRowData, 11));
};


/**
 * @param {?proto.TotalFinalRowData|undefined} value
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
*/
proto.UpdateEstimateCloseoutRequest.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateEstimateCloseoutRequest} returns this
 */
proto.UpdateEstimateCloseoutRequest.prototype.clearTotalfinalprice = function() {
  return this.setTotalfinalprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateEstimateCloseoutRequest.prototype.hasTotalfinalprice = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateEstimateCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateEstimateCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateEstimateCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateEstimateCloseoutResponse}
 */
proto.UpdateEstimateCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateEstimateCloseoutResponse;
  return proto.UpdateEstimateCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateEstimateCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateEstimateCloseoutResponse}
 */
proto.UpdateEstimateCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateEstimateCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateEstimateCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateEstimateCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateEstimateCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateEstimateCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateEstimateCloseoutResponse} returns this
 */
proto.UpdateEstimateCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteEstimateCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteEstimateCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteEstimateCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEstimateCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteEstimateCloseoutRequest}
 */
proto.DeleteEstimateCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteEstimateCloseoutRequest;
  return proto.DeleteEstimateCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteEstimateCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteEstimateCloseoutRequest}
 */
proto.DeleteEstimateCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteEstimateCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteEstimateCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteEstimateCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEstimateCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.DeleteEstimateCloseoutRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteEstimateCloseoutRequest} returns this
 */
proto.DeleteEstimateCloseoutRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteEstimateCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteEstimateCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteEstimateCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEstimateCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteEstimateCloseoutResponse}
 */
proto.DeleteEstimateCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteEstimateCloseoutResponse;
  return proto.DeleteEstimateCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteEstimateCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteEstimateCloseoutResponse}
 */
proto.DeleteEstimateCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteEstimateCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteEstimateCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteEstimateCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEstimateCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteEstimateCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteEstimateCloseoutResponse} returns this
 */
proto.DeleteEstimateCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto);
