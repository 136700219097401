// src/components/settings/UserForm.tsx

import React from "react";
import { UB_AccountUser } from "../../api/protosCompiled/ub_users/ub_users_pb";
import { useFetchRoles } from "../../hooks/useFetchRoles";
import { isFieldDisabled } from "./permissions";

//need to map an erro string to a user friendly message
const mapErrorToMessage = (error: string) => {
  switch (error) {
    case 'pq: duplicate key value violates unique constraint "users_email_key"':
      return "This email is already in use.";
    default:
      return "An error occurred. Please try again.";
  }
};

type Props = {
  userData: Partial<UB_AccountUser.AsObject>;
  onChange: (
    field: {
      object: "accountUser" | "user";
      field: string;
    },
    value: string | boolean
  ) => void;
  onSubmit: (e: React.FormEvent) => void;
  onCancel: () => void;
  title: string;
  currentLoggedInUser?: UB_AccountUser.AsObject;
  error: string | null;
};

export const UserForm: React.FC<Props> = ({
  userData,
  onChange,
  onSubmit,
  onCancel,
  title,
  currentLoggedInUser,
  error,
}) => {
  const { data: roles, isLoading: isLoadingRoles } = useFetchRoles();

  // Construct userBeingEdited from userData
  const userBeingEdited: UB_AccountUser.AsObject = {
    user: {
      id: userData.user?.id || "",
      name: userData.user?.name || "",
      email: userData.user?.email || "",
      userphotourl: userData.user?.userphotourl || "",
    },
    ubaccountid: userData.ubaccountid || currentLoggedInUser?.ubaccountid || "",
    isloggedin: userData.isloggedin || false,
    roleid: userData.roleid || "",
    rolename: roles?.find((role) => role.id === userData.roleid)?.role || "",
    isprimaryadmin: userData.isprimaryadmin || false,
    password: userData.password || "",
    isactive: userData.isactive || true,
  };

  return (
    <div style={{ padding: "20px" }}>
      <h3 id="user-form-title">{title}</h3>

      {/* If we have an error, display it here in red. */}
      {error && (
        <div style={{ color: "red", marginBottom: "10px" }}>
          <strong>{mapErrorToMessage(error)}</strong>
        </div>
      )}

      <form onSubmit={onSubmit}>
        <div>
          <label>Name: </label>
          <input
            type="text"
            value={userData.user?.name || ""}
            onChange={(e) =>
              onChange({ object: "user", field: "name" }, e.target.value)
            }
            required
            disabled={isFieldDisabled(
              currentLoggedInUser,
              userBeingEdited,
              "name"
            )}
          />
        </div>
        <div>
          <label>Email: </label>
          <input
            type="email"
            value={userData.user?.email || ""}
            onChange={(e) =>
              onChange({ object: "user", field: "email" }, e.target.value)
            }
            required
            disabled={isFieldDisabled(
              currentLoggedInUser,
              userBeingEdited,
              "email"
            )}
          />
        </div>
        <div>
          <label>Role ID: </label>
          <select
            value={userData.roleid || ""}
            onChange={(e) =>
              onChange(
                { object: "accountUser", field: "roleid" },
                e.target.value
              )
            }
            disabled={isFieldDisabled(
              currentLoggedInUser,
              userBeingEdited,
              "roleid"
            )}
          >
            {roles?.map((role: any) => (
              <option key={role.id} value={role.id}>
                {role.role}
              </option>
            ))}
          </select>
          {isLoadingRoles && <p>Loading roles...</p>}
        </div>
        <div>
          <label>Is Account Admin: </label>
          <input
            type="checkbox"
            checked={userData.isprimaryadmin || false}
            onChange={(e) =>
              onChange(
                { object: "accountUser", field: "isprimaryadmin" },
                e.target.checked
              )
            }
            disabled={isFieldDisabled(
              currentLoggedInUser,
              userBeingEdited,
              "isprimaryadmin"
            )}
          />
        </div>
        <div>
          <label>Is Logged In: </label>
          <input
            type="checkbox"
            checked={userData.isloggedin || false}
            onChange={(e) =>
              onChange(
                { object: "accountUser", field: "isloggedin" },
                e.target.checked
              )
            }
            disabled={
              isFieldDisabled(
                currentLoggedInUser,
                userBeingEdited,
                "isloggedin"
              ) ||
              userBeingEdited.user?.id === "" ||
              !userBeingEdited.isloggedin
            }
          />
        </div>
        <div>
          <label>Has Active License: </label>
          <input
            type="checkbox"
            checked={userData.isactive || false}
            onChange={(e) =>
              onChange(
                { object: "accountUser", field: "isactive" },
                e.target.checked
              )
            }
            disabled={isFieldDisabled(
              currentLoggedInUser,
              userBeingEdited,
              "isactive"
            )}
          />
        </div>
        <div>
          <label>Password: </label>
          <input
            type="password"
            value={userData.password || ""}
            placeholder="leave blank to keep existing"
            onChange={(e) =>
              onChange(
                { object: "accountUser", field: "password" },
                e.target.value
              )
            }
            disabled={isFieldDisabled(
              currentLoggedInUser,
              userBeingEdited,
              "password"
            )}
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <button type="submit">Save</button>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
