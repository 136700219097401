// src/components/AccountSettings/AccountSettings.tsx

import React, { useState } from "react";
import { useFetchUser, useFetchUsers } from "../../hooks/useFetchUsers";
import { Dialog, DialogContent } from "@mui/material";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from "../../hooks/useCreateUserMutation";
import {
  UB_AccountUser,
  UB_User,
} from "../../api/protosCompiled/ub_users/ub_users_pb";
import { UserForm } from "./UserForm";
import { EmailService } from "../../api/emailService";
import { useFetchRoles } from "../../hooks/useFetchRoles";
import { PermissionsChart } from "./PermissionsChart";
import { isFieldDisabled } from "./permissions";
import { useFetchAccount } from "../../hooks/useFetchAccount";
import { GetAccountResponse } from "../../api/protosCompiled/ub_account/ub_account_pb";

type CombinedField =
  | { object: "accountUser"; field: keyof UB_AccountUser.AsObject }
  | { object: "user"; field: keyof UB_User.AsObject };

export const AccountSettings: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const { data: users, isLoading, error: fetchUserError } = useFetchUsers(); //TODO change this out with account users
  const { data: currentLoggedInUser } = useFetchUser();
  const { data: roles } = useFetchRoles();
  const { data: ubAccount } = useFetchAccount();
  const ubaccountid = currentLoggedInUser?.ubaccountid || "";
  const fromEmail = process.env.REACT_APP_FROM_EMAIL || "";

  // Dialog State
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("Add New User");
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempPassword, setTempPassword] = useState("");

  // Current user in the sense of the user we are currently creating/editing
  const [currentUserData, setCurrentUserData] = useState<
    Partial<UB_AccountUser.AsObject>
  >({});

  const createUserMutation = useCreateUserMutation();
  const updateUserMutation = useUpdateUserMutation();

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText("help@unitybuildestimation.com");
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Hide the message after 2 seconds
  };

  // Generates a string of random characters for a temporary password for the user
  const generateTempPassword = () => {
    const randomString = Math.random().toString(36).substring(2, 8);
    setTempPassword(randomString);
    return randomString;
  };

  const handleOpenDialogForCreateUser = () => {
    setError(null); // Clear previous errors
    setIsEditMode(false);
    setDialogTitle("Create New User");
    setCurrentUserData({
      user: {
        id: "",
        name: "",
        email: "",
        userphotourl: "",
      },
      ubaccountid: ubaccountid,
      roleid: roles ? roles[1].id : "",
      isprimaryadmin: false,
      isloggedin: false,
      password: generateTempPassword(), //users to set their own passwords post log in
      isactive: true,
    });
    setShowDialog(true);
  };

  const handleOpenDialogForUpdateUser = (user: UB_AccountUser.AsObject) => {
    setError(null); // Clear previous errors
    if (!user) return;
    setIsEditMode(true);
    setDialogTitle("Edit User");
    setCurrentUserData({
      user: {
        id: user.user!.id,
        name: user.user!.name,
        email: user.user!.email,
        userphotourl: user.user!.userphotourl,
      },
      ubaccountid: ubaccountid,
      roleid: user.roleid,
      isprimaryadmin: user.isprimaryadmin,
      isloggedin: user.isloggedin,
      //password is not exposed, we handle in the backend
      isactive: user.isactive,
    });
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleUserChange = (
    combinedField: CombinedField,
    value: string | boolean
  ) => {
    setCurrentUserData((prev) => {
      if (combinedField.object === "user" && prev.user) {
        return {
          ...prev,
          user: {
            ...prev.user,
            [combinedField.field]: value,
          },
        };
      }
      return {
        ...prev,
        [combinedField.field]: value,
      };
    });
  };

  const formattedEmail = currentUserData.user?.email.replace(
    /[^a-zA-Z0-9@._-]/g,
    ""
  ); // Sanitize email

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Remove password from `currentUserData` if it’s blank
    const updatedUserData = { ...currentUserData };
    if (!updatedUserData.password) {
      delete updatedUserData.password; // Prevent sending an empty password
    }
    if (isEditMode) {
      // Update existing user
      updateUserMutation.mutate(currentUserData as UB_AccountUser.AsObject, {
        onSuccess: async () => {
          // Send email after successful update
          if (currentUserData.user!.email) {
            await EmailService.sendEmail({
              from: fromEmail,
              toList: [currentUserData.user!.email],
              subject: "Your Account Was Updated",
              plaintextcontent: `
Subject: Your Unity-Build Estimation Account Was Updated

Hello ${currentUserData.user?.name},

This is a confirmation that your Unity-Build Estimation account information was recently updated by ${currentUserData.user?.name}.

If you believe this account was compromised, please contact us at ${fromEmail} immediately.

To access your account:
1. Visit https://unitybuildestimation.com.
2. Click the "Log In" button in the top left corner.
3. Follow the log in instructions provided.

If you encounter any difficulties logging in or have any questions, please do not hesitate to reach out to us at ${fromEmail}.

Thank you for continuing to trust Unity-Build Estimation for your estimation needs.

Best regards,  
The Unity-Build Team
              `,
              htmlcontent: "",
            });
          }
          handleDialogClose();
        },
        onError: (err: any) => {
          // Catch server-side error and store it in local state
          console.error("Update user error:", err);
          setError(err.message || "Failed to update user. Please try again.");
        },
      });
    } else {
      // Create a new user
      createUserMutation.mutate(currentUserData as UB_AccountUser.AsObject, {
        onSuccess: async () => {
          // Send email after successful creation
          if (currentUserData.user!.email) {
            await EmailService.sendEmail({
              from: fromEmail,
              toList: [currentUserData.user!.email],
              subject: "Welcome to Our Service",
              plaintextcontent: `
Subject: Welcome to Unity-Build Estimation!

Hello ${currentUserData.user?.name},

We are excited to welcome you to Unity-Build Estimation. Your account has been successfully created, and you can now log in to start using our services.

Here are your login details:
- Email: ${formattedEmail}
- Temporary Password: ${tempPassword}

To access your account:
1. Visit https://unitybuildestimation.com.
2. Click the "Log In" button in the top left corner.
3. Use the credentials provided above.

Next Steps:
- Once logged in, we recommend updating your password for enhanced security. 
- You can update your password by clicking on the avatar in the top left corner, selecting "User Settings," and following the prompts.

If you experience any issues or have questions, feel free to contact us at ${fromEmail}.

Thank you for choosing Unity-Build Estimation! We look forward to supporting your estimation needs.

Best regards,  
The Unity-Build Team
              `,
              htmlcontent: "",
            });
          }
          handleDialogClose();
        },
        onError: (err: any) => {
          // Catch server-side error and store it in local state
          console.error("Create user error:", err);
          setError(err.message || "Failed to create user. Please try again.");
        },
      });
    }
  };

  if (isLoading) {
    return <div>Loading account settings...</div>;
  }

  if (fetchUserError) {
    return <div>Error loading users data.</div>;
  }

  const numAvailableSeats = (
    ubAccount: GetAccountResponse.AsObject | undefined
  ) => {
    if (!ubAccount) return 0; // Return a default value if ubAccount is undefined
    const loggedInUsersCount =
      ubAccount.usersList?.filter((user) => user.isloggedin).length || 0;
    const totalUsers = ubAccount.account?.numusers || 0;
    return totalUsers - loggedInUsersCount;
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        <h2>{ubAccount?.account?.name}'s Account Settings</h2>{" "}
        {/* Add a title for the section */}
        <h4>User: {currentLoggedInUser?.user?.name}</h4>
        <PermissionsChart />
        {/* Add the permissions chart here */}
        <div style={{ marginBottom: "20px" }}>
          <button
            onClick={handleOpenDialogForCreateUser}
            disabled={isFieldDisabled(
              currentLoggedInUser,
              undefined,
              "Add New User"
            )}
          >
            Add New User
          </button>
          {/* Add other actions like Edit Subscription here if needed */}
          {currentLoggedInUser?.isprimaryadmin && (
            <h4>
              To make account changes, please email{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                  marginLeft: "5px",
                }}
                onClick={handleCopy}
              >
                help@unitybuildestimation.com
              </span>
              . Change requests must be received no less than 5 buisness days
              prior to subsription renewals to effect the next renewal.
              {copied && (
                <span style={{ marginLeft: "10px", color: "green" }}>
                  Copied to clipboard!
                </span>
              )}
            </h4>
          )}
          <strong>Current Seat Count:</strong> {ubAccount?.account?.numusers}
          <br />
          <strong>Current Available Seats:</strong>{" "}
          {numAvailableSeats(ubAccount as any)}
          <br />
        </div>
        <table
          style={{
            width: "100%", // Ensure the table spans the full width
            borderCollapse: "collapse", // Avoid extra spacing around borders
            tableLayout: "auto", // Allow columns to size based on content
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  whiteSpace: "nowrap",
                }}
              >
                Name
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  whiteSpace: "nowrap",
                }}
              >
                Email
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  whiteSpace: "nowrap",
                }}
              >
                User Level
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  whiteSpace: "nowrap",
                }}
              >
                Account Admin
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  whiteSpace: "nowrap",
                }}
              >
                Logged In
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  whiteSpace: "nowrap",
                }}
              >
                Is Active
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  whiteSpace: "nowrap",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => (
                <tr key={user.user?.id}>
                  <td style={{ padding: "8px" }}>{user.user?.name}</td>
                  <td style={{ padding: "8px" }}>{user.user?.email}</td>
                  <td style={{ padding: "8px" }}>{user.rolename}</td>
                  <td style={{ padding: "8px" }}>
                    {user.isprimaryadmin ? "Yes" : "No"}
                  </td>
                  <td style={{ padding: "8px" }}>
                    {user.isloggedin ? "Yes" : "No"}
                  </td>
                  <td style={{ padding: "8px" }}>
                    {user.isactive ? "Yes" : "No"}
                  </td>
                  <td style={{ padding: "8px" }}>
                    <button onClick={() => handleOpenDialogForUpdateUser(user)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* User Form Dialog */}
      <Dialog
        open={showDialog}
        onClose={handleDialogClose}
        aria-labelledby="user-form-title"
      >
        <DialogContent>
          <UserForm
            userData={currentUserData}
            onChange={handleUserChange as any}
            onSubmit={handleFormSubmit}
            onCancel={handleDialogClose}
            title={dialogTitle}
            currentLoggedInUser={currentLoggedInUser}
            error={error}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
