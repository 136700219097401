/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: ub_account.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as ub_account_pb from './ub_account_pb'; // proto import: "ub_account.proto"


export class UB_AccountServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateAccount = new grpcWeb.MethodDescriptor(
    '/UB_AccountService/CreateAccount',
    grpcWeb.MethodType.UNARY,
    ub_account_pb.CreateAccountRequest,
    ub_account_pb.CreateAccountResponse,
    (request: ub_account_pb.CreateAccountRequest) => {
      return request.serializeBinary();
    },
    ub_account_pb.CreateAccountResponse.deserializeBinary
  );

  createAccount(
    request: ub_account_pb.CreateAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_account_pb.CreateAccountResponse>;

  createAccount(
    request: ub_account_pb.CreateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_account_pb.CreateAccountResponse) => void): grpcWeb.ClientReadableStream<ub_account_pb.CreateAccountResponse>;

  createAccount(
    request: ub_account_pb.CreateAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_account_pb.CreateAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_AccountService/CreateAccount',
        request,
        metadata || {},
        this.methodDescriptorCreateAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_AccountService/CreateAccount',
    request,
    metadata || {},
    this.methodDescriptorCreateAccount);
  }

  methodDescriptorUpdateAccount = new grpcWeb.MethodDescriptor(
    '/UB_AccountService/UpdateAccount',
    grpcWeb.MethodType.UNARY,
    ub_account_pb.UpdateAccountRequest,
    ub_account_pb.UpdateAccountResponse,
    (request: ub_account_pb.UpdateAccountRequest) => {
      return request.serializeBinary();
    },
    ub_account_pb.UpdateAccountResponse.deserializeBinary
  );

  updateAccount(
    request: ub_account_pb.UpdateAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_account_pb.UpdateAccountResponse>;

  updateAccount(
    request: ub_account_pb.UpdateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_account_pb.UpdateAccountResponse) => void): grpcWeb.ClientReadableStream<ub_account_pb.UpdateAccountResponse>;

  updateAccount(
    request: ub_account_pb.UpdateAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_account_pb.UpdateAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_AccountService/UpdateAccount',
        request,
        metadata || {},
        this.methodDescriptorUpdateAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_AccountService/UpdateAccount',
    request,
    metadata || {},
    this.methodDescriptorUpdateAccount);
  }

  methodDescriptorGetAccount = new grpcWeb.MethodDescriptor(
    '/UB_AccountService/GetAccount',
    grpcWeb.MethodType.UNARY,
    ub_account_pb.GetAccountRequest,
    ub_account_pb.GetAccountResponse,
    (request: ub_account_pb.GetAccountRequest) => {
      return request.serializeBinary();
    },
    ub_account_pb.GetAccountResponse.deserializeBinary
  );

  getAccount(
    request: ub_account_pb.GetAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_account_pb.GetAccountResponse>;

  getAccount(
    request: ub_account_pb.GetAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_account_pb.GetAccountResponse) => void): grpcWeb.ClientReadableStream<ub_account_pb.GetAccountResponse>;

  getAccount(
    request: ub_account_pb.GetAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_account_pb.GetAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_AccountService/GetAccount',
        request,
        metadata || {},
        this.methodDescriptorGetAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_AccountService/GetAccount',
    request,
    metadata || {},
    this.methodDescriptorGetAccount);
  }

}

