import { useQuery } from "react-query";
import { LookupUPCResponse } from "../api/protosCompiled/upcService/upcService_pb";
import { UPCLookupService } from "../api/UPCLookupService";

export interface ReadableItem {
  title: string;
  description: string;
  upc: string;
  brand: string;
  model: string;
  category: string;
  lowestRecordedPrice: number;
  highestRecordedPrice: number;
  imagesList: string[];
  offersList: {
    merchant: string;
    price: number;
    domain: string;
    link: string;
  }[];
}

export function useFetchUPC(upc: string) {
  const fetchUpc = async (): Promise<ReadableItem[]> => {
    const res: LookupUPCResponse = await UPCLookupService.lookupUPC(upc);
    const items = res.getItemsList() || [];
    return items.map((item) => ({
      title: item.getTitle() || "N/A",
      description: item.getDescription() || "N/A",
      upc: item.getUpc() || "N/A",
      brand: item.getBrand() || "N/A",
      model: item.getModel() || "N/A",
      category: item.getCategory() || "N/A",
      lowestRecordedPrice: item.getLowestRecordedPrice() || 0,
      highestRecordedPrice: item.getHighestRecordedPrice() || 0,
      imagesList: item.getImagesList() || [],
      offersList: (item.getOffersList() || []).map((offer) => ({
        merchant: offer.getMerchant() || "Unknown",
        price: offer.getPrice() || 0,
        domain: offer.getDomain() || "Unknown",
        link: offer.getLink() || "#",
      })),
    }));
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    ReadableItem[],
    Error
  >(["upc", upc], fetchUpc, {
    enabled: !!upc,
    refetchOnWindowFocus: false,
  });
  return { data, error, isLoading, isFetching, refetch };
}
