// source: defaultCloseout.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.CreateOrUpdateDefaultDirectLaborTypeRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultDirectLaborTypeResponse', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultIndirectLaborTypeRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultIndirectLaborTypeResponse', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultLaborFactoringTypeRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultLaborFactoringTypeResponse', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse', null, global);
goog.exportSymbol('proto.DefaultDirectLaborType', null, global);
goog.exportSymbol('proto.DefaultEquipmentType', null, global);
goog.exportSymbol('proto.DefaultGeneralExpenseType', null, global);
goog.exportSymbol('proto.DefaultIncidentalLaborType', null, global);
goog.exportSymbol('proto.DefaultIndirectLaborType', null, global);
goog.exportSymbol('proto.DefaultLaborFactoringType', null, global);
goog.exportSymbol('proto.DefaultQuoteType', null, global);
goog.exportSymbol('proto.DefaultSubcontractType', null, global);
goog.exportSymbol('proto.DeleteDefaultDirectLaborTypeRequest', null, global);
goog.exportSymbol('proto.DeleteDefaultDirectLaborTypeResponse', null, global);
goog.exportSymbol('proto.DeleteDefaultEquipmentExpenseTypeRequest', null, global);
goog.exportSymbol('proto.DeleteDefaultEquipmentExpenseTypeResponse', null, global);
goog.exportSymbol('proto.DeleteDefaultGeneralExpenseTypeRequest', null, global);
goog.exportSymbol('proto.DeleteDefaultGeneralExpenseTypeResponse', null, global);
goog.exportSymbol('proto.DeleteDefaultIncidentalLaborTypeRequest', null, global);
goog.exportSymbol('proto.DeleteDefaultIncidentalLaborTypeResponse', null, global);
goog.exportSymbol('proto.DeleteDefaultIndirectLaborTypeRequest', null, global);
goog.exportSymbol('proto.DeleteDefaultIndirectLaborTypeResponse', null, global);
goog.exportSymbol('proto.DeleteDefaultLaborFactoringTypeRequest', null, global);
goog.exportSymbol('proto.DeleteDefaultLaborFactoringTypeResponse', null, global);
goog.exportSymbol('proto.DeleteDefaultQuoteExpenseTypeRequest', null, global);
goog.exportSymbol('proto.DeleteDefaultQuoteExpenseTypeResponse', null, global);
goog.exportSymbol('proto.DeleteDefaultSubcontractExpenseTypeRequest', null, global);
goog.exportSymbol('proto.DeleteDefaultSubcontractExpenseTypeResponse', null, global);
goog.exportSymbol('proto.GetDefaultDirectLaborTypesRequest', null, global);
goog.exportSymbol('proto.GetDefaultDirectLaborTypesResponse', null, global);
goog.exportSymbol('proto.GetDefaultEquipmentExpenseTypesRequest', null, global);
goog.exportSymbol('proto.GetDefaultEquipmentExpenseTypesResponse', null, global);
goog.exportSymbol('proto.GetDefaultGeneralExpenseTypesRequest', null, global);
goog.exportSymbol('proto.GetDefaultGeneralExpenseTypesResponse', null, global);
goog.exportSymbol('proto.GetDefaultIncidentalLaborTypesRequest', null, global);
goog.exportSymbol('proto.GetDefaultIncidentalLaborTypesResponse', null, global);
goog.exportSymbol('proto.GetDefaultIndirectLaborTypesRequest', null, global);
goog.exportSymbol('proto.GetDefaultIndirectLaborTypesResponse', null, global);
goog.exportSymbol('proto.GetDefaultLaborFactoringTypesRequest', null, global);
goog.exportSymbol('proto.GetDefaultLaborFactoringTypesResponse', null, global);
goog.exportSymbol('proto.GetDefaultQuoteExpenseTypesRequest', null, global);
goog.exportSymbol('proto.GetDefaultQuoteExpenseTypesResponse', null, global);
goog.exportSymbol('proto.GetDefaultSubcontractExpenseTypesRequest', null, global);
goog.exportSymbol('proto.GetDefaultSubcontractExpenseTypesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DefaultDirectLaborType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DefaultDirectLaborType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DefaultDirectLaborType.displayName = 'proto.DefaultDirectLaborType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultDirectLaborTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultDirectLaborTypeRequest.displayName = 'proto.CreateOrUpdateDefaultDirectLaborTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultDirectLaborTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultDirectLaborTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultDirectLaborTypeResponse.displayName = 'proto.CreateOrUpdateDefaultDirectLaborTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultDirectLaborTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDefaultDirectLaborTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultDirectLaborTypesRequest.displayName = 'proto.GetDefaultDirectLaborTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultDirectLaborTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDefaultDirectLaborTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDefaultDirectLaborTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultDirectLaborTypesResponse.displayName = 'proto.GetDefaultDirectLaborTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultDirectLaborTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultDirectLaborTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultDirectLaborTypeRequest.displayName = 'proto.DeleteDefaultDirectLaborTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultDirectLaborTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultDirectLaborTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultDirectLaborTypeResponse.displayName = 'proto.DeleteDefaultDirectLaborTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DefaultLaborFactoringType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DefaultLaborFactoringType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DefaultLaborFactoringType.displayName = 'proto.DefaultLaborFactoringType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultLaborFactoringTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.displayName = 'proto.CreateOrUpdateDefaultLaborFactoringTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultLaborFactoringTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.displayName = 'proto.CreateOrUpdateDefaultLaborFactoringTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultLaborFactoringTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDefaultLaborFactoringTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultLaborFactoringTypesRequest.displayName = 'proto.GetDefaultLaborFactoringTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultLaborFactoringTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDefaultLaborFactoringTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDefaultLaborFactoringTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultLaborFactoringTypesResponse.displayName = 'proto.GetDefaultLaborFactoringTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultLaborFactoringTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultLaborFactoringTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultLaborFactoringTypeRequest.displayName = 'proto.DeleteDefaultLaborFactoringTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultLaborFactoringTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultLaborFactoringTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultLaborFactoringTypeResponse.displayName = 'proto.DeleteDefaultLaborFactoringTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DefaultIndirectLaborType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DefaultIndirectLaborType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DefaultIndirectLaborType.displayName = 'proto.DefaultIndirectLaborType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultIndirectLaborTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.displayName = 'proto.CreateOrUpdateDefaultIndirectLaborTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultIndirectLaborTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.displayName = 'proto.CreateOrUpdateDefaultIndirectLaborTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultIndirectLaborTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDefaultIndirectLaborTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultIndirectLaborTypesRequest.displayName = 'proto.GetDefaultIndirectLaborTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultIndirectLaborTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDefaultIndirectLaborTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDefaultIndirectLaborTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultIndirectLaborTypesResponse.displayName = 'proto.GetDefaultIndirectLaborTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultIndirectLaborTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultIndirectLaborTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultIndirectLaborTypeRequest.displayName = 'proto.DeleteDefaultIndirectLaborTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultIndirectLaborTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultIndirectLaborTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultIndirectLaborTypeResponse.displayName = 'proto.DeleteDefaultIndirectLaborTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DefaultIncidentalLaborType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DefaultIncidentalLaborType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DefaultIncidentalLaborType.displayName = 'proto.DefaultIncidentalLaborType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.displayName = 'proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.displayName = 'proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultIncidentalLaborTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDefaultIncidentalLaborTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultIncidentalLaborTypesRequest.displayName = 'proto.GetDefaultIncidentalLaborTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultIncidentalLaborTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDefaultIncidentalLaborTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDefaultIncidentalLaborTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultIncidentalLaborTypesResponse.displayName = 'proto.GetDefaultIncidentalLaborTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultIncidentalLaborTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultIncidentalLaborTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultIncidentalLaborTypeRequest.displayName = 'proto.DeleteDefaultIncidentalLaborTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultIncidentalLaborTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultIncidentalLaborTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultIncidentalLaborTypeResponse.displayName = 'proto.DeleteDefaultIncidentalLaborTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DefaultEquipmentType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DefaultEquipmentType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DefaultEquipmentType.displayName = 'proto.DefaultEquipmentType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.displayName = 'proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.displayName = 'proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultEquipmentExpenseTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDefaultEquipmentExpenseTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultEquipmentExpenseTypesRequest.displayName = 'proto.GetDefaultEquipmentExpenseTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultEquipmentExpenseTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDefaultEquipmentExpenseTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDefaultEquipmentExpenseTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultEquipmentExpenseTypesResponse.displayName = 'proto.GetDefaultEquipmentExpenseTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultEquipmentExpenseTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultEquipmentExpenseTypeRequest.displayName = 'proto.DeleteDefaultEquipmentExpenseTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultEquipmentExpenseTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultEquipmentExpenseTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultEquipmentExpenseTypeResponse.displayName = 'proto.DeleteDefaultEquipmentExpenseTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DefaultGeneralExpenseType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DefaultGeneralExpenseType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DefaultGeneralExpenseType.displayName = 'proto.DefaultGeneralExpenseType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.displayName = 'proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.displayName = 'proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultGeneralExpenseTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDefaultGeneralExpenseTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultGeneralExpenseTypesRequest.displayName = 'proto.GetDefaultGeneralExpenseTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultGeneralExpenseTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDefaultGeneralExpenseTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDefaultGeneralExpenseTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultGeneralExpenseTypesResponse.displayName = 'proto.GetDefaultGeneralExpenseTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultGeneralExpenseTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultGeneralExpenseTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultGeneralExpenseTypeRequest.displayName = 'proto.DeleteDefaultGeneralExpenseTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultGeneralExpenseTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultGeneralExpenseTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultGeneralExpenseTypeResponse.displayName = 'proto.DeleteDefaultGeneralExpenseTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DefaultSubcontractType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DefaultSubcontractType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DefaultSubcontractType.displayName = 'proto.DefaultSubcontractType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.displayName = 'proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.displayName = 'proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultSubcontractExpenseTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDefaultSubcontractExpenseTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultSubcontractExpenseTypesRequest.displayName = 'proto.GetDefaultSubcontractExpenseTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultSubcontractExpenseTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDefaultSubcontractExpenseTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDefaultSubcontractExpenseTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultSubcontractExpenseTypesResponse.displayName = 'proto.GetDefaultSubcontractExpenseTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultSubcontractExpenseTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultSubcontractExpenseTypeRequest.displayName = 'proto.DeleteDefaultSubcontractExpenseTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultSubcontractExpenseTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultSubcontractExpenseTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultSubcontractExpenseTypeResponse.displayName = 'proto.DeleteDefaultSubcontractExpenseTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DefaultQuoteType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DefaultQuoteType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DefaultQuoteType.displayName = 'proto.DefaultQuoteType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.displayName = 'proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.displayName = 'proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultQuoteExpenseTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDefaultQuoteExpenseTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultQuoteExpenseTypesRequest.displayName = 'proto.GetDefaultQuoteExpenseTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDefaultQuoteExpenseTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetDefaultQuoteExpenseTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.GetDefaultQuoteExpenseTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDefaultQuoteExpenseTypesResponse.displayName = 'proto.GetDefaultQuoteExpenseTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultQuoteExpenseTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultQuoteExpenseTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultQuoteExpenseTypeRequest.displayName = 'proto.DeleteDefaultQuoteExpenseTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteDefaultQuoteExpenseTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteDefaultQuoteExpenseTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteDefaultQuoteExpenseTypeResponse.displayName = 'proto.DeleteDefaultQuoteExpenseTypeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DefaultDirectLaborType.prototype.toObject = function(opt_includeInstance) {
  return proto.DefaultDirectLaborType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DefaultDirectLaborType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultDirectLaborType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    distributionpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DefaultDirectLaborType}
 */
proto.DefaultDirectLaborType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DefaultDirectLaborType;
  return proto.DefaultDirectLaborType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DefaultDirectLaborType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DefaultDirectLaborType}
 */
proto.DefaultDirectLaborType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistributionpercent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DefaultDirectLaborType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DefaultDirectLaborType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DefaultDirectLaborType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultDirectLaborType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDistributionpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.DefaultDirectLaborType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultDirectLaborType} returns this
 */
proto.DefaultDirectLaborType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float laborRate = 2;
 * @return {number}
 */
proto.DefaultDirectLaborType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultDirectLaborType} returns this
 */
proto.DefaultDirectLaborType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float burdenPercent = 3;
 * @return {number}
 */
proto.DefaultDirectLaborType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultDirectLaborType} returns this
 */
proto.DefaultDirectLaborType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float fringe = 4;
 * @return {number}
 */
proto.DefaultDirectLaborType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultDirectLaborType} returns this
 */
proto.DefaultDirectLaborType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float distributionPercent = 5;
 * @return {number}
 */
proto.DefaultDirectLaborType.prototype.getDistributionpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultDirectLaborType} returns this
 */
proto.DefaultDirectLaborType.prototype.setDistributionpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.DefaultDirectLaborType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultDirectLaborType} returns this
 */
proto.DefaultDirectLaborType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultDirectLaborTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultDirectLaborTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultdirectlabortype: (f = msg.getDefaultdirectlabortype()) && proto.DefaultDirectLaborType.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultDirectLaborTypeRequest}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultDirectLaborTypeRequest;
  return proto.CreateOrUpdateDefaultDirectLaborTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultDirectLaborTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultDirectLaborTypeRequest}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultDirectLaborType;
      reader.readMessage(value,proto.DefaultDirectLaborType.deserializeBinaryFromReader);
      msg.setDefaultdirectlabortype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultDirectLaborTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultDirectLaborTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultdirectlabortype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DefaultDirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DefaultDirectLaborType defaultDirectLaborType = 1;
 * @return {?proto.DefaultDirectLaborType}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.prototype.getDefaultdirectlabortype = function() {
  return /** @type{?proto.DefaultDirectLaborType} */ (
    jspb.Message.getWrapperField(this, proto.DefaultDirectLaborType, 1));
};


/**
 * @param {?proto.DefaultDirectLaborType|undefined} value
 * @return {!proto.CreateOrUpdateDefaultDirectLaborTypeRequest} returns this
*/
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.prototype.setDefaultdirectlabortype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateDefaultDirectLaborTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.prototype.clearDefaultdirectlabortype = function() {
  return this.setDefaultdirectlabortype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.prototype.hasDefaultdirectlabortype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateDefaultDirectLaborTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultDirectLaborTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultDirectLaborTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultDirectLaborTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultDirectLaborTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultDirectLaborTypeResponse}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultDirectLaborTypeResponse;
  return proto.CreateOrUpdateDefaultDirectLaborTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultDirectLaborTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultDirectLaborTypeResponse}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultDirectLaborTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultDirectLaborTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultDirectLaborTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultDirectLaborTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateOrUpdateDefaultDirectLaborTypeResponse} returns this
 */
proto.CreateOrUpdateDefaultDirectLaborTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultDirectLaborTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultDirectLaborTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultDirectLaborTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultDirectLaborTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultDirectLaborTypesRequest}
 */
proto.GetDefaultDirectLaborTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultDirectLaborTypesRequest;
  return proto.GetDefaultDirectLaborTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultDirectLaborTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultDirectLaborTypesRequest}
 */
proto.GetDefaultDirectLaborTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultDirectLaborTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultDirectLaborTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultDirectLaborTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultDirectLaborTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sessionToken = 1;
 * @return {string}
 */
proto.GetDefaultDirectLaborTypesRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDefaultDirectLaborTypesRequest} returns this
 */
proto.GetDefaultDirectLaborTypesRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDefaultDirectLaborTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultDirectLaborTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultDirectLaborTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultDirectLaborTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultDirectLaborTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultdirectlabortypesList: jspb.Message.toObjectList(msg.getDefaultdirectlabortypesList(),
    proto.DefaultDirectLaborType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultDirectLaborTypesResponse}
 */
proto.GetDefaultDirectLaborTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultDirectLaborTypesResponse;
  return proto.GetDefaultDirectLaborTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultDirectLaborTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultDirectLaborTypesResponse}
 */
proto.GetDefaultDirectLaborTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultDirectLaborType;
      reader.readMessage(value,proto.DefaultDirectLaborType.deserializeBinaryFromReader);
      msg.addDefaultdirectlabortypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultDirectLaborTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultDirectLaborTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultDirectLaborTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultDirectLaborTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultdirectlabortypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DefaultDirectLaborType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DefaultDirectLaborType defaultDirectLaborTypes = 1;
 * @return {!Array<!proto.DefaultDirectLaborType>}
 */
proto.GetDefaultDirectLaborTypesResponse.prototype.getDefaultdirectlabortypesList = function() {
  return /** @type{!Array<!proto.DefaultDirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DefaultDirectLaborType, 1));
};


/**
 * @param {!Array<!proto.DefaultDirectLaborType>} value
 * @return {!proto.GetDefaultDirectLaborTypesResponse} returns this
*/
proto.GetDefaultDirectLaborTypesResponse.prototype.setDefaultdirectlabortypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DefaultDirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DefaultDirectLaborType}
 */
proto.GetDefaultDirectLaborTypesResponse.prototype.addDefaultdirectlabortypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DefaultDirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDefaultDirectLaborTypesResponse} returns this
 */
proto.GetDefaultDirectLaborTypesResponse.prototype.clearDefaultdirectlabortypesList = function() {
  return this.setDefaultdirectlabortypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultDirectLaborTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultDirectLaborTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultDirectLaborTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultDirectLaborTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultDirectLaborTypeRequest}
 */
proto.DeleteDefaultDirectLaborTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultDirectLaborTypeRequest;
  return proto.DeleteDefaultDirectLaborTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultDirectLaborTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultDirectLaborTypeRequest}
 */
proto.DeleteDefaultDirectLaborTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultDirectLaborTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultDirectLaborTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultDirectLaborTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultDirectLaborTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDefaultDirectLaborTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultDirectLaborTypeRequest} returns this
 */
proto.DeleteDefaultDirectLaborTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDefaultDirectLaborTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultDirectLaborTypeRequest} returns this
 */
proto.DeleteDefaultDirectLaborTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultDirectLaborTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultDirectLaborTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultDirectLaborTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultDirectLaborTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultDirectLaborTypeResponse}
 */
proto.DeleteDefaultDirectLaborTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultDirectLaborTypeResponse;
  return proto.DeleteDefaultDirectLaborTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultDirectLaborTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultDirectLaborTypeResponse}
 */
proto.DeleteDefaultDirectLaborTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultDirectLaborTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultDirectLaborTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultDirectLaborTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultDirectLaborTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDefaultDirectLaborTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDefaultDirectLaborTypeResponse} returns this
 */
proto.DeleteDefaultDirectLaborTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DefaultLaborFactoringType.prototype.toObject = function(opt_includeInstance) {
  return proto.DefaultLaborFactoringType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DefaultLaborFactoringType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultLaborFactoringType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    impactpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    laborpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DefaultLaborFactoringType}
 */
proto.DefaultLaborFactoringType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DefaultLaborFactoringType;
  return proto.DefaultLaborFactoringType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DefaultLaborFactoringType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DefaultLaborFactoringType}
 */
proto.DefaultLaborFactoringType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImpactpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborpercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DefaultLaborFactoringType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DefaultLaborFactoringType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DefaultLaborFactoringType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultLaborFactoringType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getImpactpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getLaborpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DefaultLaborFactoringType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultLaborFactoringType} returns this
 */
proto.DefaultLaborFactoringType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.DefaultLaborFactoringType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultLaborFactoringType} returns this
 */
proto.DefaultLaborFactoringType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float laborRate = 3;
 * @return {number}
 */
proto.DefaultLaborFactoringType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultLaborFactoringType} returns this
 */
proto.DefaultLaborFactoringType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float burdenPercent = 4;
 * @return {number}
 */
proto.DefaultLaborFactoringType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultLaborFactoringType} returns this
 */
proto.DefaultLaborFactoringType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float fringe = 5;
 * @return {number}
 */
proto.DefaultLaborFactoringType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultLaborFactoringType} returns this
 */
proto.DefaultLaborFactoringType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float impactPercent = 6;
 * @return {number}
 */
proto.DefaultLaborFactoringType.prototype.getImpactpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultLaborFactoringType} returns this
 */
proto.DefaultLaborFactoringType.prototype.setImpactpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float laborPercent = 7;
 * @return {number}
 */
proto.DefaultLaborFactoringType.prototype.getLaborpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultLaborFactoringType} returns this
 */
proto.DefaultLaborFactoringType.prototype.setLaborpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultLaborFactoringTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultlaborfactoringtype: (f = msg.getDefaultlaborfactoringtype()) && proto.DefaultLaborFactoringType.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultLaborFactoringTypeRequest}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultLaborFactoringTypeRequest;
  return proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultLaborFactoringTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultLaborFactoringTypeRequest}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultLaborFactoringType;
      reader.readMessage(value,proto.DefaultLaborFactoringType.deserializeBinaryFromReader);
      msg.setDefaultlaborfactoringtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultLaborFactoringTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultlaborfactoringtype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DefaultLaborFactoringType.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DefaultLaborFactoringType defaultLaborFactoringType = 1;
 * @return {?proto.DefaultLaborFactoringType}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.prototype.getDefaultlaborfactoringtype = function() {
  return /** @type{?proto.DefaultLaborFactoringType} */ (
    jspb.Message.getWrapperField(this, proto.DefaultLaborFactoringType, 1));
};


/**
 * @param {?proto.DefaultLaborFactoringType|undefined} value
 * @return {!proto.CreateOrUpdateDefaultLaborFactoringTypeRequest} returns this
*/
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.prototype.setDefaultlaborfactoringtype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateDefaultLaborFactoringTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.prototype.clearDefaultlaborfactoringtype = function() {
  return this.setDefaultlaborfactoringtype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.prototype.hasDefaultlaborfactoringtype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateDefaultLaborFactoringTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultLaborFactoringTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultLaborFactoringTypeResponse}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultLaborFactoringTypeResponse;
  return proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultLaborFactoringTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultLaborFactoringTypeResponse}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultLaborFactoringTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateOrUpdateDefaultLaborFactoringTypeResponse} returns this
 */
proto.CreateOrUpdateDefaultLaborFactoringTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultLaborFactoringTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultLaborFactoringTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultLaborFactoringTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultLaborFactoringTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultLaborFactoringTypesRequest}
 */
proto.GetDefaultLaborFactoringTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultLaborFactoringTypesRequest;
  return proto.GetDefaultLaborFactoringTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultLaborFactoringTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultLaborFactoringTypesRequest}
 */
proto.GetDefaultLaborFactoringTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultLaborFactoringTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultLaborFactoringTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultLaborFactoringTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultLaborFactoringTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sessionToken = 1;
 * @return {string}
 */
proto.GetDefaultLaborFactoringTypesRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDefaultLaborFactoringTypesRequest} returns this
 */
proto.GetDefaultLaborFactoringTypesRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDefaultLaborFactoringTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultLaborFactoringTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultLaborFactoringTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultLaborFactoringTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultLaborFactoringTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultlaborfactoringtypesList: jspb.Message.toObjectList(msg.getDefaultlaborfactoringtypesList(),
    proto.DefaultLaborFactoringType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultLaborFactoringTypesResponse}
 */
proto.GetDefaultLaborFactoringTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultLaborFactoringTypesResponse;
  return proto.GetDefaultLaborFactoringTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultLaborFactoringTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultLaborFactoringTypesResponse}
 */
proto.GetDefaultLaborFactoringTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultLaborFactoringType;
      reader.readMessage(value,proto.DefaultLaborFactoringType.deserializeBinaryFromReader);
      msg.addDefaultlaborfactoringtypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultLaborFactoringTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultLaborFactoringTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultLaborFactoringTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultLaborFactoringTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultlaborfactoringtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DefaultLaborFactoringType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DefaultLaborFactoringType defaultLaborFactoringTypes = 1;
 * @return {!Array<!proto.DefaultLaborFactoringType>}
 */
proto.GetDefaultLaborFactoringTypesResponse.prototype.getDefaultlaborfactoringtypesList = function() {
  return /** @type{!Array<!proto.DefaultLaborFactoringType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DefaultLaborFactoringType, 1));
};


/**
 * @param {!Array<!proto.DefaultLaborFactoringType>} value
 * @return {!proto.GetDefaultLaborFactoringTypesResponse} returns this
*/
proto.GetDefaultLaborFactoringTypesResponse.prototype.setDefaultlaborfactoringtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DefaultLaborFactoringType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DefaultLaborFactoringType}
 */
proto.GetDefaultLaborFactoringTypesResponse.prototype.addDefaultlaborfactoringtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DefaultLaborFactoringType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDefaultLaborFactoringTypesResponse} returns this
 */
proto.GetDefaultLaborFactoringTypesResponse.prototype.clearDefaultlaborfactoringtypesList = function() {
  return this.setDefaultlaborfactoringtypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultLaborFactoringTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultLaborFactoringTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultLaborFactoringTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultLaborFactoringTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultLaborFactoringTypeRequest}
 */
proto.DeleteDefaultLaborFactoringTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultLaborFactoringTypeRequest;
  return proto.DeleteDefaultLaborFactoringTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultLaborFactoringTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultLaborFactoringTypeRequest}
 */
proto.DeleteDefaultLaborFactoringTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultLaborFactoringTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultLaborFactoringTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultLaborFactoringTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultLaborFactoringTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDefaultLaborFactoringTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultLaborFactoringTypeRequest} returns this
 */
proto.DeleteDefaultLaborFactoringTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDefaultLaborFactoringTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultLaborFactoringTypeRequest} returns this
 */
proto.DeleteDefaultLaborFactoringTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultLaborFactoringTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultLaborFactoringTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultLaborFactoringTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultLaborFactoringTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultLaborFactoringTypeResponse}
 */
proto.DeleteDefaultLaborFactoringTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultLaborFactoringTypeResponse;
  return proto.DeleteDefaultLaborFactoringTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultLaborFactoringTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultLaborFactoringTypeResponse}
 */
proto.DeleteDefaultLaborFactoringTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultLaborFactoringTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultLaborFactoringTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultLaborFactoringTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultLaborFactoringTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDefaultLaborFactoringTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDefaultLaborFactoringTypeResponse} returns this
 */
proto.DeleteDefaultLaborFactoringTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DefaultIndirectLaborType.prototype.toObject = function(opt_includeInstance) {
  return proto.DefaultIndirectLaborType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DefaultIndirectLaborType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultIndirectLaborType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DefaultIndirectLaborType}
 */
proto.DefaultIndirectLaborType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DefaultIndirectLaborType;
  return proto.DefaultIndirectLaborType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DefaultIndirectLaborType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DefaultIndirectLaborType}
 */
proto.DefaultIndirectLaborType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DefaultIndirectLaborType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DefaultIndirectLaborType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DefaultIndirectLaborType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultIndirectLaborType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.DefaultIndirectLaborType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultIndirectLaborType} returns this
 */
proto.DefaultIndirectLaborType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float laborRate = 2;
 * @return {number}
 */
proto.DefaultIndirectLaborType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultIndirectLaborType} returns this
 */
proto.DefaultIndirectLaborType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float burdenPercent = 3;
 * @return {number}
 */
proto.DefaultIndirectLaborType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultIndirectLaborType} returns this
 */
proto.DefaultIndirectLaborType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float fringe = 4;
 * @return {number}
 */
proto.DefaultIndirectLaborType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultIndirectLaborType} returns this
 */
proto.DefaultIndirectLaborType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string id = 5;
 * @return {string}
 */
proto.DefaultIndirectLaborType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultIndirectLaborType} returns this
 */
proto.DefaultIndirectLaborType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultIndirectLaborTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultindirectlabortype: (f = msg.getDefaultindirectlabortype()) && proto.DefaultIndirectLaborType.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultIndirectLaborTypeRequest}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultIndirectLaborTypeRequest;
  return proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultIndirectLaborTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultIndirectLaborTypeRequest}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultIndirectLaborType;
      reader.readMessage(value,proto.DefaultIndirectLaborType.deserializeBinaryFromReader);
      msg.setDefaultindirectlabortype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultIndirectLaborTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultindirectlabortype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DefaultIndirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DefaultIndirectLaborType defaultIndirectLaborType = 1;
 * @return {?proto.DefaultIndirectLaborType}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.prototype.getDefaultindirectlabortype = function() {
  return /** @type{?proto.DefaultIndirectLaborType} */ (
    jspb.Message.getWrapperField(this, proto.DefaultIndirectLaborType, 1));
};


/**
 * @param {?proto.DefaultIndirectLaborType|undefined} value
 * @return {!proto.CreateOrUpdateDefaultIndirectLaborTypeRequest} returns this
*/
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.prototype.setDefaultindirectlabortype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateDefaultIndirectLaborTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.prototype.clearDefaultindirectlabortype = function() {
  return this.setDefaultindirectlabortype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.prototype.hasDefaultindirectlabortype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateDefaultIndirectLaborTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultIndirectLaborTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultIndirectLaborTypeResponse}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultIndirectLaborTypeResponse;
  return proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultIndirectLaborTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultIndirectLaborTypeResponse}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultIndirectLaborTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateOrUpdateDefaultIndirectLaborTypeResponse} returns this
 */
proto.CreateOrUpdateDefaultIndirectLaborTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultIndirectLaborTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultIndirectLaborTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultIndirectLaborTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultIndirectLaborTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultIndirectLaborTypesRequest}
 */
proto.GetDefaultIndirectLaborTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultIndirectLaborTypesRequest;
  return proto.GetDefaultIndirectLaborTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultIndirectLaborTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultIndirectLaborTypesRequest}
 */
proto.GetDefaultIndirectLaborTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultIndirectLaborTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultIndirectLaborTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultIndirectLaborTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultIndirectLaborTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sessionToken = 1;
 * @return {string}
 */
proto.GetDefaultIndirectLaborTypesRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDefaultIndirectLaborTypesRequest} returns this
 */
proto.GetDefaultIndirectLaborTypesRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDefaultIndirectLaborTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultIndirectLaborTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultIndirectLaborTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultIndirectLaborTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultIndirectLaborTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultindirectlabortypesList: jspb.Message.toObjectList(msg.getDefaultindirectlabortypesList(),
    proto.DefaultIndirectLaborType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultIndirectLaborTypesResponse}
 */
proto.GetDefaultIndirectLaborTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultIndirectLaborTypesResponse;
  return proto.GetDefaultIndirectLaborTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultIndirectLaborTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultIndirectLaborTypesResponse}
 */
proto.GetDefaultIndirectLaborTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultIndirectLaborType;
      reader.readMessage(value,proto.DefaultIndirectLaborType.deserializeBinaryFromReader);
      msg.addDefaultindirectlabortypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultIndirectLaborTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultIndirectLaborTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultIndirectLaborTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultIndirectLaborTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultindirectlabortypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DefaultIndirectLaborType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DefaultIndirectLaborType defaultIndirectLaborTypes = 1;
 * @return {!Array<!proto.DefaultIndirectLaborType>}
 */
proto.GetDefaultIndirectLaborTypesResponse.prototype.getDefaultindirectlabortypesList = function() {
  return /** @type{!Array<!proto.DefaultIndirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DefaultIndirectLaborType, 1));
};


/**
 * @param {!Array<!proto.DefaultIndirectLaborType>} value
 * @return {!proto.GetDefaultIndirectLaborTypesResponse} returns this
*/
proto.GetDefaultIndirectLaborTypesResponse.prototype.setDefaultindirectlabortypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DefaultIndirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DefaultIndirectLaborType}
 */
proto.GetDefaultIndirectLaborTypesResponse.prototype.addDefaultindirectlabortypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DefaultIndirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDefaultIndirectLaborTypesResponse} returns this
 */
proto.GetDefaultIndirectLaborTypesResponse.prototype.clearDefaultindirectlabortypesList = function() {
  return this.setDefaultindirectlabortypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultIndirectLaborTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultIndirectLaborTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultIndirectLaborTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultIndirectLaborTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultIndirectLaborTypeRequest}
 */
proto.DeleteDefaultIndirectLaborTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultIndirectLaborTypeRequest;
  return proto.DeleteDefaultIndirectLaborTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultIndirectLaborTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultIndirectLaborTypeRequest}
 */
proto.DeleteDefaultIndirectLaborTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultIndirectLaborTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultIndirectLaborTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultIndirectLaborTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultIndirectLaborTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDefaultIndirectLaborTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultIndirectLaborTypeRequest} returns this
 */
proto.DeleteDefaultIndirectLaborTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDefaultIndirectLaborTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultIndirectLaborTypeRequest} returns this
 */
proto.DeleteDefaultIndirectLaborTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultIndirectLaborTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultIndirectLaborTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultIndirectLaborTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultIndirectLaborTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultIndirectLaborTypeResponse}
 */
proto.DeleteDefaultIndirectLaborTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultIndirectLaborTypeResponse;
  return proto.DeleteDefaultIndirectLaborTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultIndirectLaborTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultIndirectLaborTypeResponse}
 */
proto.DeleteDefaultIndirectLaborTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultIndirectLaborTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultIndirectLaborTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultIndirectLaborTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultIndirectLaborTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDefaultIndirectLaborTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDefaultIndirectLaborTypeResponse} returns this
 */
proto.DeleteDefaultIndirectLaborTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DefaultIncidentalLaborType.prototype.toObject = function(opt_includeInstance) {
  return proto.DefaultIncidentalLaborType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DefaultIncidentalLaborType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultIncidentalLaborType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DefaultIncidentalLaborType}
 */
proto.DefaultIncidentalLaborType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DefaultIncidentalLaborType;
  return proto.DefaultIncidentalLaborType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DefaultIncidentalLaborType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DefaultIncidentalLaborType}
 */
proto.DefaultIncidentalLaborType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DefaultIncidentalLaborType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DefaultIncidentalLaborType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DefaultIncidentalLaborType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultIncidentalLaborType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.DefaultIncidentalLaborType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultIncidentalLaborType} returns this
 */
proto.DefaultIncidentalLaborType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float laborRate = 2;
 * @return {number}
 */
proto.DefaultIncidentalLaborType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultIncidentalLaborType} returns this
 */
proto.DefaultIncidentalLaborType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float burdenPercent = 3;
 * @return {number}
 */
proto.DefaultIncidentalLaborType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultIncidentalLaborType} returns this
 */
proto.DefaultIncidentalLaborType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float fringe = 4;
 * @return {number}
 */
proto.DefaultIncidentalLaborType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultIncidentalLaborType} returns this
 */
proto.DefaultIncidentalLaborType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string id = 5;
 * @return {string}
 */
proto.DefaultIncidentalLaborType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultIncidentalLaborType} returns this
 */
proto.DefaultIncidentalLaborType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultincidentallabortype: (f = msg.getDefaultincidentallabortype()) && proto.DefaultIncidentalLaborType.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest;
  return proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultIncidentalLaborType;
      reader.readMessage(value,proto.DefaultIncidentalLaborType.deserializeBinaryFromReader);
      msg.setDefaultincidentallabortype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultincidentallabortype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DefaultIncidentalLaborType.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DefaultIncidentalLaborType defaultIncidentalLaborType = 1;
 * @return {?proto.DefaultIncidentalLaborType}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.prototype.getDefaultincidentallabortype = function() {
  return /** @type{?proto.DefaultIncidentalLaborType} */ (
    jspb.Message.getWrapperField(this, proto.DefaultIncidentalLaborType, 1));
};


/**
 * @param {?proto.DefaultIncidentalLaborType|undefined} value
 * @return {!proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest} returns this
*/
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.prototype.setDefaultincidentallabortype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.prototype.clearDefaultincidentallabortype = function() {
  return this.setDefaultincidentallabortype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.prototype.hasDefaultincidentallabortype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse;
  return proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse} returns this
 */
proto.CreateOrUpdateDefaultIncidentalLaborTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultIncidentalLaborTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultIncidentalLaborTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultIncidentalLaborTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultIncidentalLaborTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultIncidentalLaborTypesRequest}
 */
proto.GetDefaultIncidentalLaborTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultIncidentalLaborTypesRequest;
  return proto.GetDefaultIncidentalLaborTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultIncidentalLaborTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultIncidentalLaborTypesRequest}
 */
proto.GetDefaultIncidentalLaborTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultIncidentalLaborTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultIncidentalLaborTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultIncidentalLaborTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultIncidentalLaborTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sessionToken = 1;
 * @return {string}
 */
proto.GetDefaultIncidentalLaborTypesRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDefaultIncidentalLaborTypesRequest} returns this
 */
proto.GetDefaultIncidentalLaborTypesRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDefaultIncidentalLaborTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultIncidentalLaborTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultIncidentalLaborTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultIncidentalLaborTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultIncidentalLaborTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultincidentallabortypesList: jspb.Message.toObjectList(msg.getDefaultincidentallabortypesList(),
    proto.DefaultIncidentalLaborType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultIncidentalLaborTypesResponse}
 */
proto.GetDefaultIncidentalLaborTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultIncidentalLaborTypesResponse;
  return proto.GetDefaultIncidentalLaborTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultIncidentalLaborTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultIncidentalLaborTypesResponse}
 */
proto.GetDefaultIncidentalLaborTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultIncidentalLaborType;
      reader.readMessage(value,proto.DefaultIncidentalLaborType.deserializeBinaryFromReader);
      msg.addDefaultincidentallabortypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultIncidentalLaborTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultIncidentalLaborTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultIncidentalLaborTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultIncidentalLaborTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultincidentallabortypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DefaultIncidentalLaborType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DefaultIncidentalLaborType defaultIncidentalLaborTypes = 1;
 * @return {!Array<!proto.DefaultIncidentalLaborType>}
 */
proto.GetDefaultIncidentalLaborTypesResponse.prototype.getDefaultincidentallabortypesList = function() {
  return /** @type{!Array<!proto.DefaultIncidentalLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DefaultIncidentalLaborType, 1));
};


/**
 * @param {!Array<!proto.DefaultIncidentalLaborType>} value
 * @return {!proto.GetDefaultIncidentalLaborTypesResponse} returns this
*/
proto.GetDefaultIncidentalLaborTypesResponse.prototype.setDefaultincidentallabortypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DefaultIncidentalLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DefaultIncidentalLaborType}
 */
proto.GetDefaultIncidentalLaborTypesResponse.prototype.addDefaultincidentallabortypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DefaultIncidentalLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDefaultIncidentalLaborTypesResponse} returns this
 */
proto.GetDefaultIncidentalLaborTypesResponse.prototype.clearDefaultincidentallabortypesList = function() {
  return this.setDefaultincidentallabortypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultIncidentalLaborTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultIncidentalLaborTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultIncidentalLaborTypeRequest}
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultIncidentalLaborTypeRequest;
  return proto.DeleteDefaultIncidentalLaborTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultIncidentalLaborTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultIncidentalLaborTypeRequest}
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultIncidentalLaborTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultIncidentalLaborTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultIncidentalLaborTypeRequest} returns this
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultIncidentalLaborTypeRequest} returns this
 */
proto.DeleteDefaultIncidentalLaborTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultIncidentalLaborTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultIncidentalLaborTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultIncidentalLaborTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultIncidentalLaborTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultIncidentalLaborTypeResponse}
 */
proto.DeleteDefaultIncidentalLaborTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultIncidentalLaborTypeResponse;
  return proto.DeleteDefaultIncidentalLaborTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultIncidentalLaborTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultIncidentalLaborTypeResponse}
 */
proto.DeleteDefaultIncidentalLaborTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultIncidentalLaborTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultIncidentalLaborTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultIncidentalLaborTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultIncidentalLaborTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDefaultIncidentalLaborTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDefaultIncidentalLaborTypeResponse} returns this
 */
proto.DeleteDefaultIncidentalLaborTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DefaultEquipmentType.prototype.toObject = function(opt_includeInstance) {
  return proto.DefaultEquipmentType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DefaultEquipmentType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultEquipmentType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DefaultEquipmentType}
 */
proto.DefaultEquipmentType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DefaultEquipmentType;
  return proto.DefaultEquipmentType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DefaultEquipmentType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DefaultEquipmentType}
 */
proto.DefaultEquipmentType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DefaultEquipmentType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DefaultEquipmentType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DefaultEquipmentType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultEquipmentType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.DefaultEquipmentType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultEquipmentType} returns this
 */
proto.DefaultEquipmentType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float unitCost = 2;
 * @return {number}
 */
proto.DefaultEquipmentType.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultEquipmentType} returns this
 */
proto.DefaultEquipmentType.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.DefaultEquipmentType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultEquipmentType} returns this
 */
proto.DefaultEquipmentType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultequipmentexpensetype: (f = msg.getDefaultequipmentexpensetype()) && proto.DefaultEquipmentType.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest;
  return proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultEquipmentType;
      reader.readMessage(value,proto.DefaultEquipmentType.deserializeBinaryFromReader);
      msg.setDefaultequipmentexpensetype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultequipmentexpensetype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DefaultEquipmentType.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DefaultEquipmentType defaultEquipmentExpenseType = 1;
 * @return {?proto.DefaultEquipmentType}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.prototype.getDefaultequipmentexpensetype = function() {
  return /** @type{?proto.DefaultEquipmentType} */ (
    jspb.Message.getWrapperField(this, proto.DefaultEquipmentType, 1));
};


/**
 * @param {?proto.DefaultEquipmentType|undefined} value
 * @return {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest} returns this
*/
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.prototype.setDefaultequipmentexpensetype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.prototype.clearDefaultequipmentexpensetype = function() {
  return this.setDefaultequipmentexpensetype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.prototype.hasDefaultequipmentexpensetype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse;
  return proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse} returns this
 */
proto.CreateOrUpdateDefaultEquipmentExpenseTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultEquipmentExpenseTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultEquipmentExpenseTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultEquipmentExpenseTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultEquipmentExpenseTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultEquipmentExpenseTypesRequest}
 */
proto.GetDefaultEquipmentExpenseTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultEquipmentExpenseTypesRequest;
  return proto.GetDefaultEquipmentExpenseTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultEquipmentExpenseTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultEquipmentExpenseTypesRequest}
 */
proto.GetDefaultEquipmentExpenseTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultEquipmentExpenseTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultEquipmentExpenseTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultEquipmentExpenseTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultEquipmentExpenseTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sessionToken = 1;
 * @return {string}
 */
proto.GetDefaultEquipmentExpenseTypesRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDefaultEquipmentExpenseTypesRequest} returns this
 */
proto.GetDefaultEquipmentExpenseTypesRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDefaultEquipmentExpenseTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultEquipmentExpenseTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultEquipmentExpenseTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultEquipmentExpenseTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultEquipmentExpenseTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultequipmentexpensetypesList: jspb.Message.toObjectList(msg.getDefaultequipmentexpensetypesList(),
    proto.DefaultEquipmentType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultEquipmentExpenseTypesResponse}
 */
proto.GetDefaultEquipmentExpenseTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultEquipmentExpenseTypesResponse;
  return proto.GetDefaultEquipmentExpenseTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultEquipmentExpenseTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultEquipmentExpenseTypesResponse}
 */
proto.GetDefaultEquipmentExpenseTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultEquipmentType;
      reader.readMessage(value,proto.DefaultEquipmentType.deserializeBinaryFromReader);
      msg.addDefaultequipmentexpensetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultEquipmentExpenseTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultEquipmentExpenseTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultEquipmentExpenseTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultEquipmentExpenseTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultequipmentexpensetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DefaultEquipmentType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DefaultEquipmentType defaultEquipmentExpenseTypes = 1;
 * @return {!Array<!proto.DefaultEquipmentType>}
 */
proto.GetDefaultEquipmentExpenseTypesResponse.prototype.getDefaultequipmentexpensetypesList = function() {
  return /** @type{!Array<!proto.DefaultEquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DefaultEquipmentType, 1));
};


/**
 * @param {!Array<!proto.DefaultEquipmentType>} value
 * @return {!proto.GetDefaultEquipmentExpenseTypesResponse} returns this
*/
proto.GetDefaultEquipmentExpenseTypesResponse.prototype.setDefaultequipmentexpensetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DefaultEquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DefaultEquipmentType}
 */
proto.GetDefaultEquipmentExpenseTypesResponse.prototype.addDefaultequipmentexpensetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DefaultEquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDefaultEquipmentExpenseTypesResponse} returns this
 */
proto.GetDefaultEquipmentExpenseTypesResponse.prototype.clearDefaultequipmentexpensetypesList = function() {
  return this.setDefaultequipmentexpensetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultEquipmentExpenseTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultEquipmentExpenseTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultEquipmentExpenseTypeRequest}
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultEquipmentExpenseTypeRequest;
  return proto.DeleteDefaultEquipmentExpenseTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultEquipmentExpenseTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultEquipmentExpenseTypeRequest}
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultEquipmentExpenseTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultEquipmentExpenseTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultEquipmentExpenseTypeRequest} returns this
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultEquipmentExpenseTypeRequest} returns this
 */
proto.DeleteDefaultEquipmentExpenseTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultEquipmentExpenseTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultEquipmentExpenseTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultEquipmentExpenseTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultEquipmentExpenseTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultEquipmentExpenseTypeResponse}
 */
proto.DeleteDefaultEquipmentExpenseTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultEquipmentExpenseTypeResponse;
  return proto.DeleteDefaultEquipmentExpenseTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultEquipmentExpenseTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultEquipmentExpenseTypeResponse}
 */
proto.DeleteDefaultEquipmentExpenseTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultEquipmentExpenseTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultEquipmentExpenseTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultEquipmentExpenseTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultEquipmentExpenseTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDefaultEquipmentExpenseTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDefaultEquipmentExpenseTypeResponse} returns this
 */
proto.DeleteDefaultEquipmentExpenseTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DefaultGeneralExpenseType.prototype.toObject = function(opt_includeInstance) {
  return proto.DefaultGeneralExpenseType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DefaultGeneralExpenseType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultGeneralExpenseType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DefaultGeneralExpenseType}
 */
proto.DefaultGeneralExpenseType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DefaultGeneralExpenseType;
  return proto.DefaultGeneralExpenseType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DefaultGeneralExpenseType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DefaultGeneralExpenseType}
 */
proto.DefaultGeneralExpenseType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DefaultGeneralExpenseType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DefaultGeneralExpenseType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DefaultGeneralExpenseType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultGeneralExpenseType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.DefaultGeneralExpenseType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultGeneralExpenseType} returns this
 */
proto.DefaultGeneralExpenseType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float unitCost = 2;
 * @return {number}
 */
proto.DefaultGeneralExpenseType.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultGeneralExpenseType} returns this
 */
proto.DefaultGeneralExpenseType.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.DefaultGeneralExpenseType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultGeneralExpenseType} returns this
 */
proto.DefaultGeneralExpenseType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultgeneralexpensetype: (f = msg.getDefaultgeneralexpensetype()) && proto.DefaultGeneralExpenseType.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest;
  return proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultGeneralExpenseType;
      reader.readMessage(value,proto.DefaultGeneralExpenseType.deserializeBinaryFromReader);
      msg.setDefaultgeneralexpensetype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultgeneralexpensetype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DefaultGeneralExpenseType.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DefaultGeneralExpenseType defaultGeneralExpenseType = 1;
 * @return {?proto.DefaultGeneralExpenseType}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.prototype.getDefaultgeneralexpensetype = function() {
  return /** @type{?proto.DefaultGeneralExpenseType} */ (
    jspb.Message.getWrapperField(this, proto.DefaultGeneralExpenseType, 1));
};


/**
 * @param {?proto.DefaultGeneralExpenseType|undefined} value
 * @return {!proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest} returns this
*/
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.prototype.setDefaultgeneralexpensetype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.prototype.clearDefaultgeneralexpensetype = function() {
  return this.setDefaultgeneralexpensetype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.prototype.hasDefaultgeneralexpensetype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse;
  return proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse} returns this
 */
proto.CreateOrUpdateDefaultGeneralExpenseTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultGeneralExpenseTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultGeneralExpenseTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultGeneralExpenseTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultGeneralExpenseTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultGeneralExpenseTypesRequest}
 */
proto.GetDefaultGeneralExpenseTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultGeneralExpenseTypesRequest;
  return proto.GetDefaultGeneralExpenseTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultGeneralExpenseTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultGeneralExpenseTypesRequest}
 */
proto.GetDefaultGeneralExpenseTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultGeneralExpenseTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultGeneralExpenseTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultGeneralExpenseTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultGeneralExpenseTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sessionToken = 1;
 * @return {string}
 */
proto.GetDefaultGeneralExpenseTypesRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDefaultGeneralExpenseTypesRequest} returns this
 */
proto.GetDefaultGeneralExpenseTypesRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDefaultGeneralExpenseTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultGeneralExpenseTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultGeneralExpenseTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultGeneralExpenseTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultGeneralExpenseTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultgeneralexpensetypesList: jspb.Message.toObjectList(msg.getDefaultgeneralexpensetypesList(),
    proto.DefaultGeneralExpenseType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultGeneralExpenseTypesResponse}
 */
proto.GetDefaultGeneralExpenseTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultGeneralExpenseTypesResponse;
  return proto.GetDefaultGeneralExpenseTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultGeneralExpenseTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultGeneralExpenseTypesResponse}
 */
proto.GetDefaultGeneralExpenseTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultGeneralExpenseType;
      reader.readMessage(value,proto.DefaultGeneralExpenseType.deserializeBinaryFromReader);
      msg.addDefaultgeneralexpensetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultGeneralExpenseTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultGeneralExpenseTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultGeneralExpenseTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultGeneralExpenseTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultgeneralexpensetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DefaultGeneralExpenseType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DefaultGeneralExpenseType defaultGeneralExpenseTypes = 1;
 * @return {!Array<!proto.DefaultGeneralExpenseType>}
 */
proto.GetDefaultGeneralExpenseTypesResponse.prototype.getDefaultgeneralexpensetypesList = function() {
  return /** @type{!Array<!proto.DefaultGeneralExpenseType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DefaultGeneralExpenseType, 1));
};


/**
 * @param {!Array<!proto.DefaultGeneralExpenseType>} value
 * @return {!proto.GetDefaultGeneralExpenseTypesResponse} returns this
*/
proto.GetDefaultGeneralExpenseTypesResponse.prototype.setDefaultgeneralexpensetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DefaultGeneralExpenseType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DefaultGeneralExpenseType}
 */
proto.GetDefaultGeneralExpenseTypesResponse.prototype.addDefaultgeneralexpensetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DefaultGeneralExpenseType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDefaultGeneralExpenseTypesResponse} returns this
 */
proto.GetDefaultGeneralExpenseTypesResponse.prototype.clearDefaultgeneralexpensetypesList = function() {
  return this.setDefaultgeneralexpensetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultGeneralExpenseTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultGeneralExpenseTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultGeneralExpenseTypeRequest}
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultGeneralExpenseTypeRequest;
  return proto.DeleteDefaultGeneralExpenseTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultGeneralExpenseTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultGeneralExpenseTypeRequest}
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultGeneralExpenseTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultGeneralExpenseTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultGeneralExpenseTypeRequest} returns this
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultGeneralExpenseTypeRequest} returns this
 */
proto.DeleteDefaultGeneralExpenseTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultGeneralExpenseTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultGeneralExpenseTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultGeneralExpenseTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultGeneralExpenseTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultGeneralExpenseTypeResponse}
 */
proto.DeleteDefaultGeneralExpenseTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultGeneralExpenseTypeResponse;
  return proto.DeleteDefaultGeneralExpenseTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultGeneralExpenseTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultGeneralExpenseTypeResponse}
 */
proto.DeleteDefaultGeneralExpenseTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultGeneralExpenseTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultGeneralExpenseTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultGeneralExpenseTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultGeneralExpenseTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDefaultGeneralExpenseTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDefaultGeneralExpenseTypeResponse} returns this
 */
proto.DeleteDefaultGeneralExpenseTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DefaultSubcontractType.prototype.toObject = function(opt_includeInstance) {
  return proto.DefaultSubcontractType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DefaultSubcontractType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultSubcontractType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quotedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DefaultSubcontractType}
 */
proto.DefaultSubcontractType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DefaultSubcontractType;
  return proto.DefaultSubcontractType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DefaultSubcontractType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DefaultSubcontractType}
 */
proto.DefaultSubcontractType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuotedcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DefaultSubcontractType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DefaultSubcontractType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DefaultSubcontractType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultSubcontractType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuotedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.DefaultSubcontractType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultSubcontractType} returns this
 */
proto.DefaultSubcontractType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float quotedCost = 2;
 * @return {number}
 */
proto.DefaultSubcontractType.prototype.getQuotedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultSubcontractType} returns this
 */
proto.DefaultSubcontractType.prototype.setQuotedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float adjustedPercent = 3;
 * @return {number}
 */
proto.DefaultSubcontractType.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DefaultSubcontractType} returns this
 */
proto.DefaultSubcontractType.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.DefaultSubcontractType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultSubcontractType} returns this
 */
proto.DefaultSubcontractType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultsubcontractexpensetype: (f = msg.getDefaultsubcontractexpensetype()) && proto.DefaultSubcontractType.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest;
  return proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultSubcontractType;
      reader.readMessage(value,proto.DefaultSubcontractType.deserializeBinaryFromReader);
      msg.setDefaultsubcontractexpensetype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultsubcontractexpensetype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DefaultSubcontractType.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DefaultSubcontractType defaultSubcontractExpenseType = 1;
 * @return {?proto.DefaultSubcontractType}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.prototype.getDefaultsubcontractexpensetype = function() {
  return /** @type{?proto.DefaultSubcontractType} */ (
    jspb.Message.getWrapperField(this, proto.DefaultSubcontractType, 1));
};


/**
 * @param {?proto.DefaultSubcontractType|undefined} value
 * @return {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest} returns this
*/
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.prototype.setDefaultsubcontractexpensetype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.prototype.clearDefaultsubcontractexpensetype = function() {
  return this.setDefaultsubcontractexpensetype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.prototype.hasDefaultsubcontractexpensetype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse;
  return proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse} returns this
 */
proto.CreateOrUpdateDefaultSubcontractExpenseTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultSubcontractExpenseTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultSubcontractExpenseTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultSubcontractExpenseTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultSubcontractExpenseTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultSubcontractExpenseTypesRequest}
 */
proto.GetDefaultSubcontractExpenseTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultSubcontractExpenseTypesRequest;
  return proto.GetDefaultSubcontractExpenseTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultSubcontractExpenseTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultSubcontractExpenseTypesRequest}
 */
proto.GetDefaultSubcontractExpenseTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultSubcontractExpenseTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultSubcontractExpenseTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultSubcontractExpenseTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultSubcontractExpenseTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sessionToken = 1;
 * @return {string}
 */
proto.GetDefaultSubcontractExpenseTypesRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDefaultSubcontractExpenseTypesRequest} returns this
 */
proto.GetDefaultSubcontractExpenseTypesRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDefaultSubcontractExpenseTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultSubcontractExpenseTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultSubcontractExpenseTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultSubcontractExpenseTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultSubcontractExpenseTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultsubcontractexpensetypesList: jspb.Message.toObjectList(msg.getDefaultsubcontractexpensetypesList(),
    proto.DefaultSubcontractType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultSubcontractExpenseTypesResponse}
 */
proto.GetDefaultSubcontractExpenseTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultSubcontractExpenseTypesResponse;
  return proto.GetDefaultSubcontractExpenseTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultSubcontractExpenseTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultSubcontractExpenseTypesResponse}
 */
proto.GetDefaultSubcontractExpenseTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultSubcontractType;
      reader.readMessage(value,proto.DefaultSubcontractType.deserializeBinaryFromReader);
      msg.addDefaultsubcontractexpensetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultSubcontractExpenseTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultSubcontractExpenseTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultSubcontractExpenseTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultSubcontractExpenseTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultsubcontractexpensetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DefaultSubcontractType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DefaultSubcontractType defaultSubcontractExpenseTypes = 1;
 * @return {!Array<!proto.DefaultSubcontractType>}
 */
proto.GetDefaultSubcontractExpenseTypesResponse.prototype.getDefaultsubcontractexpensetypesList = function() {
  return /** @type{!Array<!proto.DefaultSubcontractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DefaultSubcontractType, 1));
};


/**
 * @param {!Array<!proto.DefaultSubcontractType>} value
 * @return {!proto.GetDefaultSubcontractExpenseTypesResponse} returns this
*/
proto.GetDefaultSubcontractExpenseTypesResponse.prototype.setDefaultsubcontractexpensetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DefaultSubcontractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DefaultSubcontractType}
 */
proto.GetDefaultSubcontractExpenseTypesResponse.prototype.addDefaultsubcontractexpensetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DefaultSubcontractType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDefaultSubcontractExpenseTypesResponse} returns this
 */
proto.GetDefaultSubcontractExpenseTypesResponse.prototype.clearDefaultsubcontractexpensetypesList = function() {
  return this.setDefaultsubcontractexpensetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultSubcontractExpenseTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultSubcontractExpenseTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultSubcontractExpenseTypeRequest}
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultSubcontractExpenseTypeRequest;
  return proto.DeleteDefaultSubcontractExpenseTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultSubcontractExpenseTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultSubcontractExpenseTypeRequest}
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultSubcontractExpenseTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultSubcontractExpenseTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultSubcontractExpenseTypeRequest} returns this
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultSubcontractExpenseTypeRequest} returns this
 */
proto.DeleteDefaultSubcontractExpenseTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultSubcontractExpenseTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultSubcontractExpenseTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultSubcontractExpenseTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultSubcontractExpenseTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultSubcontractExpenseTypeResponse}
 */
proto.DeleteDefaultSubcontractExpenseTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultSubcontractExpenseTypeResponse;
  return proto.DeleteDefaultSubcontractExpenseTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultSubcontractExpenseTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultSubcontractExpenseTypeResponse}
 */
proto.DeleteDefaultSubcontractExpenseTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultSubcontractExpenseTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultSubcontractExpenseTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultSubcontractExpenseTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultSubcontractExpenseTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDefaultSubcontractExpenseTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDefaultSubcontractExpenseTypeResponse} returns this
 */
proto.DeleteDefaultSubcontractExpenseTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DefaultQuoteType.prototype.toObject = function(opt_includeInstance) {
  return proto.DefaultQuoteType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DefaultQuoteType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultQuoteType.toObject = function(includeInstance, msg) {
  var f, obj = {
    quotegroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DefaultQuoteType}
 */
proto.DefaultQuoteType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DefaultQuoteType;
  return proto.DefaultQuoteType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DefaultQuoteType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DefaultQuoteType}
 */
proto.DefaultQuoteType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DefaultQuoteType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DefaultQuoteType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DefaultQuoteType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DefaultQuoteType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string quotegroup = 1;
 * @return {string}
 */
proto.DefaultQuoteType.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultQuoteType} returns this
 */
proto.DefaultQuoteType.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.DefaultQuoteType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DefaultQuoteType} returns this
 */
proto.DefaultQuoteType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultquoteexpensetype: (f = msg.getDefaultquoteexpensetype()) && proto.DefaultQuoteType.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest;
  return proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultQuoteType;
      reader.readMessage(value,proto.DefaultQuoteType.deserializeBinaryFromReader);
      msg.setDefaultquoteexpensetype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultquoteexpensetype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DefaultQuoteType.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DefaultQuoteType defaultQuoteExpenseType = 1;
 * @return {?proto.DefaultQuoteType}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.prototype.getDefaultquoteexpensetype = function() {
  return /** @type{?proto.DefaultQuoteType} */ (
    jspb.Message.getWrapperField(this, proto.DefaultQuoteType, 1));
};


/**
 * @param {?proto.DefaultQuoteType|undefined} value
 * @return {!proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest} returns this
*/
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.prototype.setDefaultquoteexpensetype = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.prototype.clearDefaultquoteexpensetype = function() {
  return this.setDefaultquoteexpensetype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.prototype.hasDefaultquoteexpensetype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest} returns this
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse;
  return proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse} returns this
 */
proto.CreateOrUpdateDefaultQuoteExpenseTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultQuoteExpenseTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultQuoteExpenseTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultQuoteExpenseTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultQuoteExpenseTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultQuoteExpenseTypesRequest}
 */
proto.GetDefaultQuoteExpenseTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultQuoteExpenseTypesRequest;
  return proto.GetDefaultQuoteExpenseTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultQuoteExpenseTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultQuoteExpenseTypesRequest}
 */
proto.GetDefaultQuoteExpenseTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultQuoteExpenseTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultQuoteExpenseTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultQuoteExpenseTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultQuoteExpenseTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sessionToken = 1;
 * @return {string}
 */
proto.GetDefaultQuoteExpenseTypesRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDefaultQuoteExpenseTypesRequest} returns this
 */
proto.GetDefaultQuoteExpenseTypesRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetDefaultQuoteExpenseTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDefaultQuoteExpenseTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDefaultQuoteExpenseTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDefaultQuoteExpenseTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultQuoteExpenseTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultquoteexpensetypesList: jspb.Message.toObjectList(msg.getDefaultquoteexpensetypesList(),
    proto.DefaultQuoteType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDefaultQuoteExpenseTypesResponse}
 */
proto.GetDefaultQuoteExpenseTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDefaultQuoteExpenseTypesResponse;
  return proto.GetDefaultQuoteExpenseTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDefaultQuoteExpenseTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDefaultQuoteExpenseTypesResponse}
 */
proto.GetDefaultQuoteExpenseTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DefaultQuoteType;
      reader.readMessage(value,proto.DefaultQuoteType.deserializeBinaryFromReader);
      msg.addDefaultquoteexpensetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDefaultQuoteExpenseTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDefaultQuoteExpenseTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDefaultQuoteExpenseTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDefaultQuoteExpenseTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultquoteexpensetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DefaultQuoteType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DefaultQuoteType defaultQuoteExpenseTypes = 1;
 * @return {!Array<!proto.DefaultQuoteType>}
 */
proto.GetDefaultQuoteExpenseTypesResponse.prototype.getDefaultquoteexpensetypesList = function() {
  return /** @type{!Array<!proto.DefaultQuoteType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DefaultQuoteType, 1));
};


/**
 * @param {!Array<!proto.DefaultQuoteType>} value
 * @return {!proto.GetDefaultQuoteExpenseTypesResponse} returns this
*/
proto.GetDefaultQuoteExpenseTypesResponse.prototype.setDefaultquoteexpensetypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DefaultQuoteType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DefaultQuoteType}
 */
proto.GetDefaultQuoteExpenseTypesResponse.prototype.addDefaultquoteexpensetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DefaultQuoteType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetDefaultQuoteExpenseTypesResponse} returns this
 */
proto.GetDefaultQuoteExpenseTypesResponse.prototype.clearDefaultquoteexpensetypesList = function() {
  return this.setDefaultquoteexpensetypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultQuoteExpenseTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultQuoteExpenseTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultQuoteExpenseTypeRequest}
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultQuoteExpenseTypeRequest;
  return proto.DeleteDefaultQuoteExpenseTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultQuoteExpenseTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultQuoteExpenseTypeRequest}
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultQuoteExpenseTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultQuoteExpenseTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultQuoteExpenseTypeRequest} returns this
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteDefaultQuoteExpenseTypeRequest} returns this
 */
proto.DeleteDefaultQuoteExpenseTypeRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteDefaultQuoteExpenseTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteDefaultQuoteExpenseTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteDefaultQuoteExpenseTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultQuoteExpenseTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteDefaultQuoteExpenseTypeResponse}
 */
proto.DeleteDefaultQuoteExpenseTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteDefaultQuoteExpenseTypeResponse;
  return proto.DeleteDefaultQuoteExpenseTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteDefaultQuoteExpenseTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteDefaultQuoteExpenseTypeResponse}
 */
proto.DeleteDefaultQuoteExpenseTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteDefaultQuoteExpenseTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteDefaultQuoteExpenseTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteDefaultQuoteExpenseTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteDefaultQuoteExpenseTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteDefaultQuoteExpenseTypeResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteDefaultQuoteExpenseTypeResponse} returns this
 */
proto.DeleteDefaultQuoteExpenseTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto);
