import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AttributeGroup } from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { MasterFormatDivision } from "../../../api/protosCompiled/masterFormatDivision/masterFormatDivision_pb";
import { useStore } from "zustand";
import { useGlobalItemStore } from "../../../states/globalItemStore";

interface AttributeGroupSelectorProps {
  filterDivision: string;
  setFilterDivision: (value: string) => void;
  selectedAttributeGroupId: string | null;
  setSelectedAttributeGroupId: (value: string) => void;
  attributeGroups?: AttributeGroup.AsObject[];
  masterFormatDivision?: MasterFormatDivision.AsObject[];
}

export const AttributeGroupSelector: React.FC<AttributeGroupSelectorProps> = ({
  filterDivision,
  setFilterDivision,
  selectedAttributeGroupId,
  setSelectedAttributeGroupId,
  attributeGroups,
  masterFormatDivision,
}) => {
  const { currentItem } = useStore(useGlobalItemStore);
  return (
    <Box mb={2}>
      <FormControl fullWidth>
        <InputLabel id="filter-division-label">Filter by Division</InputLabel>
        <Select
          labelId="filter-division-label"
          value={filterDivision}
          onChange={(e) => setFilterDivision(e.target.value)}
          disabled={
            currentItem
              ? currentItem?.costsList &&
                currentItem.costsList.length > 0 &&
                currentItem.costsList[0].uomid !== 0
              : true
          }
        >
          <MenuItem value="">All Divisions</MenuItem>
          {attributeGroups &&
            masterFormatDivision
              ?.filter((division) =>
                attributeGroups.some(
                  (group) => group.masterformatid === division.id
                )
              )
              .map((division) => (
                <MenuItem key={division.id} value={division.id}>
                  {`${division.divisionnumber} - ${division.divisionname}`}
                </MenuItem>
              ))}
        </Select>
      </FormControl>

      <FormHelperText>
        <Tooltip title="Attribute groups define the key characteristics of the item (e.g., size, color, material). Select a group to assign specific attributes to this item.">
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </FormHelperText>
      <FormControl fullWidth>
        <InputLabel id="attribute-group-label">Attribute Group</InputLabel>
        <Select
          labelId="attribute-group-label"
          value={selectedAttributeGroupId || ""}
          onChange={(e) => setSelectedAttributeGroupId(e.target.value)}
          disabled={
            currentItem
              ? currentItem?.costsList &&
                currentItem.costsList.length > 0 &&
                currentItem.costsList[0].uomid !== 0
              : true
          }
        >
          <MenuItem value="">Select Attribute Group</MenuItem>
          {attributeGroups
            ?.filter(
              (group) =>
                !filterDivision || group.masterformatid === filterDivision
            )
            .map((group) => (
              <MenuItem
                key={group.attributegroupid}
                value={group.attributegroupid.toString()}
              >
                {group.attributegroupname}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};
