import {
  AttributeGroup,
  AttributeValue,
} from "../../../../api/protosCompiled/globalItem/globalItem_pb";
import { MasterFormatDivision } from "../../../../api/protosCompiled/masterFormatDivision/masterFormatDivision_pb";
import { useFetchAttributeGroups } from "../../../../hooks/useFetchAttributeGroups";

export function filterAttributeGroups(
  filterDivision: string,
  attributeGroups: AttributeGroup.AsObject[] | undefined
) {
  return filterDivision
    ? attributeGroups?.filter(
        (group) => group.masterformatid === filterDivision
      )
    : attributeGroups;
}

export function setAttributeValue(
  editingAttributeGroupId: number | null,
  newAttributeGroupName: string,
  selectedDivision: MasterFormatDivision.AsObject | null
): AttributeGroup.AsObject {
  return {
    attributegroupid: editingAttributeGroupId || 0,
    attributegroupname: newAttributeGroupName,
    attributevaluesList: [],
    masterformatid: selectedDivision?.id || "",
  };
}

/**
 * Sort and group attribute values.
 * @param attributeValues List of attribute values to process.
 * @returns A sorted and grouped record of attribute values by name.
 */
export function sortAndGroupAttributes(
  attributeValues: AttributeValue.AsObject[]
): Record<string, AttributeValue.AsObject[]> {
  // Sort the values
  const sortedValues = [...attributeValues].sort((a, b) => {
    const aVarVal =
      a.attributevariablesList.length > 0
        ? a.attributevariablesList[0].variablevalue
        : null;
    const bVarVal =
      b.attributevariablesList.length > 0
        ? b.attributevariablesList[0].variablevalue
        : null;

    if (aVarVal !== null && bVarVal !== null) {
      return aVarVal - bVarVal;
    }
    if (aVarVal !== null) return -1;
    if (bVarVal !== null) return 1;

    return a.attributevaluename.localeCompare(b.attributevaluename);
  });

  // Group by name
  const groupedByName: Record<string, AttributeValue.AsObject[]> = {};
  for (const value of sortedValues) {
    const name = value.attributevaluename;
    if (!groupedByName[name]) {
      groupedByName[name] = [];
    }
    groupedByName[name].push(value);
  }

  return groupedByName;
}

export const useGetAttributeGroupName = () => {
  const { data: attributeGroups } = useFetchAttributeGroups();
  const getAttributeGroupName = (attributeGroupId: number) => {
    return (
      attributeGroups?.find(
        (group) => group.attributegroupid === attributeGroupId
      )?.attributegroupname || ""
    );
  };
  return getAttributeGroupName;
};
