import { useMutation, useQuery, useQueryClient } from "react-query";
import { DefaultCloseoutService as dc } from "../api/DefaultCloseoutService";
import {
  DefaultDirectLaborType,
  DefaultEquipmentType,
  DefaultGeneralExpenseType,
  DefaultIncidentalLaborType,
  DefaultIndirectLaborType,
  DefaultLaborFactoringType,
  DefaultQuoteType,
  DefaultSubcontractType,
} from "../api/protosCompiled/defaultCloseout/defaultCloseout_pb";

export function useCreateOrUpdateDefaultDirectLaborType() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: DefaultDirectLaborType.AsObject) =>
      dc.createOrUpdateDefaultDirectLaborType(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defaultDirectLaborTypes"]);
      },
      onError: (error) => {
        console.error(
          "Error creating or updating default direct labor type",
          error
        );
      },
    }
  );
}

export function useGetDefaultDirectLaborTypes() {
  return useQuery<DefaultDirectLaborType.AsObject[]>(
    ["defaultDirectLaborTypes"],
    async () => {
      const response = await dc.getDefaultDirectLaborTypes();
      return response.defaultdirectlabortypesList;
    },
    {
      onError: (error) => {
        console.error("Error fetching default direct labor types", error);
      },
    }
  );
}

export function useDeleteDefaultDirectLaborType() {
  const queryClient = useQueryClient();
  return useMutation((id: string) => dc.deleteDefaultDirectLaborType(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["defaultDirectLaborTypes"]);
    },
    onError: (error) => {
      console.error("Error deleting default direct labor type", error);
    },
  });
}

export function useCreateOrUpdateDefaultLaborFactoringType() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: DefaultLaborFactoringType.AsObject) =>
      dc.createOrUpdateDefaultLaborFactoringType(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defaultLaborFactoringTypes"]);
      },
      onError: (error) => {
        console.error(
          "Error creating or updating default labor factoring type",
          error
        );
      },
    }
  );
}

export function useGetDefaultLaborFactoringTypes() {
  return useQuery<DefaultLaborFactoringType.AsObject[]>(
    ["defaultLaborFactoringTypes"],
    async () => {
      const response = await dc.getDefaultLaborFactoringTypes();
      return response.defaultlaborfactoringtypesList;
    },
    {
      onError: (error) => {
        console.error("Error fetching default labor factoring types", error);
      },
    }
  );
}

export function useDeleteDefaultLaborFactoringType() {
  const queryClient = useQueryClient();
  return useMutation((id: string) => dc.deleteDefaultLaborFactoringType(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["defaultLaborFactoringTypes"]);
    },
    onError: (error) => {
      console.error("Error deleting default labor factoring type", error);
    },
  });
}

export function useCreateOrUpdateDefaultIndirectLaborType() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: DefaultIndirectLaborType.AsObject) =>
      dc.createOrUpdateDefaultIndirectLaborType(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defaultIndirectLaborTypes"]);
      },
      onError: (error) => {
        console.error(
          "Error creating or updating default indirect labor type",
          error
        );
      },
    }
  );
}

export function useGetDefaultIndirectLaborTypes() {
  return useQuery<DefaultIndirectLaborType.AsObject[]>(
    ["defaultIndirectLaborTypes"],
    async () => {
      const response = await dc.getDefaultIndirectLaborTypes();
      console.log("Calling getDefaultIndirectLaborTypes:", response);
      return response.defaultindirectlabortypesList;
    },
    {
      onError: (error) => {
        console.error("Error fetching default indirect labor types", error);
      },
    }
  );
}

export function useDeleteDefaultIndirectLaborType() {
  const queryClient = useQueryClient();
  return useMutation((id: string) => dc.deleteDefaultIndirectLaborType(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["defaultIndirectLaborTypes"]);
    },
    onError: (error) => {
      console.error("Error deleting default indirect labor type", error);
    },
  });
}

export function useCreateOrUpdateDefaultIncidentalLaborType() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: DefaultIncidentalLaborType.AsObject) =>
      dc.createOrUpdateDefaultIncidentalLaborType(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defaultIncidentalLaborTypes"]);
      },
      onError: (error) => {
        console.error(
          "Error creating or updating default incidental labor type",
          error
        );
      },
    }
  );
}

export function useGetDefaultIncidentalLaborTypes() {
  return useQuery<DefaultIncidentalLaborType.AsObject[]>(
    ["defaultIncidentalLaborTypes"],
    async () => {
      const response = await dc.getDefaultIncidentalLaborTypes();
      return response.defaultincidentallabortypesList;
    },
    {
      onError: (error) => {
        console.error("Error fetching default incidental labor types", error);
      },
    }
  );
}

export function useDeleteDefaultIncidentalLaborType() {
  const queryClient = useQueryClient();
  return useMutation((id: string) => dc.deleteDefaultIncidentalLaborType(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["defaultIncidentalLaborTypes"]);
    },
    onError: (error) => {
      console.error("Error deleting default incidental labor type", error);
    },
  });
}

export function useCreateOrUpdateDefaultEquipmentExpenseType() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: DefaultEquipmentType.AsObject) =>
      dc.createOrUpdateDefaultEquipmentExpenseType(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defaultEquipmentExpenseTypes"]);
      },
      onError: (error) => {
        console.error(
          "Error creating or updating default equipment expense type",
          error
        );
      },
    }
  );
}

export function useGetDefaultEquipmentExpenseTypes() {
  return useQuery<DefaultEquipmentType.AsObject[]>(
    ["defaultEquipmentExpenseTypes"],
    async () => {
      const response = await dc.getDefaultEquipmentExpenseTypes();
      return response.defaultequipmentexpensetypesList;
    },
    {
      onError: (error) => {
        console.error("Error fetching default equipment expense types", error);
      },
    }
  );
}

export function useDeleteDefaultEquipmentExpenseType() {
  const queryClient = useQueryClient();
  return useMutation((id: string) => dc.deleteDefaultEquipmentExpenseType(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["defaultEquipmentExpenseTypes"]);
    },
    onError: (error) => {
      console.error("Error deleting default equipment expense type", error);
    },
  });
}

export function useCreateOrUpdateDefaultGeneralExpenseType() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: DefaultGeneralExpenseType.AsObject) =>
      dc.createOrUpdateDefaultGeneralExpenseType(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defaultGeneralExpenseTypes"]);
      },
      onError: (error) => {
        console.error(
          "Error creating or updating default general expense type",
          error
        );
      },
    }
  );
}

export function useGetDefaultGeneralExpenseTypes() {
  return useQuery<DefaultGeneralExpenseType.AsObject[]>(
    ["defaultGeneralExpenseTypes"],
    async () => {
      const response = await dc.getDefaultGeneralExpenseTypes();
      return response.defaultgeneralexpensetypesList;
    },
    {
      onError: (error) => {
        console.error("Error fetching default general expense types", error);
      },
    }
  );
}

export function useDeleteDefaultGeneralExpenseType() {
  const queryClient = useQueryClient();
  return useMutation((id: string) => dc.deleteDefaultGeneralExpenseType(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["defaultGeneralExpenseTypes"]);
    },
    onError: (error) => {
      console.error("Error deleting default general expense type", error);
    },
  });
}

export function useCreateOrUpdateDefaultSubcontractExpenseType() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: DefaultSubcontractType.AsObject) =>
      dc.createOrUpdateDefaultSubcontractExpenseType(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defaultSubcontractExpenseTypes"]);
      },
      onError: (error) => {
        console.error(
          "Error creating or updating default subcontract expense type",
          error
        );
      },
    }
  );
}

export function useGetDefaultSubcontractExpenseTypes() {
  return useQuery<DefaultSubcontractType.AsObject[]>(
    ["defaultSubcontractExpenseTypes"],
    async () => {
      const response = await dc.getDefaultSubcontractExpenseTypes();
      return response.defaultsubcontractexpensetypesList;
    },
    {
      onError: (error) => {
        console.error(
          "Error fetching default subcontract expense types",
          error
        );
      },
    }
  );
}

export function useDeleteDefaultSubcontractExpenseType() {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => dc.deleteDefaultSubcontractExpenseType(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defaultSubcontractExpenseTypes"]);
      },
      onError: (error) => {
        console.error("Error deleting default subcontract expense type", error);
      },
    }
  );
}

export function useCreateOrUpdateDefaultQuoteExpenseType() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: DefaultQuoteType.AsObject) =>
      dc.createOrUpdateDefaultQuoteExpenseType(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["defaultQuoteExpenseTypes"]);
      },
      onError: (error) => {
        console.error(
          "Error creating or updating default quote expense type",
          error
        );
      },
    }
  );
}

export function useGetDefaultQuoteExpenseTypes() {
  return useQuery<DefaultQuoteType.AsObject[]>(
    ["defaultQuoteExpenseTypes"],
    async () => {
      const response = await dc.getDefaultQuoteExpenseTypes();
      return response.defaultquoteexpensetypesList;
    },
    {
      onError: (error) => {
        console.error("Error fetching default quote expense types", error);
      },
    }
  );
}

export function useDeleteDefaultQuoteExpenseType() {
  const queryClient = useQueryClient();
  return useMutation((id: string) => dc.deleteDefaultQuoteExpenseType(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["defaultQuoteExpenseTypes"]);
    },
    onError: (error) => {
      console.error("Error deleting default quote expense type", error);
    },
  });
}
