import { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { ExtentionRow } from "./ExtentionRow";
import {
  EstimateCloseout,
  ExtensionType,
} from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { useUnityBuildStore } from "../../states/store";
import {
  recalculateDirectLabor,
  recalculateIndirectLabor,
  recalculateLaborFactoring,
} from "./closeoutHelpers";
import { Calculations as c } from "../../utils/calculations";

export const rowStyle = { width: "85px" };

export const ExtentionHeader = ({
  estimateCloseout,
  extention,
}: {
  estimateCloseout: EstimateCloseout.AsObject | undefined;
  extention: ExtensionType.AsObject[];
}) => {
  const {
    setIncludedExtentionTypes,
    setIncludedDirectLaborTypes,
    setIncludedLaborFactoringTypes,
    setIncludedIndirectLaborTypes,
    setIncludedTotalPricingTypes,
    updateExtentionType,
  } = useStore(useCloseoutStore);
  const { selectedEstimate } = useStore(useUnityBuildStore);
  const [searchTerm, setSearchTerm] = useState("");
  const [newTotalLaborHours, setNewTotalLaborHours] = useState<number>(0);
  const [newTotalMaterialCost, setNewTotalMaterialCost] = useState<number>(0);
  const handleSearchChange = (event: any) => setSearchTerm(event.target.value);

  useEffect(() => {
    if (!estimateCloseout) {
      return;
    }

    let totalLaborHours = extention.reduce(
      (sum, item) => sum + (item.totallaborhours || 0),
      0
    );

    let totalItemCost = 0;
    extention.forEach((item) => {
      totalItemCost += c.calulateTotalMaterialCost(
        c.materialCostPerUnit(undefined, item),
        item.itemqty
      );
    });

    setNewTotalLaborHours(totalLaborHours);
    setNewTotalMaterialCost(totalItemCost);

    // Recalculation direct labor logic
    if (estimateCloseout.directlaborList) {
      recalculateDirectLabor(
        estimateCloseout.directlaborList,
        totalLaborHours,
        setIncludedDirectLaborTypes
      );
    }

    // Recalculate labor factoring
    if (estimateCloseout.laborfactoringList) {
      recalculateLaborFactoring(
        estimateCloseout.laborfactoringList,
        totalLaborHours,
        setIncludedLaborFactoringTypes
      );
    }

    // Recalculate indirect labor
    if (estimateCloseout.indirectlaborList) {
      recalculateIndirectLabor(
        estimateCloseout.indirectlaborList,
        totalLaborHours,
        setIncludedIndirectLaborTypes
      );
    }
  }, [
    extention,
    estimateCloseout,
    selectedEstimate.estimateid,
    setIncludedDirectLaborTypes,
    setIncludedLaborFactoringTypes,
    setIncludedIndirectLaborTypes,
    setIncludedTotalPricingTypes,
  ]);

  const handleFieldUpdate = (index: number, field: string, value: number) => {
    const updatedItem = {
      ...extention[index],
      [field]: value,
    };

    // Update cost and labor hours based on the field updated
    if (field === "overrideunitcost") {
      updatedItem.itemunitcost = value;
      updatedItem.totalcost = c.calulateTotalMaterialCost(
        c.materialCostPerUnit(undefined, updatedItem),
        updatedItem.itemqty
      );
      updatedItem.overrideunitcost = true;
    } else if (field === "hourlyoverrideproductionrate") {
      updatedItem.hourlyproductionrate = value;

      updatedItem.totallaborhours = c.calculateTotalLaborHours(
        updatedItem.itemqty,
        undefined,
        updatedItem
      );

      updatedItem.overrideproductionrate = true;
    }

    // Update state
    const updatedItems = [...extention];
    updatedItems[index] = updatedItem;
    setIncludedExtentionTypes(updatedItems);
    updateExtentionType(index, updatedItem);

    // Recalculate the total material cost and labor hours
    recalculateTotals(updatedItems);
  };

  // Utility to recalculate total material cost and labor hours
  const recalculateTotals = (updatedItems: ExtensionType.AsObject[]) => {
    const totalLaborHours = updatedItems.reduce(
      (sum, item) => sum + (item.totallaborhours || 0),
      0
    );
    const totalMaterialCost = updatedItems.reduce(
      (sum, item) => sum + (item.totalcost || 0),
      0
    );
    setNewTotalLaborHours(totalLaborHours);
    setNewTotalMaterialCost(totalMaterialCost);
  };

  // New: Called when user wants to open "CreateItemTab" for a row
  const handleOpenCreateItemTab = (item: ExtensionType.AsObject) => {
    // TODO: Actually open the CreateItemTab. For now, just log:
    console.log("Open CreateItemTab for item:", item);
  };

  return (
    <>
      <div>
        <input
          id="search-items"
          placeholder="Search Items"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Attribute Group</th>
              <th>Item Qty</th>
              <th>Item UOM</th>
              <th>Item Cost Per UOM</th>
              <th>Total Material Cost: ${newTotalMaterialCost.toFixed(2)}</th>
              <th>Hourly Production Rate Per Item UOM</th>
              <th>Total Labor Hours: {newTotalLaborHours.toFixed(2)}</th>

              {/* NEW column for "Actions" */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {extention.map((item, index) => (
              <ExtentionRow
                key={index}
                item={item}
                index={index}
                handleFieldUpdate={handleFieldUpdate}
                // Pass down new prop for opening the item tab
                onOpenCreateItemTab={handleOpenCreateItemTab}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
