import React, { useState, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Box,
} from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { ObjectService } from "../../api/ObjectService";
import { AssistantService } from "../../api/AssistantService";

export const UploadDocument = () => {
  const { selectedProject, sessionToken } = useStore(useUnityBuildStore);
  const inputRef = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [uploadComplete, setUploadComplete] = useState(false);

  const handleUploadClick = () => {
    if (inputRef.current) {
      setFiles([]);
      setIsUploading(false);
      setUploadComplete(false);
      setProgress({});
      setErrors({});
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      setFiles(selectedFiles);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsUploading(true);
    setUploadComplete(false);

    try {
      for (const file of files) {
        setProgress((prev) => ({
          ...prev,
          [file.name]: "Uploading...",
        }));

        try {
          // Upload the file to GCS
          const response = await ObjectService.handlePUTGCSRequest(
            file,
            selectedProject.id
          );
          setProgress((prev) => ({
            ...prev,
            [file.name]: "Processing...",
          }));

          // Trigger document processing and listen to progress updates
          await ObjectService.triggerDocumentProcessing(
            response,
            sessionToken,
            (status) => {
              setProgress((prev) => ({
                ...prev,
                [file.name]: status,
              }));
            }
          );

          setProgress((prev) => ({
            ...prev,
            [file.name]: "Completed",
          }));
        } catch (error) {
          const errorMessage =
            error instanceof Error
              ? error.message
              : "An unexpected error occurred";

          console.error(`Error processing file ${file.name}:`, errorMessage);

          setErrors((prev) => ({
            ...prev,
            [file.name]: errorMessage,
          }));

          setProgress((prev) => ({
            ...prev,
            [file.name]: "Error",
          }));
        }
      }

      setUploadComplete(true);
    } catch (err) {
      console.error("General error during upload or processing:", err);
    } finally {
      setIsUploading(false);
      const res = await AssistantService.addGCSObjectsToVectorStore({
        projectid: selectedProject.id,
        objectid: "",
      });
      console.log("addGCSObjectsToVectorStore response", res);
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        padding: "16px",
      }}
    >
      <form onSubmit={handleSubmit}>
        <Button onClick={handleUploadClick} disabled={isUploading}>
          Upload Files
        </Button>
        <input
          ref={inputRef}
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
        />

        {files.length > 0 && (
          <Button
            type="submit"
            color="primary"
            disabled={!files.length || isUploading}
          >
            Start Upload
          </Button>
        )}
      </form>

      {files.map((file) => (
        <Card key={file.name} variant="outlined" style={{ marginTop: "15px" }}>
          <CardContent>
            <Typography variant="body2">{file.name}</Typography>
            <Typography variant="caption" style={{ marginTop: "8px" }}>
              {errors[file.name]
                ? `Error: ${errors[file.name]}`
                : progress[file.name] || "Start Upload to Process"}
            </Typography>
            <LinearProgress
              variant={
                progress[file.name] === "Completed"
                  ? "determinate"
                  : progress[file.name] === "Error"
                  ? "buffer" // Inactive bar for error
                  : progress[file.name]
                  ? "indeterminate"
                  : "buffer"
              }
              value={progress[file.name] === "Completed" ? 100 : undefined}
              style={{
                marginTop: "10px",
                backgroundColor: errors[file.name] ? "red" : undefined,
              }}
            />
          </CardContent>
        </Card>
      ))}

      {uploadComplete && (
        <Typography
          variant="h6"
          color="primary"
          align="center"
          style={{ marginTop: "20px" }}
        >
          Processing Complete
        </Typography>
      )}
    </Box>
  );
};
