import { MasterFormatDivisionService } from "../api/MasterFormatDivisionService";
import { useUnityBuildStore } from "../states/store";
import { useQuery } from "react-query";

export const useGetMasterFormatDivision = () => {
  const { data, error, isLoading } = useQuery(
    "masterFormatDivision",
    async () => {
      const response =
        await MasterFormatDivisionService.getMasterFormatDivision();
      return response.masterformatdivisionList;
    },
    {
      enabled: !!useUnityBuildStore.getState().sessionToken,
    }
  );
  return { data, error, isLoading };
};
