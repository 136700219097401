import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useStore } from "zustand";
import { useGlobalItemStore } from "../../../states/globalItemStore";
import { ReadableItem, useFetchUPC } from "../../../hooks/useFetchUPC";

export const UPCDetailsCard: React.FC = () => {
  const globalItemStore = useStore(useGlobalItemStore);
  const { currentItem } = useStore(useGlobalItemStore);
  // Ensure attributesList exists and is not empty
  const attributesList = globalItemStore.currentItem?.attributesList || [];
  const initialUpc = attributesList.length > 0 ? attributesList[0].upc : "";

  // State to track selected UPC
  const [selectedUpc, setSelectedUpc] = useState<string>(initialUpc);

  useEffect(() => {
    setSelectedUpc("");
  }, [currentItem]);

  // Fetch UPC data based on the currently selected UPC
  const { data: itemsData, isLoading } = useFetchUPC(selectedUpc);

  // Handle changes in the selected UPC
  const handleUpcChange = (event: SelectChangeEvent<string>) => {
    setSelectedUpc(event.target.value);
  };

  // Render loading state
  if (isLoading) {
    return <CircularProgress />;
  }

  // Handle case when attributesList is empty
  if (attributesList.length === 0) {
    return (
      <Typography color="textSecondary">No attributes available.</Typography>
    );
  }

  // Handle case when no UPC data is available
  if (!itemsData || itemsData.length === 0) {
    return (
      <>
        <Select
          value={selectedUpc}
          onChange={handleUpcChange}
          style={{ width: "100%", marginBottom: "16px" }}
        >
          {attributesList.map((attr) => (
            <MenuItem key={attr.itemattributevaluemapid} value={attr.upc}>
              {`${attr.attributevaluename} - ${attr.upc}`}
            </MenuItem>
          ))}
        </Select>
        <Typography color="textSecondary">No UPC data available.</Typography>
      </>
    );
  }

  // Assume the first item from the API response for display
  const itemData: ReadableItem = itemsData[0];

  return (
    <>
      {/* Dropdown for selecting UPC */}
      <Select value={selectedUpc} onChange={handleUpcChange}>
        {attributesList.map((attr) => (
          <MenuItem key={attr.itemattributevaluemapid} value={attr.upc}>
            {`${attr.attributevaluename} - ${attr.upc}`}
          </MenuItem>
        ))}
      </Select>

      {/* Display UPC details */}
      <Card style={{ marginTop: "16px", maxHeight: "670px" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">{itemData.title}</Typography>
              <Typography variant="body2" color="textSecondary">
                Brand: {itemData.brand || "N/A"}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Model: {itemData.model || "N/A"}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Category: {itemData.category || "N/A"}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                UPC: {itemData.upc}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {itemData.imagesList && itemData.imagesList.length > 0 ? (
                <img
                  src={itemData.imagesList[0]}
                  alt={itemData.title}
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              ) : (
                <Typography>No image available</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{itemData.description}</Typography>
            </Grid>
            {itemData.offersList?.map((offer, index) => (
              <Grid key={index} item xs={12}>
                <Typography variant="body2">
                  Offer: {offer.merchant} - ${offer.price?.toFixed(2)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <a
                    href={offer.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on {offer.domain}
                  </a>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
