import { ChangeOrderAssembly } from "../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { EstimateAssembly } from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";

export const calculateRealWorldDistance = (
  pixelDistance: number,
  pdfMeasurementScale: number
) => {
  const inchDistance = pixelDistance / 72; // Pixels to inches (assuming 72 DPI)
  const footDistance = inchDistance * pdfMeasurementScale; // Inches to feet based on document's scale
  return footDistance; // Distance in feet
};

// Function to convert a point's pixel coordinates to real-world coordinates (in feet)
export const convertPointToRealWorld = (
  point: { x: number; y: number },
  pdfMeasurementScale: number
) => {
  const xFootDistance = calculateRealWorldDistance(
    point.x,
    pdfMeasurementScale
  );
  const yFootDistance = calculateRealWorldDistance(
    point.y,
    pdfMeasurementScale
  );
  return { x: xFootDistance, y: yFootDistance };
};

// Function to calculate the area of a polygon using the Shoelace formula
export const calculatePolygonArea = (points: { x: number; y: number }[]) => {
  let area = 0;
  const numPoints = points.length;

  for (let i = 0; i < numPoints; i++) {
    const j = (i + 1) % numPoints;
    area += points[i].x * points[j].y;
    area -= points[j].x * points[i].y;
  }

  area = Math.abs(area) / 2;
  return area; // Area in square feet
};

export const calculateCentroid = (points: { x: number; y: number }[]) => {
  let centroidX = 0;
  let centroidY = 0;
  let signedArea = 0;
  const numPoints = points.length;

  for (let i = 0; i < numPoints; i++) {
    const x0 = points[i].x;
    const y0 = points[i].y;
    const x1 = points[(i + 1) % numPoints].x;
    const y1 = points[(i + 1) % numPoints].y;
    const a = x0 * y1 - x1 * y0;
    signedArea += a;
    centroidX += (x0 + x1) * a;
    centroidY += (y0 + y1) * a;
  }

  signedArea *= 0.5;
  centroidX = centroidX / (6 * signedArea);
  centroidY = centroidY / (6 * signedArea);

  return { x: centroidX, y: centroidY };
};

export const getAdjustedCursorPosition = (
  lastPoint: { x: number; y: number },
  currentCursor: { x: number; y: number },
  angleOptions: number[],
  ctrlDown: boolean,
  selectedTakeoffAssembly:
    | EstimateAssembly.AsObject
    | ChangeOrderAssembly.AsObject
) => {
  if (
    (selectedTakeoffAssembly &&
      selectedTakeoffAssembly.assemblymeasurementtype === "count") ||
    !ctrlDown
  ) {
    return currentCursor;
  }
  const dx = currentCursor.x - lastPoint.x;
  const dy = currentCursor.y - lastPoint.y;

  // Calculate the angle from the last point to the cursor in degrees
  let angle = Math.atan2(dy, dx) * (180 / Math.PI);

  // Ensure the angle is between 0 and 360 degrees
  if (angle < 0) {
    angle += 360;
  }

  // Find the closest allowed angle
  let closestAngle = angleOptions[0];
  let smallestDifference = Math.abs(angle - angleOptions[0]);

  for (let i = 1; i < angleOptions.length; i++) {
    const currentDifference = Math.abs(angle - angleOptions[i]);
    if (currentDifference < smallestDifference) {
      smallestDifference = currentDifference;
      closestAngle = angleOptions[i];
    }
  }

  // Convert the closest angle back to radians
  const snappedAngle = (closestAngle * Math.PI) / 180;

  // Calculate the distance between the last point and the current cursor
  const distance = Math.sqrt(dx * dx + dy * dy);

  // Calculate the new cursor position using the snapped angle
  const adjustedX = lastPoint.x + distance * Math.cos(snappedAngle);
  const adjustedY = lastPoint.y + distance * Math.sin(snappedAngle);

  return { x: adjustedX, y: adjustedY };
};
