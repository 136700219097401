import { Folder } from "../../../api/protosCompiled/folder/folder_pb";
import { Foldertype, FolderNode } from "../../../types/FolderTypes";
import { GlobalAssembly } from "../../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { GlobalItem } from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { useStore } from "zustand";
import { useGlobalAssemblyStore } from "../../../states/globalAssemblyStore";
import { useGlobalItemStore } from "../../../states/globalItemStore";
import { useFolderStore } from "../../../states/folderStore";
import {
  useUpdateAssemblyFolder,
  useUpdateItemFolder,
  useUpdateProjectFolder,
} from "../../../hooks/useFetchFolders";

/**
 *
 * @param itemOrAssembly
 * @param targetFolderId
 * @returns
 * useMapItemOrAssemblyToFolder takes an item or assembly and a target folder id and returns a folder object
 */
export const useMapItemOrAssemblyToFolder = (
  itemOrAssembly: GlobalItem.AsObject | GlobalAssembly.AsObject,
  targetFolderId?: string
): (() => Folder.AsObject) => {
  const globalItemStore = useStore(useGlobalItemStore);
  const globalAssemblyStore = useStore(useGlobalAssemblyStore);

  const mapItemOrAssemblytoFolder = () => {
    const folderMap = {
      folderid: targetFolderId || itemOrAssembly.folderid,
      name:
        globalItemStore.currentItem?.name ||
        globalAssemblyStore.currentAssembly?.assemblyname ||
        "",
      parentid:
        globalItemStore.currentItem?.parentid ||
        globalAssemblyStore.currentAssembly?.folderid ||
        "",
      isfile:
        globalItemStore.currentItem?.isfile ||
        globalAssemblyStore.currentAssembly.isfile ||
        false,
      level:
        globalItemStore.currentItem?.level ||
        globalAssemblyStore.currentAssembly.level ||
        0,
      entitytype:
        globalItemStore.currentItem?.entitytype ||
        globalAssemblyStore.currentAssembly.entitytype ||
        "",
      isactive:
        globalItemStore.currentItem?.isactive ||
        globalAssemblyStore.currentAssembly.isactive ||
        false,
      lastupdated: "",
    };
    return folderMap;
  };

  return mapItemOrAssemblytoFolder;
};

// Transform AsObject (from protobuf) into FolderNode by adding `children`
export const mapFoldersToFolderNodes = (foldersList: any[]): FolderNode[] => {
  if (!foldersList || !Array.isArray(foldersList)) {
    console.error(
      "Invalid foldersList passed to mapFoldersToFolderNodes:",
      foldersList
    );
    return [];
  }
  return foldersList.map((folder) => ({
    ...folder,
    children: [], // Initialize the children property as an empty array
  }));
};

// Utility function to create a nested folder structure
export const buildFolderTree = (
  folderType: Foldertype,
  foldersList: FolderNode[]
): FolderNode[] => {
  const map: { [key: string]: FolderNode } = {};
  const roots: FolderNode[] = [];

  // Initialize the map with all folders
  // why dont we just fetch the data we need based on the entity type from the database?
  //then we wouldnt have to perform ththis filter
  foldersList.forEach((folder) => {
    if (folder.entitytype === folderType) {
      map[folder.folderid] = { ...folder, children: [] };
    }
  });

  // Build the tree
  foldersList.forEach((folder) => {
    if (folder.parentid) {
      // If the folder has a parent, add it to its parent's children
      if (map[folder.parentid]) {
        map[folder.parentid].children.push(map[folder.folderid]);
      }
    } else {
      // If the folder has no parent, it is a root folder
      roots.push(map[folder.folderid]);
    }
  });

  return roots;
};

export const deleteFolderHelper = async (
  e: React.MouseEvent<HTMLButtonElement>,
  folder: FolderNode,
  deleteProjectFolder: Function,
  deleteAssemblyFolder: Function,
  deleteItemFolder: Function
) => {
  e.stopPropagation();
  try {
    if ((folder?.entitytype as Foldertype) === "project") {
      deleteProjectFolder(folder.folderid);
    } else if ((folder?.entitytype as Foldertype) === "assembly") {
      console.log("deleteAssemblyFolder", folder.folderid);
      deleteAssemblyFolder(folder.folderid);
    } else if ((folder?.entitytype as Foldertype) === "item") {
      deleteItemFolder(folder.folderid);
    }
  } catch (error) {
    console.error("Error deleting folder:", error);
  }
};

export const createFolderHelper = (
  e: React.MouseEvent<HTMLButtonElement>,
  folder: FolderNode,
  createProjectFolder: Function,
  createAssemblyFolder: Function,
  createItemFolder: Function
) => {
  e.stopPropagation();
  const newFolder: Folder.AsObject = {
    folderid: "",
    name: "New Folder",
    parentid: folder.folderid,
    isfile: false,
    level: folder.level + 1,
    entitytype: folder.entitytype,
    isactive: true,
    lastupdated: "",
  };
  try {
    console.log("Creating folder:", newFolder);
    if (folder.entitytype === "project") {
      createProjectFolder(newFolder);
    } else if (folder.entitytype === "assembly") {
      createAssemblyFolder(newFolder);
    } else if (folder.entitytype === "item") {
      createItemFolder(newFolder);
    }
  } catch (error) {
    console.error("Error creating folder:", error);
  }
};

export const renameFolderHelper = (
  e: React.KeyboardEvent<HTMLInputElement>,
  folder: FolderNode,
  updateProjectFolder: Function,
  updateAssemblyFolder: Function,
  updateItemFolder: Function
) => {
  e.stopPropagation();
  const updatedFolder: FolderNode = {
    ...folder,
    name: (e.target as HTMLInputElement).value,
  };
  // Call a function to update the folder in your state or database
  if (folder.entitytype === "project") {
    updateProjectFolder(updatedFolder);
  } else if (folder.entitytype === "assembly") {
    updateAssemblyFolder(updatedFolder);
  } else if (folder.entitytype === "item") {
    updateItemFolder(updatedFolder);
  }
};

type SetFolderNodeProps = {
  isfile: boolean;
  entitytype: Foldertype;
  isactive: boolean;
  assembly?: GlobalAssembly.AsObject;
  item?: GlobalItem.AsObject;
};

/**
 *
 * @param item - GlobalItem.AsObject
 * @param assembly - GlobalAssembly.AsObject
 * @param entitytype - "item", "assembly", or "project"
 * @param isactive - default to true
 * @param isfile - default to true
 * @returns
 */
export function setFolderNode(data: SetFolderNodeProps): FolderNode {
  return {
    folderid: data.assembly?.folderid || data.item?.folderid || "",
    name: data?.assembly?.assemblyname || data?.item?.name || "",
    parentid: data?.assembly?.parentid || data?.item?.parentid || "",
    isfile: data.isfile ?? true,
    level: data?.assembly?.level || data?.item?.level || 0,
    entitytype: data.entitytype,
    isactive: data.isactive ?? true,
    lastupdated: "",
    children: [],
  };
}

// helpers.ts
export const isCreatingInFolder = (
  folder: FolderNode | null,
  isViewingItemForm: boolean
) => {
  const { currentFolder } = useFolderStore.getState();

  // Bail out if either is null/undefined
  if (!folder || !currentFolder) {
    return false;
  }

  if (folder.entitytype === "item" && isViewingItemForm) {
    return folder.folderid === currentFolder.folderid;
  } else if (folder.entitytype === "assembly" && !isViewingItemForm) {
    return folder.folderid === currentFolder.folderid;
  }

  return false;
};

type MoveCurrentFolderProps = {
  e: React.MouseEvent<HTMLElement>;
  isMoveMode: boolean;
  setIsMoveMode: Function;
  destinationFolder: FolderNode;
  folderBeingMoved: FolderNode;
};

type MoveFolderHelperProps = MoveCurrentFolderProps & {
  updateFolder: Function;
  setCurrentFolder: React.Dispatch<React.SetStateAction<FolderNode | null>>;
};

export const moveCurrentFolderHelper = async (d: MoveFolderHelperProps) => {
  d.e.stopPropagation();
  if (!d.isMoveMode || !d.folderBeingMoved || !d.setCurrentFolder) return;

  if (d.folderBeingMoved.folderid === d.destinationFolder.folderid) {
    console.warn("Cannot move folder into itself.");
    return;
  }

  if (d.folderBeingMoved.folderid === d.destinationFolder.parentid) {
    console.warn("Cannot move a parent into its child.");
    return;
  }

  if (d.folderBeingMoved.parentid === d.destinationFolder.folderid) {
    console.warn("Folder is alrready nested in the destination folder.");
    return;
  }

  const updatedCurrentFolder = () => {
    return {
      ...d.folderBeingMoved,
      level: d.destinationFolder.level + 1,
      parentid: d.destinationFolder.folderid,
    };
  };

  try {
    const currentFolder = updatedCurrentFolder();
    d.updateFolder(currentFolder, {
      onSuccess: () => {
        d.setIsMoveMode(false);
        d.setCurrentFolder(null);
      },
    });
  } catch (error) {
    console.error("Error moving folder:", error);
  }
};

export const useHandleMoveCurrentFolder = () => {
  const { mutate: updateProjectFolder } = useUpdateProjectFolder();
  const { mutate: updateAssemblyFolder } = useUpdateAssemblyFolder();
  const { mutate: updateItemFolder } = useUpdateItemFolder();
  const { isMoveMode, setIsMoveMode, setCurrentFolder } =
    useStore(useFolderStore);

  const moveCurrentFolder = (d: MoveCurrentFolderProps) => {
    if (!d.isMoveMode) return;
    moveCurrentFolderHelper({
      e: d.e,
      isMoveMode: isMoveMode,
      setIsMoveMode: setIsMoveMode,
      destinationFolder: d.destinationFolder,
      folderBeingMoved: d.folderBeingMoved,
      updateFolder:
        d.folderBeingMoved.entitytype === "item"
          ? updateItemFolder
          : d.folderBeingMoved.entitytype === "assembly"
          ? updateAssemblyFolder
          : updateProjectFolder,
      setCurrentFolder: setCurrentFolder as React.Dispatch<
        React.SetStateAction<FolderNode | null>
      >,
    });
  };
  return moveCurrentFolder;
};

type MoveFolderProps = {
  e: React.MouseEvent<HTMLElement>;
  assembly?: GlobalAssembly.AsObject;
  item?: GlobalItem.AsObject;
  entitytype: Foldertype;
};

export const useHandleMoveItemOrAssemblyFile = () => {
  const { isMoveMode, setIsMoveMode } = useStore(useFolderStore);
  const { setCurrentFolder } = useStore(useFolderStore);

  const moveItemOrAssemblyFile = (d: MoveFolderProps) => {
    if (d.assembly?.folderid && d.item?.folderid) {
      console.error(
        "Both assembly and item are provided, only one should be provided"
      );
      return;
    }
    const folder: FolderNode = {
      folderid: d.assembly?.folderid || d.item?.folderid || "",
      name: d.assembly?.assemblyname || d.item?.name || "",
      parentid: d.assembly?.parentid || d.item?.parentid || "",
      isfile: true,
      level: d.assembly?.level || d.item?.level || 2,
      entitytype: d.entitytype,
      isactive: true,
      lastupdated: "",
      children: [],
    };
    if (isMoveMode) return;
    setIsMoveMode(true);
    setCurrentFolder(folder);
  };
  return moveItemOrAssemblyFile;
};
