import { useState } from "react";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { Calculations as c } from "../../utils/calculations";
import { QuoteType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { QuoteRow } from "./QuoteRow";
import { useGetDefaultQuoteExpenseTypes } from "../../hooks/useDefaultCloseoutHooks";

export const QuoteHeader = ({
  quoteExpenses,
}: {
  quoteExpenses: QuoteType.AsObject[];
}) => {
  const { setIncludedQuoteTypes, updateQuoteType } = useStore(useCloseoutStore);

  const { data: quoteExpenseDefaults } = useGetDefaultQuoteExpenseTypes();

  const [selectedQuoteType, setselectedQuoteType] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value as string;

    // Ensure quoteExpenseDefaults is defined
    if (!quoteExpenseDefaults) {
      console.warn("Quote expense defaults are undefined.");
      return;
    }

    const defaultQuoteType = quoteExpenseDefaults.find(
      (quoteType) => quoteType.quotegroup === newValue
    );

    if (defaultQuoteType) {
      // Merge DefaultQuoteType with QuoteType
      const newQuoteType: QuoteType.AsObject = {
        quotegroup: defaultQuoteType.quotegroup,
        quotedcost: 0, // Initialize quoted cost to 0
        adjustedpercent: 0, // Initialize adjusted percent to 0
        totalcost: 0, // Initialize total cost to 0
        isdefaulttype: true, // Mark as a default type
        quoteitemsList: [], // Initialize with an empty list
      };

      // Recalculate total cost using utility function
      newQuoteType.totalcost = c.calculateQuoteTotal(newQuoteType);

      // Prevent duplicate entries
      if (!quoteExpenses.some((quote) => quote.quotegroup === newValue)) {
        const newIncludedQuoteTypes = [...quoteExpenses, newQuoteType];
        setIncludedQuoteTypes(newIncludedQuoteTypes);
        setselectedQuoteType("");
      }
    }
  };

  const handleDelete = (quotegroupName: string) => {
    const newIncludedGenExpenseTypes = quoteExpenses.filter(
      (l) => l.quotegroup !== quotegroupName
    );
    setIncludedQuoteTypes(newIncludedGenExpenseTypes);
  };

  const handleFieldUpdate = (field: string, value: any, index: number) => {
    const updatedQuote = { ...quoteExpenses[index] };

    if (field === "quotedCost") {
      updatedQuote.quotedcost = value;
    } else if (field === "adjustedPercent") {
      updatedQuote.adjustedpercent = value;
    }

    // Recalculate total cost after updating quotedCost or adjustedPercent
    // Assuming calculateQuoteTotal uses quote.quotedcost, quote.adjustedpercent,
    // and sums up sub-items total to produce total cost
    updatedQuote.totalcost = c.calculateQuoteTotal(updatedQuote);

    updateQuoteType(index, updatedQuote);
  };

  const handleItemUpdate = (
    quoteIndex: number,
    itemIndex: number,
    field: string,
    value: number
  ) => {
    const updatedQuotes = [...quoteExpenses];
    const updatedQuote = { ...updatedQuotes[quoteIndex] };
    const updatedItems = [...updatedQuote.quoteitemsList];

    // Update the specified field for the specific item
    updatedItems[itemIndex] = { ...updatedItems[itemIndex], [field]: value };
    updatedQuote.quoteitemsList = updatedItems;

    // After updating an item's cost or qty, recalculate total cost
    // c.calculateQuoteTotal should consider quotedcost, adjustedpercent, and item costs
    updatedQuote.totalcost = c.calculateQuoteTotal(updatedQuote);

    updatedQuotes[quoteIndex] = updatedQuote;
    setIncludedQuoteTypes(updatedQuotes);
  };

  return (
    <>
      <select value={selectedQuoteType} onChange={handleChange}>
        <option value="" disabled selected hidden>
          Select quote type
        </option>
        {quoteExpenseDefaults &&
          quoteExpenseDefaults
            .sort((a, b) => a.quotegroup.localeCompare(b.quotegroup)) // Sorting alphabetically
            .map((quoteExpensesType, index) => (
              <option value={quoteExpensesType.quotegroup} key={index}>
                {quoteExpensesType.quotegroup}
              </option>
            ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quoted Cost</th>
              <th>% Adjustment</th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {quoteExpenses.map((quote, index) => (
              <QuoteRow
                key={index}
                quote={quote}
                handleFieldUpdate={(field, value) =>
                  handleFieldUpdate(field, value, index)
                }
                handleItemUpdate={(itemIndex, field, value) =>
                  handleItemUpdate(index, itemIndex, field, value)
                }
                handleDelete={handleDelete}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
