import { Avatar } from "@mui/material";
import { UB_AccountUser } from "../api/protosCompiled/ub_users/ub_users_pb";

export const UserPicture = ({ user }: { user: UB_AccountUser.AsObject }) => {
  return (
    <>
      <Avatar
        src={`${user.user?.userphotourl || ""}?_t=${Date.now()}`}
        alt="User Profile Picture"
        sx={{ width: 48, height: 48, marginLeft: 1 }}
        slotProps={{
          img: {
            referrerPolicy: "no-referrer",
          },
        }}
      />
    </>
  );
};
