import { Button, Container, Typography, Box, Grid } from "@mui/material";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/signup");
  };

  // Calculate and set the --vh CSS variable
  useEffect(() => {
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    setViewportHeight();
    window.addEventListener("resize", setViewportHeight);
    return () => window.removeEventListener("resize", setViewportHeight);
  }, []);

  const galleryItems = [
    {
      original: "/images/auditTrail.png",
      thumbnail: "/images/auditTrail.png",
    },
    {
      original: "/images/assemblies.png",
      thumbnail: "/images/assemblies.png",
    },
    {
      original: "/images/assemblyBuilder.png",
      thumbnail: "/images/assemblyBuilder.png",
    },
    {
      original: "/images/aicount.png",
      thumbnail: "/images/aicount.png",
    },
    {
      original: "/images/aiChat.png",
      thumbnail: "/images/aiChat.png",
    },
    {
      original: "/images/extension.png",
      thumbnail: "/images/extension.png",
    },
    {
      original: "/images/totalPrice.png",
      thumbnail: "/images/totalPrice.png",
    },
  ];

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "calc(var(--vh, 1vh) * 100)",
        bgcolor: "#f9f9f9",
      }}
    >
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {/* Header Section */}
        <Typography variant="h3" align="center" gutterBottom>
          Streamline Your Construction Estimation
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          gutterBottom
        >
          Manage projects, estimates, and gain insights with AI-powered analysis
        </Typography>

        {/* Call to Action */}
        {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleGetStarted}
            disabled={true}
          >
            Get Started
          </Button>
        </Box> */}

        {/* Features Section */}
        <Box sx={{ mt: 6 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Why Choose Unity Build Estimation?
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Empower your construction projects with tools designed to enhance
            efficiency and accuracy.
          </Typography>
          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <img
                  src="/icons/project-management.svg"
                  alt="Project Management"
                  style={{ width: 50, marginRight: 16 }}
                />
                <Typography variant="body1">
                  <strong>
                    Create projects, estimates, contracts, and change orders
                  </strong>{" "}
                  easily in one platform.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <img
                  src="/icons/takeoff-tools.svg"
                  alt="Takeoff Tools"
                  style={{ width: 50, marginRight: 16 }}
                />
                <Typography variant="body1">
                  <strong>Use onscreen takeoff tools</strong> for precise
                  measurements and estimations.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <img
                  src="/icons/items-assemblies.svg"
                  alt="Items and Assemblies"
                  style={{ width: 50, marginRight: 16 }}
                />
                <Typography variant="body1">
                  <strong>Create items and assemblies</strong> with assigned
                  material and labor costs.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <img
                  src="/icons/ai-tools.svg"
                  alt="AI Tools"
                  style={{ width: 50, marginRight: 16 }}
                />
                <Typography variant="body1">
                  <strong>Leverage AI-powered tools</strong> to interact with
                  your documents and get suggestions.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <img
                  src="/icons/licensing.svg"
                  alt="Licensing"
                  style={{ width: 50, marginRight: 16 }}
                />
                <Typography variant="body1">
                  <strong>Seat-based licensing</strong> allows unlimited users
                  as long as there is an available seat.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Image Gallery */}
        <Box sx={{ mt: 6 }}>
          <Typography variant="h4" align="center" gutterBottom>
            See Unity Build in Action
          </Typography>
          <ImageGallery
            items={galleryItems}
            showPlayButton={false}
            showFullscreenButton={true}
          />
        </Box>

        {/* Pricing Section */}
        {/* <Box sx={{ mt: 6 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Simple, Transparent Pricing
          </Typography>
          <Typography variant="h5" align="center" color="primary" gutterBottom>
            $300/month + $100/month for each additional seat
          </Typography>
          <Typography variant="body1" align="center">
            Invest in a tool that saves you time and money on every project.
          </Typography>
        </Box> */}

        {/* Second Call to Action */}
        {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 6, mb: 8 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleGetStarted}
            disabled={true}
          >
            Get Started
          </Button>
        </Box> */}

        {/* Footer */}
        <Typography
          variant="body2"
          align="center"
          color="textSecondary"
          sx={{ mb: 4 }}
        >
          Version 1.0 will be in beta and available on March 1st, 2025.
        </Typography>
      </Container>
    </Box>
  );
};

export default LandingPage;
