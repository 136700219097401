import { useState } from "react";
import { ItemService } from "../../../api/GlobalItemService";
import {
  GlobalItem,
  ItemCost,
} from "../../../api/protosCompiled/globalItem/globalItem_pb";

type AssignCostsProps = {
  items: GlobalItem.AsObject[];
};

/**
 * Convert the stored or user-entered UPC into an 11-digit display string.
 *
 * 1) Strip all non-digits.
 * 2) If > 11 digits, keep only the last 11.
 * 3) If < 11 digits, pad left with zeros to length 11.
 */
export function convertUPCToDisplay(
  upcValue: number | string | undefined
): string {
  if (!upcValue) return "";

  // 1) Convert to string, remove non-digits
  let numericStr = String(upcValue).replace(/\D/g, "");

  // 2) If longer than 11, keep last 11
  if (numericStr.length > 11) {
    numericStr = numericStr.slice(numericStr.length - 11);
  }

  // 3) If shorter than 11, pad left with zeros
  numericStr = numericStr.padStart(11, "0");

  return numericStr;
}

/**
 * Convert what the user typed (possibly 8, 10, 12+ digits) to a numeric UPC for the DB.
 *
 * 1) Strip non-digits.
 * 2) If > 11 digits, keep last 11.
 * 3) If < 11 digits, pad left with zeros.
 * 4) Convert to number, or default to 0 if empty/invalid.
 */
export function convertDisplayToUPC(displayUPC: string): number {
  // 1) Remove any non-numeric characters
  let numericStr = displayUPC.replace(/\D/g, "");

  // 2) If longer than 11, keep last 11
  if (numericStr.length > 11) {
    numericStr = numericStr.slice(numericStr.length - 11);
  }

  // 3) If shorter, pad left
  numericStr = numericStr.padStart(11, "0");

  // 4) Parse
  return numericStr ? parseInt(numericStr, 10) : 0;
}

export const AssignCosts = ({ items }: AssignCostsProps) => {
  const [editedCosts, setEditedCosts] = useState<{ [key: string]: any }>({});
  const [editedUPCs, setEditedUPCs] = useState<{ [key: string]: string }>({});

  // Handle changes to the input fields
  const handleCostInputChange = (
    attributeId: string,
    field: string,
    value: string
  ) => {
    // Only allow valid numeric input or empty input
    if (/^(\d+(\.\d*)?|\.\d*)?$/.test(value)) {
      setEditedCosts((prev) => ({
        ...prev,
        [attributeId]: {
          ...prev[attributeId],
          [field]: value,
        },
      }));
    }
  };

  const handleCostBlur = async (
    item: GlobalItem.AsObject,
    attributeId: string,
    e: any
  ) => {
    e.preventDefault();
    try {
      const matchingCost = item.costsList.find(
        (cost) => cost.itemattributevalueid === parseInt(attributeId)
      );
      if (matchingCost) {
        const editedCost: ItemCost.AsObject = editedCosts[attributeId] || {};

        const updatedCost: ItemCost.AsObject = {
          ...matchingCost,
          itemid: item.folderid,
          itemunitcost:
            editedCost.itemunitcost !== undefined
              ? editedCost.itemunitcost
              : matchingCost.itemunitcost,
          hourlyproductionrate:
            editedCost.hourlyproductionrate !== undefined
              ? editedCost.hourlyproductionrate
              : matchingCost.hourlyproductionrate,
        };

        // Pass only the updated cost object to updateItemCost
        await ItemService.updateItemCost(updatedCost);
      } else {
        console.error("No matching cost found for attributeId:", attributeId);
      }
    } catch (error) {
      console.error("Error updating item costs:", error);
    }
  };

  // Handle changes to the UPC field
  const handleAttributeUPCValueChange = (
    attributeId: string,
    value: string
  ) => {
    setEditedUPCs((prev) => ({
      ...prev,
      [attributeId]: value,
    }));
  };

  // Handle blur for UPC updates
  const handleAttributeUPCValueBlur = async (
    item: GlobalItem.AsObject,
    attributeId: string
  ) => {
    try {
      const matchingAttribute = item.attributesList.find(
        (attr) => attr.attributevalueid.toString() === attributeId
      );

      if (!matchingAttribute) {
        console.error("No matching attribute found:", attributeId);
        return;
      }

      // 1) Convert to numeric (store in DB):
      const numericUPC = convertDisplayToUPC(editedUPCs[attributeId]);
      await ItemService.updateUPC(
        item.folderid,
        matchingAttribute.attributevalueid,
        numericUPC
      );

      // 2) For consistent display, convert back to 11-digit string:
      setEditedUPCs((prev) => ({
        ...prev,
        [attributeId]: convertUPCToDisplay(numericUPC),
      }));
    } catch (error) {
      console.error("Error updating attribute UPC:", error);
    }
  };

  return (
    <div
      style={{
        height: "700px",
        position: "relative",
        border: "1px solid black",
        overflowY: "auto",
      }}
    >
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Attribute</th>
            <th>UOM</th>
            <th>Unit Cost</th>
            <th>Hourly Production Rate</th>
            <th>UPC</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item) =>
            item.attributesList.map((attribute, index) => {
              const matchingCost = item.costsList.find(
                (cost) =>
                  cost.itemattributevalueid === attribute.attributevalueid
              );

              return (
                <tr key={`${item.folderid}-${attribute.attributevalueid}`}>
                  <td>
                    {attribute.attributevaluename} - {item.name}
                  </td>
                  <td>{matchingCost?.uomname ?? "N/A"}</td>
                  <td>
                    <input
                      type="text"
                      name="itemunitcost"
                      value={
                        editedCosts[attribute.attributevalueid]?.itemunitcost ??
                        matchingCost?.itemunitcost?.toString() ??
                        ""
                      }
                      onChange={(e) =>
                        handleCostInputChange(
                          attribute.attributevalueid.toString(),
                          "itemunitcost",
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        handleCostBlur(
                          item,
                          attribute.attributevalueid.toString(),
                          e
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="hourlyproductionrate"
                      value={
                        editedCosts[attribute.attributevalueid]
                          ?.hourlyproductionrate ??
                        matchingCost?.hourlyproductionrate?.toString() ??
                        ""
                      }
                      onChange={(e) =>
                        handleCostInputChange(
                          attribute.attributevalueid.toString(),
                          "hourlyproductionrate",
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        handleCostBlur(
                          item,
                          attribute.attributevalueid.toString(),
                          e
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="upc"
                      value={
                        editedUPCs[attribute.attributevalueid] ??
                        attribute.upc ??
                        ""
                      }
                      onChange={(e) =>
                        handleAttributeUPCValueChange(
                          attribute.attributevalueid.toString(),
                          e.target.value
                        )
                      }
                      onBlur={() =>
                        handleAttributeUPCValueBlur(
                          item,
                          attribute.attributevalueid.toString()
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};
