import { clients } from "../clients/grpcClients";
import {
  CreateEstimateAssemblyRequest,
  CreateEstimateAssemblyResponse,
  EstimateAssembly,
  UpdateEstimateAssemblyRequest,
  UpdateEstimateAssemblyResponse,
  GetTakeoffStatsResponse,
  GetTakeoffStatsRequest,
  EstimatePoint,
  EstimateItemQtyFormula,
  EstimateAssemblyItemAttribute,
  GetEstimateAssembliesByEstimateIdRequest,
  GetEstimateAssembliesByEstimateIdResponse,
  EstimateBreakoutMap,
} from "./protosCompiled/estimateAssembly/estimateAssembly_pb";
import { Point } from "../types/Point";
import { useUnityBuildStore } from "../states/store";
import { last } from "lodash";
import { ChangeOrderAssembly } from "./protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

export class ProjectEstimateAssemblyService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  private static convertToEstimatePoints = (
    pointsList: Point[]
  ): EstimatePoint[] => {
    return pointsList.map((point) => {
      const estimatePoint = new EstimatePoint();
      estimatePoint.setPointid(point.pointid || "");
      estimatePoint.setX(point.x || 0);
      estimatePoint.setY(point.y || 0);
      estimatePoint.setAngle(point.angle || 0);
      estimatePoint.setVerticallength(point.verticallength || 0);
      estimatePoint.setAllowtrailingline(point.allowtrailingline || false);
      estimatePoint.setIsincludedincount(point.isincludedincount || false);
      estimatePoint.setIsselected(point.isselected || false);
      estimatePoint.setIsactive(point.isactive || false);
      return estimatePoint;
    });
  };

  private static buildEstimateAssemblyMessage(
    assembly: EstimateAssembly.AsObject
  ): EstimateAssembly {
    const estimateAssemblyMessage = new EstimateAssembly();
    estimateAssemblyMessage.setTakeoffid(assembly.takeoffid || "");
    estimateAssemblyMessage.setProjectid(assembly.projectid || "");
    estimateAssemblyMessage.setEstimateid(assembly.estimateid || "");
    estimateAssemblyMessage.setObjectid(assembly.objectid || "");

    estimateAssemblyMessage.setSegmentlength(assembly.segmentlength || 0);
    estimateAssemblyMessage.setSegmentcount(calculateSegmentCount(assembly));
    estimateAssemblyMessage.setSqft(assembly.sqft || 0);

    // Calculate pointCount from points where isIncludedInCount = true
    const pointsList = this.convertToEstimatePoints(assembly.pointsList || []);
    estimateAssemblyMessage.setPointsList(pointsList);
    estimateAssemblyMessage.setPointcount(
      pointsList.filter((p) => p.getIsincludedincount()).length
    );

    estimateAssemblyMessage.setPointtype(assembly.pointtype || "");
    estimateAssemblyMessage.setPointsize(assembly.pointsize || 0);
    estimateAssemblyMessage.setPointbordercolor(
      assembly.pointbordercolor || ""
    );
    estimateAssemblyMessage.setPointfillcolor(assembly.pointfillcolor || "");
    estimateAssemblyMessage.setLinesize(assembly.linesize || 0);
    estimateAssemblyMessage.setLinecolor(assembly.linecolor || "");
    estimateAssemblyMessage.setLinedasharray(assembly.linedasharray || "");
    estimateAssemblyMessage.setUserscale(assembly.userscale || 0);
    estimateAssemblyMessage.setPagenumber(assembly.pagenumber || 1);
    estimateAssemblyMessage.setAssemblyname(assembly.assemblyname || "");
    estimateAssemblyMessage.setAssemblymeasurementtype(
      assembly.assemblymeasurementtype || ""
    );
    estimateAssemblyMessage.setImageclassificationid(
      assembly.imageclassificationid || 0
    );

    if (assembly.itemqtyformulasList) {
      const itemQtyFormulas = assembly.itemqtyformulasList.map((formula) => {
        const estimateItemQtyFormula = new EstimateItemQtyFormula();
        estimateItemQtyFormula.setItemid(formula.itemid || "");
        estimateItemQtyFormula.setItemname(formula.itemname || "");
        estimateItemQtyFormula.setItemqty(formula.itemqty || 0);
        estimateItemQtyFormula.setForevery(formula.forevery || 0);
        estimateItemQtyFormula.setTakeoffvariabletype(
          formula.takeoffvariabletype || ""
        );
        estimateItemQtyFormula.setAnd(formula.and || false);
        estimateItemQtyFormula.setOr(formula.or || false);
        estimateItemQtyFormula.setIsquoted(formula.isquoted || false);
        estimateItemQtyFormula.setAssemblyname(formula.assemblyname || "");
        estimateItemQtyFormula.setQuotegroup(formula.quotegroup || "");
        estimateItemQtyFormula.setAttributegroupname(
          formula.attributegroupname || ""
        );
        estimateItemQtyFormula.setAttributegroupid(
          formula.attributegroupid || 0
        );

        if (formula.breakoutsList) {
          const breakouts = formula.breakoutsList.map((breakout) => {
            const breakoutItem = new EstimateBreakoutMap();
            breakoutItem.setBreakoutid(breakout?.breakoutid || "");
            breakoutItem.setBreakoutmapid(breakout?.breakoutmapid || "");
            breakoutItem.setBreakoutname(breakout.breakoutname);
            breakoutItem.setMultiplier(breakout?.multiplier || 1);
            breakoutItem.setTakeoffid(breakout?.takeoffid || ""); //synominous with estimate_assembly_id
            breakoutItem.setEstimateid(breakout?.estimateid || "");
            return breakoutItem;
          });
          estimateItemQtyFormula.setBreakoutsList(breakouts);
        }

        if (formula.attributesList) {
          const attributes = formula.attributesList.map((attr) => {
            const attribute = new EstimateAssemblyItemAttribute();
            attribute.setAttributevalueid(attr.attributevalueid || 0);
            attribute.setAttributevaluename(attr.attributevaluename);
            attribute.setAttributegroupname(attr.attributegroupname);
            attribute.setAttributegroupid(attr.attributegroupid);
            return attribute;
          });
          estimateItemQtyFormula.setAttributesList(attributes);
        }
        return estimateItemQtyFormula;
      });
      estimateAssemblyMessage.setItemqtyformulasList(itemQtyFormulas);
    }

    return estimateAssemblyMessage;
  }

  public static getProjectEstimateAssemblies = (
    estimateId: string
  ): Promise<GetEstimateAssembliesByEstimateIdResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetEstimateAssembliesByEstimateIdRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.estimateAssemblyServiceClient.getEstimateAssembliesByEstimateId(
        req,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static getAssemblyStatsForEstimate = (
    estimateId: string
  ): Promise<GetTakeoffStatsResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetTakeoffStatsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.estimateAssemblyServiceClient.getTakeoffStatsByEstimateID(
        req,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static createProjectEstimateAssembly = (
    assembly: EstimateAssembly.AsObject
  ): Promise<CreateEstimateAssemblyResponse.AsObject> => {
    const estimateAssemblyMessage = this.buildEstimateAssemblyMessage(assembly);

    const req = new CreateEstimateAssemblyRequest();
    req.setEstimateassembly(estimateAssemblyMessage);
    req.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      clients.estimateAssemblyServiceClient.createEstimateAssembly(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateProjectEstimateAssembly = (
    assembly: EstimateAssembly.AsObject
  ): Promise<UpdateEstimateAssemblyResponse.AsObject> => {
    const estimateAssemblyMessage = this.buildEstimateAssemblyMessage(assembly);

    const req = new UpdateEstimateAssemblyRequest();
    req.setEstimateassembly(estimateAssemblyMessage);
    req.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      clients.estimateAssemblyServiceClient.updateEstimateAssembly(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}

export const calculateSegmentCount = (
  assembly: EstimateAssembly.AsObject | ChangeOrderAssembly.AsObject
): number => {
  const pointsList = assembly.pointsList || [];

  if (pointsList.length < 2) {
    return 0; // No segments if fewer than two points
  }

  let segmentCount = 0;

  // Start checking from the second point
  for (let i = 1; i < pointsList.length; i++) {
    const pointTwo = pointsList[i];

    if (pointTwo.isincludedincount && pointTwo.allowtrailingline) {
      segmentCount++;
    }
  }

  //Cleanup check
  if (segmentCount === 0) {
    if (assembly.pointsList.some((p) => p.allowtrailingline)) {
      segmentCount = 1;
    }
    return 0;
  }

  //ckeck the last point
  const lastPoint = last(pointsList);
  if (lastPoint!.allowtrailingline && !lastPoint!.isincludedincount) {
    segmentCount++;
  }

  return segmentCount;
};
