import { useEffect } from "react";
import { ListEstimates } from "./estimateAndChangeOrders/ListEstimates";
import { DocumentViewport } from "./DocumentViewport";
import { useUnityBuildStore } from "../states/store";
import { ListContracts } from "./estimateAndChangeOrders/ListContracts";
import { PointEditor } from "./SVGelements/PointEditor";
import { ColorAndStyleEditor } from "./SVGelements/ColorAndStyleEditor";
import { useStore } from "zustand";
import { CloseoutTabs } from "./closeout/CloseoutTabs";
import { ToolBar } from "./ToolBar";
import { ObjectService } from "../api/ObjectService";
import { ListChangeOrders } from "./estimateAndChangeOrders/ListChangeOrders";
import { Dialog, DialogContent } from "@mui/material";
import TakeoffPallette from "./takeoffPallette/TakeoffPalletteTab";

export function MasterProjectView() {
  const {
    countedAssemblies,
    selectedObjectId,
    currentPage,
    setFilteredCountedAssemblies,
    userBackgroundColor,
    selectedProject,
    isOpeningViewport,
    isEditingPoint,
    isEditingStyle,
    selectedTakeoffAssembly,
    isViewingEstimateList,
    isAnnotating,
    isViewingExtension,
    isViewingTakeoffPallette,
    isViewingChangeOrderList,
    selectedContract,
    selectedChangeOrder,
    setIsViewingExtension,
  } = useStore(useUnityBuildStore);

  useEffect(() => {
    if (countedAssemblies && selectedObjectId && currentPage) {
      const assemblies = ObjectService.filterAssemblyByObjectIdandPageNumber(
        countedAssemblies,
        selectedObjectId,
        currentPage
      );
      setFilteredCountedAssemblies(assemblies);
    }
  }, [
    countedAssemblies,
    selectedObjectId,
    setFilteredCountedAssemblies,
    currentPage,
  ]);

  return (
    <>
      {isViewingEstimateList && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <ListEstimates />
          </div>
          {(!selectedContract.contractid ||
            !selectedChangeOrder.changeorderid) && (
            <div style={{ width: "50%" }}>
              <ListContracts />
            </div>
          )}
        </div>
      )}

      {selectedProject.id && isOpeningViewport && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          {/* ToolBar at the top */}
          <div style={{ flexShrink: 0, zIndex: 10 }}>
            <ToolBar />
            {/* TakeoffPallette positioned correctly */}
            {isViewingTakeoffPallette && (
              <div
                className="TakeoffPalletteContainer"
                style={{
                  backgroundColor: userBackgroundColor,
                }}
              >
                <TakeoffPallette />
              </div>
            )}
          </div>

          {/* Content area */}
          <div style={{ display: "flex", flexGrow: 1 }}>
            {/* DocumentViewport takes the remaining width */}
            <div style={{ flexGrow: 1, overflow: "hidden" }}>
              <DocumentViewport />
            </div>
          </div>
        </div>
      )}

      {isEditingPoint && selectedTakeoffAssembly && <PointEditor />}
      {isAnnotating === false && isEditingStyle && selectedTakeoffAssembly && (
        <ColorAndStyleEditor />
      )}
      <Dialog
        open={isViewingExtension}
        onClose={() => setIsViewingExtension(false)}
        fullScreen={true}
        style={{
          height: "100vh",
          backgroundColor: userBackgroundColor,
        }}
      >
        <DialogContent>
          <CloseoutTabs />
        </DialogContent>
      </Dialog>
      {isViewingExtension && <CloseoutTabs />}
      {isViewingChangeOrderList && <ListChangeOrders />}
    </>
  );
}
