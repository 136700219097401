// inferenceHooks.ts

import { useQuery } from "react-query";
import { ClassificationService as cs } from "../api/ClassificationService";
import { useCallback, useEffect, useRef, useState } from "react";

import { useStore } from "zustand";
import {
  InferenceCountObject,
  useInferenceStore,
} from "../states/inferenceStore";
import { useUnityBuildStore } from "../states/store";
import { filterInferenceObjects } from "../utils/filterInferenceObjects";
import { capturePDFArea } from "./useHandleTakeoffMouseEvents";
import {
  GCS_BUCKET_NAME,
  HIGH_RES_SCALE,
  IMG_CAPTURE_SIZE,
} from "../utils/constants";
import { useFetchObjects } from "./objectHooks";
import { ToolBarHelper } from "./toolbarHooks";
import { InferenceService } from "../api/InferenceService";

// Inference namespace to group related hooks and functions
export namespace Inference {
  export function useFetchClass() {
    const { data, error, isLoading } = useQuery("class", async () => {
      const response = await cs.getAllImageClassifications();
      return response.classificationsList;
    });

    return { data, error, isLoading };
  }

  export function useFetchClassMap() {
    const InfStore = useInferenceStore();

    return useCallback(async () => {
      if (InfStore.classMap) return;

      try {
        const response = await cs.getClassificationMap();

        const classificationMap: Record<string, string> = {};
        response.classificationmapMap.forEach(
          ([key, value]: [string, string]) => {
            classificationMap[key] = value;
          }
        );

        InfStore.setClassMap(classificationMap);
      } catch (error) {
        console.error("Failed to fetch classification map:", error);
      }
    }, [InfStore]);
  }

  export function useHandleInferenceItemFilter(
    inferenceCountObjects: InferenceCountObject[],
    setFilteredInferenceCountObjects: (data: InferenceCountObject[]) => void,
    setUniqueClassNames: (data: string[]) => void,
    setSelectedResults: (data: boolean[]) => void,
    setFilteredToOriginalIndexMap: (
      data: { filteredIndex: number; originalIndex: number; image: string }[]
    ) => void,
    setAllSelected: (value: boolean) => void,
    boundingBoxImages: string[][]
  ) {
    const UBstore = useStore(useUnityBuildStore);
    const InfStore = useInferenceStore();

    return useCallback(
      (selectedValue: string) => {
        const { filteredInferenceCountObjects, uniqueClassNames } =
          filterInferenceObjects(
            inferenceCountObjects,
            UBstore.currentPage,
            UBstore.selectedObjectId,
            InfStore.classMap ?? {},
            selectedValue,
            InfStore.setInferenceItemFilter
          );

        setFilteredInferenceCountObjects(filteredInferenceCountObjects);
        setUniqueClassNames(uniqueClassNames);

        if (filteredInferenceCountObjects.length > 0) {
          const totalBoxes =
            filteredInferenceCountObjects[0].resultsList[0].boxesList.length;
          setSelectedResults(new Array(totalBoxes).fill(false));
        }

        const indexMap: {
          filteredIndex: number;
          originalIndex: number;
          image: string;
        }[] = [];

        filteredInferenceCountObjects.forEach((inferenceObject, infIndex) => {
          inferenceObject.resultsList.forEach((result) => {
            result.labelsList.forEach((label, originalIndex) => {
              if (
                !selectedValue ||
                label ===
                  parseInt(
                    Object.keys(InfStore.classMap ?? {}).find(
                      (key) => InfStore.classMap![key] === selectedValue
                    ) || ""
                  )
              ) {
                const image =
                  boundingBoxImages[infIndex]?.[originalIndex] || "";
                indexMap.push({
                  filteredIndex: indexMap.length,
                  originalIndex,
                  image,
                });
              }
            });
          });
        });

        setFilteredToOriginalIndexMap(indexMap);
        setAllSelected(false);
      },
      //eslint-disable-next-line react-hooks/exhaustive-deps
      [
        inferenceCountObjects,
        UBstore.currentPage,
        UBstore.selectedObjectId,
        InfStore.classMap,
        boundingBoxImages,
      ]
    );
  }

  export function useCaptureBoundingBoxImages(
    filteredInferenceCountObjects: InferenceCountObject[],
    setBoundingBoxImages: (data: string[][]) => void
  ) {
    return useCallback(async () => {
      const pdfCanvas = document.querySelector(
        ".react-pdf__Page__canvas"
      ) as HTMLCanvasElement;
      if (!pdfCanvas) return;

      const images: string[][] = [];

      for (const inferenceObject of filteredInferenceCountObjects) {
        const resultImages: string[] = [];
        for (const result of inferenceObject.resultsList) {
          for (const box of result.boxesList) {
            const centerX = ((box.xmin + box.xmax) / 2) * HIGH_RES_SCALE;
            const centerY = ((box.ymin + box.ymax) / 2) * HIGH_RES_SCALE;

            const cursorPositionRef = { current: { x: centerX, y: centerY } };

            const image = await capturePDFArea(
              pdfCanvas,
              cursorPositionRef,
              IMG_CAPTURE_SIZE
            );
            resultImages.push(image);
          }
        }
        images.push(resultImages);
      }

      setBoundingBoxImages(images);
    }, [filteredInferenceCountObjects, setBoundingBoxImages]);
  }

  export function useFilteredInferenceEffects(
    inferenceCountObjects: InferenceCountObject[],
    UBstore: any,
    InfStore: any,
    selectedClass: string,
    setFilteredInferenceCountObjects: (data: InferenceCountObject[]) => void,
    setUniqueClassNames: (data: string[]) => void,
    setSelectedResults: (data: boolean[]) => void,
    captureBoundingBoxImages: () => void,
    setFilteredToOriginalIndexMap: (
      data: { filteredIndex: number; originalIndex: number; image: string }[]
    ) => void,
    boundingBoxImages: string[][]
  ) {
    useEffect(() => {
      const { filteredInferenceCountObjects, uniqueClassNames } =
        filterInferenceObjects(
          inferenceCountObjects,
          UBstore.currentPage,
          UBstore.selectedObjectId,
          InfStore.classMap ?? {},
          selectedClass,
          InfStore.setInferenceItemFilter
        );

      setFilteredInferenceCountObjects(filteredInferenceCountObjects);
      setUniqueClassNames(uniqueClassNames);

      if (filteredInferenceCountObjects.length > 0) {
        const totalBoxes =
          filteredInferenceCountObjects[0].resultsList[0].boxesList.length;
        setSelectedResults(new Array(totalBoxes).fill(false));
      }

      captureBoundingBoxImages();

      const indexMap: {
        filteredIndex: number;
        originalIndex: number;
        image: string;
      }[] = [];
      filteredInferenceCountObjects.forEach((inferenceObject, infIndex) => {
        inferenceObject.resultsList.forEach((result) => {
          result.labelsList.forEach((label, originalIndex) => {
            if (
              !selectedClass ||
              label ===
                parseInt(
                  Object.keys(InfStore.classMap ?? {}).find(
                    (key) => InfStore.classMap![key] === selectedClass
                  ) || ""
                )
            ) {
              const image = boundingBoxImages[infIndex]?.[originalIndex] || "";
              indexMap.push({
                filteredIndex: indexMap.length,
                originalIndex,
                image,
              });
            }
          });
        });
      });

      setFilteredToOriginalIndexMap(indexMap);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      inferenceCountObjects,
      UBstore.currentPage,
      UBstore.selectedObjectId,
      InfStore.classMap,
      selectedClass,
      InfStore.setInferenceItemFilter,
    ]);
  }

  export const useHandleInference = () => {
    const UBstore = useStore(useUnityBuildStore);
    const InfStore = useStore(useInferenceStore);
    const { data: availableObjects } = useFetchObjects();
    const [runningInference, setRunningInference] = useState<boolean>(false);
    const [elapsedTime, setElapsedTime] = useState<number>(0); // Use state for dynamic timer
    const intervalRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the interval ID

    const runInference = async () => {
      if (UBstore.selectedObjectId) {
        if (!availableObjects) return;
        setRunningInference(true);

        const gscObject = ToolBarHelper.filterAvailableObjects(
          availableObjects,
          UBstore.selectedObjectId
        );

        if (!gscObject) {
          console.error("Selected object not found.");
          setRunningInference(false);
          return;
        }

        // Update GCS Object Names List
        InfStore.setGcsObjectNamesList([
          ...InfStore.gcsObjectNamesList,
          gscObject.gcsobjectname,
        ]);

        // Start a timer to measure the time it takes to run the inference
        const startTime = performance.now();
        setElapsedTime(0); // Reset elapsed time

        // Start interval to update the elapsed time every second
        intervalRef.current = setInterval(() => {
          setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);

        // Run the inference
        const currentPageIndex = UBstore.currentPage - 1;
        try {
          const response = await InferenceService.runCountInference(
            UBstore.selectedObjectId,
            [currentPageIndex],
            GCS_BUCKET_NAME,
            [gscObject.gcsobjectname]
          );

          // Calculate the elapsed time
          const endTime = performance.now();
          const totalElapsedTime = (endTime - startTime) / 1000;
          setElapsedTime(totalElapsedTime); // Set final elapsed time

          // Prepare the new inference object with the desired parameters
          const newInferenceCountObject: InferenceCountObject = {
            objectid: UBstore.selectedObjectId,
            pagenumbersList: [currentPageIndex],
            gcsobjectnamesList: [gscObject.gcsobjectname],
            ...response,
          };

          // Update the state, replacing or adding entries as necessary
          useInferenceStore.setState((state) => {
            const updatedInferenceCountObjects =
              state.inferenceCountObjects.filter(
                (obj) =>
                  !(
                    obj.pagenumbersList.includes(currentPageIndex) &&
                    obj.gcsobjectnamesList[0] === gscObject.gcsobjectname
                  )
              );

            // Add the new object
            updatedInferenceCountObjects.push(newInferenceCountObject);

            return {
              inferenceCountObjects: updatedInferenceCountObjects,
            };
          });
        } catch (error) {
          console.error("Error in running inference:", error);
        } finally {
          setRunningInference(false);
          if (intervalRef.current) {
            clearInterval(intervalRef.current); // Clear interval when inference is complete
          }
        }
      }
    };

    const clearInference = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Clear interval if inference is cleared manually
      }
      setElapsedTime(0); // Reset elapsed time
      InfStore.setInferenceCountObjects([]);
    };

    // Cleanup interval on component unmount or when runningInference changes
    useEffect(() => {
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }, []);

    return { runInference, clearInference, runningInference, elapsedTime };
  };
}
