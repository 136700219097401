// source: changeOrderCloseout.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.ChangeOrderCloseout', null, global);
goog.exportSymbol('proto.ChangeOrderDirectLaborType', null, global);
goog.exportSymbol('proto.ChangeOrderEquipmentType', null, global);
goog.exportSymbol('proto.ChangeOrderExtensionType', null, global);
goog.exportSymbol('proto.ChangeOrderGeneralExpenseType', null, global);
goog.exportSymbol('proto.ChangeOrderIncidentalLaborType', null, global);
goog.exportSymbol('proto.ChangeOrderIndirectLaborType', null, global);
goog.exportSymbol('proto.ChangeOrderLaborFactoringType', null, global);
goog.exportSymbol('proto.ChangeOrderQuoteItem', null, global);
goog.exportSymbol('proto.ChangeOrderQuoteType', null, global);
goog.exportSymbol('proto.ChangeOrderSubcontractType', null, global);
goog.exportSymbol('proto.ChangeOrderTotalFinalPricingData', null, global);
goog.exportSymbol('proto.ChangeOrderTotalFinalRowData', null, global);
goog.exportSymbol('proto.ChangeOrderTotalPricingType', null, global);
goog.exportSymbol('proto.ChangeOrderTotalRowPricingData', null, global);
goog.exportSymbol('proto.CreateChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.CreateOrUpdateChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.CreateOrUpdateChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.DeleteChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.DeleteChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.GetChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.GetChangeOrderCloseoutResponse', null, global);
goog.exportSymbol('proto.GetChangeOrderCloseoutsRequest', null, global);
goog.exportSymbol('proto.GetChangeOrderCloseoutsResponse', null, global);
goog.exportSymbol('proto.UpdateChangeOrderCloseoutRequest', null, global);
goog.exportSymbol('proto.UpdateChangeOrderCloseoutResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderExtensionType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderExtensionType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderExtensionType.displayName = 'proto.ChangeOrderExtensionType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderDirectLaborType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderDirectLaborType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderDirectLaborType.displayName = 'proto.ChangeOrderDirectLaborType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderLaborFactoringType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderLaborFactoringType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderLaborFactoringType.displayName = 'proto.ChangeOrderLaborFactoringType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderIncidentalLaborType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderIncidentalLaborType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderIncidentalLaborType.displayName = 'proto.ChangeOrderIncidentalLaborType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderIndirectLaborType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderIndirectLaborType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderIndirectLaborType.displayName = 'proto.ChangeOrderIndirectLaborType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderEquipmentType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderEquipmentType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderEquipmentType.displayName = 'proto.ChangeOrderEquipmentType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderGeneralExpenseType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderGeneralExpenseType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderGeneralExpenseType.displayName = 'proto.ChangeOrderGeneralExpenseType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderSubcontractType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderSubcontractType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderSubcontractType.displayName = 'proto.ChangeOrderSubcontractType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderQuoteItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderQuoteItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderQuoteItem.displayName = 'proto.ChangeOrderQuoteItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderQuoteType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ChangeOrderQuoteType.repeatedFields_, null);
};
goog.inherits(proto.ChangeOrderQuoteType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderQuoteType.displayName = 'proto.ChangeOrderQuoteType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderTotalRowPricingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderTotalRowPricingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderTotalRowPricingData.displayName = 'proto.ChangeOrderTotalRowPricingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderTotalPricingType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderTotalPricingType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderTotalPricingType.displayName = 'proto.ChangeOrderTotalPricingType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderTotalFinalPricingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeOrderTotalFinalPricingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderTotalFinalPricingData.displayName = 'proto.ChangeOrderTotalFinalPricingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderTotalFinalRowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ChangeOrderTotalFinalRowData.repeatedFields_, null);
};
goog.inherits(proto.ChangeOrderTotalFinalRowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderTotalFinalRowData.displayName = 'proto.ChangeOrderTotalFinalRowData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeOrderCloseout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ChangeOrderCloseout.repeatedFields_, null);
};
goog.inherits(proto.ChangeOrderCloseout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeOrderCloseout.displayName = 'proto.ChangeOrderCloseout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateChangeOrderCloseoutRequest.repeatedFields_, null);
};
goog.inherits(proto.CreateChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateChangeOrderCloseoutRequest.displayName = 'proto.CreateChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateChangeOrderCloseoutResponse.displayName = 'proto.CreateChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateOrUpdateChangeOrderCloseoutRequest.repeatedFields_, null);
};
goog.inherits(proto.CreateOrUpdateChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateChangeOrderCloseoutRequest.displayName = 'proto.CreateOrUpdateChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateOrUpdateChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateOrUpdateChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateOrUpdateChangeOrderCloseoutResponse.displayName = 'proto.CreateOrUpdateChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetChangeOrderCloseoutRequest.displayName = 'proto.GetChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetChangeOrderCloseoutResponse.displayName = 'proto.GetChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetChangeOrderCloseoutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetChangeOrderCloseoutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetChangeOrderCloseoutsRequest.displayName = 'proto.GetChangeOrderCloseoutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetChangeOrderCloseoutsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetChangeOrderCloseoutsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetChangeOrderCloseoutsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetChangeOrderCloseoutsResponse.displayName = 'proto.GetChangeOrderCloseoutsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateChangeOrderCloseoutRequest.repeatedFields_, null);
};
goog.inherits(proto.UpdateChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderCloseoutRequest.displayName = 'proto.UpdateChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateChangeOrderCloseoutResponse.displayName = 'proto.UpdateChangeOrderCloseoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteChangeOrderCloseoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteChangeOrderCloseoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteChangeOrderCloseoutRequest.displayName = 'proto.DeleteChangeOrderCloseoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteChangeOrderCloseoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteChangeOrderCloseoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteChangeOrderCloseoutResponse.displayName = 'proto.DeleteChangeOrderCloseoutResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderExtensionType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderExtensionType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderExtensionType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderExtensionType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    itemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overrideunitcost: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    overrideproductionrate: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totallaborhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    itemuom: jspb.Message.getFieldWithDefault(msg, 9, ""),
    itemuomvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    quotegroup: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderExtensionType}
 */
proto.ChangeOrderExtensionType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderExtensionType;
  return proto.ChangeOrderExtensionType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderExtensionType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderExtensionType}
 */
proto.ChangeOrderExtensionType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemunitcost(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverrideunitcost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyproductionrate(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverrideproductionrate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotallaborhours(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemuom(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemuomvalue(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderExtensionType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderExtensionType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderExtensionType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderExtensionType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getItemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverrideunitcost();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getOverrideproductionrate();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTotallaborhours();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getItemuom();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getItemuomvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChangeOrderExtensionType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float itemQty = 2;
 * @return {number}
 */
proto.ChangeOrderExtensionType.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float itemUnitCost = 3;
 * @return {number}
 */
proto.ChangeOrderExtensionType.prototype.getItemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setItemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool overrideUnitCost = 4;
 * @return {boolean}
 */
proto.ChangeOrderExtensionType.prototype.getOverrideunitcost = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setOverrideunitcost = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional float hourlyProductionRate = 5;
 * @return {number}
 */
proto.ChangeOrderExtensionType.prototype.getHourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setHourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool overrideProductionRate = 6;
 * @return {boolean}
 */
proto.ChangeOrderExtensionType.prototype.getOverrideproductionrate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setOverrideproductionrate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional float totalCost = 7;
 * @return {number}
 */
proto.ChangeOrderExtensionType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float totalLaborHours = 8;
 * @return {number}
 */
proto.ChangeOrderExtensionType.prototype.getTotallaborhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setTotallaborhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string itemUom = 9;
 * @return {string}
 */
proto.ChangeOrderExtensionType.prototype.getItemuom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setItemuom = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional float itemUomValue = 10;
 * @return {number}
 */
proto.ChangeOrderExtensionType.prototype.getItemuomvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setItemuomvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional bool isQuoted = 11;
 * @return {boolean}
 */
proto.ChangeOrderExtensionType.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string attributeGroupName = 12;
 * @return {string}
 */
proto.ChangeOrderExtensionType.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string quoteGroup = 13;
 * @return {string}
 */
proto.ChangeOrderExtensionType.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderExtensionType} returns this
 */
proto.ChangeOrderExtensionType.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderDirectLaborType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderDirectLaborType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderDirectLaborType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderDirectLaborType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    distributionpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderDirectLaborType}
 */
proto.ChangeOrderDirectLaborType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderDirectLaborType;
  return proto.ChangeOrderDirectLaborType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderDirectLaborType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderDirectLaborType}
 */
proto.ChangeOrderDirectLaborType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistributionpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderDirectLaborType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderDirectLaborType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderDirectLaborType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderDirectLaborType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getDistributionpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChangeOrderDirectLaborType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderDirectLaborType} returns this
 */
proto.ChangeOrderDirectLaborType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float allocatedHours = 2;
 * @return {number}
 */
proto.ChangeOrderDirectLaborType.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderDirectLaborType} returns this
 */
proto.ChangeOrderDirectLaborType.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float laborRate = 3;
 * @return {number}
 */
proto.ChangeOrderDirectLaborType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderDirectLaborType} returns this
 */
proto.ChangeOrderDirectLaborType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float burdenPercent = 4;
 * @return {number}
 */
proto.ChangeOrderDirectLaborType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderDirectLaborType} returns this
 */
proto.ChangeOrderDirectLaborType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float fringe = 5;
 * @return {number}
 */
proto.ChangeOrderDirectLaborType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderDirectLaborType} returns this
 */
proto.ChangeOrderDirectLaborType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float distributionPercent = 6;
 * @return {number}
 */
proto.ChangeOrderDirectLaborType.prototype.getDistributionpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderDirectLaborType} returns this
 */
proto.ChangeOrderDirectLaborType.prototype.setDistributionpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalCost = 7;
 * @return {number}
 */
proto.ChangeOrderDirectLaborType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderDirectLaborType} returns this
 */
proto.ChangeOrderDirectLaborType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderLaborFactoringType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderLaborFactoringType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderLaborFactoringType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderLaborFactoringType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    impactpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    laborpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    distributionpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderLaborFactoringType}
 */
proto.ChangeOrderLaborFactoringType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderLaborFactoringType;
  return proto.ChangeOrderLaborFactoringType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderLaborFactoringType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderLaborFactoringType}
 */
proto.ChangeOrderLaborFactoringType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImpactpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborpercent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistributionpercent(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderLaborFactoringType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderLaborFactoringType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderLaborFactoringType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderLaborFactoringType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getImpactpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getLaborpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDistributionpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChangeOrderLaborFactoringType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderLaborFactoringType} returns this
 */
proto.ChangeOrderLaborFactoringType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float allocatedHours = 2;
 * @return {number}
 */
proto.ChangeOrderLaborFactoringType.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderLaborFactoringType} returns this
 */
proto.ChangeOrderLaborFactoringType.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float laborRate = 3;
 * @return {number}
 */
proto.ChangeOrderLaborFactoringType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderLaborFactoringType} returns this
 */
proto.ChangeOrderLaborFactoringType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float burdenPercent = 4;
 * @return {number}
 */
proto.ChangeOrderLaborFactoringType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderLaborFactoringType} returns this
 */
proto.ChangeOrderLaborFactoringType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float fringe = 5;
 * @return {number}
 */
proto.ChangeOrderLaborFactoringType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderLaborFactoringType} returns this
 */
proto.ChangeOrderLaborFactoringType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float impactPercent = 6;
 * @return {number}
 */
proto.ChangeOrderLaborFactoringType.prototype.getImpactpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderLaborFactoringType} returns this
 */
proto.ChangeOrderLaborFactoringType.prototype.setImpactpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float laborPercent = 7;
 * @return {number}
 */
proto.ChangeOrderLaborFactoringType.prototype.getLaborpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderLaborFactoringType} returns this
 */
proto.ChangeOrderLaborFactoringType.prototype.setLaborpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float distributionPercent = 8;
 * @return {number}
 */
proto.ChangeOrderLaborFactoringType.prototype.getDistributionpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderLaborFactoringType} returns this
 */
proto.ChangeOrderLaborFactoringType.prototype.setDistributionpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float totalCost = 9;
 * @return {number}
 */
proto.ChangeOrderLaborFactoringType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderLaborFactoringType} returns this
 */
proto.ChangeOrderLaborFactoringType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderIncidentalLaborType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderIncidentalLaborType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderIncidentalLaborType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderIncidentalLaborType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderIncidentalLaborType}
 */
proto.ChangeOrderIncidentalLaborType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderIncidentalLaborType;
  return proto.ChangeOrderIncidentalLaborType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderIncidentalLaborType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderIncidentalLaborType}
 */
proto.ChangeOrderIncidentalLaborType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderIncidentalLaborType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderIncidentalLaborType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderIncidentalLaborType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderIncidentalLaborType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChangeOrderIncidentalLaborType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderIncidentalLaborType} returns this
 */
proto.ChangeOrderIncidentalLaborType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float allocatedHours = 2;
 * @return {number}
 */
proto.ChangeOrderIncidentalLaborType.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIncidentalLaborType} returns this
 */
proto.ChangeOrderIncidentalLaborType.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float laborRate = 3;
 * @return {number}
 */
proto.ChangeOrderIncidentalLaborType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIncidentalLaborType} returns this
 */
proto.ChangeOrderIncidentalLaborType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float burdenPercent = 4;
 * @return {number}
 */
proto.ChangeOrderIncidentalLaborType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIncidentalLaborType} returns this
 */
proto.ChangeOrderIncidentalLaborType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float fringe = 5;
 * @return {number}
 */
proto.ChangeOrderIncidentalLaborType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIncidentalLaborType} returns this
 */
proto.ChangeOrderIncidentalLaborType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float totalCost = 6;
 * @return {number}
 */
proto.ChangeOrderIncidentalLaborType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIncidentalLaborType} returns this
 */
proto.ChangeOrderIncidentalLaborType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderIndirectLaborType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderIndirectLaborType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderIndirectLaborType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderIndirectLaborType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allocatedhours: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    laborrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    burdenpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    fringe: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    distributionpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderIndirectLaborType}
 */
proto.ChangeOrderIndirectLaborType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderIndirectLaborType;
  return proto.ChangeOrderIndirectLaborType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderIndirectLaborType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderIndirectLaborType}
 */
proto.ChangeOrderIndirectLaborType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAllocatedhours(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaborrate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBurdenpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFringe(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistributionpercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderIndirectLaborType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderIndirectLaborType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderIndirectLaborType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderIndirectLaborType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllocatedhours();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLaborrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getBurdenpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFringe();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getDistributionpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChangeOrderIndirectLaborType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderIndirectLaborType} returns this
 */
proto.ChangeOrderIndirectLaborType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float allocatedHours = 2;
 * @return {number}
 */
proto.ChangeOrderIndirectLaborType.prototype.getAllocatedhours = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIndirectLaborType} returns this
 */
proto.ChangeOrderIndirectLaborType.prototype.setAllocatedhours = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float laborRate = 3;
 * @return {number}
 */
proto.ChangeOrderIndirectLaborType.prototype.getLaborrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIndirectLaborType} returns this
 */
proto.ChangeOrderIndirectLaborType.prototype.setLaborrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float burdenPercent = 4;
 * @return {number}
 */
proto.ChangeOrderIndirectLaborType.prototype.getBurdenpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIndirectLaborType} returns this
 */
proto.ChangeOrderIndirectLaborType.prototype.setBurdenpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float fringe = 5;
 * @return {number}
 */
proto.ChangeOrderIndirectLaborType.prototype.getFringe = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIndirectLaborType} returns this
 */
proto.ChangeOrderIndirectLaborType.prototype.setFringe = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float distributionPercent = 6;
 * @return {number}
 */
proto.ChangeOrderIndirectLaborType.prototype.getDistributionpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIndirectLaborType} returns this
 */
proto.ChangeOrderIndirectLaborType.prototype.setDistributionpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalCost = 7;
 * @return {number}
 */
proto.ChangeOrderIndirectLaborType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderIndirectLaborType} returns this
 */
proto.ChangeOrderIndirectLaborType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderEquipmentType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderEquipmentType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderEquipmentType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderEquipmentType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderEquipmentType}
 */
proto.ChangeOrderEquipmentType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderEquipmentType;
  return proto.ChangeOrderEquipmentType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderEquipmentType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderEquipmentType}
 */
proto.ChangeOrderEquipmentType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderEquipmentType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderEquipmentType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderEquipmentType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderEquipmentType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChangeOrderEquipmentType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderEquipmentType} returns this
 */
proto.ChangeOrderEquipmentType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float quantity = 2;
 * @return {number}
 */
proto.ChangeOrderEquipmentType.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderEquipmentType} returns this
 */
proto.ChangeOrderEquipmentType.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float duration = 3;
 * @return {number}
 */
proto.ChangeOrderEquipmentType.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderEquipmentType} returns this
 */
proto.ChangeOrderEquipmentType.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float unitCost = 4;
 * @return {number}
 */
proto.ChangeOrderEquipmentType.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderEquipmentType} returns this
 */
proto.ChangeOrderEquipmentType.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float totalCost = 5;
 * @return {number}
 */
proto.ChangeOrderEquipmentType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderEquipmentType} returns this
 */
proto.ChangeOrderEquipmentType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderGeneralExpenseType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderGeneralExpenseType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderGeneralExpenseType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderGeneralExpenseType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    durationmultiplier: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    durationmultipliername: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderGeneralExpenseType}
 */
proto.ChangeOrderGeneralExpenseType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderGeneralExpenseType;
  return proto.ChangeOrderGeneralExpenseType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderGeneralExpenseType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderGeneralExpenseType}
 */
proto.ChangeOrderGeneralExpenseType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDurationmultiplier(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDurationmultipliername(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUnitcost(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderGeneralExpenseType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderGeneralExpenseType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderGeneralExpenseType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderGeneralExpenseType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDurationmultiplier();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDurationmultipliername();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChangeOrderGeneralExpenseType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderGeneralExpenseType} returns this
 */
proto.ChangeOrderGeneralExpenseType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float quantity = 2;
 * @return {number}
 */
proto.ChangeOrderGeneralExpenseType.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderGeneralExpenseType} returns this
 */
proto.ChangeOrderGeneralExpenseType.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float duration = 3;
 * @return {number}
 */
proto.ChangeOrderGeneralExpenseType.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderGeneralExpenseType} returns this
 */
proto.ChangeOrderGeneralExpenseType.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float durationMultiplier = 4;
 * @return {number}
 */
proto.ChangeOrderGeneralExpenseType.prototype.getDurationmultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderGeneralExpenseType} returns this
 */
proto.ChangeOrderGeneralExpenseType.prototype.setDurationmultiplier = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string durationMultiplierName = 5;
 * @return {string}
 */
proto.ChangeOrderGeneralExpenseType.prototype.getDurationmultipliername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderGeneralExpenseType} returns this
 */
proto.ChangeOrderGeneralExpenseType.prototype.setDurationmultipliername = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float unitCost = 6;
 * @return {number}
 */
proto.ChangeOrderGeneralExpenseType.prototype.getUnitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderGeneralExpenseType} returns this
 */
proto.ChangeOrderGeneralExpenseType.prototype.setUnitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalCost = 7;
 * @return {number}
 */
proto.ChangeOrderGeneralExpenseType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderGeneralExpenseType} returns this
 */
proto.ChangeOrderGeneralExpenseType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderSubcontractType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderSubcontractType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderSubcontractType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderSubcontractType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quotedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderSubcontractType}
 */
proto.ChangeOrderSubcontractType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderSubcontractType;
  return proto.ChangeOrderSubcontractType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderSubcontractType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderSubcontractType}
 */
proto.ChangeOrderSubcontractType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuotedcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderSubcontractType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderSubcontractType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderSubcontractType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderSubcontractType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuotedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChangeOrderSubcontractType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderSubcontractType} returns this
 */
proto.ChangeOrderSubcontractType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float quotedCost = 2;
 * @return {number}
 */
proto.ChangeOrderSubcontractType.prototype.getQuotedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderSubcontractType} returns this
 */
proto.ChangeOrderSubcontractType.prototype.setQuotedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float adjustedPercent = 3;
 * @return {number}
 */
proto.ChangeOrderSubcontractType.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderSubcontractType} returns this
 */
proto.ChangeOrderSubcontractType.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float totalCost = 4;
 * @return {number}
 */
proto.ChangeOrderSubcontractType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderSubcontractType} returns this
 */
proto.ChangeOrderSubcontractType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderQuoteItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderQuoteItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderQuoteItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderQuoteItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    itemcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderQuoteItem}
 */
proto.ChangeOrderQuoteItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderQuoteItem;
  return proto.ChangeOrderQuoteItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderQuoteItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderQuoteItem}
 */
proto.ChangeOrderQuoteItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderQuoteItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderQuoteItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderQuoteItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderQuoteItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getItemcost();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string itemName = 5;
 * @return {string}
 */
proto.ChangeOrderQuoteItem.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderQuoteItem} returns this
 */
proto.ChangeOrderQuoteItem.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float itemQty = 6;
 * @return {number}
 */
proto.ChangeOrderQuoteItem.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderQuoteItem} returns this
 */
proto.ChangeOrderQuoteItem.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float itemCost = 7;
 * @return {number}
 */
proto.ChangeOrderQuoteItem.prototype.getItemcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderQuoteItem} returns this
 */
proto.ChangeOrderQuoteItem.prototype.setItemcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ChangeOrderQuoteType.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderQuoteType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderQuoteType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderQuoteType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderQuoteType.toObject = function(includeInstance, msg) {
  var f, obj = {
    quotegroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quotedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    isdefaulttype: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    quoteitemsList: jspb.Message.toObjectList(msg.getQuoteitemsList(),
    proto.ChangeOrderQuoteItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderQuoteType}
 */
proto.ChangeOrderQuoteType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderQuoteType;
  return proto.ChangeOrderQuoteType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderQuoteType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderQuoteType}
 */
proto.ChangeOrderQuoteType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuotedcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdefaulttype(value);
      break;
    case 6:
      var value = new proto.ChangeOrderQuoteItem;
      reader.readMessage(value,proto.ChangeOrderQuoteItem.deserializeBinaryFromReader);
      msg.addQuoteitems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderQuoteType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderQuoteType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderQuoteType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderQuoteType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuotedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getIsdefaulttype();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getQuoteitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ChangeOrderQuoteItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string quotegroup = 1;
 * @return {string}
 */
proto.ChangeOrderQuoteType.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderQuoteType} returns this
 */
proto.ChangeOrderQuoteType.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float quotedCost = 2;
 * @return {number}
 */
proto.ChangeOrderQuoteType.prototype.getQuotedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderQuoteType} returns this
 */
proto.ChangeOrderQuoteType.prototype.setQuotedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float adjustedPercent = 3;
 * @return {number}
 */
proto.ChangeOrderQuoteType.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderQuoteType} returns this
 */
proto.ChangeOrderQuoteType.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float totalCost = 4;
 * @return {number}
 */
proto.ChangeOrderQuoteType.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderQuoteType} returns this
 */
proto.ChangeOrderQuoteType.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool isDefaultType = 5;
 * @return {boolean}
 */
proto.ChangeOrderQuoteType.prototype.getIsdefaulttype = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ChangeOrderQuoteType} returns this
 */
proto.ChangeOrderQuoteType.prototype.setIsdefaulttype = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated ChangeOrderQuoteItem quoteItems = 6;
 * @return {!Array<!proto.ChangeOrderQuoteItem>}
 */
proto.ChangeOrderQuoteType.prototype.getQuoteitemsList = function() {
  return /** @type{!Array<!proto.ChangeOrderQuoteItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderQuoteItem, 6));
};


/**
 * @param {!Array<!proto.ChangeOrderQuoteItem>} value
 * @return {!proto.ChangeOrderQuoteType} returns this
*/
proto.ChangeOrderQuoteType.prototype.setQuoteitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ChangeOrderQuoteItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderQuoteItem}
 */
proto.ChangeOrderQuoteType.prototype.addQuoteitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ChangeOrderQuoteItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderQuoteType} returns this
 */
proto.ChangeOrderQuoteType.prototype.clearQuoteitemsList = function() {
  return this.setQuoteitemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderTotalRowPricingData.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderTotalRowPricingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderTotalRowPricingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderTotalRowPricingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    extendedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    adjustedpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    adjustedcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    overheadpercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totaloverhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    markuppercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalmarkup: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderTotalRowPricingData}
 */
proto.ChangeOrderTotalRowPricingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderTotalRowPricingData;
  return proto.ChangeOrderTotalRowPricingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderTotalRowPricingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderTotalRowPricingData}
 */
proto.ChangeOrderTotalRowPricingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setExtendedcost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedpercent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdjustedcost(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverheadpercent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaloverhead(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMarkuppercent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalmarkup(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderTotalRowPricingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderTotalRowPricingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderTotalRowPricingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderTotalRowPricingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtendedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getAdjustedpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAdjustedcost();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverheadpercent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTotaloverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMarkuppercent();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTotalmarkup();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
};


/**
 * optional float extendedCost = 1;
 * @return {number}
 */
proto.ChangeOrderTotalRowPricingData.prototype.getExtendedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalRowPricingData} returns this
 */
proto.ChangeOrderTotalRowPricingData.prototype.setExtendedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float adjustedPercent = 2;
 * @return {number}
 */
proto.ChangeOrderTotalRowPricingData.prototype.getAdjustedpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalRowPricingData} returns this
 */
proto.ChangeOrderTotalRowPricingData.prototype.setAdjustedpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float adjustedCost = 3;
 * @return {number}
 */
proto.ChangeOrderTotalRowPricingData.prototype.getAdjustedcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalRowPricingData} returns this
 */
proto.ChangeOrderTotalRowPricingData.prototype.setAdjustedcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float overheadPercent = 4;
 * @return {number}
 */
proto.ChangeOrderTotalRowPricingData.prototype.getOverheadpercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalRowPricingData} returns this
 */
proto.ChangeOrderTotalRowPricingData.prototype.setOverheadpercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float totalOverhead = 5;
 * @return {number}
 */
proto.ChangeOrderTotalRowPricingData.prototype.getTotaloverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalRowPricingData} returns this
 */
proto.ChangeOrderTotalRowPricingData.prototype.setTotaloverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float markupPercent = 6;
 * @return {number}
 */
proto.ChangeOrderTotalRowPricingData.prototype.getMarkuppercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalRowPricingData} returns this
 */
proto.ChangeOrderTotalRowPricingData.prototype.setMarkuppercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float totalMarkup = 7;
 * @return {number}
 */
proto.ChangeOrderTotalRowPricingData.prototype.getTotalmarkup = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalRowPricingData} returns this
 */
proto.ChangeOrderTotalRowPricingData.prototype.setTotalmarkup = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float totalCost = 8;
 * @return {number}
 */
proto.ChangeOrderTotalRowPricingData.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalRowPricingData} returns this
 */
proto.ChangeOrderTotalRowPricingData.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderTotalPricingType.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderTotalPricingType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderTotalPricingType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderTotalPricingType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowdata: (f = msg.getRowdata()) && proto.ChangeOrderTotalRowPricingData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderTotalPricingType}
 */
proto.ChangeOrderTotalPricingType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderTotalPricingType;
  return proto.ChangeOrderTotalPricingType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderTotalPricingType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderTotalPricingType}
 */
proto.ChangeOrderTotalPricingType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.ChangeOrderTotalRowPricingData;
      reader.readMessage(value,proto.ChangeOrderTotalRowPricingData.deserializeBinaryFromReader);
      msg.setRowdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderTotalPricingType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderTotalPricingType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderTotalPricingType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderTotalPricingType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowdata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ChangeOrderTotalRowPricingData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChangeOrderTotalPricingType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderTotalPricingType} returns this
 */
proto.ChangeOrderTotalPricingType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ChangeOrderTotalRowPricingData rowData = 2;
 * @return {?proto.ChangeOrderTotalRowPricingData}
 */
proto.ChangeOrderTotalPricingType.prototype.getRowdata = function() {
  return /** @type{?proto.ChangeOrderTotalRowPricingData} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderTotalRowPricingData, 2));
};


/**
 * @param {?proto.ChangeOrderTotalRowPricingData|undefined} value
 * @return {!proto.ChangeOrderTotalPricingType} returns this
*/
proto.ChangeOrderTotalPricingType.prototype.setRowdata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ChangeOrderTotalPricingType} returns this
 */
proto.ChangeOrderTotalPricingType.prototype.clearRowdata = function() {
  return this.setRowdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ChangeOrderTotalPricingType.prototype.hasRowdata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderTotalFinalPricingData.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderTotalFinalPricingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderTotalFinalPricingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderTotalFinalPricingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totaloverhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalmarkup: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalfinalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderTotalFinalPricingData}
 */
proto.ChangeOrderTotalFinalPricingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderTotalFinalPricingData;
  return proto.ChangeOrderTotalFinalPricingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderTotalFinalPricingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderTotalFinalPricingData}
 */
proto.ChangeOrderTotalFinalPricingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalcost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotaloverhead(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalmarkup(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderTotalFinalPricingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderTotalFinalPricingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderTotalFinalPricingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderTotalFinalPricingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcost();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getTotaloverhead();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTotalmarkup();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTotalfinalprice();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float totalCost = 1;
 * @return {number}
 */
proto.ChangeOrderTotalFinalPricingData.prototype.getTotalcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalFinalPricingData} returns this
 */
proto.ChangeOrderTotalFinalPricingData.prototype.setTotalcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float totalOverhead = 2;
 * @return {number}
 */
proto.ChangeOrderTotalFinalPricingData.prototype.getTotaloverhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalFinalPricingData} returns this
 */
proto.ChangeOrderTotalFinalPricingData.prototype.setTotaloverhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float totalMarkup = 3;
 * @return {number}
 */
proto.ChangeOrderTotalFinalPricingData.prototype.getTotalmarkup = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalFinalPricingData} returns this
 */
proto.ChangeOrderTotalFinalPricingData.prototype.setTotalmarkup = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float totalFinalPrice = 4;
 * @return {number}
 */
proto.ChangeOrderTotalFinalPricingData.prototype.getTotalfinalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ChangeOrderTotalFinalPricingData} returns this
 */
proto.ChangeOrderTotalFinalPricingData.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ChangeOrderTotalFinalRowData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderTotalFinalRowData.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderTotalFinalRowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderTotalFinalRowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderTotalFinalRowData.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalfinaldata: (f = msg.getTotalfinaldata()) && proto.ChangeOrderTotalFinalPricingData.toObject(includeInstance, f),
    rowtypesList: jspb.Message.toObjectList(msg.getRowtypesList(),
    proto.ChangeOrderTotalPricingType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderTotalFinalRowData}
 */
proto.ChangeOrderTotalFinalRowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderTotalFinalRowData;
  return proto.ChangeOrderTotalFinalRowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderTotalFinalRowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderTotalFinalRowData}
 */
proto.ChangeOrderTotalFinalRowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderTotalFinalPricingData;
      reader.readMessage(value,proto.ChangeOrderTotalFinalPricingData.deserializeBinaryFromReader);
      msg.setTotalfinaldata(value);
      break;
    case 2:
      var value = new proto.ChangeOrderTotalPricingType;
      reader.readMessage(value,proto.ChangeOrderTotalPricingType.deserializeBinaryFromReader);
      msg.addRowtypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderTotalFinalRowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderTotalFinalRowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderTotalFinalRowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderTotalFinalRowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalfinaldata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ChangeOrderTotalFinalPricingData.serializeBinaryToWriter
    );
  }
  f = message.getRowtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ChangeOrderTotalPricingType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChangeOrderTotalFinalPricingData totalFinalData = 1;
 * @return {?proto.ChangeOrderTotalFinalPricingData}
 */
proto.ChangeOrderTotalFinalRowData.prototype.getTotalfinaldata = function() {
  return /** @type{?proto.ChangeOrderTotalFinalPricingData} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderTotalFinalPricingData, 1));
};


/**
 * @param {?proto.ChangeOrderTotalFinalPricingData|undefined} value
 * @return {!proto.ChangeOrderTotalFinalRowData} returns this
*/
proto.ChangeOrderTotalFinalRowData.prototype.setTotalfinaldata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ChangeOrderTotalFinalRowData} returns this
 */
proto.ChangeOrderTotalFinalRowData.prototype.clearTotalfinaldata = function() {
  return this.setTotalfinaldata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ChangeOrderTotalFinalRowData.prototype.hasTotalfinaldata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ChangeOrderTotalPricingType rowTypes = 2;
 * @return {!Array<!proto.ChangeOrderTotalPricingType>}
 */
proto.ChangeOrderTotalFinalRowData.prototype.getRowtypesList = function() {
  return /** @type{!Array<!proto.ChangeOrderTotalPricingType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderTotalPricingType, 2));
};


/**
 * @param {!Array<!proto.ChangeOrderTotalPricingType>} value
 * @return {!proto.ChangeOrderTotalFinalRowData} returns this
*/
proto.ChangeOrderTotalFinalRowData.prototype.setRowtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ChangeOrderTotalPricingType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderTotalPricingType}
 */
proto.ChangeOrderTotalFinalRowData.prototype.addRowtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ChangeOrderTotalPricingType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderTotalFinalRowData} returns this
 */
proto.ChangeOrderTotalFinalRowData.prototype.clearRowtypesList = function() {
  return this.setRowtypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ChangeOrderCloseout.repeatedFields_ = [2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeOrderCloseout.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeOrderCloseout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeOrderCloseout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderCloseout.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extensionList: jspb.Message.toObjectList(msg.getExtensionList(),
    proto.ChangeOrderExtensionType.toObject, includeInstance),
    directlaborList: jspb.Message.toObjectList(msg.getDirectlaborList(),
    proto.ChangeOrderDirectLaborType.toObject, includeInstance),
    incidentallaborList: jspb.Message.toObjectList(msg.getIncidentallaborList(),
    proto.ChangeOrderIncidentalLaborType.toObject, includeInstance),
    laborfactoringList: jspb.Message.toObjectList(msg.getLaborfactoringList(),
    proto.ChangeOrderLaborFactoringType.toObject, includeInstance),
    indirectlaborList: jspb.Message.toObjectList(msg.getIndirectlaborList(),
    proto.ChangeOrderIndirectLaborType.toObject, includeInstance),
    equipmentexpenseList: jspb.Message.toObjectList(msg.getEquipmentexpenseList(),
    proto.ChangeOrderEquipmentType.toObject, includeInstance),
    generalexpenseList: jspb.Message.toObjectList(msg.getGeneralexpenseList(),
    proto.ChangeOrderGeneralExpenseType.toObject, includeInstance),
    subcontractexpenseList: jspb.Message.toObjectList(msg.getSubcontractexpenseList(),
    proto.ChangeOrderSubcontractType.toObject, includeInstance),
    quoteexpenseList: jspb.Message.toObjectList(msg.getQuoteexpenseList(),
    proto.ChangeOrderQuoteType.toObject, includeInstance),
    totalfinalprice: (f = msg.getTotalfinalprice()) && proto.ChangeOrderTotalFinalRowData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeOrderCloseout}
 */
proto.ChangeOrderCloseout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeOrderCloseout;
  return proto.ChangeOrderCloseout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeOrderCloseout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeOrderCloseout}
 */
proto.ChangeOrderCloseout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    case 2:
      var value = new proto.ChangeOrderExtensionType;
      reader.readMessage(value,proto.ChangeOrderExtensionType.deserializeBinaryFromReader);
      msg.addExtension$(value);
      break;
    case 3:
      var value = new proto.ChangeOrderDirectLaborType;
      reader.readMessage(value,proto.ChangeOrderDirectLaborType.deserializeBinaryFromReader);
      msg.addDirectlabor(value);
      break;
    case 4:
      var value = new proto.ChangeOrderIncidentalLaborType;
      reader.readMessage(value,proto.ChangeOrderIncidentalLaborType.deserializeBinaryFromReader);
      msg.addIncidentallabor(value);
      break;
    case 5:
      var value = new proto.ChangeOrderLaborFactoringType;
      reader.readMessage(value,proto.ChangeOrderLaborFactoringType.deserializeBinaryFromReader);
      msg.addLaborfactoring(value);
      break;
    case 6:
      var value = new proto.ChangeOrderIndirectLaborType;
      reader.readMessage(value,proto.ChangeOrderIndirectLaborType.deserializeBinaryFromReader);
      msg.addIndirectlabor(value);
      break;
    case 7:
      var value = new proto.ChangeOrderEquipmentType;
      reader.readMessage(value,proto.ChangeOrderEquipmentType.deserializeBinaryFromReader);
      msg.addEquipmentexpense(value);
      break;
    case 8:
      var value = new proto.ChangeOrderGeneralExpenseType;
      reader.readMessage(value,proto.ChangeOrderGeneralExpenseType.deserializeBinaryFromReader);
      msg.addGeneralexpense(value);
      break;
    case 9:
      var value = new proto.ChangeOrderSubcontractType;
      reader.readMessage(value,proto.ChangeOrderSubcontractType.deserializeBinaryFromReader);
      msg.addSubcontractexpense(value);
      break;
    case 10:
      var value = new proto.ChangeOrderQuoteType;
      reader.readMessage(value,proto.ChangeOrderQuoteType.deserializeBinaryFromReader);
      msg.addQuoteexpense(value);
      break;
    case 11:
      var value = new proto.ChangeOrderTotalFinalRowData;
      reader.readMessage(value,proto.ChangeOrderTotalFinalRowData.deserializeBinaryFromReader);
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeOrderCloseout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeOrderCloseout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeOrderCloseout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeOrderCloseout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ChangeOrderExtensionType.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ChangeOrderDirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ChangeOrderIncidentalLaborType.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ChangeOrderLaborFactoringType.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ChangeOrderIndirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.ChangeOrderEquipmentType.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.ChangeOrderGeneralExpenseType.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.ChangeOrderSubcontractType.serializeBinaryToWriter
    );
  }
  f = message.getQuoteexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.ChangeOrderQuoteType.serializeBinaryToWriter
    );
  }
  f = message.getTotalfinalprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.ChangeOrderTotalFinalRowData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.ChangeOrderCloseout.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ChangeOrderExtensionType extension = 2;
 * @return {!Array<!proto.ChangeOrderExtensionType>}
 */
proto.ChangeOrderCloseout.prototype.getExtensionList = function() {
  return /** @type{!Array<!proto.ChangeOrderExtensionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderExtensionType, 2));
};


/**
 * @param {!Array<!proto.ChangeOrderExtensionType>} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setExtensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ChangeOrderExtensionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderExtensionType}
 */
proto.ChangeOrderCloseout.prototype.addExtension$ = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ChangeOrderExtensionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearExtensionList = function() {
  return this.setExtensionList([]);
};


/**
 * repeated ChangeOrderDirectLaborType directLabor = 3;
 * @return {!Array<!proto.ChangeOrderDirectLaborType>}
 */
proto.ChangeOrderCloseout.prototype.getDirectlaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderDirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderDirectLaborType, 3));
};


/**
 * @param {!Array<!proto.ChangeOrderDirectLaborType>} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setDirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ChangeOrderDirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderDirectLaborType}
 */
proto.ChangeOrderCloseout.prototype.addDirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ChangeOrderDirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearDirectlaborList = function() {
  return this.setDirectlaborList([]);
};


/**
 * repeated ChangeOrderIncidentalLaborType incidentalLabor = 4;
 * @return {!Array<!proto.ChangeOrderIncidentalLaborType>}
 */
proto.ChangeOrderCloseout.prototype.getIncidentallaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderIncidentalLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderIncidentalLaborType, 4));
};


/**
 * @param {!Array<!proto.ChangeOrderIncidentalLaborType>} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setIncidentallaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ChangeOrderIncidentalLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderIncidentalLaborType}
 */
proto.ChangeOrderCloseout.prototype.addIncidentallabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ChangeOrderIncidentalLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearIncidentallaborList = function() {
  return this.setIncidentallaborList([]);
};


/**
 * repeated ChangeOrderLaborFactoringType laborFactoring = 5;
 * @return {!Array<!proto.ChangeOrderLaborFactoringType>}
 */
proto.ChangeOrderCloseout.prototype.getLaborfactoringList = function() {
  return /** @type{!Array<!proto.ChangeOrderLaborFactoringType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderLaborFactoringType, 5));
};


/**
 * @param {!Array<!proto.ChangeOrderLaborFactoringType>} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setLaborfactoringList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ChangeOrderLaborFactoringType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderLaborFactoringType}
 */
proto.ChangeOrderCloseout.prototype.addLaborfactoring = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ChangeOrderLaborFactoringType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearLaborfactoringList = function() {
  return this.setLaborfactoringList([]);
};


/**
 * repeated ChangeOrderIndirectLaborType indirectLabor = 6;
 * @return {!Array<!proto.ChangeOrderIndirectLaborType>}
 */
proto.ChangeOrderCloseout.prototype.getIndirectlaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderIndirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderIndirectLaborType, 6));
};


/**
 * @param {!Array<!proto.ChangeOrderIndirectLaborType>} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setIndirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ChangeOrderIndirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderIndirectLaborType}
 */
proto.ChangeOrderCloseout.prototype.addIndirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ChangeOrderIndirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearIndirectlaborList = function() {
  return this.setIndirectlaborList([]);
};


/**
 * repeated ChangeOrderEquipmentType equipmentExpense = 7;
 * @return {!Array<!proto.ChangeOrderEquipmentType>}
 */
proto.ChangeOrderCloseout.prototype.getEquipmentexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderEquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderEquipmentType, 7));
};


/**
 * @param {!Array<!proto.ChangeOrderEquipmentType>} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setEquipmentexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.ChangeOrderEquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderEquipmentType}
 */
proto.ChangeOrderCloseout.prototype.addEquipmentexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.ChangeOrderEquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearEquipmentexpenseList = function() {
  return this.setEquipmentexpenseList([]);
};


/**
 * repeated ChangeOrderGeneralExpenseType generalExpense = 8;
 * @return {!Array<!proto.ChangeOrderGeneralExpenseType>}
 */
proto.ChangeOrderCloseout.prototype.getGeneralexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderGeneralExpenseType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderGeneralExpenseType, 8));
};


/**
 * @param {!Array<!proto.ChangeOrderGeneralExpenseType>} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setGeneralexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.ChangeOrderGeneralExpenseType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderGeneralExpenseType}
 */
proto.ChangeOrderCloseout.prototype.addGeneralexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.ChangeOrderGeneralExpenseType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearGeneralexpenseList = function() {
  return this.setGeneralexpenseList([]);
};


/**
 * repeated ChangeOrderSubcontractType subcontractExpense = 9;
 * @return {!Array<!proto.ChangeOrderSubcontractType>}
 */
proto.ChangeOrderCloseout.prototype.getSubcontractexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderSubcontractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderSubcontractType, 9));
};


/**
 * @param {!Array<!proto.ChangeOrderSubcontractType>} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setSubcontractexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.ChangeOrderSubcontractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderSubcontractType}
 */
proto.ChangeOrderCloseout.prototype.addSubcontractexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.ChangeOrderSubcontractType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearSubcontractexpenseList = function() {
  return this.setSubcontractexpenseList([]);
};


/**
 * repeated ChangeOrderQuoteType quoteExpense = 10;
 * @return {!Array<!proto.ChangeOrderQuoteType>}
 */
proto.ChangeOrderCloseout.prototype.getQuoteexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderQuoteType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderQuoteType, 10));
};


/**
 * @param {!Array<!proto.ChangeOrderQuoteType>} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setQuoteexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.ChangeOrderQuoteType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderQuoteType}
 */
proto.ChangeOrderCloseout.prototype.addQuoteexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.ChangeOrderQuoteType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearQuoteexpenseList = function() {
  return this.setQuoteexpenseList([]);
};


/**
 * optional ChangeOrderTotalFinalRowData totalFinalPrice = 11;
 * @return {?proto.ChangeOrderTotalFinalRowData}
 */
proto.ChangeOrderCloseout.prototype.getTotalfinalprice = function() {
  return /** @type{?proto.ChangeOrderTotalFinalRowData} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderTotalFinalRowData, 11));
};


/**
 * @param {?proto.ChangeOrderTotalFinalRowData|undefined} value
 * @return {!proto.ChangeOrderCloseout} returns this
*/
proto.ChangeOrderCloseout.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ChangeOrderCloseout} returns this
 */
proto.ChangeOrderCloseout.prototype.clearTotalfinalprice = function() {
  return this.setTotalfinalprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ChangeOrderCloseout.prototype.hasTotalfinalprice = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CreateChangeOrderCloseoutRequest.repeatedFields_ = [2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extensionList: jspb.Message.toObjectList(msg.getExtensionList(),
    proto.ChangeOrderExtensionType.toObject, includeInstance),
    directlaborList: jspb.Message.toObjectList(msg.getDirectlaborList(),
    proto.ChangeOrderDirectLaborType.toObject, includeInstance),
    incidentallaborList: jspb.Message.toObjectList(msg.getIncidentallaborList(),
    proto.ChangeOrderIncidentalLaborType.toObject, includeInstance),
    laborfactoringList: jspb.Message.toObjectList(msg.getLaborfactoringList(),
    proto.ChangeOrderLaborFactoringType.toObject, includeInstance),
    indirectlaborList: jspb.Message.toObjectList(msg.getIndirectlaborList(),
    proto.ChangeOrderIndirectLaborType.toObject, includeInstance),
    equipmentexpenseList: jspb.Message.toObjectList(msg.getEquipmentexpenseList(),
    proto.ChangeOrderEquipmentType.toObject, includeInstance),
    generalexpenseList: jspb.Message.toObjectList(msg.getGeneralexpenseList(),
    proto.ChangeOrderGeneralExpenseType.toObject, includeInstance),
    subcontractexpenseList: jspb.Message.toObjectList(msg.getSubcontractexpenseList(),
    proto.ChangeOrderSubcontractType.toObject, includeInstance),
    quoteexpenseList: jspb.Message.toObjectList(msg.getQuoteexpenseList(),
    proto.ChangeOrderQuoteType.toObject, includeInstance),
    totalfinalprice: (f = msg.getTotalfinalprice()) && proto.ChangeOrderTotalFinalRowData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateChangeOrderCloseoutRequest}
 */
proto.CreateChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateChangeOrderCloseoutRequest;
  return proto.CreateChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateChangeOrderCloseoutRequest}
 */
proto.CreateChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    case 2:
      var value = new proto.ChangeOrderExtensionType;
      reader.readMessage(value,proto.ChangeOrderExtensionType.deserializeBinaryFromReader);
      msg.addExtension$(value);
      break;
    case 3:
      var value = new proto.ChangeOrderDirectLaborType;
      reader.readMessage(value,proto.ChangeOrderDirectLaborType.deserializeBinaryFromReader);
      msg.addDirectlabor(value);
      break;
    case 4:
      var value = new proto.ChangeOrderIncidentalLaborType;
      reader.readMessage(value,proto.ChangeOrderIncidentalLaborType.deserializeBinaryFromReader);
      msg.addIncidentallabor(value);
      break;
    case 5:
      var value = new proto.ChangeOrderLaborFactoringType;
      reader.readMessage(value,proto.ChangeOrderLaborFactoringType.deserializeBinaryFromReader);
      msg.addLaborfactoring(value);
      break;
    case 6:
      var value = new proto.ChangeOrderIndirectLaborType;
      reader.readMessage(value,proto.ChangeOrderIndirectLaborType.deserializeBinaryFromReader);
      msg.addIndirectlabor(value);
      break;
    case 7:
      var value = new proto.ChangeOrderEquipmentType;
      reader.readMessage(value,proto.ChangeOrderEquipmentType.deserializeBinaryFromReader);
      msg.addEquipmentexpense(value);
      break;
    case 8:
      var value = new proto.ChangeOrderGeneralExpenseType;
      reader.readMessage(value,proto.ChangeOrderGeneralExpenseType.deserializeBinaryFromReader);
      msg.addGeneralexpense(value);
      break;
    case 9:
      var value = new proto.ChangeOrderSubcontractType;
      reader.readMessage(value,proto.ChangeOrderSubcontractType.deserializeBinaryFromReader);
      msg.addSubcontractexpense(value);
      break;
    case 10:
      var value = new proto.ChangeOrderQuoteType;
      reader.readMessage(value,proto.ChangeOrderQuoteType.deserializeBinaryFromReader);
      msg.addQuoteexpense(value);
      break;
    case 11:
      var value = new proto.ChangeOrderTotalFinalRowData;
      reader.readMessage(value,proto.ChangeOrderTotalFinalRowData.deserializeBinaryFromReader);
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ChangeOrderExtensionType.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ChangeOrderDirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ChangeOrderIncidentalLaborType.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ChangeOrderLaborFactoringType.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ChangeOrderIndirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.ChangeOrderEquipmentType.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.ChangeOrderGeneralExpenseType.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.ChangeOrderSubcontractType.serializeBinaryToWriter
    );
  }
  f = message.getQuoteexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.ChangeOrderQuoteType.serializeBinaryToWriter
    );
  }
  f = message.getTotalfinalprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.ChangeOrderTotalFinalRowData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ChangeOrderExtensionType extension = 2;
 * @return {!Array<!proto.ChangeOrderExtensionType>}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getExtensionList = function() {
  return /** @type{!Array<!proto.ChangeOrderExtensionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderExtensionType, 2));
};


/**
 * @param {!Array<!proto.ChangeOrderExtensionType>} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setExtensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ChangeOrderExtensionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderExtensionType}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.addExtension$ = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ChangeOrderExtensionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearExtensionList = function() {
  return this.setExtensionList([]);
};


/**
 * repeated ChangeOrderDirectLaborType directLabor = 3;
 * @return {!Array<!proto.ChangeOrderDirectLaborType>}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getDirectlaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderDirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderDirectLaborType, 3));
};


/**
 * @param {!Array<!proto.ChangeOrderDirectLaborType>} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setDirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ChangeOrderDirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderDirectLaborType}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.addDirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ChangeOrderDirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearDirectlaborList = function() {
  return this.setDirectlaborList([]);
};


/**
 * repeated ChangeOrderIncidentalLaborType incidentalLabor = 4;
 * @return {!Array<!proto.ChangeOrderIncidentalLaborType>}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getIncidentallaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderIncidentalLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderIncidentalLaborType, 4));
};


/**
 * @param {!Array<!proto.ChangeOrderIncidentalLaborType>} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setIncidentallaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ChangeOrderIncidentalLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderIncidentalLaborType}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.addIncidentallabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ChangeOrderIncidentalLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearIncidentallaborList = function() {
  return this.setIncidentallaborList([]);
};


/**
 * repeated ChangeOrderLaborFactoringType laborFactoring = 5;
 * @return {!Array<!proto.ChangeOrderLaborFactoringType>}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getLaborfactoringList = function() {
  return /** @type{!Array<!proto.ChangeOrderLaborFactoringType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderLaborFactoringType, 5));
};


/**
 * @param {!Array<!proto.ChangeOrderLaborFactoringType>} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setLaborfactoringList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ChangeOrderLaborFactoringType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderLaborFactoringType}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.addLaborfactoring = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ChangeOrderLaborFactoringType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearLaborfactoringList = function() {
  return this.setLaborfactoringList([]);
};


/**
 * repeated ChangeOrderIndirectLaborType indirectLabor = 6;
 * @return {!Array<!proto.ChangeOrderIndirectLaborType>}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getIndirectlaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderIndirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderIndirectLaborType, 6));
};


/**
 * @param {!Array<!proto.ChangeOrderIndirectLaborType>} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setIndirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ChangeOrderIndirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderIndirectLaborType}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.addIndirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ChangeOrderIndirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearIndirectlaborList = function() {
  return this.setIndirectlaborList([]);
};


/**
 * repeated ChangeOrderEquipmentType equipmentExpense = 7;
 * @return {!Array<!proto.ChangeOrderEquipmentType>}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getEquipmentexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderEquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderEquipmentType, 7));
};


/**
 * @param {!Array<!proto.ChangeOrderEquipmentType>} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setEquipmentexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.ChangeOrderEquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderEquipmentType}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.addEquipmentexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.ChangeOrderEquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearEquipmentexpenseList = function() {
  return this.setEquipmentexpenseList([]);
};


/**
 * repeated ChangeOrderGeneralExpenseType generalExpense = 8;
 * @return {!Array<!proto.ChangeOrderGeneralExpenseType>}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getGeneralexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderGeneralExpenseType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderGeneralExpenseType, 8));
};


/**
 * @param {!Array<!proto.ChangeOrderGeneralExpenseType>} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setGeneralexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.ChangeOrderGeneralExpenseType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderGeneralExpenseType}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.addGeneralexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.ChangeOrderGeneralExpenseType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearGeneralexpenseList = function() {
  return this.setGeneralexpenseList([]);
};


/**
 * repeated ChangeOrderSubcontractType subcontractExpense = 9;
 * @return {!Array<!proto.ChangeOrderSubcontractType>}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getSubcontractexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderSubcontractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderSubcontractType, 9));
};


/**
 * @param {!Array<!proto.ChangeOrderSubcontractType>} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setSubcontractexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.ChangeOrderSubcontractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderSubcontractType}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.addSubcontractexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.ChangeOrderSubcontractType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearSubcontractexpenseList = function() {
  return this.setSubcontractexpenseList([]);
};


/**
 * repeated ChangeOrderQuoteType quoteExpense = 10;
 * @return {!Array<!proto.ChangeOrderQuoteType>}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getQuoteexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderQuoteType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderQuoteType, 10));
};


/**
 * @param {!Array<!proto.ChangeOrderQuoteType>} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setQuoteexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.ChangeOrderQuoteType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderQuoteType}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.addQuoteexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.ChangeOrderQuoteType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearQuoteexpenseList = function() {
  return this.setQuoteexpenseList([]);
};


/**
 * optional ChangeOrderTotalFinalRowData totalFinalPrice = 11;
 * @return {?proto.ChangeOrderTotalFinalRowData}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.getTotalfinalprice = function() {
  return /** @type{?proto.ChangeOrderTotalFinalRowData} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderTotalFinalRowData, 11));
};


/**
 * @param {?proto.ChangeOrderTotalFinalRowData|undefined} value
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
*/
proto.CreateChangeOrderCloseoutRequest.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateChangeOrderCloseoutRequest} returns this
 */
proto.CreateChangeOrderCloseoutRequest.prototype.clearTotalfinalprice = function() {
  return this.setTotalfinalprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateChangeOrderCloseoutRequest.prototype.hasTotalfinalprice = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateChangeOrderCloseoutResponse}
 */
proto.CreateChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateChangeOrderCloseoutResponse;
  return proto.CreateChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateChangeOrderCloseoutResponse}
 */
proto.CreateChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.CreateChangeOrderCloseoutResponse.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateChangeOrderCloseoutResponse} returns this
 */
proto.CreateChangeOrderCloseoutResponse.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.repeatedFields_ = [2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extensionList: jspb.Message.toObjectList(msg.getExtensionList(),
    proto.ChangeOrderExtensionType.toObject, includeInstance),
    directlaborList: jspb.Message.toObjectList(msg.getDirectlaborList(),
    proto.ChangeOrderDirectLaborType.toObject, includeInstance),
    incidentallaborList: jspb.Message.toObjectList(msg.getIncidentallaborList(),
    proto.ChangeOrderIncidentalLaborType.toObject, includeInstance),
    laborfactoringList: jspb.Message.toObjectList(msg.getLaborfactoringList(),
    proto.ChangeOrderLaborFactoringType.toObject, includeInstance),
    indirectlaborList: jspb.Message.toObjectList(msg.getIndirectlaborList(),
    proto.ChangeOrderIndirectLaborType.toObject, includeInstance),
    equipmentexpenseList: jspb.Message.toObjectList(msg.getEquipmentexpenseList(),
    proto.ChangeOrderEquipmentType.toObject, includeInstance),
    generalexpenseList: jspb.Message.toObjectList(msg.getGeneralexpenseList(),
    proto.ChangeOrderGeneralExpenseType.toObject, includeInstance),
    subcontractexpenseList: jspb.Message.toObjectList(msg.getSubcontractexpenseList(),
    proto.ChangeOrderSubcontractType.toObject, includeInstance),
    quoteexpenseList: jspb.Message.toObjectList(msg.getQuoteexpenseList(),
    proto.ChangeOrderQuoteType.toObject, includeInstance),
    totalfinalprice: (f = msg.getTotalfinalprice()) && proto.ChangeOrderTotalFinalRowData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateChangeOrderCloseoutRequest;
  return proto.CreateOrUpdateChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    case 2:
      var value = new proto.ChangeOrderExtensionType;
      reader.readMessage(value,proto.ChangeOrderExtensionType.deserializeBinaryFromReader);
      msg.addExtension$(value);
      break;
    case 3:
      var value = new proto.ChangeOrderDirectLaborType;
      reader.readMessage(value,proto.ChangeOrderDirectLaborType.deserializeBinaryFromReader);
      msg.addDirectlabor(value);
      break;
    case 4:
      var value = new proto.ChangeOrderIncidentalLaborType;
      reader.readMessage(value,proto.ChangeOrderIncidentalLaborType.deserializeBinaryFromReader);
      msg.addIncidentallabor(value);
      break;
    case 5:
      var value = new proto.ChangeOrderLaborFactoringType;
      reader.readMessage(value,proto.ChangeOrderLaborFactoringType.deserializeBinaryFromReader);
      msg.addLaborfactoring(value);
      break;
    case 6:
      var value = new proto.ChangeOrderIndirectLaborType;
      reader.readMessage(value,proto.ChangeOrderIndirectLaborType.deserializeBinaryFromReader);
      msg.addIndirectlabor(value);
      break;
    case 7:
      var value = new proto.ChangeOrderEquipmentType;
      reader.readMessage(value,proto.ChangeOrderEquipmentType.deserializeBinaryFromReader);
      msg.addEquipmentexpense(value);
      break;
    case 8:
      var value = new proto.ChangeOrderGeneralExpenseType;
      reader.readMessage(value,proto.ChangeOrderGeneralExpenseType.deserializeBinaryFromReader);
      msg.addGeneralexpense(value);
      break;
    case 9:
      var value = new proto.ChangeOrderSubcontractType;
      reader.readMessage(value,proto.ChangeOrderSubcontractType.deserializeBinaryFromReader);
      msg.addSubcontractexpense(value);
      break;
    case 10:
      var value = new proto.ChangeOrderQuoteType;
      reader.readMessage(value,proto.ChangeOrderQuoteType.deserializeBinaryFromReader);
      msg.addQuoteexpense(value);
      break;
    case 11:
      var value = new proto.ChangeOrderTotalFinalRowData;
      reader.readMessage(value,proto.ChangeOrderTotalFinalRowData.deserializeBinaryFromReader);
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ChangeOrderExtensionType.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ChangeOrderDirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ChangeOrderIncidentalLaborType.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ChangeOrderLaborFactoringType.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ChangeOrderIndirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.ChangeOrderEquipmentType.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.ChangeOrderGeneralExpenseType.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.ChangeOrderSubcontractType.serializeBinaryToWriter
    );
  }
  f = message.getQuoteexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.ChangeOrderQuoteType.serializeBinaryToWriter
    );
  }
  f = message.getTotalfinalprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.ChangeOrderTotalFinalRowData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ChangeOrderExtensionType extension = 2;
 * @return {!Array<!proto.ChangeOrderExtensionType>}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getExtensionList = function() {
  return /** @type{!Array<!proto.ChangeOrderExtensionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderExtensionType, 2));
};


/**
 * @param {!Array<!proto.ChangeOrderExtensionType>} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setExtensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ChangeOrderExtensionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderExtensionType}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.addExtension$ = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ChangeOrderExtensionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearExtensionList = function() {
  return this.setExtensionList([]);
};


/**
 * repeated ChangeOrderDirectLaborType directLabor = 3;
 * @return {!Array<!proto.ChangeOrderDirectLaborType>}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getDirectlaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderDirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderDirectLaborType, 3));
};


/**
 * @param {!Array<!proto.ChangeOrderDirectLaborType>} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setDirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ChangeOrderDirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderDirectLaborType}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.addDirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ChangeOrderDirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearDirectlaborList = function() {
  return this.setDirectlaborList([]);
};


/**
 * repeated ChangeOrderIncidentalLaborType incidentalLabor = 4;
 * @return {!Array<!proto.ChangeOrderIncidentalLaborType>}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getIncidentallaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderIncidentalLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderIncidentalLaborType, 4));
};


/**
 * @param {!Array<!proto.ChangeOrderIncidentalLaborType>} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setIncidentallaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ChangeOrderIncidentalLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderIncidentalLaborType}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.addIncidentallabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ChangeOrderIncidentalLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearIncidentallaborList = function() {
  return this.setIncidentallaborList([]);
};


/**
 * repeated ChangeOrderLaborFactoringType laborFactoring = 5;
 * @return {!Array<!proto.ChangeOrderLaborFactoringType>}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getLaborfactoringList = function() {
  return /** @type{!Array<!proto.ChangeOrderLaborFactoringType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderLaborFactoringType, 5));
};


/**
 * @param {!Array<!proto.ChangeOrderLaborFactoringType>} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setLaborfactoringList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ChangeOrderLaborFactoringType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderLaborFactoringType}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.addLaborfactoring = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ChangeOrderLaborFactoringType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearLaborfactoringList = function() {
  return this.setLaborfactoringList([]);
};


/**
 * repeated ChangeOrderIndirectLaborType indirectLabor = 6;
 * @return {!Array<!proto.ChangeOrderIndirectLaborType>}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getIndirectlaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderIndirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderIndirectLaborType, 6));
};


/**
 * @param {!Array<!proto.ChangeOrderIndirectLaborType>} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setIndirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ChangeOrderIndirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderIndirectLaborType}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.addIndirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ChangeOrderIndirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearIndirectlaborList = function() {
  return this.setIndirectlaborList([]);
};


/**
 * repeated ChangeOrderEquipmentType equipmentExpense = 7;
 * @return {!Array<!proto.ChangeOrderEquipmentType>}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getEquipmentexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderEquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderEquipmentType, 7));
};


/**
 * @param {!Array<!proto.ChangeOrderEquipmentType>} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setEquipmentexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.ChangeOrderEquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderEquipmentType}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.addEquipmentexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.ChangeOrderEquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearEquipmentexpenseList = function() {
  return this.setEquipmentexpenseList([]);
};


/**
 * repeated ChangeOrderGeneralExpenseType generalExpense = 8;
 * @return {!Array<!proto.ChangeOrderGeneralExpenseType>}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getGeneralexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderGeneralExpenseType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderGeneralExpenseType, 8));
};


/**
 * @param {!Array<!proto.ChangeOrderGeneralExpenseType>} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setGeneralexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.ChangeOrderGeneralExpenseType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderGeneralExpenseType}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.addGeneralexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.ChangeOrderGeneralExpenseType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearGeneralexpenseList = function() {
  return this.setGeneralexpenseList([]);
};


/**
 * repeated ChangeOrderSubcontractType subcontractExpense = 9;
 * @return {!Array<!proto.ChangeOrderSubcontractType>}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getSubcontractexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderSubcontractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderSubcontractType, 9));
};


/**
 * @param {!Array<!proto.ChangeOrderSubcontractType>} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setSubcontractexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.ChangeOrderSubcontractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderSubcontractType}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.addSubcontractexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.ChangeOrderSubcontractType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearSubcontractexpenseList = function() {
  return this.setSubcontractexpenseList([]);
};


/**
 * repeated ChangeOrderQuoteType quoteExpense = 10;
 * @return {!Array<!proto.ChangeOrderQuoteType>}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getQuoteexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderQuoteType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderQuoteType, 10));
};


/**
 * @param {!Array<!proto.ChangeOrderQuoteType>} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setQuoteexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.ChangeOrderQuoteType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderQuoteType}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.addQuoteexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.ChangeOrderQuoteType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearQuoteexpenseList = function() {
  return this.setQuoteexpenseList([]);
};


/**
 * optional ChangeOrderTotalFinalRowData totalFinalPrice = 11;
 * @return {?proto.ChangeOrderTotalFinalRowData}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.getTotalfinalprice = function() {
  return /** @type{?proto.ChangeOrderTotalFinalRowData} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderTotalFinalRowData, 11));
};


/**
 * @param {?proto.ChangeOrderTotalFinalRowData|undefined} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
*/
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutRequest} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.clearTotalfinalprice = function() {
  return this.setTotalfinalprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateOrUpdateChangeOrderCloseoutRequest.prototype.hasTotalfinalprice = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateOrUpdateChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateOrUpdateChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateOrUpdateChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutResponse}
 */
proto.CreateOrUpdateChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateOrUpdateChangeOrderCloseoutResponse;
  return proto.CreateOrUpdateChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateOrUpdateChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutResponse}
 */
proto.CreateOrUpdateChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateOrUpdateChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateOrUpdateChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateOrUpdateChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateOrUpdateChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.CreateOrUpdateChangeOrderCloseoutResponse.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateOrUpdateChangeOrderCloseoutResponse} returns this
 */
proto.CreateOrUpdateChangeOrderCloseoutResponse.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetChangeOrderCloseoutRequest}
 */
proto.GetChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetChangeOrderCloseoutRequest;
  return proto.GetChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetChangeOrderCloseoutRequest}
 */
proto.GetChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.GetChangeOrderCloseoutRequest.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetChangeOrderCloseoutRequest} returns this
 */
proto.GetChangeOrderCloseoutRequest.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeordercloseout: (f = msg.getChangeordercloseout()) && proto.ChangeOrderCloseout.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetChangeOrderCloseoutResponse}
 */
proto.GetChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetChangeOrderCloseoutResponse;
  return proto.GetChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetChangeOrderCloseoutResponse}
 */
proto.GetChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderCloseout;
      reader.readMessage(value,proto.ChangeOrderCloseout.deserializeBinaryFromReader);
      msg.setChangeordercloseout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeordercloseout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChangeOrderCloseout changeOrderCloseout = 1;
 * @return {?proto.ChangeOrderCloseout}
 */
proto.GetChangeOrderCloseoutResponse.prototype.getChangeordercloseout = function() {
  return /** @type{?proto.ChangeOrderCloseout} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderCloseout, 1));
};


/**
 * @param {?proto.ChangeOrderCloseout|undefined} value
 * @return {!proto.GetChangeOrderCloseoutResponse} returns this
*/
proto.GetChangeOrderCloseoutResponse.prototype.setChangeordercloseout = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetChangeOrderCloseoutResponse} returns this
 */
proto.GetChangeOrderCloseoutResponse.prototype.clearChangeordercloseout = function() {
  return this.setChangeordercloseout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetChangeOrderCloseoutResponse.prototype.hasChangeordercloseout = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetChangeOrderCloseoutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetChangeOrderCloseoutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetChangeOrderCloseoutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChangeOrderCloseoutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagenumber: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    pagesize: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetChangeOrderCloseoutsRequest}
 */
proto.GetChangeOrderCloseoutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetChangeOrderCloseoutsRequest;
  return proto.GetChangeOrderCloseoutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetChangeOrderCloseoutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetChangeOrderCloseoutsRequest}
 */
proto.GetChangeOrderCloseoutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPagenumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetChangeOrderCloseoutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetChangeOrderCloseoutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetChangeOrderCloseoutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChangeOrderCloseoutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagenumber();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float pageNumber = 1;
 * @return {number}
 */
proto.GetChangeOrderCloseoutsRequest.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetChangeOrderCloseoutsRequest} returns this
 */
proto.GetChangeOrderCloseoutsRequest.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float pageSize = 2;
 * @return {number}
 */
proto.GetChangeOrderCloseoutsRequest.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetChangeOrderCloseoutsRequest} returns this
 */
proto.GetChangeOrderCloseoutsRequest.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetChangeOrderCloseoutsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetChangeOrderCloseoutsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetChangeOrderCloseoutsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetChangeOrderCloseoutsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChangeOrderCloseoutsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeordercloseoutsList: jspb.Message.toObjectList(msg.getChangeordercloseoutsList(),
    proto.ChangeOrderCloseout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetChangeOrderCloseoutsResponse}
 */
proto.GetChangeOrderCloseoutsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetChangeOrderCloseoutsResponse;
  return proto.GetChangeOrderCloseoutsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetChangeOrderCloseoutsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetChangeOrderCloseoutsResponse}
 */
proto.GetChangeOrderCloseoutsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ChangeOrderCloseout;
      reader.readMessage(value,proto.ChangeOrderCloseout.deserializeBinaryFromReader);
      msg.addChangeordercloseouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetChangeOrderCloseoutsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetChangeOrderCloseoutsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetChangeOrderCloseoutsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetChangeOrderCloseoutsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeordercloseoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ChangeOrderCloseout.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChangeOrderCloseout changeOrderCloseouts = 1;
 * @return {!Array<!proto.ChangeOrderCloseout>}
 */
proto.GetChangeOrderCloseoutsResponse.prototype.getChangeordercloseoutsList = function() {
  return /** @type{!Array<!proto.ChangeOrderCloseout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderCloseout, 1));
};


/**
 * @param {!Array<!proto.ChangeOrderCloseout>} value
 * @return {!proto.GetChangeOrderCloseoutsResponse} returns this
*/
proto.GetChangeOrderCloseoutsResponse.prototype.setChangeordercloseoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ChangeOrderCloseout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderCloseout}
 */
proto.GetChangeOrderCloseoutsResponse.prototype.addChangeordercloseouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ChangeOrderCloseout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetChangeOrderCloseoutsResponse} returns this
 */
proto.GetChangeOrderCloseoutsResponse.prototype.clearChangeordercloseoutsList = function() {
  return this.setChangeordercloseoutsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateChangeOrderCloseoutRequest.repeatedFields_ = [2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extensionList: jspb.Message.toObjectList(msg.getExtensionList(),
    proto.ChangeOrderExtensionType.toObject, includeInstance),
    directlaborList: jspb.Message.toObjectList(msg.getDirectlaborList(),
    proto.ChangeOrderDirectLaborType.toObject, includeInstance),
    incidentallaborList: jspb.Message.toObjectList(msg.getIncidentallaborList(),
    proto.ChangeOrderIncidentalLaborType.toObject, includeInstance),
    laborfactoringList: jspb.Message.toObjectList(msg.getLaborfactoringList(),
    proto.ChangeOrderLaborFactoringType.toObject, includeInstance),
    indirectlaborList: jspb.Message.toObjectList(msg.getIndirectlaborList(),
    proto.ChangeOrderIndirectLaborType.toObject, includeInstance),
    equipmentexpenseList: jspb.Message.toObjectList(msg.getEquipmentexpenseList(),
    proto.ChangeOrderEquipmentType.toObject, includeInstance),
    generalexpenseList: jspb.Message.toObjectList(msg.getGeneralexpenseList(),
    proto.ChangeOrderGeneralExpenseType.toObject, includeInstance),
    subcontractexpenseList: jspb.Message.toObjectList(msg.getSubcontractexpenseList(),
    proto.ChangeOrderSubcontractType.toObject, includeInstance),
    quoteexpenseList: jspb.Message.toObjectList(msg.getQuoteexpenseList(),
    proto.ChangeOrderQuoteType.toObject, includeInstance),
    totalfinalprice: (f = msg.getTotalfinalprice()) && proto.ChangeOrderTotalFinalRowData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderCloseoutRequest}
 */
proto.UpdateChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderCloseoutRequest;
  return proto.UpdateChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderCloseoutRequest}
 */
proto.UpdateChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    case 2:
      var value = new proto.ChangeOrderExtensionType;
      reader.readMessage(value,proto.ChangeOrderExtensionType.deserializeBinaryFromReader);
      msg.addExtension$(value);
      break;
    case 3:
      var value = new proto.ChangeOrderDirectLaborType;
      reader.readMessage(value,proto.ChangeOrderDirectLaborType.deserializeBinaryFromReader);
      msg.addDirectlabor(value);
      break;
    case 4:
      var value = new proto.ChangeOrderIncidentalLaborType;
      reader.readMessage(value,proto.ChangeOrderIncidentalLaborType.deserializeBinaryFromReader);
      msg.addIncidentallabor(value);
      break;
    case 5:
      var value = new proto.ChangeOrderLaborFactoringType;
      reader.readMessage(value,proto.ChangeOrderLaborFactoringType.deserializeBinaryFromReader);
      msg.addLaborfactoring(value);
      break;
    case 6:
      var value = new proto.ChangeOrderIndirectLaborType;
      reader.readMessage(value,proto.ChangeOrderIndirectLaborType.deserializeBinaryFromReader);
      msg.addIndirectlabor(value);
      break;
    case 7:
      var value = new proto.ChangeOrderEquipmentType;
      reader.readMessage(value,proto.ChangeOrderEquipmentType.deserializeBinaryFromReader);
      msg.addEquipmentexpense(value);
      break;
    case 8:
      var value = new proto.ChangeOrderGeneralExpenseType;
      reader.readMessage(value,proto.ChangeOrderGeneralExpenseType.deserializeBinaryFromReader);
      msg.addGeneralexpense(value);
      break;
    case 9:
      var value = new proto.ChangeOrderSubcontractType;
      reader.readMessage(value,proto.ChangeOrderSubcontractType.deserializeBinaryFromReader);
      msg.addSubcontractexpense(value);
      break;
    case 10:
      var value = new proto.ChangeOrderQuoteType;
      reader.readMessage(value,proto.ChangeOrderQuoteType.deserializeBinaryFromReader);
      msg.addQuoteexpense(value);
      break;
    case 11:
      var value = new proto.ChangeOrderTotalFinalRowData;
      reader.readMessage(value,proto.ChangeOrderTotalFinalRowData.deserializeBinaryFromReader);
      msg.setTotalfinalprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtensionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ChangeOrderExtensionType.serializeBinaryToWriter
    );
  }
  f = message.getDirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ChangeOrderDirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getIncidentallaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ChangeOrderIncidentalLaborType.serializeBinaryToWriter
    );
  }
  f = message.getLaborfactoringList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ChangeOrderLaborFactoringType.serializeBinaryToWriter
    );
  }
  f = message.getIndirectlaborList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ChangeOrderIndirectLaborType.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.ChangeOrderEquipmentType.serializeBinaryToWriter
    );
  }
  f = message.getGeneralexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.ChangeOrderGeneralExpenseType.serializeBinaryToWriter
    );
  }
  f = message.getSubcontractexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.ChangeOrderSubcontractType.serializeBinaryToWriter
    );
  }
  f = message.getQuoteexpenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.ChangeOrderQuoteType.serializeBinaryToWriter
    );
  }
  f = message.getTotalfinalprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.ChangeOrderTotalFinalRowData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ChangeOrderExtensionType extension = 2;
 * @return {!Array<!proto.ChangeOrderExtensionType>}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getExtensionList = function() {
  return /** @type{!Array<!proto.ChangeOrderExtensionType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderExtensionType, 2));
};


/**
 * @param {!Array<!proto.ChangeOrderExtensionType>} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setExtensionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ChangeOrderExtensionType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderExtensionType}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.addExtension$ = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ChangeOrderExtensionType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearExtensionList = function() {
  return this.setExtensionList([]);
};


/**
 * repeated ChangeOrderDirectLaborType directLabor = 3;
 * @return {!Array<!proto.ChangeOrderDirectLaborType>}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getDirectlaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderDirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderDirectLaborType, 3));
};


/**
 * @param {!Array<!proto.ChangeOrderDirectLaborType>} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setDirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ChangeOrderDirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderDirectLaborType}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.addDirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ChangeOrderDirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearDirectlaborList = function() {
  return this.setDirectlaborList([]);
};


/**
 * repeated ChangeOrderIncidentalLaborType incidentalLabor = 4;
 * @return {!Array<!proto.ChangeOrderIncidentalLaborType>}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getIncidentallaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderIncidentalLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderIncidentalLaborType, 4));
};


/**
 * @param {!Array<!proto.ChangeOrderIncidentalLaborType>} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setIncidentallaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ChangeOrderIncidentalLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderIncidentalLaborType}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.addIncidentallabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ChangeOrderIncidentalLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearIncidentallaborList = function() {
  return this.setIncidentallaborList([]);
};


/**
 * repeated ChangeOrderLaborFactoringType laborFactoring = 5;
 * @return {!Array<!proto.ChangeOrderLaborFactoringType>}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getLaborfactoringList = function() {
  return /** @type{!Array<!proto.ChangeOrderLaborFactoringType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderLaborFactoringType, 5));
};


/**
 * @param {!Array<!proto.ChangeOrderLaborFactoringType>} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setLaborfactoringList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ChangeOrderLaborFactoringType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderLaborFactoringType}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.addLaborfactoring = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ChangeOrderLaborFactoringType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearLaborfactoringList = function() {
  return this.setLaborfactoringList([]);
};


/**
 * repeated ChangeOrderIndirectLaborType indirectLabor = 6;
 * @return {!Array<!proto.ChangeOrderIndirectLaborType>}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getIndirectlaborList = function() {
  return /** @type{!Array<!proto.ChangeOrderIndirectLaborType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderIndirectLaborType, 6));
};


/**
 * @param {!Array<!proto.ChangeOrderIndirectLaborType>} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setIndirectlaborList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ChangeOrderIndirectLaborType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderIndirectLaborType}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.addIndirectlabor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ChangeOrderIndirectLaborType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearIndirectlaborList = function() {
  return this.setIndirectlaborList([]);
};


/**
 * repeated ChangeOrderEquipmentType equipmentExpense = 7;
 * @return {!Array<!proto.ChangeOrderEquipmentType>}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getEquipmentexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderEquipmentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderEquipmentType, 7));
};


/**
 * @param {!Array<!proto.ChangeOrderEquipmentType>} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setEquipmentexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.ChangeOrderEquipmentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderEquipmentType}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.addEquipmentexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.ChangeOrderEquipmentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearEquipmentexpenseList = function() {
  return this.setEquipmentexpenseList([]);
};


/**
 * repeated ChangeOrderGeneralExpenseType generalExpense = 8;
 * @return {!Array<!proto.ChangeOrderGeneralExpenseType>}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getGeneralexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderGeneralExpenseType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderGeneralExpenseType, 8));
};


/**
 * @param {!Array<!proto.ChangeOrderGeneralExpenseType>} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setGeneralexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.ChangeOrderGeneralExpenseType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderGeneralExpenseType}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.addGeneralexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.ChangeOrderGeneralExpenseType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearGeneralexpenseList = function() {
  return this.setGeneralexpenseList([]);
};


/**
 * repeated ChangeOrderSubcontractType subcontractExpense = 9;
 * @return {!Array<!proto.ChangeOrderSubcontractType>}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getSubcontractexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderSubcontractType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderSubcontractType, 9));
};


/**
 * @param {!Array<!proto.ChangeOrderSubcontractType>} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setSubcontractexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.ChangeOrderSubcontractType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderSubcontractType}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.addSubcontractexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.ChangeOrderSubcontractType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearSubcontractexpenseList = function() {
  return this.setSubcontractexpenseList([]);
};


/**
 * repeated ChangeOrderQuoteType quoteExpense = 10;
 * @return {!Array<!proto.ChangeOrderQuoteType>}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getQuoteexpenseList = function() {
  return /** @type{!Array<!proto.ChangeOrderQuoteType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChangeOrderQuoteType, 10));
};


/**
 * @param {!Array<!proto.ChangeOrderQuoteType>} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setQuoteexpenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.ChangeOrderQuoteType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChangeOrderQuoteType}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.addQuoteexpense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.ChangeOrderQuoteType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearQuoteexpenseList = function() {
  return this.setQuoteexpenseList([]);
};


/**
 * optional ChangeOrderTotalFinalRowData totalFinalPrice = 11;
 * @return {?proto.ChangeOrderTotalFinalRowData}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.getTotalfinalprice = function() {
  return /** @type{?proto.ChangeOrderTotalFinalRowData} */ (
    jspb.Message.getWrapperField(this, proto.ChangeOrderTotalFinalRowData, 11));
};


/**
 * @param {?proto.ChangeOrderTotalFinalRowData|undefined} value
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
*/
proto.UpdateChangeOrderCloseoutRequest.prototype.setTotalfinalprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateChangeOrderCloseoutRequest} returns this
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.clearTotalfinalprice = function() {
  return this.setTotalfinalprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateChangeOrderCloseoutRequest.prototype.hasTotalfinalprice = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateChangeOrderCloseoutResponse}
 */
proto.UpdateChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateChangeOrderCloseoutResponse;
  return proto.UpdateChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateChangeOrderCloseoutResponse}
 */
proto.UpdateChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.UpdateChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateChangeOrderCloseoutResponse} returns this
 */
proto.UpdateChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteChangeOrderCloseoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteChangeOrderCloseoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteChangeOrderCloseoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteChangeOrderCloseoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteChangeOrderCloseoutRequest}
 */
proto.DeleteChangeOrderCloseoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteChangeOrderCloseoutRequest;
  return proto.DeleteChangeOrderCloseoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteChangeOrderCloseoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteChangeOrderCloseoutRequest}
 */
proto.DeleteChangeOrderCloseoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteChangeOrderCloseoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteChangeOrderCloseoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteChangeOrderCloseoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteChangeOrderCloseoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.DeleteChangeOrderCloseoutRequest.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteChangeOrderCloseoutRequest} returns this
 */
proto.DeleteChangeOrderCloseoutRequest.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteChangeOrderCloseoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteChangeOrderCloseoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteChangeOrderCloseoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteChangeOrderCloseoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteChangeOrderCloseoutResponse}
 */
proto.DeleteChangeOrderCloseoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteChangeOrderCloseoutResponse;
  return proto.DeleteChangeOrderCloseoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteChangeOrderCloseoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteChangeOrderCloseoutResponse}
 */
proto.DeleteChangeOrderCloseoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteChangeOrderCloseoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteChangeOrderCloseoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteChangeOrderCloseoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteChangeOrderCloseoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.DeleteChangeOrderCloseoutResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeleteChangeOrderCloseoutResponse} returns this
 */
proto.DeleteChangeOrderCloseoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto);
