import { ColorPicker } from "./ColorPicker";
import { useUnityBuildStore } from "../../states/store";
import { mutateSelectedAssembly } from "../../api/MutateSelectedAssembly";
import { useSubmitTakeoffAssembly } from "../../hooks/useSubmitTakeoffAssembly";
import { useStore } from "zustand";
import { keepBoxInView } from "../../utils/keepBoxInView";

export const ColorAndStyleEditor = () => {
  const {
    selectedTakeoffAssembly,
    setIsEditingPointStyle,
    isEditingPointBorderColor,
    isEditingPointFillColor,
    isEditingLineColor,
    userBackgroundColor,
    editingPointX,
    editingPointY,
  } = useStore(useUnityBuildStore);

  const submitAssembly = useSubmitTakeoffAssembly();

  let pointType = selectedTakeoffAssembly?.pointtype;
  let pointSize = selectedTakeoffAssembly?.pointsize;
  let lineSize = selectedTakeoffAssembly?.linesize;
  let lineDashArray = selectedTakeoffAssembly?.linedasharray;

  //TODO: Research the types of lines and such you want and modify the arrays below
  const pointTypes = ["circle", "square"];
  const line_dash_arrays = [
    {
      name: "Solid line: ───────────",
      value: "",
    },
    { name: "Dotted line: · · · · · · · · · · · ·", value: "1,1" },

    { name: "Dash line: — — — — — ", value: "10,10" },
    { name: "Dash-Dot line: — · — · — ", value: "5,5,1,5" },
  ];

  //NOTE: If we dont get immediate and persistant feedback, then
  // useRef like we do in the ColorPicker component is the way to go

  const handleSetPointType = async (type: string) => {
    const updatedAssembly = mutateSelectedAssembly({
      isEditingPointType: true,
      assembly: selectedTakeoffAssembly,
      pointType: type,
    });

    await submitAssembly({ assembly: updatedAssembly, isUpdating: true });
  };

  const handleSetPointSize = async (size: number) => {
    const updatedAssembly = mutateSelectedAssembly({
      isEditingPointSize: true,
      assembly: selectedTakeoffAssembly,
      size: size,
    });

    await submitAssembly({ assembly: updatedAssembly, isUpdating: true });
  };

  const handleSetLineSize = async (size: number) => {
    const updatedAssembly = mutateSelectedAssembly({
      isEditingLineSize: true,
      assembly: selectedTakeoffAssembly,
      size: size,
    });

    await submitAssembly({ assembly: updatedAssembly, isUpdating: true });
  };

  const handleSetline_dash_array = async (type: string) => {
    const updatedAssembly = mutateSelectedAssembly({
      isEditinglineDashArray: true,
      assembly: selectedTakeoffAssembly,
      lineDashArray: type,
    });

    await submitAssembly({ assembly: updatedAssembly, isUpdating: true });
  };

  // --------------------------------
  // Box positioning + rendering
  // --------------------------------
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const boxWidth = 250;
  const boxHeight = 300;

  const { x: adjustedX, y: adjustedY } = keepBoxInView(
    editingPointX,
    editingPointY,
    boxWidth,
    boxHeight,
    windowWidth,
    windowHeight
  );

  return (
    <>
      <div
        onContextMenu={(event) => event.preventDefault()}
        style={{
          position: "absolute",
          top: adjustedY + 5,
          left: adjustedX + 5,
          width: boxWidth,
          backgroundColor: userBackgroundColor,
          border: "1px solid black",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          Count Shape: <br />
          <select
            onChange={(e) => handleSetPointType(e.target.value)}
            defaultValue={pointType}
          >
            {pointTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div>
          Count Size: <br />
          <input
            type="number"
            defaultValue={pointSize}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (value > 50) {
                handleSetPointSize(50);
                alert("Value cannot exceed 50");
                return;
              }
              handleSetPointSize(value);
            }}
            max={50}
          />
        </div>
        {selectedTakeoffAssembly?.assemblymeasurementtype === "length" ? (
          <>
            <div>
              Length Pattern: <br />
            </div>
            <div>
              <select
                onChange={(e) => handleSetline_dash_array(e.target.value)}
                defaultValue={lineDashArray}
              >
                {line_dash_arrays.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              Length Pattern Size: <br />
            </div>
            <div>
              <input
                type="number"
                defaultValue={lineSize}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value > 50) {
                    handleSetLineSize(50);
                    alert("Value cannot exceed 50");
                    return;
                  }
                  handleSetLineSize(value);
                }}
                max={50}
              />
            </div>
            <button
              onClick={() =>
                setIsEditingPointStyle({
                  isEditingLineColor: !isEditingLineColor,
                })
              }
              style={{
                color: isEditingLineColor ? "red" : "black",
              }}
            >
              Set Line Color
            </button>
          </>
        ) : null}

        <button
          onClick={() =>
            setIsEditingPointStyle({
              isEditingPointBorderColor: !isEditingPointBorderColor,
            })
          }
          style={{
            color: isEditingPointBorderColor ? "red" : "black",
          }}
        >
          Set Border Color
        </button>

        <button
          onClick={() =>
            setIsEditingPointStyle({
              isEditingPointFillColor: !isEditingPointFillColor,
            })
          }
          style={{
            color: isEditingPointFillColor ? "red" : "black",
          }}
        >
          Set Fill Color
        </button>
        {isEditingPointBorderColor ||
        isEditingPointFillColor ||
        isEditingLineColor ? (
          <ColorPicker />
        ) : null}
      </div>
    </>
  );
};
